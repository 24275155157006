import { mapActions } from 'vuex'
import { notification } from '@/util'

export default {
  name: 'absensi-modal',
  props: {
    isVisible: { type: Boolean },
    closed: { type: Function },
    refresh: { type: Function },
    dataAbsensiModal: { type: Object }
  },
  watch: {
    isVisible: function(newStatus) {
      newStatus === true ? this.$modal.show('absensi-modal') : this.$modal.hide('absensi-modal')
    }
  },
  methods: {
    ...mapActions(['getStudentList', 'updateSiswa']),
    searchByEmail() {
      if (this.$route.params.classType === 'international') {
        this.getStudentList({
          params: {
            page: 0,
            size: 10,
            query: this.dataAbsensiModal.email,
            status: 'INTERNATIONAL_REGISTER_CS_DAY',
            type: 'INTERNATIONAL'
          }
        }).then(res => {
          this.update(res.data[0].id)
        })
      } else {
        this.getStudentList({
          params: {
            page: 0,
            size: 10,
            query: this.dataAbsensiModal.email,
            status: 'BOOTCAMP',
            type: 'Bootcamp'
          }
        })
          .then(res => {
            this.update(res.data[0].id)
          })
          .catch(() => notification.errorMessage('Siswa gagal ditandai masuk dikarenakan history log tidak sesuai urutan!'))
      }
    },
    update(id) {
      if (this.$route.params.classType === 'international') {
        this.updateSiswa({
          studentLmsClassId: id,
          requestBody: {
            status: 'INTERNATIONAL_ATTEND_CS_DAY',
            keterangan: 'Hadir CS Day',
            type: 'INTERNATIONAL'
          }
        }).then(() => this.refresh())
      } else {
        this.updateSiswa({
          studentLmsClassId: id,
          requestBody: {
            status: 'ORIENTED',
            keterangan: 'Hadir Orientasi',
            type: 'BOOTCAMP'
          }
        }).then(() => this.refresh())
      }
    },
    exit() {
      this.closed()
    }
  }
}
