import { mapActions } from 'vuex'
import { notification } from '@/util'

export default {
  name: 'send-email-result',
  computed: {
    siswaForEmail: function() {
      return JSON.parse(sessionStorage.getItem('siswaForEmail'))
    },
    emailBody: function() {
      return {
        judul: JSON.parse(sessionStorage.getItem('emailBody')).judul,
        isi: JSON.parse(sessionStorage.getItem('emailBody')).isi
      }
    }
  },
  methods: {
    ...mapActions(['sendEmailManual']),
    send() {
      this.sendEmailManual({
        requestBody: {
          isiEmail: this.emailBody.isi,
          judulEmail: this.emailBody.judul,
          listPenerima: sessionStorage
            .getItem('siswaForEmail')
            .slice(1, sessionStorage.getItem('siswaForEmail').length - 1)
            .replaceAll('"', '')
        }
      }).then(res => res)
      notification.successMessage('Email Terkirim')
      this.$router.push({ name: 'Kirim Email' })
    }
  }
}
