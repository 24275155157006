<template>
  <div class="bg-white border">
    <div class="w-full px-5 py-3 flex justify-between items-center mdmax:px-3">
      <p class="font-bold text-base text-custom-black-1">Notifications</p>
    </div>
    <div id="notif" class="max-h-96 overflow-y-auto">
      <template v-if="notifications.length > 0">
        <div class="border-t" v-for="(data, index) in notifications" :key="index">
          <div class="flex items-start p-5 gap-5 mdmax:p-3">
            <div class="w-full mdmax:text-left">
              <p class="text-custom-black-1 text-base font-semibold">{{ data.user_name }}</p>
              <p class="text-custom-black-1 text-xs font-semibold mb-2">{{ data.user_phone }}</p>
              <div class="text-custom-black-1 text-sm max-h-11 overflow-hidden line-clamp">
                <p>{{ data.last_comment }}</p>
              </div>
              <p class="text-custom-black-1 text-xs font-medium mt-2" v-if="data.last_date">
                {{ formatDate(data.last_date) }}
              </p>
            </div>
          </div>
        </div>
      </template>
      <template v-else> <div class="border-t flex justify-center items-center py-6 text-sm text-custom-black-1">- Notification is empty -</div></template>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { converter } from '@/util'
export default {
  data() {
    return {
      counter: 1,
      size: 200
    }
  },
  computed: {
    ...mapGetters(['notifications']),
    chatsScroll() {
      return document.getElementById('notif')
    }
  },
  mounted() {
    this.actionIfOnBottom()
  },
  methods: {
    ...mapActions(['getNotifications']),
    formatDate(date) {
      return converter.dateTimeView(date)
    },
    actionIfOnBottom() {
      this.chatsScroll.onscroll = () => {
        if (this.chatsScroll.scrollTop + this.chatsScroll.offsetHeight >= this.chatsScroll.scrollHeight) this.getMoreNotification()
      }
    },
    getMoreNotification() {
      this.getNotifications({
        params: {
          page: 0,
          size: this.counter * this.size
        }
      }).then(() => this.counter++)
    }
  }
}
</script>
<style lang="scss" scoped>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
