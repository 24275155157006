import { mapActions } from 'vuex'

export default {
  name: 'update-note-modal',
  props: {
    isVisible: { type: Boolean, required: true },
    closed: { type: Function, required: true },
    selectedItem: { type: Object, required: true }
  },
  data: () => ({
    message: ''
  }),
  watch: {
    isVisible: function(newStatus) {
      newStatus ? this.$modal.show('update-note') : this.$modal.hide('update-note')
    }
  },
  methods: {
    ...mapActions(['updateNote']),
    async simpan() {
      await this.updateNote({
        id: this.selectedItem.id,
        requestBody: {
          picMessage: this.message
        }
      }).then(window.location.reload())
    }
  }
}
