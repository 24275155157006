<template>
  <button class="font-bold rounded-lg border cursor-pointer focus:outline-none" @click="handleClick" :class="[buttonSize, buttonType, buttonDisabled, buttonHide]" :disabled="disabled">
    {{ text }}
  </button>
</template>
<script>
export default {
  name: 'button-new',
  props: {
    disabled: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    text: { type: String, required: true },
    type: { type: String, default: 'primary' },
    size: { type: String, default: 'regular' },
    action: { type: Function }
  },
  data: () => ({
    buttonClass: {
      primary: 'text-white border-yellow bg-yellow hover:border-yellow-300 hover:bg-yellow-300',
      secondary: 'text-yellow border-yellow-secondary bg-yellow-secondary hover:text-yellow-300',
      tertiary: 'text-yellow border-yellow bg-transparent hover:text-yellow-300 hover:border-yellow-300'
    },
    buttonSizeClass: {
      regular: 'text-sm py-2 px-6',
      big: 'text-base py-3 px-6',
      full: 'text-base py-2 px-6 w-full',
      small: 'text-xs py-2 px-3',
      extraSmall: 'text-xs py-1 px-3'
    },
    buttonDisabledClass: {
      primary: 'border-grey-field bg-grey-field cursor-not-allowed hover:border-grey-field hover:bg-grey-field',
      secondary: 'text-grey-field border-grey-e bg-grey-e cursor-not-allowed hover:text-grey-field',
      tertiary: 'text-grey-field border-grey-field cursor-not-allowed hover:text-grey-field hover:border-grey-field'
    }
  }),
  computed: {
    buttonType() {
      return this.buttonClass[this.type]
    },
    buttonSize() {
      return this.buttonSizeClass[this.size]
    },
    buttonDisabled() {
      return this.disabled ? this.buttonDisabledClass[this.type] : ''
    },
    buttonHide() {
      return this.hide ? 'hidden' : ''
    }
  },
  methods: {
    handleClick() {
      this.action()
    }
  }
}
</script>
