import axios from 'axios'
import { endpoint } from '@/constant'
import { endpointFunction } from '@/util'

const email = {
  addEmailTemplate: requestBody => {
    const url = `${endpoint.emailTemplate}`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  deleteEmailTemplate: id => {
    const url = `${endpoint.emailTemplate}/${id}`
    return axios.delete(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllEmailTemplate: params => {
    const url = `${endpoint.emailTemplate}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getEmailTemplateDetail: id => {
    const url = `${endpoint.emailTemplate}/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getEmailTemplatePreviewByName: templateName => {
    const url = `${endpoint.emailTemplate}/name/${templateName}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getPreviewEmailByEvent: (templateName, eventId) => {
    const url = `${endpoint.emailTemplate}/name/${templateName}/event/${eventId}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getPreviewEmailByName: templateName => {
    //ga kepake
    const url = `${endpoint.emailTemplate}/name/${templateName}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  sendEmailManual: requestBody => {
    const url = `${endpoint.kirimEmail}`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateEmailTemplate: (id, requestBody) => {
    const url = `${endpoint.emailTemplate}/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  }
}

export default email
