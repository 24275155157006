<template>
  <button
    @click="handleClick"
    @focus="handleFocus"
    :class="[buttonSize, buttonType, additionalClass, { disabled: disabled }]"
    class="tooltip font-bold border-1 rounded-lg transition-all outline-none inline-flex justify-center items-center gap-2"
    :disabled="disabled"
  >
    <span v-if="tooltip.show" class="tooltiptext font-normal text-xs">{{ tooltip.text }}</span>
    <span :class="`flex-shrink-0 ${disabled ? '' : 'hover:opacity-50'}`" v-if="icon"><component :height="forIcon.height" :width="forIcon.width" :color="forIcon.color" :is="icon" /></span
    >{{ buttonText }}
  </button>
</template>
<script>
export default {
  props: {
    tooltip: {
      default: () => {
        return { show: false, position: '50%', text: 'tooltip' }
      }
    },
    forIcon: {
      default: () => {
        return { height: '24', width: '24' }
      }
    },
    icon: Function,
    buttonText: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'primary'
    },
    size: {
      type: String,
      default: 'regular'
    },
    additionalClass: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    buttonClass: {
      primary: 'bg-custom-orange-1 text-white border-custom-orange-1 hover:bg-custom-orange-2 focus:outline-none',
      secondary: 'bg-custom-orange-3 border-custom-orange-3 text-custom-orange-1 hover:text-custom-orange-2 focus:outline-none',
      tertiary: 'bg-transparent border-custom-orange-1 text-custom-orange-1 hover:text-custom-orange-2 hover:border-custom-orange-2 focus:outline-none',
      greenTertiary: 'bg-transparent border-green text-green hover:text-green-300 hover:border-green-300 focus:outline-none',
      deleteTertiary: 'bg-transparent border-system-error text-system-error hover:text-red-10 hover:border-red-10 focus:outline-none',
      delete: 'bg-system-error border-system-error text-white hover:text-white hover:border-system-error focus:outline-none'
    },
    buttonCLassSize: {
      custom: '',
      regular: 'text-sm py-2 px-6',
      big: 'text-base py-3 px-6',
      full: 'text-sm py-2 px-6 w-full',
      long: 'text-sm py-2 px-16',
      small: 'text-xs py-1 px-3',
      icon: 'text-xs p-1',
      compact: 'text-xs py-2 px-4'
    }
  }),
  computed: {
    buttonType() {
      return this.buttonClass[this.type]
    },
    buttonSize() {
      return this.buttonCLassSize[this.size]
    }
  },
  methods: {
    handleClick() {
      this.$emit('action')
    },
    handleFocus() {
      this.$emit('focus')
    }
  }
}
</script>
<style lang="scss" scoped>
.disabled {
  background-color: #d6d6d6;
  color: #fff;
  border-color: #d6d6d6;
  cursor: not-allowed;
}
.hover\:bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  // background-color: rgba(251, 174, 23, var(--tw-bg-opacity)) !important;
}
.border-1 {
  border-width: 1px !important;
}
.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  // bottom: 125%;
  // margin-left: -60px;
  left: 50%;
  display: block;
  transform: translateX(-50%);
  top: -100%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
