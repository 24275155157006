import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
const SecondTemplate = () => import(/* webpackChunkName: "second-template" */ '@/templates/SecondTemplate')
export default {
  name: 'update-event',
  components: { SecondTemplate },
  data: () => ({
    name: ['', ''],
    date: ['', ''],
    startTime: ['', ''],
    endTime: ['', ''],
    link: ['', ''],
    disable: false
  }),
  computed: {
    eventId: function() {
      return this.$route.params.id
    },
    eventName: function() {
      const text = this.$route.params.event.split('-')
      let textArr = []
      let textFix = ''
      for (let i = 0; i < text.length; i++) {
        const lowerCase = text[i]
        const camelCase = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
        textArr.push(camelCase)
      }
      for (let k = 0; k < textArr.length; k++) {
        k === textArr.length - 1 ? (textFix += `${textArr[k]}`) : (textFix += `${textArr[k]} `)
      }
      return textFix
    },
    minimalDate: function() {
      return converter.date(new Date())
    },
    minimalTime: function() {
      return converter.dateTimeRequestModelTPlus7(new Date())
    },
    forInput: function() {
      return [
        {
          label: 'Name',
          readValue: this.name[0],
          writeValue: value => (this.name[1] = value)
        },
        {
          label: 'Date',
          type: 'date',
          min: this.minimalDate,
          readValue: this.date[0],
          writeValue: value => (this.date[1] = value)
        },
        {
          label: 'Start Time',
          type: 'time',
          description: 'Time will follow GMT+7',
          readValue: this.startTime[0],
          writeValue: value => (this.startTime[1] = value)
        },
        {
          label: 'End Time',
          type: 'time',
          description: 'Time will follow GMT+7',
          readValue: this.endTime[0],
          writeValue: value => (this.endTime[1] = value)
        },
        {
          label: 'Link',
          readValue: this.link[0],
          writeValue: value => (this.link[1] = value)
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.save(),
          text: 'Save',
          type: 'primary',
          size: 'small',
          disabled: this.disable
        },
        {
          action: () => this.$router.go(-1),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        }
      ]
    }
  },
  mounted() {
    this.eventId === 'add' ? () => {} : this.getOpenDayDetail()
  },
  methods: {
    ...mapActions(['getOpenDay', 'createOpenDay', 'updateOpenDay', 'showLoading', 'hideLoading']),
    getOpenDayDetail() {
      this.showLoading()
      this.getOpenDay({
        id: this.eventId
      })
        .then(res => this.distributePayload(res))
        .catch(() => {
          notification.errorMessage('Failed to load data or data not found, please try again later!')
          this.$router.go(-1)
        })
    },
    distributePayload(res) {
      this.name = [res.name, '']
      this.date = [converter.date(res.startDateTime), '']
      this.startTime = [converter.time(res.startDateTime), '']
      this.endTime = [converter.time(res.endDateTime), '']
      this.link = [res.zoomLink, '']
      this.hideLoading()
    },
    async save() {
      this.disable = true
      const delay = ms => new Promise(res => setTimeout(res, ms))
      if (this.name[1] === '') {
        notification.errorMessage('Name is required!')
      } else if (this.date[1] === '') {
        notification.errorMessage('Date is required!')
      } else if (this.moment(this.date[1]).isBefore(this.minimalDate)) {
        notification.errorMessage('Date is invalid!')
      } else if (this.startTime[1] === '') {
        notification.errorMessage('Start time is required!')
      } else if (this.moment(converter.dateTimeRequestModelTPlus7(this.date[1] + ' ' + this.startTime[1])).isBefore(this.minimalTime)) {
        notification.errorMessage('Start time is invalid!')
      } else if (this.endTime[1] === '') {
        notification.errorMessage('End time is required!')
      } else if (this.moment(converter.dateTimeRequestModelTPlus7(this.date[1] + ' ' + this.endTime[1])).isBefore(converter.dateTimeRequestModelTPlus7(this.date[1] + ' ' + this.startTime[1]))) {
        notification.errorMessage('End time is invalid!')
      } else if (this.link[1] === '') {
        notification.errorMessage('Link is required!')
      } else {
        let save = () => {}
        let id = this.eventId
        let requestBody = {
          name: this.name[1],
          startDateTime: converter.dateTimeRequestModelTPlus7(this.date[1] + ' ' + this.startTime[1]),
          endDateTime: converter.dateTimeRequestModelTPlus7(this.date[1] + ' ' + this.endTime[1]),
          zoomLink: this.link[1]
        }
        let doWhat = 'update'
        let reason = ', this can happen because the event only has 1 hour left or something else,'
        switch (this.eventId) {
          case 'add':
            save = this.createOpenDay
            id = undefined
            requestBody.customerId = 1
            doWhat = 'create'
            reason = ','
            break
          default:
            save = this.updateOpenDay
            break
        }
        this.showLoading()
        save({ id, requestBody })
          .then(() => {
            this.hideLoading()
            notification.successMessage(`Successfully ${doWhat} ${this.eventName}!`)
            this.$router.go(-1)
          })
          .catch(() => {
            this.hideLoading()
            notification.errorMessage(`Failed to ${doWhat} ${this.eventName}${reason} please check the value and try again later!`)
          })
      }
      await delay(3500)
      this.disable = false
    }
  }
}
