<template>
  <div class="flex flex-col">
    <label for="select-with-label" class="text-sm pl-2 text-custom-black-1" :class="[optionLabelFocus, optionLabelError]">{{ labelText }}</label>
    <select
      name="select-with-label"
      class="h-10 text-sm rounded-md p-2 mt-1 border border-neutral-300 focus:border-yellow focus:outline-none"
      :class="[optionFocus, optionError, optionDisabled]"
      :errorValue="errorValue"
      :disabled="disabled"
      :readValue="readValue"
      :writeValue="writeValue"
      @focus="setFocusTrue()"
      @blur="setFocusFalse()"
      @change="handleChange"
      v-model="modelValue"
    >
      <option v-for="(item, index) in options" :key="index + Math.random()" v-bind:value="item.id">{{ item.name }}</option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'select-with-label',
  props: {
    labelText: { type: String, required: true },
    options: { type: Array },
    errorValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readValue: { type: [String, Number], required: true },
    writeValue: { type: Function, required: true }
  },
  data: () => ({
    modelValue: '',
    isFocus: false
  }),
  mounted() {
    this.modelValue = this.readValue
  },
  computed: {
    optionLabelFocus() {
      return this.isFocus ? 'text-yellow' : ''
    },
    optionLabelError() {
      return this.errorValue ? 'text-red' : ''
    },
    optionFocus() {
      return this.isFocus ? 'border-yellow' : ''
    },
    optionError() {
      return this.errorValue ? 'border-red' : ''
    },
    optionDisabled() {
      return this.disabled ? 'bg-grey-e' : ''
    }
  },
  watch: {
    readValue() {
      this.modelValue = this.readValue
    },
    modelValue: function() {
      this.writeValue(this.modelValue)
    }
  },
  methods: {
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    },
    handleChange(event) {
      this.writeValue(event.target.value)
      this.$emit('value-changed', this.modelValue)
    }
  }
}
</script>
