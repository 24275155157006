/* eslint-disable no-unused-vars */
const SiswaStatus = () => import(/* webpackChunkName: "siswa-status" */ '@/components/SiswaStatus')
const PipelineModal = () => import(/* webpackChunkName: "pipeline-modal" */ '@/components/PipelineModal')
const ManageUserModal = () => import(/* webpackChunkName: "manage-user-modal" */ '@/components/ManageUserModal')
const SetPriorityModal = () => import(/* webpackChunkName: "set-priority-modal" */ '@/components/SetPriorityModal')
const UpdateNoteModal = () => import(/* webpackChunkName: "update-note-modal" */ '@/components/UpdateNoteModal')
const SetParkirModal = () => import(/* webpackChunkName: "set-parkir-modal" */ '@/components/SetParkirModal')
const FlagDuplicateModal = () => import(/* webpackChunkName: "flag-duplicate-modal" */ '@/components/FlagDuplicateModal')
const AssignAmModal = () => import(/* webpackChunkName: "assign-am-modal" */ '@/components/AssignAmModal')
const AmModal = () => import(/* webpackChunkName: "am-modal" */ '@/components/AmModal')
import { mapActions } from 'vuex'
import TooltipTopLeft from '@/atoms/others/TooltipTopLeft.vue'
import TooltipTopRight from '@/atoms/others/TooltipTopRight.vue'

export default {
  name: 'daftar-perusahaan',
  components: {
    SiswaStatus,
    PipelineModal,
    FlagDuplicateModal,
    SetParkirModal,
    SetPriorityModal,
    UpdateNoteModal,
    AssignAmModal,
    AmModal,
    ManageUserModal,
    TooltipTopLeft,
    TooltipTopRight
  },
  data: () => ({
    searchPerusahaan: '',
    sortBy: '',
    filterByAm: '',
    filterByAmOpt: [],
    filterByStatus: '',
    sortByOpt: [
      {
        name: 'companyName',
        display: 'Nama Perusahaan'
      },
      {
        name: 'picName',
        display: 'PIC'
      },
      {
        name: 'registrationDate',
        display: 'Registration Date'
      },
      {
        name: 'tujuan',
        display: 'Tujuan'
      },
      {
        name: 'accountManager',
        display: 'Account Manager'
      },
      {
        name: 'priority',
        display: 'Priority'
      },
      {
        name: 'notes',
        display: 'Notes'
      },
      {
        name: 'pipeline',
        display: 'Pipeline'
      }
    ],
    filterStatus: [
      {
        name: 'ACTIVE',
        display: 'Aktif'
      },
      {
        name: 'INACTIVE',
        display: 'Tidak Aktif'
      }
    ],
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    activeStatusName: 'IN_PROGRESS',
    startDate: '',
    endDate: '',
    isFlagDuplicateModalVisible: false,
    isSetParkirModalVisible: false,
    isSetPriorityModalVisible: false,
    isUpdateNoteModalVisible: false,
    isAssignAmModalVisible: false,
    isManageUserModalVisible: false,
    isPipelineModalVisible: false,
    isAmModalVisible: false,
    selectedItem: {},
    importResult: { errorList: [], successList: [] },
    isLoading: true,
    perusahaanList: [],
    perusahaanListSearch: [],
    statuses: [
      {
        name: 'IN_PROGRESS',
        displayName: 'In Progress',
        siswaCount: 0
      },
      {
        name: 'NEW',
        displayName: 'Baru',
        siswaCount: 0
      }
    ],
    amName: '',
    amMitraCount: 0,
    plByMitra: []
  }),
  computed: {
    userRole: function () {
      return localStorage.getItem('user_role')
    }
  },
  watch: {
    searchPerusahaan: function (oldv) {
      if (oldv !== '') {
        this.perusahaanListSearch = this.perusahaanList.filter(perusahaan => {
          if (perusahaan.companyName !== undefined) {
            perusahaan.companyName.toLowerCase().search(oldv.toLowerCase()) !== -1
          }
        })
      }
    },
    filterByAm: function () {
      this.perusahaanListSearch = this.perusahaanList.filter(perusahaan => {
        if (perusahaan.accountManager !== undefined && this.filterByAm !== undefined) {
          perusahaan.accountManager.toLowerCase().search(this.filterByAm.toLowerCase()) === 0
        }
      })
    },
    filterByStatus: function () {
      this.perusahaanListSearch = this.perusahaanList.filter(perusahaan => perusahaan.status.search(this.filterByStatus) === 0)
    },
    sortBy: function () {
      switch (this.sortBy) {
        case 'companyName':
          this.perusahaanListSearch = this.perusahaanList.sort((a, b) =>
            a.companyName.toLowerCase() > b.companyName.toLowerCase() ? 1 : b.companyName.toLowerCase() > a.companyName.toLowerCase() ? -1 : 0
          )
          break
        case 'picName':
          this.perusahaanListSearch = this.perusahaanList.sort((a, b) => (a.picName.toLowerCase() > b.picName.toLowerCase() ? 1 : b.picName.toLowerCase() > a.picName.toLowerCase() ? -1 : 0))
          break
        case 'registrationDate':
          this.perusahaanListSearch = this.perusahaanList.sort((a, b) => (a.registrationDate < b.registrationDate ? 1 : b.registrationDate < a.registrationDate ? -1 : 0))
          break
        case 'tujuan':
          this.perusahaanListSearch = this.perusahaanList.sort((a, b) => (a.tujuan.toLowerCase() > b.tujuan.toLowerCase() ? 1 : b.tujuan.toLowerCase() > a.tujuan.toLowerCase() ? -1 : 0))
          break
        case 'accountManager':
          this.perusahaanListSearch = this.perusahaanList.sort((a, b) =>
            a.accountManager.toLowerCase() > b.accountManager.toLowerCase() ? 1 : b.accountManager.toLowerCase() > a.accountManager.toLowerCase() ? -1 : 0
          )
          break
        case 'priority':
          this.perusahaanListSearch = this.perusahaanList.sort((a, b) => (a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0))
          break
        case 'notes':
          this.perusahaanListSearch = this.perusahaanList.sort((a, b) => (a.notes.toLowerCase() > b.notes.toLowerCase() ? 1 : b.notes.toLowerCase() > a.notes.toLowerCase() ? -1 : 0))
          break
        case 'pipeline':
          this.perusahaanListSearch = this.perusahaanList.sort((a, b) => (a.pipeline.length < b.pipeline.length ? 1 : b.pipeline.length < a.pipeline.length ? -1 : 0))
          break
        default:
          break
      }
    }
  },
  mounted() {
    this.changeStatus('NEW', this.currentPage), this.renderListAm()
  },
  methods: {
    ...mapActions(['getAllPartnersPage', 'getPipelineByMitra', 'getAllPartnersByAM', 'getAllAM', 'showLoading', 'hideLoading']),
    amModal(item) {
      if (this.userRole === 'CRM_ACCOUNT_MANAGER') {
        this.amName = item
        let count = []
        count = this.perusahaanList.filter(perusahaan => {
          if (perusahaan.accountManager !== undefined && this.amName !== undefined) {
            perusahaan.accountManager.toLowerCase().search(this.amName.toLowerCase()) === 0
          }
        })
        this.amMitraCount = count.length
        count.forEach(item => {
          let data = {
            companyName: item.companyName,
            pipeline: item.pipeline
          }
          this.plByMitra.push(data)
        })
      } else if (this.userRole === 'CRM_MANAGEMENT') {
        this.amName = item
        let count = []
        count = this.perusahaanList.filter(perusahaan => {
          if (perusahaan.accountManager !== undefined && this.amName !== undefined) {
            perusahaan.accountManager.toLowerCase().search(this.amName.toLowerCase()) === 0
          }
        })
        this.amMitraCount = count.length
        count.forEach(item => {
          let data = {
            companyName: item.companyName,
            pipeline: item.pipeline
          }
          this.plByMitra.push(data)
        })
      } else {
        this.amName = ''
      }
    },
    renderListAm() {
      this.getAllAM({}).then(res => (this.filterByAmOpt = res))
    },
    getSiswas(status, currentPage) {
      this.showLoading()
      if (localStorage.getItem('user_role') === 'CRM_MANAGEMENT') {
        this.getAllPartnersPage({
          params: {
            page: currentPage,
            size: 1000,
            status
          }
        }).then(res => this.distributePayload(res.mitraLists))
      } else {
        this.getAllPartnersByAM({
          params: {
            page: currentPage,
            size: 1000,
            accountManagerId: localStorage.getItem('user_id')
          }
        }).then(res => this.distributePayload(res.mitraLists))
      }
    },
    distributePayload(res) {
      this.perusahaanList = []
      res.forEach(perusahaan => {
        let data = {
          id: perusahaan.id,
          companyName: perusahaan.companyName,
          picName: perusahaan.picName,
          picEmail: perusahaan.picEmail,
          registrationDate: perusahaan.createdDate,
          tujuan: '',
          accountManager: perusahaan.accountManagerName,
          priority: perusahaan.priority,
          notes: perusahaan.picMessage,
          pipeline: [],
          isPipelineExpanded: false,
          isActionExpanded: false,
          status: perusahaan.status
        }
        perusahaan.mitraKerjasamas.forEach(kerjasama => (data.tujuan = data.tujuan + kerjasama.name + ','))
        this.perusahaanList.push(data)
      })
      this.hideLoading()
      this.countMitra()
      this.getPipeline()
    },
    getPipeline() {
      this.perusahaanList.forEach(perusahaan => {
        this.getPipelineByMitra({ id: perusahaan.id }).then(res => {
          res.forEach(pipeline => {
            perusahaan.pipeline.push(pipeline.name)
          })
        })
      })
      this.perusahaanList = this.perusahaanList.filter(perusahaan => perusahaan.companyName !== '')
      this.isLoading = false
      this.perusahaanListSearch = this.perusahaanList
      this.amModal()
    },
    countMitra() {
      this.statuses.forEach(status => {
        this.getAllPartnersPage({
          params: {
            page: 0,
            size: 1,
            status: status.name
          }
        }).then(res => (status.siswaCount = res.totalItems))
      })
    },
    changeStatus(statusName, currentPage) {
      this.isLoading = true
      this.activeStatusName = statusName
      this.getSiswas(statusName, currentPage)
      for (let i = 0; i < 10; i++) {
        this.perusahaanList.push({
          id: '',
          companyName: '',
          picName: '',
          picEmail: '',
          registrationDate: '',
          tujuan: '',
          accountManager: '',
          priority: '',
          notes: '',
          pipeline: [],
          isPipelineExpanded: false,
          isActionExpanded: false
        })
      }
    },
    showAmModal() {
      this.isAmModalVisible = !this.isAmModalVisible
    },
    setData(item) {
      this.amModal(item)
      this.isAmModalVisible = !this.isAmModalVisible
    },
    toggleAction(item) {
      item.isActionExpanded = !item.isActionExpanded
      this.selectedItem = item
    },
    toggleFlagDuplicateModal() {
      this.isFlagDuplicateModalVisible = !this.isFlagDuplicateModalVisible
    },
    toggleSetParkirModal() {
      this.isSetParkirModalVisible = !this.isSetParkirModalVisible
    },
    toggleSetPriorityModal() {
      this.isSetPriorityModalVisible = !this.isSetPriorityModalVisible
    },
    toggleUpdateNoteModal() {
      this.isUpdateNoteModalVisible = !this.isUpdateNoteModalVisible
    },
    toggleAssignAMModal() {
      this.isAssignAmModalVisible = !this.isAssignAmModalVisible
    },
    togglePipelineModal(item) {
      this.isPipelineExpanded = !this.isPipelineExpanded
    },
    toggleManageUserModal() {
      this.isManageUserModalVisible = !this.isManageUserModalVisible
    },
    async createP(id, am) {
      await sessionStorage.setItem('assignedTo', am)
      this.$router.push('/b2b/pipeline/add/' + id)
    }
  }
}
