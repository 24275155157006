import axios from 'axios'
import { endpoint } from '@/constant'
import { endpointFunction } from '@/util'

const prakerja = {
  getAllClassPrakerja: params => {
    const url = `${endpoint.prakerja}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  }
}

export default prakerja
