import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
const SecondTemplate = () => import(/* webpackChunkName: "second-template" */ '@/templates/SecondTemplate')
export default {
  name: 'update-activity',
  components: { SecondTemplate },
  data: () => ({
    performBy: '',
    activityType: '',
    activityContent: '',
    activityOutcome: '',
    oldActivityOutcome: '',
    activityTime: '',
    createdDate: '',
    note: '',
    notes: [],
    optionsNotes: []
  }),
  computed: {
    forInput: function() {
      return [
        {
          label: 'Perform by',
          readValue: this.performBy,
          writeValue: value => (this.performBy = value),
          disabled: true
        },
        {
          mode: 'select',
          label: 'Activity',
          textLength: 15,
          options: [
            { id: 'CALL', name: 'Call' },
            { id: 'TEXT', name: 'Text' },
            { id: 'EMAIL', name: 'Email' }
          ],
          readValue: this.activityType,
          writeValue: value => (this.activityType = value),
          disabled: true
        },
        {
          label: 'Content',
          readValue: this.activityContent,
          writeValue: value => (this.activityContent = value),
          disabled: true
        },
        {
          mode: 'select',
          label: 'Outcome',
          textLength: 15,
          options: [
            { id: 'ANSWERED', name: 'Answered' },
            { id: 'UNANSWERED', name: 'Unanswered' }
          ],
          readValue: this.activityOutcome,
          writeValue: value => (this.activityOutcome = value)
        },
        {
          label: 'Activity Time',
          readValue: this.activityTime,
          writeValue: value => (this.activityTime = value),
          disabled: true
        },
        {
          label: 'Timestamp',
          readValue: this.createdDate,
          writeValue: value => (this.createdDate = value),
          disabled: true
        }
      ]
    },
    forSecondInput: function() {
      return [
        {
          mode: 'select',
          label: 'Note',
          textLength: 30,
          options: this.optionsNotes,
          readValue: this.note,
          writeValue: value => (this.note = value)
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.createNote(),
          text: 'Add note',
          type: 'secondary',
          size: 'small',
          hide: true
        },
        {
          action: () => this.createNote(),
          text: 'Add note',
          type: 'secondary',
          size: 'small'
        }
      ]
    }
  },
  watch: {
    activityOutcome: function() {
      if (this.activityOutcome !== this.oldActivityOutcome) {
        const delay = ms => new Promise(res => setTimeout(res, ms))
        this.showLoading()
        this.updateActivityOutcome({
          activityId: this.$route.params.activityId,
          requestBody: {
            engagementOutcome: this.activityOutcome
          }
        })
          .then(async () => {
            notification.successMessage('Update activity outcome successfully!')
            await delay(3500)
            this.hideLoading()
            this.getActivity()
          })
          .catch(async () => {
            notification.errorMessage('Failed to update activity outcome, please try again later!')
            await delay(3500)
            this.hideLoading()
          })
      }
    }
  },
  mounted() {
    this.getActivity()
    this.getAllNotes()
  },
  methods: {
    ...mapActions(['getActivityDetail', 'updateActivityOutcome', 'addNote', 'showLoading', 'hideLoading', 'getTempNotes']),
    goBack() {
      this.$router.go(-1)
    },
    getActivity() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.showLoading()
      this.getActivityDetail({ activityId: this.$route.params.activityId })
        .then(res => this.distributePayload(res))
        .catch(async () => {
          notification.errorMessage('Failed to load data, please try again later!')
          await delay(3500)
          this.hideLoading()
          this.goBack()
        })
    },
    getAllNotes() {
      this.getTempNotes({}).then(res => {
        for (let i = 0; i < res.length; i++) {
          this.optionsNotes.push({
            id: `${res[i].id}`,
            name: res[i].notes
          })
        }
      })
    },
    distributePayload(res) {
      this.performBy = this.forNameUserFriendly(res.performBy)
      this.activityType = res.activityType
      this.activityContent = res.content
      this.activityOutcome = res.engagementOutcome
      this.oldActivityOutcome = res.engagementOutcome
      this.activityTime = converter.dateTimeView(res.activityTime)
      this.createdDate = converter.dateTimeView(res.createdDate)
      this.notes = []
      const sortedNotes = res.notes.sort((a, b) => (a.createdDate < b.createdDate ? 1 : b.createdDate < a.createdDate ? -1 : 0))
      for (let i = 0; i < sortedNotes.length; i++) {
        this.notes.push({
          firstText: this.forNameUserFriendly(sortedNotes[i].createdBy),
          secondText: converter.dateTimeView(sortedNotes[i].createdDate),
          thirdText: sortedNotes[i].templateNotes && sortedNotes[i].templateNotes !== '' ? sortedNotes[i].templateNotes.notes : '-'
        })
      }
      this.hideLoading()
    },
    forNameUserFriendly(value) {
      const splitValue = value.split('@')[0]
      const nameArr = splitValue.split('.')
      let nameArrFix = []
      for (let i = 0; i < nameArr.length; i++) {
        nameArrFix.push(`${nameArr[i].charAt(0).toUpperCase() + nameArr[i].slice(1).toLowerCase()}`)
      }
      return nameArrFix.join(' ')
    },
    createNote() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.showLoading()
      this.addNote({
        activityId: this.$route.params.activityId,
        requestBody: {
          templateNoteId: this.note
        }
      })
        .then(() => {
          notification.successMessage('Add note successfully!')
          this.note = ''
          this.hideLoading()
          this.getActivity()
        })
        .catch(async () => {
          notification.errorMessage('Failed to add note, please try again later!')
          await delay(3500)
          this.hideLoading()
        })
    }
  }
}
