import { mapActions } from 'vuex'
import { converter } from '@/util'

export default {
  name: 'edit-jadwal',
  data: () => ({
    namaAcara: '',
    tanggalAcara: '',
    jamAcara: '',
    zoomLink: ''
  }),
  mounted() {
    this.getJadwalOrientasi()
  },
  methods: {
    ...mapActions(['getOrientationScheduleDetail', 'createOrientationSchedule', 'editOrientationSchedule', 'deleteOrientationSchedule']),
    getJadwalOrientasi() {
      if (this.$route.params.id !== 'add') {
        this.getOrientationScheduleDetail({
          id: this.$route.params.id
        }).then(res => this.distributePayload(res))
      } else {
        this.namaAcara = ''
        this.tanggalAcara = ''
        this.jamAcara = ''
        this.zoomLink = ''
      }
    },
    distributePayload(res) {
      this.namaAcara = res.namaAcara
      this.tanggalAcara = converter.date(res.tanggalAcara)
      this.jamAcara = converter.time(res.tanggalAcara)
      this.zoomLink = res.zoomLink
    },
    saveJadwal() {
      let requestBody = {
        namaAcara: this.namaAcara,
        tanggalAcara: converter.dateTimeRequestModelT(this.tanggalAcara + ' ' + this.jamAcara) + '+07:00',
        jamAcara: converter.dateTimeRequestModelT(this.tanggalAcara + ' ' + this.jamAcara) + '+07:00',
        zoomLink: this.zoomLink
      }
      if (this.$route.params.id === 'add') {
        this.createOrientationSchedule({ requestBody }).then(res => res)
      } else {
        this.editOrientationSchedule({
          id: this.$route.params.id,
          requestBody
        }).then(res => res)
      }
      this.$router.go(-1)
    },
    deleteJadwal() {
      this.deleteOrientationSchedule({
        id: this.$route.params.id
      }).then(res => res)
      this.$router.go(-1)
    }
  }
}
