import { converter } from '@/util'

export default {
  name: 'currency-input-disabled',
  props: ['value'],
  computed: {
    displayValue() {
      return converter.rupiahCurrency(this.value)
    }
  }
}
