import { mapActions } from 'vuex'
import { notification } from '@/util'
const SecondTemplate = () => import(/* webpackChunkName: "second-template" */ '@/templates/SecondTemplate')
export default {
  name: 'create-update',
  components: { SecondTemplate },
  data: () => ({
    name: '',
    program: '',
    seqNo: null,
    globalEventName: '',
    options: [],
    disable: false
  }),
  computed: {
    id: function() {
      return this.$route.params.id
    },
    customerId: function() {
      return localStorage.getItem('client_id')
    },
    settingName: function() {
      const text = this.$route.params.submenu.split('-')
      let textArr = []
      let textFix = ''
      for (let i = 0; i < text.length; i++) {
        const lowerCase = text[i]
        const camelCase = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
        textArr.push(camelCase)
      }
      for (let k = 0; k < textArr.length; k++) {
        k === textArr.length - 1 ? (textFix += `${textArr[k]}`) : (textFix += `${textArr[k]} `)
      }
      return textFix
    },
    forInput: function() {
      return [
        {
          label: 'Sequence',
          type: 'number',
          readValue: this.seqNo,
          writeValue: value => (this.seqNo = value)
        },
        {
          label: 'Name',
          readValue: this.name,
          writeValue: value => (this.name = value)
        },
        {
          label: 'Program',
          mode: 'select',
          options: this.options,
          textLength: 25,
          readValue: this.program,
          writeValue: value => (this.program = value),
          disabled: this.id !== 'add'
        },
        {
          label: 'Global Event Name',
          readValue: this.globalEventName,
          writeValue: value => (this.globalEventName = value),
          hide: this.id === 'add',
          disabled: this.id !== 'add'
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.save(),
          text: 'Save',
          type: 'primary',
          size: 'small',
          disabled: this.disable
        },
        {
          action: () => this.$router.go(-1),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        }
      ]
    }
  },
  mounted() {
    this.getProgramId()
  },
  methods: {
    ...mapActions(['getAllPrograms', 'getPipelineStage', 'createPipelineStage', 'updatePipelineStage', 'showLoading', 'hideLoading']),
    getProgramId() {
      this.options = []
      this.getAllPrograms({}).then(res => {
        res.forEach(item => {
          this.options.push({ id: item.id, name: item.name })
        })
        if (this.id !== 'add') this.getDetail()
      })
    },
    getDetail() {
      this.showLoading()
      this.getPipelineStage({
        stageId: this.id,
        customerId: this.customerId
      })
        .then(res => this.distributePayload(res))
        .catch(() => {
          notification.errorMessage('Failed to load data or data not found, please try again later!')
          this.$router.go(-1)
        })
    },
    distributePayload(res) {
      this.name = res.name
      this.program = res.program_id
      this.seqNo = res.seq_no
      this.globalEventName = res.global_event_name && res.global_event_name !== '' ? res.global_event_name : '-'
      this.hideLoading()
    },
    forGlobalEventName(name) {
      return name
        .toUpperCase()
        .split(' ')
        .join('_')
    },
    async save() {
      this.disable = true
      const delay = ms => new Promise(res => setTimeout(res, ms))
      if (!this.seqNo || this.seqNo < 1 || this.seqNo > 999) {
        notification.errorMessage('Sequence is required and must between 1-999!')
      } else if (this.name === '') {
        notification.errorMessage('Name is required!')
      } else if (this.program === '') {
        notification.errorMessage('Program is required!')
      } else {
        let save = () => {}
        let stageId = this.id
        const customerId = this.customerId
        let requestBody = {
          seq_no: this.seqNo,
          name: this.name,
          program_id: this.program,
          global_event_name: this.id === 'add' ? this.forGlobalEventName(this.name) : this.globalEventName
        }
        let doWhat = 'update'
        switch (this.id) {
          case 'add':
            save = this.createPipelineStage
            stageId = undefined
            doWhat = 'create'
            break
          default:
            save = this.updatePipelineStage
            break
        }
        this.showLoading()
        save({ stageId, customerId, requestBody })
          .then(() => {
            this.hideLoading()
            notification.successMessage(`Successfully ${doWhat} ${this.settingName}!`)
            this.$router.go(-1)
          })
          .catch(() => {
            this.hideLoading()
            notification.errorMessage(`Failed to ${doWhat} ${this.settingName}, please try again later!`)
          })
      }
      await delay(3500)
      this.disable = false
    }
  }
}
