const Modal = () => import(/* webpackChunkName: "modal" */ '@/atoms/tags/Modal')
const ButtonsGroup = () => import(/* webpackChunkName: "buttons-group" */ '@/molecules/tags/ButtonsGroup')
const FirstTemplate = () => import(/* webpackChunkName: "first-template" */ '@/templates/FirstTemplate')
const GeneralIcon = () => import(/* webpackChunkName: "general-icon" */ '@/atoms/icons/GeneralIcon')
import { mapActions, mapGetters } from 'vuex'
import { converter, notification } from '@/util'
export default {
  name: 'g2-scholarship',
  components: {
    Modal,
    ButtonsGroup,
    FirstTemplate,
    GeneralIcon,
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    ModalV2: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
  },
  data: () => ({
    //=======Upload File=======
    fileDocument: null,
    isImportModalVisible: false,
    newName: null,
    fileDocument: null,
    scholarshipSelected: "IndoDigi",
    scholarshipOptions: ["IndoDigi", "TechChamp"],
    //=========================
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    customerId: 1,
    currentWorkflowStatusId: 7,
    startDate: '',
    endDate: '',
    minimalDate: '',
    maximalDate: '',
    keyword: '',
    isModalNotes: false,
    isModalIndodigi: false,
    hide: true,
    enabled: true,
    disabled: false,
    isLoading: true,
    forNotes: {},
    awardeeList: [],
    filterSelect: [],
    forOptions: [
      {
        label: 'Status',
        options: [{ id: '', name: 'All' }]
      },
      {
        label: 'Program',
        options: [
          { id: '', name: 'All' },
          { id: 'IndoDigi', name: 'IndoDigi' },
          { id: 'TechChamp', name: 'Tech Champ' }
        ]
      }
    ],

  }),
  computed: {
    ...mapGetters(['templateCsvScholarship']),
    forFilterSelect: function () {
      let arr = []
      for (let i = 0; i < this.forOptions.length; i++) {
        arr.push({
          name: this.forOptions[i].label,
          value: this.forOptions[i].options[0].id
        })
      }
      return arr
    },
    forFilter: function () {
      return [
        {
          label: this.forOptions[0].label,
          options: this.forOptions[0].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[0].value : this.forFilterSelect[0].value,
          writeValue: response => (this.forFilterSelect[0].value = response)
        },
        {
          label: this.forOptions[1].label,
          options: this.forOptions[1].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[1].value : this.forFilterSelect[1].value,
          writeValue: response => (this.forFilterSelect[1].value = response)
        }
      ]
    },
    forInput: function () {
      return [
        {
          label: 'Start Date',
          type: 'date',
          max: this.maximalDate,
          readValue: this.startDate,
          writeValue: value => (this.startDate = value)
        },
        {
          label: 'End Date',
          type: 'date',
          max: this.maximalDate,
          min: this.minimalDate,
          disabled: this.enabled,
          readValue: this.endDate,
          writeValue: value => (this.endDate = value)
        }
      ]
    },
    buttons: function () {
      return [
        {
          action: () => { },
          text: 'Tambah',
          type: 'tertiary',
          size: 'small',
          hide: true
        },
        {
          action: () => { this.isImportModalVisible = true },
          text: 'Import',
          type: 'secondary',
          size: 'small'
        },
        {
          action: () => this.download(),
          text: this.disabled ? 'Downloading' : 'Download',
          type: 'primary',
          size: 'small',
          disabled: this.disabled
        }
      ]
    },
    firstButtons: function () {
      return [
        {
          action: () => this.filter(),
          text: 'Apply',
          type: 'primary',
          size: 'small'
        },
        {
          action: () => this.clearFilter(),
          text: 'Clear',
          type: 'tertiary',
          size: 'small',
          hide: this.hide
        }
      ]
    },
    secondButtons: function () {
      return [
        {
          action: () => this.searchAwardee(this.keyword),
          text: 'Search',
          type: 'primary',
          size: 'small'
        }
      ]
    },
    buttonsModalImport: function () {
      return [
        {
          action: () => this.hideUpload(),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.forGetUploadUrl(),
          text: 'Save',
          type: 'secondary',
          size: 'small',
          disabled: this.fileDocument === null
        }
      ]
    },
    buttonsModalNotes: function () {
      return [
        {
          action: () => (this.forNotes.programCode === 'IndoDigi' ? (this.isModalIndodigi = false) : (this.isModalNotes = false)),
          text: 'Ok',
          type: 'tertiary',
          size: 'small'
        }
      ]
    }
  },
  watch: {
    startDate: function () {
      if (this.startDate === '') {
        this.hide = true
        this.endDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate === this.maximalDate) {
        this.hide = false
        this.endDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate > this.maximalDate) {
        this.hide = false
        notification.errorMessage('Please enter the start date correctly!')
        this.minimalDate = ''
        this.endDate = ''
        this.enabled = true
      } else {
        this.hide = false
        this.enabled = false
        this.minimalDate = this.startDate
      }
    },
    forFilterSelect: {
      handler: function (newValue) {
        this.filterSelect = newValue
      },
      immediate: true
    },
    filterSelect: {
      handler: function () {
        for (let i = 0; i < this.filterSelect.length; i++) {
          for (let j = 0; j < this.forOptions.length; j++) {
            if (this.filterSelect[i].value !== this.forOptions[j].options[0].id) {
              this.hide = false
            }
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.isLoading = true
    this.maximalDate = converter.date(new Date())
    this.getStatusOptions()
  },
  methods: {
    ...mapActions(['getWorkflowOptionScholarship', 'getAwardeeList', 'downloadScholarship', 'getUploadUrl', 'updateUploadFile', 'importSchorashipDataViaCsv', 'showLoading', 'hideLoading']),
    //======Upload File=======
    openTemplate() {
      window.open(this.templateCsvScholarship)
    },
    showUpload() {
      this.isImportModalVisible = true
    },
    hideUpload() {
      this.remove()
      this.isImportModalVisible = false
    },
    onChange() {
      this.showLoading()
      const file = this.$refs.file.files[0]
      let reader = new FileReader()
      reader.onload = e => {
        if (!e.target.result.includes('data:text/csv')) {
          notification.errorMessage('Please select a CSV file!')
        } else if (e.target.result.length > this.maxFileDocument) {
          notification.errorMessage('File is larger than 5MB!')
        } else {
          this.newName = file.name
          this.typeFileDocument = file.type
          this.fileDocument = e.target.result
        }
      }
      reader.readAsDataURL(file)
      setTimeout(() => this.hideLoading(), 3500)
    },
    remove() {
      this.fileDocument = null
    },
    dragover(event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('border-custom-orange-1')) {
        event.currentTarget.classList.remove('border-gray-300')
        event.currentTarget.classList.add('border-custom-orange-1')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('border-custom-orange-1')
      event.currentTarget.classList.add('border-gray-300')
    },
    drop(event) {
      if (!this.fileDocument) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange()

        event.currentTarget.classList.add('border-gray-300')
        event.currentTarget.classList.remove('border-custom-orange-1')
      } else {
        this.showLoading()
        notification.errorMessage('Only 1 file can be upload!')
        setTimeout(() => this.hideLoading(), 2000)
      }
    },
    forGetUploadUrl() {
      this.showLoading()
      this.getUploadUrl({
        params: { contentType: this.typeFileDocument }
      }).then(
        response => this.uploadDocument(response),
        () => {
          notification.errorMessage('Request failed, please try again later!')
          setTimeout(() => this.hideLoading(), 2000)
        }
      )
    },
    uploadDocument(response) {
      let binary = atob(this.fileDocument.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: this.typeFileDocument })
      this.updateUploadFile({
        url: response.uploadUrl,
        data: blobData,
        contentType: this.typeFileDocument
      }).then(
        () => this.importDocument(response.uploadUrl.split('?')[0]),
        () => {
          notification.errorMessage('Request failed, please try again later!')
          setTimeout(() => this.hideLoading(), 2000)
        }
      )
    },
    importDocument(documentUrl) {
      const requestBody = {
        customerId: 1,
        urlFile: documentUrl,
        programCode: this.scholarshipSelected
      }

      this.importSchorashipDataViaCsv({
        requestBody
      })
        .then(() => {
          this.successActionAfterImportData()
        })
        .catch((err) => {
          if (err === 'Request failed with status code 500') {
            notification.errorMessage('Failed to import data, please try again later!')
            this.hideLoading()
          } else {
            this.successActionAfterImportData()
          }

        })

    },
    successActionAfterImportData() {
      this.isLoading = true
      setTimeout(() => {
        this.hideUpload()
        notification.successMessage('Import document successfully!')
        this.maximalDate = converter.date(new Date())
        this.getStatusOptions()
      }, 3500)
    },
    //========================
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getAllAwardee()
    },
    async download() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.disabled = true
      await this.downloadScholarship(
        this.startDate !== '' && this.endDate !== ''
          ? {
            params: {
              q: this.keyword,
              startDate: `${this.startDate} 00:00`,
              endDate: `${this.endDate} 23:59`,
              statusId: this.filterSelect.length > 0 ? `${this.filterSelect[0].value}` : '',
              programCode: this.filterSelect.length > 0 ? `${this.filterSelect[1].value}` : '',
              customerId: this.customerId
            }
          }
          : {
            params: {
              q: this.keyword,
              statusId: this.filterSelect.length > 0 ? `${this.filterSelect[0].value}` : '',
              programCode: this.filterSelect.length > 0 ? `${this.filterSelect[1].value}` : '',
              customerId: this.customerId
            }
          }
      )
        .then(res => this.createDownloadFile(res))
        .catch(() => notification.errorMessage('Download failed, please try again later!'))
      await delay(3000)
      this.disabled = false
    },
    createDownloadFile(res) {
      var fileURL = window.URL.createObjectURL(new Blob([res]))
      var fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `${this.$route.name} - ${converter.dateTimeView(new Date())}.csv`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    getStatusOptions() {
      this.showLoading()
      this.getWorkflowOptionScholarship({
        customerId: this.customerId,
        currentWorkflowStatusId: this.currentWorkflowStatusId
      })
        .then(res => {
          this.forOptions[0].options = [{ id: '', name: 'All' }]
          for (let i = 0; i < res.length; i++) {
            this.forOptions[0].options.push({
              id: `${res[i].workflowStatusToId}`,
              name: this.toUserFriendlyStatus(res[i].statusName)
            })
          }
          this.getAllAwardee()
        })
        .catch(() => notification.errorMessage('Failed to load data, please try again later!'))
    },
    getAllAwardee() {
      if (this.startDate !== '' && this.endDate === '') {
        notification.errorMessage('Please input the start date and end date!')
      } else if (this.startDate !== '' && this.endDate !== '') {
        this.showLoading()
        this.getAwardeeList({
          params: {
            page: this.currentPage,
            size: this.size,
            q: this.keyword,
            startDate: `${this.startDate} 00:00`,
            endDate: `${this.endDate} 23:59`,
            statusId: this.filterSelect.length > 0 ? `${this.filterSelect[0].value}` : '',
            programCode: this.filterSelect.length > 0 ? `${this.filterSelect[1].value}` : '',
            customerId: this.customerId,
            direction: 'DESC',
            sortBy: 'updatedDate'
          }
        }).then(res => this.distributePayload(res))
      } else {
        this.showLoading()
        this.getAwardeeList({
          params: {
            page: this.currentPage,
            size: this.size,
            q: this.keyword,
            statusId: this.filterSelect.length > 0 ? `${this.filterSelect[0].value}` : '',
            programCode: this.filterSelect.length > 0 ? `${this.filterSelect[1].value}` : '',
            customerId: this.customerId,
            direction: 'DESC',
            sortBy: 'updatedDate'
          }
        }).then(res => {
          this.distributePayload(res)
        })
      }
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getAllAwardee()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.totalItems = res.pagination.total
      this.totalPages = Math.ceil(res.pagination.total / this.size)
      this.awardeeList = []
      res.data.forEach(awardee => {
        this.awardeeList.push({
          firstQuery: awardee.id,
          firstColumn: awardee.name,
          firstColumnDesc: awardee.email,
          secondColumn: awardee.phoneNumber,
          thirdColumn: this.toUserFriendlyStatus(awardee.workflowStatus.status),
          fourthColumn: awardee.programCode === 'IndoDigi' ? 'IndoDigi' : 'Tech Champ',
          fifthColumn: this.toUserFriendlyNotes(awardee.additionalInfo),
          fifthColumnParams: JSON.stringify({
            programCode: awardee.programCode,
            name: awardee.name,
            email: awardee.email,
            submissionNumber: awardee.scholarshipSubmissionNumber,
            additionalInfo: awardee.additionalInfo
          }),
          sixthColumn: awardee.updatedDate ? this.toUserFriendlyTime('date', converter.dateTimeView(awardee.updatedDate), undefined) : '-',
          sixthColumnDesc: awardee.updatedDate ? this.toUserFriendlyTime('hour', converter.dateTimeView(awardee.updatedDate), undefined) : undefined
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    toUserFriendlyStatus(status) {
      const statusArr = status.split(' ')
      let statusArrFinal = []
      for (let i = 0; i < statusArr.length; i++) {
        let word = statusArr[i][0].toUpperCase() + statusArr[i].slice(1).toLowerCase()
        statusArrFinal.push(word)
      }
      return statusArrFinal.join(' ')
    },
    toUserFriendlyFilter(id, index) {
      let feedBack = '-'
      this.forOptions[index].options.forEach(element => {
        if (`${element.id}` === `${id}`) {
          feedBack = element.name
        }
      })
      return feedBack
    },
    toUserFriendlyNotes(data) {
      return `Identity Number: ${data.identityNumber} University: ${data.universityName}  Faculty: ${data.facultyName} Major: ${data.majorName} GPA: ${data.gpa}`
    },
    toUserFriendlyTime(time, firstText, secondText) {
      switch (time) {
        case 'date':
          if (secondText) {
            return `${firstText.slice(0, -6)} - ${secondText.slice(0, -6)}`
          } else {
            return firstText.slice(0, -6)
          }
        case 'hour':
          if (secondText) {
            return `${firstText.slice(-6)} - ${secondText.slice(-6)} (GMT+7)`
          } else {
            return `${firstText.slice(-6)} (GMT+7)`
          }
      }
    },
    viewNotes(params) {
      this.forNotes = JSON.parse(params)
      this.forNotes.programCode === 'IndoDigi' ? (this.isModalIndodigi = true) : (this.isModalNotes = true)
    },
    searchAwardee(keyword) {
      this.keyword = keyword
      this.currentPage = 0
      this.getAllAwardee()
    },
    filter() {
      if (this.endDate > this.maximalDate) {
        notification.errorMessage('Please enter the end date correctly!')
      } else if (this.startDate > this.endDate) {
        notification.errorMessage('Please enter the start date or end date correctly!')
      } else {
        this.filterSelect = this.forFilterSelect
        this.currentPage = 0
        this.getAllAwardee()
      }
    },
    clearFilter() {
      this.hide = true
      this.startDate = ''
      for (let i = 0; i < this.forFilterSelect.length; i++) {
        this.forFilterSelect[i].value = this.forOptions[i].options[0].id
      }
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getAllAwardee()
    },
    clearSearch() {
      this.keyword = ''
      this.currentPage = 0
      this.getAllAwardee()
    },
    toEditAwardee(id) {
      this.$router.push('g2-scholarship/edit/' + id)
    }
  }
}
