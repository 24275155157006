import api from '@/api'

const state = {
    waitingListData: [],
    waitingListTotal: 0,
    waitingListPages: 0
}

const actions = {
    getClassListWithTotalLeads: ({ commit }, { params }) => {
        return new Promise((resolve, reject) => {
            api.classes.getClassListWithTotalLeads(params).then(
                response => {
                    if (response?.data?.code === 200) {
                        commit('setData', response?.data?.data?.data)
                        commit('setTotal', response?.data?.data?.totalRows)
                        commit('setPages', response?.data?.data?.totalPages)
                    }
                    resolve(response?.data?.data)
                },
                error => {
                    reject(error)
                }
            )
        })
    }
}

const mutations = {
    setData(state, value) {
        state.waitingListData = value
    },
    setTotal(state, value) {
        state.waitingListTotal = value
    },
    setPages(state, value) {
        state.waitingListPages = value
    },
}

const getters = {
    waitingListData: state => {
        return state.waitingListData
    },
    waitingListTotal: state => {
        return state.waitingListTotal
    },
    waitingListPages: state => {
        return state.waitingListPages
    }
}

export default { state, getters, actions, mutations }