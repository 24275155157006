const GeneralIcon = () => import(/* webpackChunkName: "general-icon" */ '@/atoms/icons/GeneralIcon')
const UpdateStudentModal = () => import(/* webpackChunkName: "update-student-modal" */ '@/components/UpdateStudentModal')
const InfoModal = () => import(/* webpackChunkName: "info-modal" */ '@/components/InfoModal')
const Modal = () => import(/* webpackChunkName: "modal" */ '@/atoms/tags/Modal')
const ModalV2 = () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
const SelectWithLabel = () => import(/* webpackChunkName: "select-with-label" */ '@/molecules/forms/SelectWithLabel')
const InputWithLabel = () => import(/* webpackChunkName: "input-with-label" */ '@/molecules/forms/InputWithLabel')
const ButtonsGroup = () => import(/* webpackChunkName: "buttons-group" */ '@/molecules/tags/ButtonsGroup')
const FirstTemplate = () => import(/* webpackChunkName: "first-template" */ '@/templates/FirstTemplate')
import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
export default {
  name: 'bootcampv3',
  components: {
    GeneralIcon,
    UpdateStudentModal,
    InfoModal,
    Modal,
    SelectWithLabel,
    InputWithLabel,
    ButtonsGroup,
    FirstTemplate,
    ModalV2,
    Button: () => import(/* webpackChunkName: "button" */ '@/components/Button/Button')
  },
  data: () => ({
    isConfirmModalVisible: false,
    bulkData: {
      prospectIds: '',
      status: '',
      paymentMethod: '',
      description: ''
    },
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    variables: 0,
    forMaxlength: 4000,
    maxFileDocument: 5000000,
    activeClassType: 'Bootcamp',
    templateChatUuid: '',
    templateChatName: '',
    previewChatTemplate: '',
    chatVariable: '',
    broadcastMessage: '',
    selectedAdmin: '',
    programId: '',
    startDate: '',
    endDate: '',
    forEndDate: '',
    minimalDate: '',
    maximalDate: '',
    keyword: '',
    activityType: 'TEXT',
    activityContent: '',
    activityOutcome: 'UNANSWERED',
    activityNote: 'Empty',
    activityTime: '',
    typeFileDocument: '',
    emptyNotesId: '',
    newName: null,
    fileDocument: null,
    isModalSelectAdmin: false,
    isImportModalVisible: false,
    isUpdateStudentModalVisible: false,
    isInfoModalVisible: false,
    isModalActivityLog: false,
    isModalBroadcast: false,
    isCanBroadcast: false,
    hide: true,
    enabled: true,
    disabled: false,
    isLoading: true,
    canUpdate: true,
    templateChatOptions: [],
    forBulkUpdate: [],
    prospectIdsForActivityLog: [],
    selectedData: [],
    studentList: [],
    filterSelect: [],
    forOptions: [
      {
        label: 'Alliance',
        options: [{ id: '', name: 'All' }]
      },
      {
        label: 'Class Type',
        options: [{ id: '', name: 'All' }]
      },
      {
        label: 'Status',
        options: [{ id: '', name: 'All' }]
      },
      {
        label: 'Last Activity',
        options: [{ id: '', name: 'All' }]
      },
      {
        label: 'Outcome',
        options: [
          { id: '', name: 'All' },
          { id: 'ANSWERED', name: 'Answered' },
          { id: 'UNANSWERED', name: 'Unanswered' }
        ]
      },
      {
        label: 'Notes',
        options: [{ id: '', name: 'All' }]
      }
    ],
    paymentOptions: [
      {
        label: 'Upfront Cash',
        value: 'UPFRONT_CASH'
      },
      {
        label: 'Pay as you go',
        value: 'PAY_AS_YOU_GO'
      },
      {
        label: 'Student Loan',
        value: 'STUDENT_LOAN'
      },
      {
        label: 'ISA',
        value: 'ISA'
      }
    ]
  }),
  computed: {
    forUserFriendly: function() {
      return this.forOptions[2].options
    },
    forFilterSelect: function() {
      let arr = []
      for (let i = 0; i < this.forOptions.length; i++) {
        arr.push({
          name: this.forOptions[i].label,
          value: i === 2 ? [] : this.forOptions[i].options[0].id
        })
      }
      return arr
    },
    forFilter: function() {
      return [
        {
          label: this.forOptions[0].label,
          options: this.forOptions[0].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[0].value : this.forFilterSelect[0].value,
          writeValue: response => (this.forFilterSelect[0].value = response)
        },
        {
          label: this.forOptions[1].label,
          options: this.forOptions[1].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[1].value : this.forFilterSelect[1].value,
          writeValue: response => (this.forFilterSelect[1].value = response)
        },
        {
          label: this.forOptions[2].label,
          mode: 'multiple-select',
          options: this.forOptions[2].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[2].value : this.forFilterSelect[2].value,
          writeValue: response => (this.forFilterSelect[2].value = response)
        },
        {
          label: this.forOptions[3].label,
          options: this.forOptions[3].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[3].value : this.forFilterSelect[3].value,
          writeValue: response => (this.forFilterSelect[3].value = response)
        },
        {
          label: this.forOptions[4].label,
          options: this.forOptions[4].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[4].value : this.forFilterSelect[4].value,
          writeValue: response => (this.forFilterSelect[4].value = response)
        },
        {
          label: this.forOptions[5].label,
          options: this.forOptions[5].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[5].value : this.forFilterSelect[5].value,
          writeValue: response => (this.forFilterSelect[5].value = response)
        }
      ]
    },
    forInput: function() {
      return [
        {
          label: 'Start Date',
          type: 'date',
          max: this.maximalDate,
          readValue: this.startDate,
          writeValue: value => (this.startDate = value)
        },
        {
          label: 'End Date',
          type: 'date',
          max: this.maximalDate,
          min: this.minimalDate,
          disabled: this.enabled,
          readValue: this.forEndDate,
          writeValue: value => (this.forEndDate = value)
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.toEditSiswa('add', 'tambah'),
          text: 'Tambah',
          type: 'tertiary',
          size: 'small',
          hide: true
        },
        {
          action: () => this.showUpload(),
          text: 'Import',
          type: 'secondary',
          size: 'small'
        },
        {
          action: () => this.download(),
          text: this.disabled ? 'Downloading' : 'Download',
          type: 'primary',
          size: 'small',
          disabled: this.disabled
        }
      ]
    },
    firstButtons: function() {
      return [
        {
          action: () => this.filter(),
          text: 'Apply',
          type: 'primary',
          size: 'small'
        },
        {
          action: () => this.clearFilter(),
          text: 'Clear',
          type: 'tertiary',
          size: 'small',
          hide: this.hide
        }
      ]
    },
    secondButtons: function() {
      return [
        {
          action: () => this.searchStudent(this.keyword),
          text: 'Search',
          type: 'primary',
          size: 'small'
        }
      ]
    },
    thirdButtons: function() {
      return [
        {
          action: () => window.open('https://admin.g2academy.co/'),
          text: 'Open Day',
          type: 'secondary',
          size: 'regular'
        },
        {
          action: () => this.$router.push({ name: 'Diagnostic Assessment' }),
          text: 'Diagnostic Assessment',
          type: 'secondary',
          size: 'regular',
          hide: true
        },
        {
          action: () => this.$router.push({ name: 'Pra Bootcamp Payment' }),
          text: 'Bukti Pembayaran',
          type: 'secondary',
          size: 'regular',
          hide: true
        }
      ]
    },
    fourthButtons: function() {
      return [
        {
          action: () => (this.isModalSelectAdmin = true),
          text: 'Broadcast',
          size: 'regular'
        },
        {
          action: () => this.updateStudent(this.canUpdate),
          text: 'Update status',
          type: 'secondary',
          size: 'regular'
        },
        {
          action: () => this.getIdForActivityLog(),
          text: 'Add activity',
          type: 'tertiary',
          size: 'regular'
        }
      ]
    },
    buttonsBroadcast: function() {
      return [
        {
          action: () => (this.isModalBroadcast = false),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.sendChatTemplate(),
          text: 'Send',
          type: 'secondary',
          size: 'small',
          disabled: this.selectedAdmin === 'CINDY' ? !this.broadcastMessage : (!this.isCanBroadcast && this.variables > 0) || !this.templateChatUuid
        }
      ]
    },
    inputsModalActivity: function() {
      return [
        {
          mode: 'select',
          label: 'Type',
          textLength: 15,
          options: [
            { id: 'CALL', name: 'Call' },
            { id: 'TEXT', name: 'Whatsapp' },
            { id: 'EMAIL', name: 'Email' }
          ],
          readValue: this.activityType,
          writeValue: value => (this.activityType = value)
        },
        {
          mode: 'select',
          label: 'Content',
          textLength: 35,
          options: this.forOptions[3].options,
          readValue: this.activityContent,
          writeValue: value => (this.activityContent = value)
        },
        {
          mode: 'select',
          label: 'Outcome',
          textLength: 15,
          options: [
            { id: 'ANSWERED', name: 'Answered' },
            { id: 'UNANSWERED', name: 'Unanswered' }
          ],
          readValue: this.activityOutcome,
          writeValue: value => (this.activityOutcome = value)
        },
        {
          mode: 'select',
          label: 'Note',
          textLength: 30,
          options: this.forOptions[5].options,
          readValue: this.activityNote,
          writeValue: value => (this.activityNote = value)
        },
        {
          label: 'Time',
          type: 'datetime-local',
          readValue: this.activityTime,
          writeValue: value => (this.activityTime = value)
        }
      ]
    },
    selectAdmin: function() {
      return {
        label: 'Admin',
        textLength: 10,
        options: [
          { id: 'CINDY', name: 'Cindy' },
          { id: 'QISQUS', name: 'Qisqus' }
        ],
        readValue: this.selectedAdmin,
        writeValue: value => (this.selectedAdmin = value)
      }
    },
    buttonsModalImport: function() {
      return [
        {
          action: () => this.hideUpload(),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.forGetUploadUrl(),
          text: 'Save',
          type: 'secondary',
          size: 'small',
          disabled: this.fileDocument === null
        }
      ]
    },
    buttonsModalSelectAdmin: function() {
      return [
        {
          action: () => (this.isModalSelectAdmin = false),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.afterSelectAdmin(),
          text: 'Next',
          type: 'secondary',
          size: 'small',
          disabled: !this.selectedAdmin
        }
      ]
    },
    buttonsModalActivity: function() {
      return [
        {
          action: () => this.hideModalActivityLog(),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.saveActivityLog(),
          text: 'Save',
          type: 'secondary',
          size: 'small'
        }
      ]
    }
  },
  watch: {
    startDate: function() {
      if (this.startDate === '') {
        this.hide = true
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate === this.maximalDate) {
        this.hide = false
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate > this.maximalDate) {
        this.hide = false
        notification.errorMessage('Please enter the start date correctly!')
        this.minimalDate = ''
        this.forEndDate = ''
        this.enabled = true
      } else {
        this.hide = false
        this.enabled = false
        this.minimalDate = this.startDate
      }
    },
    forEndDate: function() {
      this.endDate = converter.datePlusDay(this.forEndDate, 1)
    },
    forFilterSelect: {
      handler: function(newValue) {
        this.filterSelect = newValue
      },
      immediate: true
    },
    filterSelect: {
      handler: function() {
        for (let i = 0; i < this.filterSelect.length - 1; i++) {
          for (let j = 0; j < this.forOptions.length - 1; j++) {
            if (this.filterSelect[i].value !== this.forOptions[j].options[0].id) {
              this.hide = false
            }
          }
        }
        this.filterSelect[0].value !== '' ||
        this.filterSelect[1].value !== '' ||
        this.filterSelect[2].value.length > 0 ||
        this.filterSelect[3].value !== '' ||
        this.filterSelect[4].value !== '' ||
        this.filterSelect[5].value !== ''
          ? (this.hide = false)
          : (this.hide = true)
      },
      deep: true
    },
    isModalSelectAdmin: function(value) {
      if (value) this.selectedAdmin = ''
    },
    isModalBroadcast: function(value) {
      if (value) {
        this.templateChatUuid = this.previewChatTemplate = this.templateChatName = this.chatVariable = this.broadcastMessage = ''
        this.variables = 0
      }
    },
    templateChatUuid: function(uuid) {
      this.chatVariable = ''
      if (uuid) {
        for (let i = 0; i < this.templateChatOptions.length; i++) {
          if (this.templateChatOptions[i].uuid === uuid) {
            this.previewChatTemplate = this.templateChatOptions[i].hsm_details[0].header_content
              ? `${this.templateChatOptions[i].hsm_details[0].header_content}\n\n${this.templateChatOptions[i].hsm_details[0].content}`
              : this.templateChatOptions[i].hsm_details[0].content
            this.variables = this.templateChatOptions[i].hsm_details[0].number_of_arguments
            this.templateChatName = this.templateChatOptions[i].name
          }
        }
      }
    },
    chatVariable: function(value) {
      !value || value.split(';').length !== this.variables ? (this.isCanBroadcast = false) : (this.isCanBroadcast = true)
      if (value && value.split(';').length - 1 === this.variables) {
        this.forMaxlength = value.length
      } else {
        this.forMaxlength = 4000
      }
    }
  },
  mounted() {
    this.isLoading = true
    this.maximalDate = converter.date(new Date())
    this.getProgramId()
    this.getAllAlliance()
    this.getAllContents()
    this.getAllNotes()
  },
  methods: {
    ...mapActions([
      'getProspectList',
      'getAllPrograms',
      'getAlliances',
      'getTempContents',
      'getTempNotes',
      'getClasses',
      'getTemplateChat',
      'sendBroadcastMultiple',
      'sendMessageMultiple',
      'getPipelineStages',
      'downloadProspect',
      'getUploadUrl',
      'updateUploadFile',
      'importUser',
      'updateBulkProspect',
      'addActivityBulk',
      'showLoading',
      'hideLoading'
    ]),
    closeConfirmModal() {
      this.isConfirmModalVisible = false
    },
    showConfirmModal(prospectIds, status, paymentMethod, description) {
      this.bulkData = {
        prospectIds,
        status,
        paymentMethod,
        description
      }
      this.isConfirmModalVisible = true
      this.hideUpdateStudentModal()
    },
    updateData() {
      const { prospectIds, status, paymentMethod, description } = this.bulkData
      this.closeConfirmModal()
      this.showLoading()
      let keterangan = ''
      this.toUserFriendly(status) !== 'Pending Bootcamp Payment' ? (keterangan = description) : (keterangan = `[ ${paymentMethod} ] ${description !== '' ? `- ${description}` : ''}`)
      this.updateBulkProspect({
        requestBody: {
          prospectIds,
          notes: keterangan,
          pipelineStageId: status,
          isNewBootcamp: true
        }
      })
        .then(() => {
          notification.successMessage('Data updated successfully!')
          this.hideLoading()
          this.getAllStudent()
        })
        .catch(() => {
          notification.errorMessage('Data failed to update, please try again later!')
          this.hideLoading()
        })
    },
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getAllStudent()
    },
    async download() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.disabled = true
      await this.downloadProspect(
        this.startDate !== '' && this.endDate !== ''
          ? {
              params: {
                query: this.keyword,
                queryClassType: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
                queryUtm: '',
                startDate: this.startDate,
                endDate: this.endDate,
                status: this.filterSelect.length > 0 ? `${this.filterSelect[2].value}` : '',
                lastTemplateActivityId: this.filterSelect.length > 0 ? this.filterSelect[3].value : '',
                lastActivityOutcome: this.filterSelect.length > 0 ? this.filterSelect[4].value : '',
                lastTemplateNoteId: this.filterSelect.length > 0 ? this.filterSelect[5].value : '',
                customerId: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
                programId: this.programId
              }
            }
          : {
              params: {
                query: this.keyword,
                queryClassType: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
                queryUtm: '',
                status: this.filterSelect.length > 0 ? `${this.filterSelect[2].value}` : '',
                lastTemplateActivityId: this.filterSelect.length > 0 ? this.filterSelect[3].value : '',
                lastActivityOutcome: this.filterSelect.length > 0 ? this.filterSelect[4].value : '',
                lastTemplateNoteId: this.filterSelect.length > 0 ? this.filterSelect[5].value : '',
                customerId: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
                programId: this.programId
              }
            }
      )
        .then(res => this.createDownloadFile(res))
        .catch(() => notification.errorMessage('Download failed, please try again later!'))
      await delay(3000)
      this.disabled = false
    },
    createDownloadFile(res) {
      var fileURL = window.URL.createObjectURL(new Blob([res]))
      var fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `${this.$route.name} - ${converter.dateTimeView(new Date())}.csv`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    getProgramId() {
      this.getAllPrograms({}).then(async res => {
        await res.forEach(item => {
          if (item.code === 'Bootcamp') this.programId = item.id
        })
        this.getAllClass()
        this.getAllChatTemplate()
        this.getAllStage()
        this.getAllStudent()
      })
    },
    getAllStudent() {
      if (this.startDate !== '' && this.endDate === '') {
        notification.errorMessage('Please input the start date and end date!')
      } else if (this.startDate !== '' && this.endDate !== '') {
        this.showLoading()
        this.getProspectList({
          params: {
            page: this.currentPage,
            size: this.size,
            query: this.keyword,
            queryClassType: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
            queryUtm: '',
            startDate: this.startDate,
            endDate: this.endDate,
            status: this.filterSelect.length > 0 ? `${this.filterSelect[2].value}` : '',
            lastTemplateActivityId: this.filterSelect.length > 0 ? this.filterSelect[3].value : '',
            lastActivityOutcome: this.filterSelect.length > 0 ? this.filterSelect[4].value : '',
            lastTemplateNoteId: this.filterSelect.length > 0 ? this.filterSelect[5].value : '',
            customerId: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
            programId: this.programId,
            isNewBootcamp: true
          }
        }).then(res => this.distributePayload(res))
      } else {
        this.showLoading()
        this.getProspectList({
          params: {
            page: this.currentPage,
            size: this.size,
            query: this.keyword,
            queryClassType: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
            queryUtm: '',
            status: this.filterSelect.length > 0 ? `${this.filterSelect[2].value}` : '',
            lastTemplateActivityId: this.filterSelect.length > 0 ? this.filterSelect[3].value : '',
            lastActivityOutcome: this.filterSelect.length > 0 ? this.filterSelect[4].value : '',
            lastTemplateNoteId: this.filterSelect.length > 0 ? this.filterSelect[5].value : '',
            customerId: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
            programId: this.programId,
            isNewBootcamp: true
          }
        }).then(res => {
          this.distributePayload(res)
        })
      }
    },
    getAllAlliance() {
      this.getAlliances({}).then(res => {
        this.forOptions[0].options = [{ id: '', name: 'All' }]
        for (let i = 0; i < res.length; i++) {
          this.forOptions[0].options.push({
            id: `${res[i].id}`,
            name: res[i].name
          })
        }
      })
    },
    getAllClass() {
      this.getClasses({
        params: {
          size: 100,
          page: 0,
          programId: this.programId
        }
      }).then(res => {
        this.forOptions[1].options = [{ id: '', name: 'All' }]
        for (let i = 0; i < res.data.length; i++) {
          this.forOptions[1].options.push({
            id: res.data[i].name,
            name: res.data[i].name
          })
        }
      })
    },
    getAllChatTemplate() {
      this.getTemplateChat({}).then(res => (this.templateChatOptions = res.data))
    },
    getAllStage() {
      this.getPipelineStages({ params: { programId: this.programId, customerId: localStorage.getItem('client_id'), isBootcamp: true } }).then(res => {
        this.forOptions[2].options = []
        for (let j = 0; j < res.length; j++) {
          this.forOptions[2].options.push({
            id: `${res[j].id}`,
            name: res[j].name
          })
          this.forBulkUpdate.push({
            name: `${res[j].id}`,
            display: res[j].name
          })
        }
      })
    },
    getAllContents() {
      this.getTempContents({}).then(res => {
        for (let i = 0; i < res.length; i++) {
          this.forOptions[3].options.push({
            id: `${res[i].id}`,
            name: res[i].content
          })
        }
      })
    },
    getAllNotes() {
      this.getTempNotes({}).then(res => {
        for (let i = 0; i < res.length; i++) {
          this.forOptions[5].options.push({
            id: `${res[i].id}`,
            name: res[i].notes
          })
        }
        this.emptyNotesId = this.forOptions[5].options[1].id
      })
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getAllStudent()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.studentList = []
      res.data.forEach(student => {
        this.studentList.push({
          valueParams: { id: student.studentId, fullName: student.fullName, status: student.status, phoneNumber: student.phoneNumber },
          firstQuery: student.id,
          secondQuery: student.studentId,
          firstColumn: student.fullName,
          firstColumnDesc: student.email,
          secondColumn: student.phoneNumber,
          thirdColumn: student.coreClassName ? student.coreClassName : '-',
          fourthColumn: student.classVariantName || '-',
          fifthColumn: student.paymentMethod ? this.paymentOptions.filter(payment => payment.value === student.paymentMethod)[0].label : '-',
          sixthColumn: this.toUserFriendly(student.status),
          seventhColumn: student.utmSource || '-',
          eighthColumn: student.utmCampaign || '-',
          ninthColumn: student.utmMedium || '-',
          tenthColumn: student.lastActivityContent ? student.lastActivityContent : '-',
          eleventhColumn: student.lastActivityOutcome || '-',
          twelfthColumn: student.lastActivityNotes ? student.lastActivityNotes : '-',
          thirteenthColumn: student.updatedScore ? `${student.updatedScore}` : '-',
          fourteenthColumn: student.updatedDate ? this.toUserFriendlyTime('date', converter.dateTimeView(student.updatedDate), undefined) : '-',
          fourteenthColumnDesc: student.updatedDate ? this.toUserFriendlyTime('hour', converter.dateTimeView(student.updatedDate), undefined) : undefined
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    toUserFriendly(id) {
      let status = '-'
      this.forUserFriendly.forEach(element => {
        if (element.id === id) {
          status = element.name
        }
      })
      return status
    },
    toUserFriendlyTime(time, firstText, secondText) {
      switch (time) {
        case 'date':
          if (secondText) {
            return `${firstText.slice(0, -6)} - ${secondText.slice(0, -6)}`
          } else {
            return firstText.slice(0, -6)
          }
        case 'hour':
          if (secondText) {
            return `${firstText.slice(-6)} - ${secondText.slice(-6)} (GMT+7)`
          } else {
            return `${firstText.slice(-6)} (GMT+7)`
          }
      }
    },
    afterSelectAdmin() {
      this.isModalSelectAdmin = false
      this.isModalBroadcast = true
    },
    sendChatTemplate() {
      let sendMessage = () => {}
      let payload = {}
      const chatVariableArray = this.chatVariable.split(';')
      let targets = []
      for (let i = 0; i < this.selectedData.length; i++) {
        let variables = []
        if (this.chatVariable && chatVariableArray.length > 1) {
          for (let j = 0; j < chatVariableArray.length; j++) {
            if (chatVariableArray[j] && chatVariableArray[j] === 'fullName') {
              variables.push(this.selectedData[i].fullName)
            } else if (chatVariableArray[j]) {
              variables.push(chatVariableArray[j])
            }
          }
          targets.push({
            phone_number: this.changeFormatPhoneNumber(this.selectedData[i].phoneNumber),
            variables
          })
        } else {
          if (chatVariableArray[0] && chatVariableArray[0] === 'fullName') {
            variables.push(this.selectedData[i].fullName)
          } else if (chatVariableArray[0]) {
            variables.push(chatVariableArray[0])
          }
          targets.push({
            phone_number: this.changeFormatPhoneNumber(this.selectedData[i].phoneNumber),
            variables
          })
        }
      }
      switch (this.selectedAdmin) {
        case 'QISQUS':
          sendMessage = this.sendBroadcastMultiple
          payload['name'] = this.templateChatName
          payload['template_uuid'] = this.templateChatUuid
          payload['targets'] = targets
          break
        case 'CINDY':
          sendMessage = this.sendMessageMultiple
          let receivers = []
          for (let k = 0; k < targets.length; k++) {
            receivers.push(targets[k]['phone_number'])
          }
          payload['receivers'] = receivers
          payload['message'] = this.broadcastMessage
          payload['attachment'] = ''
          break
      }
      this.showLoading()
      sendMessage({ payload })
        .then(() => {
          notification.successMessage(`Broadcast ${this.selectedAdmin === 'QISQUS' ? 'chat template' : 'message'} successfully!`)
          setTimeout(() => {
            this.isModalBroadcast = false
            this.hideLoading()
            this.getAllStudent()
          }, 3500)
        })
        .catch(() => {
          notification.errorMessage(`Failed to broadcast ${this.selectedAdmin === 'QISQUS' ? 'chat template' : 'message'}, please try again later!`)
          setTimeout(() => this.hideLoading(), 3500)
        })
    },
    changeFormatPhoneNumber(number) {
      return `62${number.substr(number.indexOf('8'))}`
    },
    onChange() {
      this.showLoading()
      const file = this.$refs.file.files[0]
      let reader = new FileReader()
      reader.onload = e => {
        if (!e.target.result.includes('data:text/csv')) {
          notification.errorMessage('Please select a CSV file!')
        } else if (e.target.result.length > this.maxFileDocument) {
          notification.errorMessage('File is larger than 5MB!')
        } else {
          this.newName = file.name
          this.typeFileDocument = file.type
          this.fileDocument = e.target.result
        }
      }
      reader.readAsDataURL(file)
      setTimeout(() => this.hideLoading(), 3500)
    },
    remove() {
      this.fileDocument = null
    },
    dragover(event) {
      event.preventDefault()
      if (!event.currentTarget.classList.contains('border-custom-orange-1')) {
        event.currentTarget.classList.remove('border-gray-300')
        event.currentTarget.classList.add('border-custom-orange-1')
      }
    },
    dragleave(event) {
      event.currentTarget.classList.remove('border-custom-orange-1')
      event.currentTarget.classList.add('border-gray-300')
    },
    drop(event) {
      if (!this.fileDocument) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange()

        event.currentTarget.classList.add('border-gray-300')
        event.currentTarget.classList.remove('border-custom-orange-1')
      } else {
        this.showLoading()
        notification.errorMessage('Only 1 file can be upload!')
        setTimeout(() => this.hideLoading(), 3500)
      }
    },
    forGetUploadUrl() {
      this.showLoading()
      this.getUploadUrl({
        params: { contentType: this.typeFileDocument }
      }).then(
        response => this.uploadDocument(response),
        () => {
          notification.errorMessage('Request failed, please try again later!')
          setTimeout(() => this.hideLoading(), 3500)
        }
      )
    },
    uploadDocument(response) {
      let binary = atob(this.fileDocument.split(',')[1])
      let array = []
      for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      let blobData = new Blob([new Uint8Array(array)], { type: this.typeFileDocument })
      this.updateUploadFile({
        url: response.uploadUrl,
        data: blobData,
        contentType: this.typeFileDocument
      }).then(
        () => this.importDocument(response.uploadUrl.split('?')[0]),
        () => {
          notification.errorMessage('Request failed, please try again later!')
          setTimeout(() => this.hideLoading(), 3500)
        }
      )
    },
    importDocument(documentUrl) {
      this.importUser({
        requestBody: {
          customerId: 1,
          originalFileName: this.newName,
          temporaryFileUrl: documentUrl
        }
      })
        .then(() => {
          notification.successMessage('Import document successfully!')
          this.hideUpload()
          this.isLoading = true
          this.maximalDate = converter.date(new Date())
          this.getProgramId()
          this.getAllAlliance()
        })
        .catch(() => notification.errorMessage('Failed to import data, please try again later!'))
      setTimeout(() => this.hideLoading(), 3500)
    },
    actionCheckbox(val) {
      this.selectedData = val
      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.selectedData[i].status !== this.selectedData[0].status) {
          this.canUpdate = false
          return
        } else {
          this.canUpdate = true
        }
      }
    },
    updateStudent(bool) {
      bool ? this.showUpdateStudentModal() : this.toggleInfoModal()
    },

    getIdForActivityLog() {
      let prospectIds = []
      for (let i = 0; i < this.selectedData.length; i++) {
        prospectIds.push(this.selectedData[i].id)
      }
      this.prospectIdsForActivityLog = prospectIds
      this.showModalActivityLog()
    },
    async saveActivityLog() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.showLoading()
      if (this.activityContent === '') {
        notification.errorMessage('Content is required! Please select one')
        // } else if (this.activityTime === '') {
        //   notification.errorMessage('Time is required!')
      } else {
        this.addActivityBulk({
          requestBody: {
            prospectIds: this.prospectIdsForActivityLog,
            activityType: this.activityType,
            engagementOutcome: this.activityOutcome,
            templateActivityId: this.activityContent,
            templateNoteId: this.activityNote === 'Empty' ? this.emptyNotesId : this.activityNote,
            activityTime: converter.dateTimeRequestModelTPlus7(this.activityTime)
          }
        })
          .then(() => {
            notification.successMessage('Add activity successfully!')
            this.hideModalActivityLog()
            this.getAllStudent()
          })
          .catch(() => notification.errorMessage('Failed to add activity, please try again later!'))
      }
      await delay(3500)
      this.hideLoading()
    },
    showUpdateStudentModal() {
      this.isUpdateStudentModalVisible = true
    },
    hideUpdateStudentModal() {
      this.isUpdateStudentModalVisible = false
    },
    toggleInfoModal() {
      this.isInfoModalVisible = !this.isInfoModalVisible
    },
    showModalActivityLog() {
      this.activityTime = converter.dateTimeFormat(new Date())
      this.isModalActivityLog = true
    },
    hideModalActivityLog() {
      this.resetInputActivityLog()
      this.isModalActivityLog = false
    },
    resetInputActivityLog() {
      this.activityType = 'TEXT'
      this.activityContent = ''
      this.activityOutcome = 'UNANSWERED'
      this.activityNote = 'Empty'
      this.activityTime = ''
    },
    showUpload() {
      this.isImportModalVisible = true
    },
    hideUpload() {
      this.remove()
      this.isImportModalVisible = false
    },
    searchStudent(keyword) {
      this.keyword = keyword
      this.currentPage = 0
      this.getAllStudent()
    },
    filter() {
      if (this.forEndDate > this.maximalDate) {
        notification.errorMessage('Please enter the end date correctly!')
      } else if (this.startDate > this.forEndDate) {
        notification.errorMessage('Please enter the start date or end date correctly!')
      } else {
        this.filterSelect = this.forFilterSelect
        this.currentPage = 0
        this.getAllStudent()
      }
    },
    clearFilter() {
      this.hide = true
      this.startDate = ''
      for (let i = 0; i < this.forFilterSelect.length; i++) {
        i === 2 ? (this.forFilterSelect[i].value = []) : (this.forFilterSelect[i].value = this.forOptions[i].options[0].id)
      }
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getAllStudent()
    },
    clearSearch() {
      this.keyword = ''
      this.currentPage = 0
      this.getAllStudent()
    },
    toEditSiswa(id, studentLmsClassId) {
      this.$router.push({
        name: 'Edit Siswa',
        params: {
          classType: 'bootcampv3',
          programId: this.programId,
          description: '0',
          id,
          studentLmsClassId,
          otherId: '0'
        }
      })
    }
  }
}
