export default {
  name: 'template-email-preview',
  props: {
    isVisible: { type: Boolean, required: true },
    title: { type: String },
    body: { type: String },
    attachment: { type: String },
    closed: { type: Function, required: true }
  },
  data: () => ({
    posterVisible: false
  }),
  watch: {
    isVisible: function(newStatus) {
      newStatus === true ? this.$modal.show('template-email-preview') : this.$modal.hide('template-email-preview')
    }
  },
  methods: {
    showPoster() {
      this.posterVisible = true
    },
    hidePoster() {
      this.posterVisible = false
    },
    hideAndClose() {
      this.hidePoster()
      this.closed()
    }
  }
}
