import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
export default {
  name: 'short-course',
  components: {
    FirstTemplate: () => import(/* webpackChunkName: "first-template" */ '@/templates/FirstTemplate')
  },
  data: () => ({
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    activeClassType: 'SHORTCOURSE',
    startDate: '',
    endDate: '',
    forEndDate: '',
    minimalDate: '',
    maximalDate: '',
    keyword: '',
    hide: true,
    enabled: true,
    disabled: false,
    isLoading: true,
    studentList: [],
    filterSelect: [],
    forOptions: [
      {
        label: 'Class Type',
        options: [{ id: '', name: 'All' }]
      },
      // {
      //   label: 'UTM',
      //   options: [
      //     { id: '', name: 'All' },
      //     { id: 'Facebook Ads', name: 'Facebook Ads' },
      //     { id: 'LinkedIn Ads', name: 'LinkedIn Ads' },
      //     { id: 'GoogleAds', name: 'Google Ads' },
      //     { id: 'Placement', name: 'Placement' },
      //     { id: 'EDM', name: 'EDM' },
      //     { id: 'Website', name: 'Website' },
      //     { id: 'blank', name: '-' }
      //   ]
      // },
      {
        label: 'Status',
        options: [{ id: '', name: 'All' }]
      }
    ]
  }),
  computed: {
    forUserFriendly: function() {
      return this.forOptions[1].options
    },
    forFilterSelect: function() {
      let arr = []
      for (let i = 0; i < this.forOptions.length; i++) {
        arr.push({
          name: this.forOptions[i].label,
          value: this.forOptions[i].options[0].id
        })
      }
      return arr
    },
    forFilter: function() {
      return [
        {
          label: this.forOptions[0].label,
          options: this.forOptions[0].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[0].value : this.forFilterSelect[0].value,
          writeValue: response => (this.forFilterSelect[0].value = response)
        },
        // {
        //   label: this.forOptions[1].label,
        //   options: this.forOptions[1].options,
        //   readValue: this.filterSelect.length > 0 ? this.filterSelect[1].value : this.forFilterSelect[1].value,
        //   writeValue: response => (this.forFilterSelect[1].value = response)
        // },
        {
          label: this.forOptions[1].label,
          options: this.forOptions[1].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[1].value : this.forFilterSelect[1].value,
          writeValue: response => (this.forFilterSelect[1].value = response)
        }
      ]
    },
    forInput: function() {
      return [
        {
          label: 'Start Date',
          type: 'date',
          max: this.maximalDate,
          readValue: this.startDate,
          writeValue: value => (this.startDate = value)
        },
        {
          label: 'End Date',
          type: 'date',
          max: this.maximalDate,
          min: this.minimalDate,
          disabled: this.enabled,
          readValue: this.forEndDate,
          writeValue: value => (this.forEndDate = value)
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.download(),
          text: this.disabled ? 'Downloading' : 'Download',
          type: 'primary',
          size: 'small',
          disabled: this.disabled
        }
      ]
    },
    firstButtons: function() {
      return [
        {
          action: () => this.filter(),
          text: 'Apply',
          type: 'primary',
          size: 'small'
        },
        {
          action: () => this.clearFilter(),
          text: 'Clear',
          type: 'tertiary',
          size: 'small',
          hide: this.hide
        }
      ]
    },
    secondButtons: function() {
      return [
        {
          action: () => this.searchStudent(this.keyword),
          text: 'Search',
          type: 'primary',
          size: 'small'
        }
      ]
    }
  },
  watch: {
    startDate: function() {
      if (this.startDate === '') {
        this.hide = true
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate === this.maximalDate) {
        this.hide = false
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate > this.maximalDate) {
        this.hide = false
        notification.errorMessage('Please enter the start date correctly!')
        this.minimalDate = ''
        this.forEndDate = ''
        this.enabled = true
      } else {
        this.hide = false
        this.enabled = false
        this.minimalDate = this.startDate
      }
    },
    forEndDate: function() {
      this.endDate = converter.datePlusDay(this.forEndDate, 1)
    },
    forFilterSelect: {
      handler: function(newValue) {
        this.filterSelect = newValue
      },
      immediate: true
    },
    filterSelect: {
      handler: function() {
        this.filterSelect.forEach(element => {
          this.forOptions.forEach(item => {
            if (element.value !== item.options[0].id) {
              this.hide = false
            }
          })
        })
      },
      deep: true
    }
  },
  mounted() {
    this.isLoading = true
    this.maximalDate = converter.date(new Date())
    this.getAllClass()
    this.getAllStage()
    this.getAllStudent()
  },
  methods: {
    ...mapActions(['getStudentList', 'getClasses', 'getStages', 'downloadCsv', 'uploadCsv', 'showLoading', 'hideLoading']),
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getAllStudent()
    },
    async download() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.disabled = true
      await this.downloadCsv(
        this.startDate !== '' && this.endDate !== ''
          ? {
              requestBody: {
                query: this.keyword,
                queryClassType: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
                // queryUtm: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
                startDate: this.startDate,
                endDate: this.endDate,
                status: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
                type: this.activeClassType
              }
            }
          : {
              requestBody: {
                query: this.keyword,
                queryClassType: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
                // queryUtm: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
                status: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
                type: this.activeClassType
              }
            }
      )
        .then(res => this.createDownloadFile(res))
        .catch(() => notification.errorMessage('Download failed, please try again later!'))
      await delay(3000)
      this.disabled = false
    },
    createDownloadFile(res) {
      var fileURL = window.URL.createObjectURL(new Blob([res]))
      var fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `${this.$route.name} - ${converter.dateTimeView(new Date())}.csv`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    getAllStudent() {
      if (this.startDate !== '' && this.endDate === '') {
        notification.errorMessage('Please input the start date and end date!')
      } else if (this.startDate !== '' && this.endDate !== '') {
        this.showLoading()
        this.getStudentList({
          params: {
            page: this.currentPage,
            size: this.size,
            query: this.keyword,
            queryClassType: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
            // queryUtm: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
            startDate: this.startDate,
            endDate: this.endDate,
            status: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
            type: this.activeClassType
          }
        }).then(res => this.distributePayload(res))
      } else {
        this.showLoading()
        this.getStudentList({
          params: {
            page: this.currentPage,
            size: this.size,
            query: this.keyword,
            queryClassType: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
            // queryUtm: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
            status: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
            type: this.activeClassType
          }
        }).then(res => {
          this.distributePayload(res)
        })
      }
    },
    getAllClass() {
      this.getClasses({
        params: {
          size: 100,
          page: 0,
          program: 'short course'
        }
      }).then(res => {
        this.forOptions[0].options = [{ id: '', name: 'All' }]
        for (let i = 0; i < res.data.length; i++) {
          this.forOptions[0].options.push({
            id: res.data[i].name,
            name: res.data[i].name
          })
        }
      })
    },
    getAllStage() {
      this.getStages({ programName: this.activeClassType }).then(res => {
        this.forOptions[1].options = [{ id: '', name: 'All' }]
        for (let j = 0; j < res.length; j++) {
          this.forOptions[1].options.push({
            id: `${res[j].id}`,
            name: res[j].name
          })
        }
      })
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getAllStudent()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.studentList = []
      res.data.forEach(student => {
        this.studentList.push({
          firstQuery: student.id,
          secondQuery: student.studentId,
          firstColumn: student.fullName,
          firstColumnDesc: student.email,
          secondColumn: student.phoneNumber,
          thirdColumn: student.coreClassName,
          fourthColumn: student.utmSource || '-',
          fifthColumn: student.utmCampaign || '-',
          sixthColumn: student.utmMedium || '-',
          seventhColumn: this.toUserFriendly(student.status),
          eighthColumn: student.updatedDate ? this.toUserFriendlyTime('date', converter.dateTimeView(student.updatedDate), undefined) : '-',
          eighthColumnDesc: student.updatedDate ? this.toUserFriendlyTime('hour', converter.dateTimeView(student.updatedDate), undefined) : undefined
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    toUserFriendly(id) {
      let status = '-'
      this.forUserFriendly.forEach(element => {
        if (element.id === id) {
          status = element.name
        }
      })
      return status
    },
    toUserFriendlyTime(time, firstText, secondText) {
      switch (time) {
        case 'date':
          if (secondText) {
            return `${firstText.slice(0, -6)} - ${secondText.slice(0, -6)}`
          } else {
            return firstText.slice(0, -6)
          }
        case 'hour':
          if (secondText) {
            return `${firstText.slice(-6)} - ${secondText.slice(-6)} (GMT+7)`
          } else {
            return `${firstText.slice(-6)} (GMT+7)`
          }
      }
    },
    searchStudent(keyword) {
      this.keyword = keyword
      this.currentPage = 0
      this.getAllStudent()
    },
    filter() {
      if (this.forEndDate > this.maximalDate) {
        notification.errorMessage('Please enter the end date correctly!')
      } else if (this.startDate > this.forEndDate) {
        notification.errorMessage('Please enter the start date or end date correctly!')
      } else {
        this.filterSelect = this.forFilterSelect
        this.currentPage = 0
        this.getAllStudent()
      }
    },
    clearFilter() {
      this.hide = true
      this.startDate = ''
      for (let i = 0; i < this.forFilterSelect.length; i++) {
        this.forFilterSelect[i].value = this.forOptions[i].options[0].id
      }
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getAllStudent()
    },
    clearSearch() {
      this.keyword = ''
      this.currentPage = 0
      this.getAllStudent()
    },
    toEditSiswa(id, studentLmsClassId) {
      this.$router.push({
        name: 'Edit Siswa',
        params: {
          classType: 'short-course',
          programId: '0',
          description: '0',
          id,
          studentLmsClassId,
          otherId: '0'
        }
      })
    }
  }
}
