<template>
  <div class="my-3 flex justify-end items-center">
    <p class="text-neutral-400 font-semibold text-sm">Rows per page:</p>
    <select class="text-sm p-2 bg-transparent cursor-pointer focus:outline-none" v-model="size">
      <option v-for="(item, index) in options" :key="index + Math.random()" v-bind:value="item">{{ item }}</option>
    </select>
  </div>
</template>
<script>
export default {
  name: 'rows-per-page',
  data: () => ({
    size: 10,
    options: [10, 25, 50, 100]
  }),
  props: {
    changeSize: { type: Function, required: true }
  },
  watch: {
    size: function() {
      this.changeSize(this.size)
    }
  }
}
</script>
