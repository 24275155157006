import { mapActions } from 'vuex'

export default {
  name: 'kir-email',
  data() {
    return {
      siswaList: [],
      statusSiswa: '',
      kelas: '',
      search: '',
      emails: [],
      selected: { item: [] },
      selectedList: []
    }
  },
  created() {
    this.getSiswaList()
  },
  methods: {
    ...mapActions(['getAllSiswa']),
    getSiswaList() {
      this.getAllSiswa({
        params: {
          page: 0,
          size: 5,
          status: 'NEW'
        }
      }).then(res => res.forEach(item => this.siswaList.push(item)))
    },
    filterProduct() {
      this.siswaList = []
      this.getAllSiswa({
        params: {
          page: 0,
          size: 5,
          status: this.statusSiswa
        }
      }).then(res => res.forEach(item => this.siswaList.push(item)))
    },
    distributePayload(res) {
      res.forEach(siswa => {
        this.siswaList.push({
          id: siswa.id,
          name: siswa.name,
          email: siswa.email,
          phoneNumber: siswa.phoneNumber
        })
      })
    },
    sendTo() {
      this.$router.push({
        path: 'tulis/' + JSON.stringify(this.selectedList)
      })
    },
    updateCheck() {
      this.selectedList = this.selected.item
    }
  },
  computed: {
    filteredList: () => {
      return this.siswaList.filter(item => {
        return item.name.match(this.search)
      })
    }
  }
}
