import { converter, notification } from '@/util'

export default {
  name: 'date-search',
  data: () => ({
    startDate: '',
    endDate: '',
    minimalDate: '',
    maximalDate: '',
    disable: true,
    forEndDate: ''
  }),
  props: {
    searchThis: { type: Function, required: true }
  },
  mounted() {
    this.maximalDate = converter.date(new Date())
  },
  watch: {
    startDate: function() {
      if (this.startDate === '') {
        this.disable = true
      } else if (this.startDate === converter.date(new Date())) {
        this.endDate = this.startDate
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.disable = true
      } else if (this.startDate > converter.date(new Date())) {
        notification.errorMessage('Please enter the start date correctly!')
        this.minimalDate = ''
        this.forEndDate = ''
        this.disable = true
      } else {
        this.disable = false
        this.minimalDate = this.startDate
      }
    },
    forEndDate: function() {
      this.endDate = converter.datePlusDay(this.forEndDate, 1)
    }
  },
  methods: {
    search() {
      if (this.forEndDate > converter.date(new Date())) {
        notification.errorMessage('Please enter the end date correctly!')
      } else if (this.startDate > this.forEndDate) {
        notification.errorMessage('Please enter the start date or end date correctly!')
      } else {
        this.searchThis(this.startDate, this.endDate)
      }
    }
  }
}
