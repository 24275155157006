import { mapActions } from 'vuex'
import { notification } from '@/util'
export default {
  name: 'top-bar',
  components: {
    GeneralIcon: () => import(/* webpackChunkName: "general-icon" */ '@/atoms/icons/GeneralIcon'),
    NotificationMenu: () => import(/* webpackChunkName: "NotificationMenu" */ '@/components/NotificationMenu'),
    InputWithLabel: () => import(/* webpackChunkName: "input-with-label" */ '@/molecules/forms/InputWithLabel'),
    Modal: () => import(/* webpackChunkName: "modal" */ '@/atoms/tags/Modal'),
    ButtonsGroup: () => import(/* webpackChunkName: "buttons-group" */ '@/molecules/tags/ButtonsGroup')
  },
  data: () => ({
    isModalAccount: false,
    pass: false,
    disabled: false,
    name: '',
    email: '',
    oldPassword: { read: '', write: '' },
    newPassword: { read: '', write: '' }
  }),
  computed: {
    inputs: function() {
      return [
        {
          label: 'Name',
          type: 'text',
          readValue: this.name,
          writeValue: value => (this.name = value),
          disabled: true
        },
        {
          label: 'Email',
          type: 'text',
          readValue: this.email,
          writeValue: value => (this.email = value),
          disabled: true
        }
      ]
    },
    passwords: function() {
      return [
        {
          label: 'Current Password',
          type: 'password',
          readValue: this.oldPassword.read,
          writeValue: value => (this.oldPassword.write = value)
        },
        {
          label: 'New Password',
          type: 'password',
          readValue: this.newPassword.read,
          writeValue: value => (this.newPassword.write = value)
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.hideModal(),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.save(),
          text: 'Save',
          type: 'secondary',
          size: 'small',
          disabled: this.disabled
        }
      ]
    }
  },
  watch: {
    'newPassword.write': function(newValue) {
      if (newValue.length >= 4) this.pass = true
    }
  },
  mounted() {
    this.name = localStorage.getItem('user_name')
    this.email = localStorage.getItem('user_email')
  },
  methods: {
    ...mapActions(['removeUserData', 'changePassword', 'showLoading', 'hideLoading']),
    forInitial(name) {
      const arr = name.split(' ')
      let newArr = []
      newArr.push(arr[0].slice(0, 1).toUpperCase())
      if (arr.length > 1) newArr.push(arr[1].slice(0, 1).toUpperCase())
      return newArr.join('')
    },
    showModal() {
      this.isModalAccount = true
    },
    hideModal() {
      this.isModalAccount = false
    },
    async save() {
      this.oldPassword.read = this.oldPassword.write
      this.newPassword.read = this.newPassword.write
      this.disabled = true
      this.showLoading()
      const delay = ms => new Promise(res => setTimeout(res, ms))
      if (this.oldPassword.write === '') {
        notification.errorMessage('Current password is required!')
      } else if (this.newPassword.write === '') {
        notification.errorMessage('New password is required!')
      } else {
        if (this.pass) {
          this.changePassword({
            requestBody: {
              currentPassword: this.oldPassword.write,
              email: this.email,
              newPassword: this.newPassword.write
            }
          })
            .then(() => {
              notification.successMessage('Change password successfully!')
              this.oldPassword = { read: '', write: '' }
              this.newPassword = { read: '', write: '' }
              this.hideModal()
            })
            .catch(res => {
              if (res.errors.error[0].slice(0, 19) === 'INVALID_CREDENTIALS') {
                notification.errorMessage('Your current password is invalid, please check the value and then try again later!')
              } else {
                notification.errorMessage('Failed to change password, please try again later!')
              }
            })
        } else {
          notification.errorMessage('Minimum 4 characters for the new password!')
        }
      }
      await delay(3500)
      this.hideLoading()
      this.disabled = false
    },
    doLogout() {
      this.removeUserData()
      this.$router.push('/login')
    }
  }
}
