/* eslint-disable no-unused-vars */
import { mapActions } from 'vuex'
import { notification } from '@/util'

export default {
  name: 'edit-siswa',
  data: () => ({
    namaPipeline: '',
    companyName: { id: '', name: '' },
    picName: '',
    studentLogs: [],
    status: '',
    projectValue: '',
    keterangan: '',
    isLoading: false,
    pipelineTypeOptions: [
      {
        name: 'Talent Hiring',
        display: 'Talent Hiring'
      },
      {
        name: 'Technology Project',
        display: 'Technology Project'
      },
      {
        name: 'Corporate Training & Bootcamp',
        display: 'Corporate Training & Bootcamp'
      }
    ],
    pipelineType: '',
    amOptions: [],
    assignedTo: ''
  }),
  computed: {
    // get class type by route ex:"/user/edit/tanpa-kelas/add" will returns "TANPA_KELAS"
    classType() {
      return this.classTypes.filter(type => type.routeName === this.$route.params.classType)[0].type
    },
    computedStatusOptions() {
      return this.$route.params.classType !== 'tanpa-kelas' ? this.statusOptions : this.classTypeOptions
    }
  },
  created() {
    this.getAMs()
    this.getMitra()
  },
  mounted() {
    this.getAssignedTo()
  },
  methods: {
    ...mapActions(['getMitraById', 'getAllAM', 'addPipeline']),
    getMitra() {
      this.isLoading = true
      this.getMitraById({ id: this.$route.params.id }).then(res => {
        this.companyName.name = res.companyName
        this.companyName.id = res.id
        this.picName = res.picName
      })
    },
    getAssignedTo() {
      this.assignedTo = sessionStorage.getItem('assignedTo')
    },
    getAMs() {
      this.isLoading = true
      this.getAllAM({}).then(res => {
        res.forEach(am => this.amOptions.push({ name: am.id, display: am.name }))
      })
    },
    createNewPipeline() {
      this.isLoading = true
      this.addPipeline({
        requestBody: {
          followUpComment: '',
          followUpStatus: '',
          mitraId: this.companyName.id,
          name: this.namaPipeline,
          progress: '0% - Created',
          projectValue: this.projectValue,
          type: this.pipelineType,
          userID: localStorage.getItem('user_id'),
          userType: localStorage.getItem('user_role')
        }
      })
        .then(() => this.$router.push('/b2b/daftar-pipeline'))
        .catch(() => {
          notification.errorMessage('Email Already Exist')
          this.isLoading = false
        })
    }
  }
}
