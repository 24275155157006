<template>
  <div class="flex justify-center items-center">
    <ul class="flex">
      <li>
        <button
          @click="previousPage(activePage)"
          :disabled="activePage === 1 || totalPages <= 1"
          class="w-8 h-8 flex justify-center items-center"
          :class="{ 'opacity-30 cursor-not-allowed': activePage === 1 || totalPages <= 1 }"
        >
          <ArrowForward direction="left" />
        </button>
      </li>
      <li v-show="totalPages <= 1">
        <button class="cursor-pointer mx-1 w-8 h-8 flex justify-center items-center rounded hover:bg-yellow bg-yellow text-white" disabled>
          {{ activePage }}
        </button>
      </li>
      <li v-show="totalPages > 1" v-for="(pageNumber, index) in displayPages" :key="index + Math.random()">
        <button
          @click="setCurrentPage(pageNumber)"
          class="cursor-pointer mx-1 px-3 min-w-8 h-8 flex justify-center items-center rounded"
          :disabled="activePage === pageNumber || pageNumber === '...'"
          :class="[
            activePage === pageNumber ? 'hover:bg-yellow bg-yellow text-white' : pageNumber === '...' ? 'bg-transparent text-yellow cursor-text' : 'bg-transparent text-yellow hover:bg-gray-300'
          ]"
        >
          {{ pageNumber }}
        </button>
      </li>
      <li>
        <button
          @click="nextPage(activePage)"
          :disabled="activePage === pages.length || totalPages <= 1"
          class="w-8 h-8 flex justify-center items-center"
          :class="{ 'opacity-30 cursor-not-allowed': activePage === pages.length || totalPages <= 1 }"
        >
          <ArrowForward direction="right" />
        </button>
      </li>
    </ul>
  </div>
</template>
<script>
const ArrowForward = () => import(/* webpackChunkName: "arrow-forward" */ '@/components/Icons/ArrowForward')
export default {
  name: 'pagination-new',
  components: { ArrowForward },
  props: {
    actualPage: { required: true },
    counter: { required: true },
    size: { required: false, default: 10 },
    totalPages: { required: false, default: 10 },
    maxVisiblePages: { required: false, default: 5 },
    currentPage: { type: Function, required: true }
  },
  data: () => ({
    displayPages: [],
    pages: [],
    activePage: 1
  }),
  computed: {
    forDisplayPages() {
      let filteredPages = this.filteredPages
      let pages = filteredPages
        ? [filteredPages[0] - 1 === 1 ? 1 : '...', ...filteredPages, filteredPages[filteredPages.length - 1] + 1 === this.totalPages - 2 ? this.totalPages - 2 : '...']
        : [...Array(this.totalPages - 2).keys()].map(page => page + 1)

      return [0, ...pages, this.totalPages - 1]
    },
    filteredPages() {
      let diff = this.maxVisiblePages / 2
      let toFilterPages = [...Array(this.totalPages).keys()].slice(2, -2)

      if (toFilterPages.length > this.maxVisiblePages) {
        let diffFirst = this.activePage - toFilterPages[0] - 1
        let diffLast = this.activePage - toFilterPages[toFilterPages.length - 1] - 1

        if (diffFirst < diff) {
          return toFilterPages.slice(0, this.maxVisiblePages)
        } else if (diffLast >= -diff) {
          return toFilterPages.slice(-this.maxVisiblePages)
        } else {
          return toFilterPages.filter(page => {
            let diffPage = this.activePage - page - 1

            return diffPage < 0 ? Math.abs(diffPage) <= diff : diffPage < diff
          })
        }
      }

      return null
    }
  },
  watch: {
    totalPages: function() {
      this.activePage = this.actualPage + 1
    },
    counter: function() {
      if (this.totalPages > 1) {
        this.activePage = this.actualPage + 1
        this.setPages()
      } else {
        this.activePage = this.actualPage + 1
      }
    }
  },
  methods: {
    setPages() {
      this.displayPages = []
      this.pages = []
      for (let i = 0; i < this.forDisplayPages.length; i++) {
        this.forDisplayPages[i] !== '...' ? this.displayPages.push(this.forDisplayPages[i] + 1) : this.displayPages.push(this.forDisplayPages[i])
      }
      for (let index = 1; index <= this.totalPages; index++) {
        this.pages.push(index)
      }
    },
    setCurrentPage(page) {
      this.activePage = page
      this.currentPage(page - 1)
    },
    nextPage(page) {
      this.activePage += 1
      this.currentPage(page)
    },
    previousPage(page) {
      this.activePage -= 1
      this.currentPage(page - 2)
    }
  }
}
</script>
