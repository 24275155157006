import { mapActions } from 'vuex'

export default {
  name: 'assign-am-modal',
  props: {
    isVisible: { type: Boolean, required: true },
    closed: { type: Function, required: true },
    selectedItem: { type: Object, required: true }
  },
  watch: {
    isVisible: function(newStatus) {
      newStatus ? this.$modal.show('assign-am') : this.$modal.hide('assign-am')
    }
  },
  mounted() {
    this.fetchAms()
  },
  data() {
    return {
      amLists: [],
      amId: ''
    }
  },
  methods: {
    ...mapActions(['updateAM', 'getAllAM']),
    async simpan() {
      await this.updateAM({
        id: this.selectedItem.id,
        params: { accountManagerId: this.amId }
      }).then(window.location.reload())
    },
    fetchAms() {
      this.getAllAM({}).then(res => this.distributePayload(res))
    },
    distributePayload(res) {
      res.forEach(am => {
        this.amLists.push({
          name: am.id,
          display: am.name
        })
      })
    }
  }
}
