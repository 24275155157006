// import { addMock, enableMocking } from '@/api-mock'
import common from './common'
import email from './email'
import event from './event'
import partner from './partner'
import prakerja from './prakerja'
import student from './student'
import classes from './classes'
import b2b from './b2b'
// if (process.env.NODE_ENV === 'development') {
//   addMock()
//   enableMocking(true)
// }

const api = {
  common,
  email,
  event,
  partner,
  prakerja,
  student,
  classes,
  b2b
}

export default api
