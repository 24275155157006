import { mapMutations, mapActions } from 'vuex'
import { converter, notification, validator } from '@/util'
import CheckboxCrm from '@/components/CheckboxCrm.vue'
import CurrencyInput from '@/components/CurrencyInput'
import CurrencyInputDisabled from '@/components/CurrencyInputDisabled'
const YesNoModal = () => import(/* webpackChunkName: "yes-no-modal" */ '@/components/YesNoModal')
const Modal = () => import(/* webpackChunkName: "modal" */ '@/atoms/tags/Modal')
const SelectWithLabel = () => import(/* webpackChunkName: "select-with-label" */ '@/molecules/forms/SelectWithLabel')
const InputWithLabel = () => import(/* webpackChunkName: "input-with-label" */ '@/molecules/forms/InputWithLabel')
const ButtonsGroup = () => import(/* webpackChunkName: "buttons-group" */ '@/molecules/tags/ButtonsGroup')
const TableList = () => import(/* webpackChunkName: "table-list" */ '@/components/TableList')
export default {
  name: 'edit-siswa',
  components: {
    ModalV2: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    CheckboxCrm,
    CurrencyInput,
    CurrencyInputDisabled,
    YesNoModal,
    Modal,
    SelectWithLabel,
    InputWithLabel,
    ButtonsGroup,
    TableList,
    Button: () => import(/* webpackChunkName: "button" */ '@/components/Button/Button')
  },
  data: () => ({
    isConfirmModalVisible: false,
    id: '',
    name: '',
    email: '',
    phoneNumber: '',
    utm: '',
    motivation: '',
    type: '',
    coreClassId: '',
    backUpCoreClassId: '',
    templateChatUuid: '',
    templateChatName: '',
    studentLogs: [],
    activities: [],
    isCanChat: 0,
    counter: 0,
    currentPage: 0,
    size: 10,
    totalItems: 0,
    totalPages: 1,
    isModalForChat: false,
    isModalActivityLog: false,
    activityType: 'TEXT',
    activityContent: '',
    activityOutcome: 'UNANSWERED',
    activityNote: 'Empty',
    activityTime: '',
    idForActivityLog: '',
    status: '',
    category: '',
    paymentType: '',
    keterangan: '',
    facebook: '-',
    instagram: '-',
    linkedin: '-',
    timestamp: '',
    selectedAdmin: '',
    emptyNotesId: '',
    isModalSelectAdmin: false,
    isYesNoModalMediumVisible: false,
    isLoading: false,
    notFound: false,
    sendEmail: true,
    disableTicketType: true,
    disable: false,
    done: false,
    forCheckStatus: false,
    ticketTypeOpt: [
      {
        name: '',
        display: 'Umum'
      },
      {
        name: 'student',
        display: 'Pelajar'
      }
    ],
    statusOptions: [
      {
        name: 'NEW',
        display: 'Baru Mendaftar Tanpa Kelas'
      },
      {
        name: 'BOOTCAMP',
        display: 'Baru Mendaftar Bootcamp'
      },
      {
        name: 'ORIENTED',
        display: 'Orientasi'
      },
      {
        name: 'WAIT_PAYMENT_LOOPS',
        display: 'Menunggu Pembayaran Loops'
      },
      {
        name: 'CONFIRM_PAYMENT_LOOPS',
        display: 'Pembayaran Loops Terkonfirmasi'
      },
      {
        name: 'WAIT_PAYMENT_PRA_BOOTCAMP',
        display: 'Menunggu Pembayaran Pramp'
      },
      {
        name: 'CONFIRM_PAYMENT_PRA_BOOTCAMP',
        display: 'Pembayaran Pramp Terkonfirmasi'
      },
      {
        name: 'WAIT_PAYMENT_BOOTCAMP',
        display: 'Menunggu Pembayaran Bootcamp'
      },
      {
        name: 'CONFIRM_PAYMENT_BOOTCAMP',
        display: 'Pembayaran Bootcamp Terkonfirmasi'
      },
      {
        name: 'NOT_PROCEED',
        display: 'Tidak Melanjutkan'
      }
    ],
    studentOptions: [
      {
        name: 'ALUMNI',
        display: 'Alumni'
      },
      {
        name: 'NON_ALUMNI',
        display: 'Non-alumni'
      }
    ],
    options: [],
    optionsContent: [],
    optionsNotes: [],
    classTypeOptions: [
      {
        name: 'BOOTCAMP',
        display: 'Bootcamp'
      }
      // {
      //   name: 'INTERNATIONAL',
      //   display: 'International'
      // }
    ],
    classTypes: [
      { routeName: 'tanpa-kelas', type: 'TANPA_KELAS' },
      { routeName: 'bootcampv2', type: 'BOOTCAMP' },
      { routeName: 'bootcampv3', type: 'BOOTCAMP' },
      { routeName: 'short-course', type: 'SHORTCOURSE' },
      { routeName: 'aws-clouducation-pre-event', type: 'clouducation-pre-event' },
      { routeName: 'aws-clouducation-main-event', type: 'clouducation-main-event' },
      { routeName: 'international', type: 'INTERNATIONAL' }
    ],
    coreClassOptions: [],
    selectedItem: [],
    receipts: [],
    isEdit: false,
    statusAlumni: '',
    amount: '',
    buktiPembayaran: '',
    paymentReceiptEventId: '',
    paymentReceiptEventDateTime: '',
    confirmedPaymentReceiptEvent: false,
    paymentOptions: [
      {
        label: 'Upfront Cash',
        value: 'UPFRONT_CASH'
      },
      {
        label: 'Pay as you go',
        value: 'PAY_AS_YOU_GO'
      },
      {
        label: 'Student Loan',
        value: 'STUDENT_LOAN'
      },
      {
        label: 'ISA',
        value: 'ISA'
      }
    ],
    selectedPayment: null
  }),
  watch: {
    type: function() {
      if (this.type === '' || this.type === undefined) {
        this.coreClassOptions = []
      } else {
        this.getAllClass()
      }
    },
    confirmedPaymentReceiptEvent: function() {
      this.getDetail()
    },
    coreClassId: async function() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      if (this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' || this.$route.params.classType === 'event') {
        this.showLoading()
        if (this.coreClassId === '') {
          notification.errorMessage(`Harap pilih ${this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' ? 'kelas' : 'event'} dengan benar!`)
          this.coreClassId = this.backUpCoreClassId
        } else if (this.coreClassId !== this.backUpCoreClassId) {
          this.updateProspectCoreClass({
            studentId: this.$route.params.studentLmsClassId,
            requestBody: { coreClassId: this.coreClassId }
          })
            .then(() => {
              notification.successMessage(`${this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' ? 'Bootcamp kelas' : 'Event'} berhasil diupdate!`)
              this.backUpCoreClassId = this.coreClassId
            })
            .catch(() => {
              notification.errorMessage(`${this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' ? 'Bootcamp kelas' : 'Event'} gagal diupdate!`)
            })
        }
        await delay(3500)
        this.hideLoading()
      }
    },
    status: function() {
      this.paymentType = ''
      if (this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' || this.$route.params.classType === 'event') this.checkStatus(this.status)
    },
    category: function() {
      this.category === 'student' ? (this.disableTicketType = false) : (this.disableTicketType = true)
    },
    isModalSelectAdmin: function(value) {
      if (value) this.selectedAdmin = ''
    },
    selectedAdmin: function(value) {
      value === 'CINDY' ? (this.isCanChat = 1) : this.checkChats()
      this.setUserContact({ name: this.name, email: this.email, phoneNumber: this.phoneNumber, admin: value })
    }
  },
  computed: {
    isBootcampClass() {
      return this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2'
    },
    descriptionConfirmModal() {
      if (this.isBootcampClass) {
        return 'Changing status will trigger an automated email. Please ensure the correct payment method is selected, as the email content will vary based on this choice.'
      }
      return 'Changing status can trigger an automated email. This action cannot be undone.'
    },
    // get class type by route ex:"/user/edit/tanpa-kelas/add" will returns "TANPA_KELAS"
    classType() {
      return this.classTypes.filter(type => type.routeName === this.$route.params.classType)[0].type
    },
    maximalDate() {
      return converter.dateTimeRequestModelT(new Date())
    },
    paymentTypeOptions() {
      if (this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2') {
        return [
          { name: 'SNPL', display: 'SNPL' },
          { name: 'Cicilan 0%', display: 'Cicilan 0%' },
          { name: 'Cash Bertahap', display: 'Cash Bertahap' },
          { name: 'Scholarship', display: 'Scholarship' },
          { name: 'Midtrans', display: 'Midtrans' }
        ]
      } else {
        return [
          { name: 'BEASISWA_G2', display: 'Beasiswa G2' },
          { name: 'REGULER', display: 'Reguler' }
        ]
      }
    },
    motivationOptions() {
      let options = [
        { id: 'Getting a job in Tech Industries', name: 'Getting a job in Tech Industries' },
        { id: 'Switching careers into Tech', name: 'Switching careers into Tech' },
        { id: 'Gaining new knowledge (upskilling)', name: 'Gaining new knowledge (upskilling)' },
        { id: 'Prakerja', name: 'Prakerja' },
        { id: 'Others', name: 'Others' }
      ]
      if (this.$route.params.id !== 'add') {
        options.push({ id: '-', name: '-' })
      }
      return options
    },
    utmOptions() {
      let options = [
        { id: 'Facebook Ads', name: 'Facebook Ads' },
        { id: 'LinkedIn Ads', name: 'LinkedIn Ads' },
        { id: 'Google Ads', name: 'Google Ads' },
        { id: 'Placement', name: 'Placement' },
        { id: 'EDM', name: 'EDM' },
        { id: 'Website', name: 'Website' }
      ]
      if (this.$route.params.id !== 'add') {
        options.push({ id: '-', name: '-' })
      }
      return options
    },
    eventType() {
      return this.$route.params.description
    },
    inputs: function() {
      return [
        {
          mode: 'select',
          label: 'Type',
          textLength: 15,
          options: [
            { id: 'CALL', name: 'Call' },
            { id: 'TEXT', name: 'Whatsapp' },
            { id: 'EMAIL', name: 'Email' }
          ],
          readValue: this.activityType,
          writeValue: value => (this.activityType = value)
        },
        {
          mode: 'select',
          label: 'Content',
          textLength: 35,
          options: this.optionsContent,
          readValue: this.activityContent,
          writeValue: value => (this.activityContent = value)
        },
        {
          mode: 'select',
          label: 'Status',
          textLength: 15,
          options: [
            { id: 'ANSWERED', name: 'Answered' },
            { id: 'UNANSWERED', name: 'Unanswered' }
          ],
          readValue: this.activityOutcome,
          writeValue: value => (this.activityOutcome = value)
        },
        {
          mode: 'select',
          label: 'Notes',
          textLength: 30,
          options: this.optionsNotes,
          readValue: this.activityNote,
          writeValue: value => (this.activityNote = value)
        },
        {
          label: 'Time',
          type: 'datetime-local',
          max: this.maximalDate,
          readValue: this.activityTime,
          writeValue: value => (this.activityTime = value)
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.hideModalActivityLog(),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.saveActivityLog(),
          text: 'Save',
          type: 'secondary',
          size: 'small'
        }
      ]
    },
    inputsChat: function() {
      return [
        {
          label: 'Name',
          type: 'text',
          readValue: this.name,
          writeValue: value => (this.name = value),
          disabled: true
        },
        {
          label: 'Email',
          type: 'text',
          readValue: this.email,
          writeValue: value => (this.email = value),
          disabled: true
        },
        {
          label: 'Phone Number',
          type: 'text',
          readValue: this.phoneNumber,
          writeValue: value => (this.phoneNumber = value),
          disabled: true
        }
      ]
    },
    selects: function() {
      return [
        {
          label: 'Template',
          readValue: this.templateChatName,
          writeValue: value => (this.templateChatName = value),
          disabled: true
        }
      ]
    },
    buttonsChat: function() {
      return [
        {
          action: () => (this.isModalForChat = false),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.sendChat(),
          text: 'Start Chat',
          type: 'secondary',
          size: 'small',
          disabled: !this.templateChatUuid
        }
      ]
    },
    selectAdmin: function() {
      return {
        label: 'Admin',
        textLength: 10,
        options: [
          { id: 'CINDY', name: 'Cindy' },
          { id: 'QISQUS', name: 'Qisqus' }
        ],
        readValue: this.selectedAdmin,
        writeValue: value => (this.selectedAdmin = value)
      }
    },
    buttonsModalSelectAdmin: function() {
      return [
        {
          action: () => (this.isModalSelectAdmin = false),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.afterSelectAdmin(),
          text: 'Next',
          type: 'secondary',
          size: 'small',
          disabled: !this.selectedAdmin
        }
      ]
    }
  },
  mounted() {
    if (this.$route.params.classType === 'tanpa-kelas') {
      this.options = this.classTypeOptions
    } else if (this.$route.params.classType === 'bootcamp') {
      this.options = this.statusOptions
    } else {
      this.getOptions()
    }

    if (this.$route.params.id !== 'add') {
      this.getSiswa()
      if (this.$route.params.classType === 'bootcampv2' || this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'event') this.getActivities()
    }
    this.getAllContents()
    this.getAllNotes()
  },
  methods: {
    ...mapActions([
      'getChatStatus',
      'getTemplateChat',
      'sendBroadcast',
      'getTempContents',
      'getTempNotes',
      'getSiswaDetail',
      'getProspectDetail',
      'getProgramStages',
      'getAvailableStages',
      'getClasses',
      'getStudentLog',
      'getProspectLog',
      'getActivityLog',
      'addActivity',
      'getEventParticipantLog',
      'getStudentByEmail',
      'updateSiswa',
      'updateProspect',
      'updateProspectCoreClass',
      'updateProspectPut',
      'updateEventParticipantLog',
      'enrollEvent',
      'getProgram',
      'createSiswa',
      'updateKelas',
      'uploadBuktiPembayaran',
      'getDetailPaymentReceiptEvent',
      'uploadPaymentReceiptEvent',
      'updatePaymentReceiptEvent',
      'deletePaymentReceiptEvent',
      'showLoading',
      'hideLoading',
      'updatePaymentMethod'
    ]),
    ...mapMutations(['setIsChatCardVisible', 'setUserContact']),
    toggleConfirmModal() {
      this.isConfirmModalVisible = !this.isConfirmModalVisible
    },
    handlePaymentChange() {
      this.showLoading()
      this.updatePaymentMethod({
        prospectId: this.$route.params.studentLmsClassId,
        requestBody: {
          paymentMethod: this.selectedPayment
        }
      })
        .then(() => {
          this.hideLoading()
          notification.successMessage('Metode pembayaran berhasil diupdate!')
        })
        .catch(() => {
          this.hideLoading()
          notification.errorMessage('Metode pembayaran gagal diupdate!')
        })
    },
    getTemplate() {
      this.getTemplateChat({}).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].name === 'template_general') {
            this.templateChatUuid = res.data[i].uuid
            this.templateChatName = res.data[i].name
          }
        }
      })
    },
    forInitial(name) {
      const arr = name.split(' ')
      let newArr = []
      newArr.push(arr[0].slice(0, 1).toUpperCase())
      if (arr.length > 1) newArr.push(arr[1].slice(0, 1).toUpperCase())
      return newArr.join('')
    },
    afterSelectAdmin() {
      this.isModalSelectAdmin = false
      this.toggleChatCard()
    },
    toggleChatCard() {
      this.showLoading()
      switch (this.isCanChat) {
        case 1:
          this.setIsChatCardVisible()
          this.hideLoading()
          break
        case 2:
          this.isModalForChat = true
          this.hideLoading()
          break
        default:
          notification.errorMessage('Failed to load data, please try again later!')
          setTimeout(() => window.location.reload(), 3500)
          break
      }
    },
    sendChat() {
      this.showLoading()
      this.sendBroadcast({
        payload: {
          name: this.templateChatName,
          template_uuid: this.templateChatUuid,
          phone_number: this.changeFormatPhoneNumber(this.phoneNumber),
          variables: []
        }
      })
        .then(async () => {
          this.isModalForChat = await false
          await this.getOptions()
          await this.getSiswa()
          await this.getActivities()
          setTimeout(() => {
            if (this.isCanChat === 1) this.toggleChatCard()
            this.hideLoading()
          }, 3500)
        })
        .catch(() => notification.errorMessage('Failed to load data, please try again later!'))
    },
    changeFormatPhoneNumber(number) {
      return `62${number.substr(number.indexOf('8'))}`
    },
    getAllContents() {
      this.getTempContents({}).then(res => {
        this.optionsContent = [{ id: '', name: 'All' }]
        for (let i = 0; i < res.length; i++) {
          this.optionsContent.push({
            id: `${res[i].id}`,
            name: res[i].content
          })
        }
      })
    },
    getAllNotes() {
      this.getTempNotes({}).then(res => {
        for (let i = 0; i < res.length; i++) {
          this.optionsNotes.push({
            id: `${res[i].id}`,
            name: res[i].notes
          })
        }
        this.emptyNotesId = this.optionsNotes[0].id
      })
    },
    getOptions() {
      if (
        this.$route.params.classType === 'bootcampv3' ||
        this.$route.params.classType === 'bootcampv2' ||
        this.$route.params.classType === 'event' ||
        this.$route.params.classType === 'short-course' ||
        this.$route.params.classType === 'aws-clouducation-pre-event' ||
        this.$route.params.classType === 'aws-clouducation-main-event'
      ) {
        if (this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' || this.$route.params.classType === 'event') this.getAllClass()
        const params =
          this.$route.params.classType === 'bootcampv3'
            ? {
                isNewBootcamp: true
              }
            : {}
        this.getAvailableStages({
          studentId: this.$route.params.studentLmsClassId,
          params
        }).then(res => {
          this.options = []
          for (let i = 0; i < res.length; i++) {
            this.options.push({
              name: res[i].id,
              display: res[i].name
            })
          }
        })
      } else {
        if (this.$route.params.classType !== 'eduverse') {
          this.getProgramStages({
            programId: this.$route.params.programId,
            params: {
              page: 0,
              size: 100
            }
          }).then(res => {
            let i = 0
            this.$route.params.description === 'open-day' ? (i = 0) : (i = 1)
            for (let j = 0; j < res.data[i].stageResponse.subStageList.length; j++) {
              let list = res.data[i].stageResponse.subStageList[j]
              let subStage = {
                name: list.id,
                display: list.name
              }
              this.options.push(subStage)
            }
          })
        }
      }
    },
    getSiswa() {
      if (
        this.$route.params.classType === 'bootcampv3' ||
        this.$route.params.classType === 'bootcampv2' ||
        this.$route.params.classType === 'event' ||
        this.$route.params.classType === 'short-course' ||
        this.$route.params.classType === 'aws-clouducation-pre-event' ||
        this.$route.params.classType === 'aws-clouducation-main-event'
      ) {
        this.getProspectDetail({ studentId: this.$route.params.studentLmsClassId }).then(res => this.distributePayload(res))
        this.getProspectLog({
          studentId: this.$route.params.studentLmsClassId,
          params: {
            page: 0,
            size: 100
          }
        }).then(res => {
          this.studentLogs = []
          let dataStudentLogs = res.data.sort((a, b) => (a.dateAccessTime < b.dateAccessTime ? 1 : b.dateAccessTime < a.dateAccessTime ? -1 : 0))
          dataStudentLogs.forEach(element => {
            this.studentLogs.push({
              id: element.id,
              dateAccessTime: converter.dateTimeView(element.dateAccessTime),
              keterangan: element.keterangan ? element.keterangan : '-',
              statusTerbaru: element.statusTerbaru
            })
          })
          this.studentLogs[0].statusTerbaru === 'Bootcamp Payment Confirmed' || this.studentLogs[0].statusTerbaru === 'Pembayaran Short Course Terkonfirmasi' ? (this.done = true) : (this.done = false)
        })
      } else {
        this.getSiswaDetail({ id: this.$route.params.id }).then(res => this.distributePayload(res))
        if (this.$route.params.classType === 'international') {
          this.getEventParticipantLog({
            eventParticipantId: this.$route.params.studentLmsClassId,
            params: {
              page: 0,
              size: 100
            }
          }).then(res => {
            this.studentLogs = []
            res.data.forEach(element => {
              this.studentLogs.push({
                id: element.eventParticipantId,
                eventParticipantStageId: element.eventParticipantStageid,
                stageId: element.stageId,
                dateAccessTime: converter.dateTimeView(element.createdDate),
                keterangan: element.description ? element.description : '-',
                statusTerbaru: element.stageName
              })
            })
            this.studentLogs.forEach(element => {
              if (this.$route.params.description === 'workshop' && element.stageId === this.options[1].name) {
                this.confirmedPaymentReceiptEvent = true
              }
            })
          })
        } else {
          this.getStudentLog({
            studentId: this.$route.params.id,
            params: {
              page: 0,
              size: 100
            }
          }).then(res => {
            this.studentLogs = []
            let dataStudentLogs = res.data.sort((a, b) => (a.dateAccessTime < b.dateAccessTime ? 1 : b.dateAccessTime < a.dateAccessTime ? -1 : 0))
            dataStudentLogs.forEach(element => {
              this.studentLogs.push({
                id: element.id,
                dateAccessTime: converter.dateTimeView(element.dateAccessTime),
                keterangan: element.keterangan ? element.keterangan : '-',
                statusTerbaru: element.statusTerbaru
              })
            })
          })
        }
      }
    },
    distributePayload(res) {
      this.id = res.id
      this.name = res.name
      this.email = res.email
      this.phoneNumber = res.phoneNumber
      this.utm = res.utm !== '' ? res.utm : '-'
      this.motivation = res.motivation !== '' ? res.motivation : '-'
      this.selectedPayment = res?.paymentMethod ? res.paymentMethod : null
      this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' || this.$route.params.classType === 'event' || this.$route.params.classType === 'short-course'
        ? (this.coreClassId = res.coreClassId)
        : (this.coreClassId = '')
      this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' || this.$route.params.classType === 'event'
        ? (this.backUpCoreClassId = res.coreClassId)
        : (this.backUpCoreClassId = '')
      if (this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' || this.$route.params.classType === 'event') this.getTemplate()
      this.timestamp = converter.dateTimeView(res.createdDate)
      if (this.$route.params.classType === 'aws-clouducation-main-event') {
        if (res.category.toLowerCase() === 'student') {
          this.category = res.category.toLowerCase()
          this.disable = true
        }
        switch (res.socialMedia.toLowerCase()) {
          case 'facebook':
            this.facebook = res.socialMediaAccount
            break
          case 'instagram':
            this.instagram = res.socialMediaAccount
            break
          case 'linkedin':
            this.linkedin = res.socialMediaAccount
            break
          default:
            break
        }
      }
    },
    checkChats() {
      this.getChatStatus({
        number: this.changeFormatPhoneNumber(this.phoneNumber)
      })
        .then(() => (this.isCanChat = 1))
        .catch(res => {
          ;`${res.substr(-3)}` === '400' ? (this.isCanChat = 1) : `${res.substr(-3)}` === '500' ? (this.isCanChat = 2) : (this.isCanChat = 0)
        })
    },
    async updateTicketType() {
      if (this.category !== '') {
        await this.updateProspectPut({
          studentId: this.$route.params.studentLmsClassId,
          requestBody: {
            category: this.category,
            keterangan: this.category,
            type: this.classType
          }
        })
          .then(() => {
            notification.successMessage('Data berhasil diupdate!')
          })
          .catch(() => {
            notification.errorMessage('Data gagal diupdate!')
          })
        await this.toggleYesNoModalMedium()
        this.$router.go()
      } else {
        notification.errorMessage('Harap isi tipe tiket terbaru!')
      }
    },
    async updateStatus() {
      this.showLoading()
      this.toggleConfirmModal()
      this.disable = true
      const delay = ms => new Promise(res => setTimeout(res, ms))
      if ((this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' || this.$route.params.classType === 'event') && this.status === '') {
        notification.errorMessage('Harap isi status terbaru!')
      } else {
        if (this.status !== '' || this.coreClassId !== '') {
          if (this.$route.params.classType === 'tanpa-kelas') {
            this.updateKelas({
              studentId: this.$route.params.id,
              requestBody: {
                coreClassID: this.coreClassId,
                type: this.type
              }
            })
              .then(() => {
                notification.successMessage('Data berhasil diupdate!')
                this.$router.go(-1)
              })
              .catch(() => notification.errorMessage('Data gagal diupdate! Harap cek jadwal orientasi!'))
          } else if (
            this.$route.params.classType === 'bootcampv3' ||
            this.$route.params.classType === 'bootcampv2' ||
            this.$route.params.classType === 'event' ||
            this.$route.params.classType === 'short-course' ||
            this.$route.params.classType === 'aws-clouducation-pre-event' ||
            this.$route.params.classType === 'aws-clouducation-main-event'
          ) {
            const requestBody = {
              keterangan: this.paymentType !== '' ? `[ ${this.paymentType} ] ${this.keterangan !== '' ? `- ${this.keterangan}` : ''}` : this.keterangan,
              status: this.status,
              type: this.classType,
              coreClassId: this.coreClassId
            }
            this.updateProspect({
              studentId: this.$route.params.studentLmsClassId,
              requestBody
            })
              .then(() => {
                notification.successMessage('Data berhasil diupdate!')
                this.$router.go(-1)
              })
              .catch(() => {
                this.hideLoading()
                if (this.$route.params.classType === 'short-course') {
                  notification.errorMessage('Schedule tidak tersedia!')
                  return
                }
                notification.errorMessage('Data gagal diupdate!')
              })
          } else if (this.$route.params.classType === 'international') {
            this.updateEventParticipantLog({
              eventParticipantId: this.$route.params.studentLmsClassId,
              requestBody: {
                description: this.keterangan,
                stageId: this.status
              }
            })
              .then(() => {
                notification.successMessage('Data berhasil diupdate!')
              })
              .catch(() => {
                notification.errorMessage('Data gagal diupdate!')
              })
            this.$router.go(-1)
          } else {
            if (this.status !== 'WAIT_PAYMENT_BOOTCAMP') {
              this.updateSiswa({
                studentLmsClassId: this.$route.params.studentLmsClassId,
                requestBody: {
                  keterangan: this.keterangan,
                  status: this.status,
                  type: this.$route.params.classType.toUpperCase()
                }
              })
                .then(() => notification.successMessage('Data berhasil diupdate!'))
                .catch(() => notification.errorMessage('Data gagal diupdate!'))
              this.$router.go(-1)
            } else {
              this.updateSiswa({
                studentLmsClassId: this.$route.params.studentLmsClassId,
                requestBody: {
                  category: this.paymentType,
                  keterangan: `[ ${this.paymentType} ] ${this.keterangan !== '' ? `- ${this.keterangan}` : ''}`,
                  status: this.status,
                  type: this.$route.params.classType.toUpperCase()
                }
              })
                .then(() => notification.successMessage('Data berhasil diupdate!'))
                .catch(() => notification.errorMessage('Data gagal diupdate!'))
              this.$router.go(-1)
            }
          }
        } else {
          this.hideLoading()
          if (this.classType === 'TANPA_KELAS') {
            notification.errorMessage('Harap isi kelas dan program!')
          } else {
            notification.errorMessage('Harap isi status terbaru!')
          }
        }
      }
      await delay(3500)
      this.disable = false
    },
    getAllClass() {
      this.getClasses({
        params: {
          size: 1000,
          page: 0,
          programId: this.$route.params.programId
        }
      }).then(res => {
        this.coreClassOptions = []
        if (this.$route.params.classType === 'bootcampv3' || this.$route.params.classType === 'bootcampv2' || this.$route.params.classType === 'event') {
          this.coreClassOptions.push({
            id: '',
            name: '-'
          })
        }
        for (let i = 0; i < res.data.length; i++) {
          this.coreClassOptions.push({
            id: res.data[i].id,
            name: res.data[i].name
          })
        }
      })
    },
    checkStatus(id) {
      if (id === '') {
        this.forCheckStatus = false
      } else {
        for (let i = 0; i < this.options.length; i++) {
          if (this.options[i].name === id && this.options[i].display === 'Pending Bootcamp Payment') {
            this.forCheckStatus = true
            return
          } else {
            this.forCheckStatus = false
          }
        }
      }
    },
    addStudent() {
      if (this.$route.params.classType === 'international') {
        if (this.email === '') {
          notification.errorMessage('Email harus diisi!')
        } else if (validator.email(this.email)) {
          notification.errorMessage('Format email yang anda masukkan salah!')
        } else {
          this.createNewSiswa()
        }
      } else {
        this.createNewSiswa()
      }
    },
    async createNewSiswa() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.isLoading = true
      if (this.name === '') {
        notification.errorMessage('Nama harus diisi!')
      } else if (this.email === '') {
        notification.errorMessage('Email harus diisi!')
      } else if (validator.email(this.email)) {
        notification.errorMessage('Format email yang anda masukkan salah!')
      } else if (this.phoneNumber === '') {
        notification.errorMessage('Nomor telepon harus diisi!')
      } else if (validator.phone(this.phoneNumber)) {
        notification.errorMessage('Format nomor telepon yang anda masukkan salah!')
      } else if (this.utm === '' && this.$route.params.classType !== 'international') {
        notification.errorMessage('UTM harus diisi!')
      } else if (this.motivation === '' && this.$route.params.classType !== 'international') {
        notification.errorMessage('Motivasi harus diisi!')
      } else if (this.type === '' && this.classType === 'BOOTCAMP') {
        notification.errorMessage('Kelas harus dipilih!')
      } else if (this.coreClassId === '' && this.classType === 'BOOTCAMP') {
        notification.errorMessage('Program harus dipilih!')
      } else {
        if (this.$route.params.classType === 'international' && this.notFound === false) {
          this.enroll()
        } else {
          this.isLoading = true
          this.createSiswa({
            requestBody: {
              coreClassId: this.classType === 'BOOTCAMP' ? this.coreClassId : '',
              email: this.email,
              fullName: this.name,
              phoneNumber: this.phoneNumber,
              utm: this.utm,
              motivation: this.motivation,
              type: this.classType === 'BOOTCAMP' ? this.type : ''
            }
          })
            .then(() => {
              if (this.$route.params.classType === 'international') {
                this.registered()
              } else {
                notification.successMessage('Siswa berhasil ditambahkan!')
                this.isLoading = false
                this.$router.go(-1)
              }
            })
            .catch(res => {
              if (res === '422') {
                notification.errorMessage('Email sudah pernah terdaftar!')
              } else if (res === '404') {
                notification.errorMessage('Harap buat orientasi terlebih dahulu!')
                notification.successMessage('Siswa berhasil ditambahkan ke tanpa kelas!')
                this.$router.go(-1)
              } else {
                notification.errorMessage('Siswa gagal ditambahkan, harap mencoba beberapa saat lagi!')
              }
            })
        }
      }
      await delay(3000)
      this.isLoading = false
    },
    registered() {
      this.getStudentByEmail({ email: this.email })
        .then(res => {
          this.id = res.id
          this.enroll()
        })
        .catch(() => notification.errorMessage('Siswa gagal ditambahkan, harap mencoba beberapa saat lagi!'))
    },
    enroll() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.isLoading = true
      this.enrollEvent({
        eventId: this.$route.params.otherId,
        requestBody: {
          sendingEmail: this.sendEmail,
          studentId: this.id
        }
      })
        .then(() => {
          notification.successMessage('Siswa berhasil ditambahkan!')
          this.isLoading = false
          this.$router.go(-1)
        })
        .catch(async res => {
          if (res.code === 422) {
            notification.errorMessage('Siswa telah terdaftar di event ini!')
            await delay(3000)
            this.isLoading = false
          } else {
            notification.errorMessage('Siswa gagal didaftarkan, harap mencoba beberapa saat lagi!')
            await delay(3000)
            this.isLoading = false
          }
        })
    },
    filterStatus(statusName) {
      if (
        this.$route.params.classType === 'bootcampv3' ||
        this.$route.params.classType === 'bootcampv2' ||
        this.$route.params.classType === 'event' ||
        this.$route.params.classType === 'international' ||
        this.$route.params.classType === 'short-course' ||
        this.$route.params.classType === 'aws-clouducation-pre-event' ||
        this.$route.params.classType === 'aws-clouducation-main-event'
      ) {
        return statusName
      } else {
        return this.statusOptions.filter(status => status.name === statusName)[0].display
      }
    },
    async searchStudent() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.showLoading()
      this.disable = true
      if (this.email === '') {
        notification.errorMessage('Email harus diisi!')
      } else if (validator.email(this.email)) {
        notification.errorMessage('Format email yang anda masukkan salah!')
      } else {
        this.getStudentByEmail({ email: this.email })
          .then(res => {
            this.id = res.id
            this.name = res.name
            this.email = res.email
            this.phoneNumber = res.phoneNumber
            this.notFound = false
          })
          .catch(() => {
            this.showLoading()
            notification.errorMessage('Siswa tidak ditemukan, harap daftarkan terlebih dahulu dengan mengisikan nama dan nomor telepon!')
            this.name = ''
            this.phoneNumber = ''
            this.notFound = true
          })
      }
      await delay(3000)
      this.disable = false
      this.hideLoading()
    },
    changeCheck() {
      this.sendEmail = !this.sendEmail
    },
    changeFile(event) {
      if (event.target.files.length > 0) {
        this.showLoading()
        this.isLoading = true
        let fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('name', event.target.files[0].name)
        this.uploadBuktiPembayaran({ data: fileData }).then(res => {
          this.buktiPembayaran = res.url
          this.isLoading = false
          this.hideLoading()
        })
      } else {
        this.isLoading = false
        this.hideLoading()
      }
    },
    getDetail() {
      this.receipts = []
      this.showLoading()
      this.getDetailPaymentReceiptEvent({
        id: this.$route.params.studentLmsClassId,
        params: {
          page: 0,
          size: 10
        }
      })
        .then(res => {
          if (res.data.length > 0) {
            this.receipts = res.data
          }
          this.hideLoading()
        })
        .catch(() => {
          notification.errorMessage('Gagal mengambil data, harap mencoba beberapa saat lagi!')
          this.hideLoading()
        })
    },
    uploadPaymentReceipt() {
      if (this.receipts.length > 0) {
        if (this.isEdit) {
          this.showLoading()
          this.updatePaymentReceiptEvent({
            id: this.$route.params.studentLmsClassId,
            requestBody: {
              paymentReceipts: [
                {
                  id: this.paymentReceiptEventId,
                  amount: this.amount,
                  description: this.statusAlumni,
                  fileURL: this.buktiPembayaran,
                  name: 'Payment Receipt Event Edited',
                  paymentDateTime: this.paymentReceiptEventDateTime
                }
              ]
            }
          })
            .then(() => {
              notification.successMessage('Data berhasil diupdate!')
              this.isEdit = false
              this.getDetail()
              this.hideLoading()
            })
            .catch(() => {
              notification.errorMessage('Data gagal diupdate, harap mencoba beberapa saat lagi!')
              this.hideLoading()
            })
        } else {
          this.getDetail()
        }
      } else {
        if (this.receiptName === '' || this.amount === '' || this.buktiPembayaran === '') {
          notification.errorMessage('Data tidak boleh kosong!')
        } else {
          this.showLoading()
          this.uploadPaymentReceiptEvent({
            id: this.$route.params.studentLmsClassId,
            requestBody: {
              paymentReceipts: [
                {
                  amount: this.amount,
                  description: this.statusAlumni,
                  fileURL: this.buktiPembayaran,
                  name: 'Payment Receipt Event',
                  paymentDateTime: converter.dateTimeRequest(new Date())
                }
              ]
            }
          })
            .then(() => {
              notification.successMessage('Data berhasil diupdate!')
              this.getDetail()
              this.hideLoading()
            })
            .catch(() => {
              notification.errorMessage('Data gagal diupdate, harap mencoba beberapa saat lagi!')
              this.hideLoading()
            })
        }
      }
    },
    deletePayments() {
      this.showLoading()
      this.deletePaymentReceiptEvent({
        id: this.$route.params.studentLmsClassId
      })
        .then(() => {
          notification.successMessage('Data berhasil diupdate!')
          this.statusAlumni = ''
          this.amount = ''
          this.buktiPembayaran = ''
          this.selectedItem = []
          this.getDetail()
          this.hideLoading()
        })
        .catch(() => {
          notification.errorMessage('Data gagal diupdate, harap mencoba beberapa saat lagi!')
          this.hideLoading()
        })
    },
    toEdit(paymentReceiptEventId, statusAlumni, amount, buktiPembayaran, paymentReceiptEventDateTime) {
      this.paymentReceiptEventId = paymentReceiptEventId
      this.statusAlumni = statusAlumni
      this.amount = amount
      this.buktiPembayaran = buktiPembayaran
      this.paymentReceiptEventDateTime = paymentReceiptEventDateTime
      this.selectedItem = []
      this.isEdit = true
    },
    closeEdit() {
      this.isEdit = false
    },
    toggleYesNoModalMedium() {
      this.isYesNoModalMediumVisible = !this.isYesNoModalMediumVisible
    },
    getActivities() {
      this.showLoading()
      if (
        this.$route.params.classType === 'bootcampv3' ||
        this.$route.params.classType === 'bootcampv2' ||
        this.$route.params.classType === 'event' ||
        this.$route.params.classType === 'short-course' ||
        this.$route.params.classType === 'aws-clouducation-pre-event' ||
        this.$route.params.classType === 'aws-clouducation-main-event'
      ) {
        this.idForActivityLog = this.$route.params.studentLmsClassId
      } else {
        this.idForActivityLog = this.$route.params.id
      }
      this.getActivityLog({
        id: this.idForActivityLog,
        params: {
          page: this.currentPage,
          size: this.size
        }
      }).then(res => this.distributePayloadActivityLog(res))
    },
    distributePayloadActivityLog(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.activities = []
      res.data.forEach(activity => {
        this.activities.push({
          firstQuery: activity.id,
          firstColumn: `${activity.activityType.charAt(0).toUpperCase() + activity.activityType.slice(1).toLowerCase()}`,
          secondColumn: activity.content ? activity.content : '-',
          thirdColumn: `${activity.engagementOutcome.charAt(0).toUpperCase() + activity.engagementOutcome.slice(1).toLowerCase()}`,
          fourthColumn: activity.activityNote ? activity.activityNote : '-',
          fifthColumn: this.forNameUserFriendly(activity.performBy),
          sixthColumn: converter.dateTimeView(activity.activityTime),
          seventhColumn: converter.dateTimeView(activity.createdDate)
        })
      })
      this.hideLoading()
    },
    forNameUserFriendly(value) {
      const splitValue = value.split('@')[0]
      const nameArr = splitValue.split('.')
      let nameArrFix = []
      for (let i = 0; i < nameArr.length; i++) {
        nameArrFix.push(`${nameArr[i].charAt(0).toUpperCase() + nameArr[i].slice(1).toLowerCase()}`)
      }
      return nameArrFix.join(' ')
    },
    async saveActivityLog() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.showLoading()
      if (this.activityContent === '') {
        notification.errorMessage('Content is required! Please select one')
        // } else if (this.activityTime === '') {
        //   notification.errorMessage('Time is required!')
        // } else if (this.moment(this.activityTime).isAfter(this.maximalDate)) {
        //   notification.errorMessage('Time is invalid!')
      } else {
        this.addActivity({
          id: this.idForActivityLog,
          requestBody: {
            activityType: this.activityType,
            engagementOutcome: this.activityOutcome,
            templateActivityId: this.activityContent,
            templateNoteId: this.activityNote === 'Empty' ? this.emptyNotesId : this.activityNote,
            activityTime: converter.dateTimeRequestModelTPlus7(this.activityTime)
          }
        })
          .then(() => {
            notification.successMessage('Add activity successfully!')
            this.hideModalActivityLog()
            this.getActivities()
          })
          .catch(() => notification.errorMessage('Failed to add activity, please try again later!'))
      }
      await delay(3500)
      this.hideLoading()
    },
    toEditActivityLog(activityId) {
      this.$router.push(`${this.$route.fullPath}/activities/${activityId}`)
    },
    showModalActivityLog() {
      this.activityTime = converter.dateTimeFormat(new Date())
      this.isModalActivityLog = true
    },
    hideModalActivityLog() {
      this.resetInputActivityLog()
      this.isModalActivityLog = false
    },
    resetInputActivityLog() {
      this.activityType = 'TEXT'
      this.activityContent = ''
      this.activityOutcome = 'UNANSWERED'
      this.activityNote = 'Empty'
      this.activityTime = ''
    },
    changePage(page) {
      this.currentPage = page
      this.getActivities()
    }
  }
}
