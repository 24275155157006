<template>
  <div>
    <input
      type="text"
      class="border-grey-field border px-2 py-2 focus:outline-none focus:ring focus:ring-black w-full rounded-lg"
      v-model="displayValue"
      @blur="isInputActive = false"
      @focus="isInputActive = true"
    />
  </div>
</template>
<script>
export default {
  name: 'currency-input',
  data: () => ({
    isInputActive: false
  }),
  props: ['value'],
  computed: {
    displayValue: {
      get: function() {
        if (this.isInputActive) {
          return this.value.toString()
        } else {
          return 'Rp ' + this.value.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1.') + ',-'
        }
      },
      set: function(modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d\\.]/g, ''))
        if (isNaN(newValue)) {
          newValue = null
        }
        this.$emit('input', newValue)
      }
    }
  }
}
</script>
