const ButtonsGroup = () => import(/* webpackChunkName: "buttons-group" */ '@/molecules/tags/ButtonsGroup')
export default {
  name: 'update-student-modal',
  components: { ButtonsGroup },
  props: {
    forStatusOption: { type: Array },
    selectedData: { type: Array, required: true },
    isVisible: { type: Boolean, required: true },
    updateData: { type: Function, required: true },
    closed: { type: Function, required: true }
  },
  data: () => ({
    status: '-',
    newStatus: '',
    paymentMethod: '',
    description: '',
    booleanPayment: false,
    disable: false,
    listId: [],
    listName: [],
    newStatusOptions: [],
    generalStatusOptions: [
      {
        name: 'BOOTCAMP',
        display: 'Baru Mendaftar Bootcamp'
      },
      {
        name: 'ORIENTED',
        display: 'Orientasi'
      },
      {
        name: 'WAIT_PAYMENT_LOOPS',
        display: 'Menunggu Pembayaran Loops'
      },
      {
        name: 'CONFIRM_PAYMENT_LOOPS',
        display: 'Pembayaran Loops Terkonfirmasi'
      },
      {
        name: 'WAIT_PAYMENT_PRA_BOOTCAMP',
        display: 'Menunggu Pembayaran Pramp'
      },
      {
        name: 'CONFIRM_PAYMENT_PRA_BOOTCAMP',
        display: 'Pembayaran Pramp Terkonfirmasi'
      },
      {
        name: 'WAIT_PAYMENT_BOOTCAMP',
        display: 'Menunggu Pembayaran Bootcamp'
      },
      {
        name: 'CONFIRM_PAYMENT_BOOTCAMP',
        display: 'Pembayaran Bootcamp Terkonfirmasi'
      },
      {
        name: 'NOT_PROCEED',
        display: 'Tidak Melanjutkan'
      }
    ]
  }),
  computed: {
    statusOptions: function() {
      return this.forStatusOption ? this.forStatusOption : this.generalStatusOptions
    },
    paymentMethodOptions: function() {
      if (this.$route.name === 'New Bootcamp') {
        return [
          { name: 'SNPL', display: 'SNPL' },
          { name: 'Cicilan 0%', display: 'Cicilan 0%' },
          { name: 'Cash Bertahap', display: 'Cash Bertahap' },
          { name: 'Scholarship', display: 'Scholarship' }
        ]
      } else {
        return [
          { name: 'BEASISWA_G2', display: 'Beasiswa G2' },
          { name: 'REGULER', display: 'Reguler' }
        ]
      }
    },
    buttons: function() {
      return [
        {
          action: () => this.closed(),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.updateStudent(),
          text: 'Save',
          type: 'secondary',
          size: 'small'
        }
      ]
    }
  },
  watch: {
    newStatus: function() {
      if (this.newStatus === 'WAIT_PAYMENT_BOOTCAMP' || this.toUserFriendly(this.newStatus) === 'Pending Bootcamp Payment') {
        this.booleanPayment = true
      } else {
        this.booleanPayment = false
        this.paymentMethod = ''
      }
    },
    paymentMethod: function() {
      this.newStatus === 'WAIT_PAYMENT_BOOTCAMP' && this.paymentMethod === '' ? (this.booleanPayment = true) : (this.booleanPayment = false)
    },
    selectedData: function() {
      this.listId = []
      this.listName = []
      this.newStatusOptions = []
      this.newStatus = ''
      this.paymentMethod = ''
      this.description = ''
      let forStatus = '-'
      if (this.selectedData.length > 0) {
        this.selectedData.forEach(element => {
          this.listId.push(element.id)
        })
        this.selectedData.forEach(element => {
          this.listName.push(element.fullName)
        })
        this.statusOptions.forEach(element => {
          if (element.name === this.selectedData[0].status) {
            forStatus = element.display
          } else {
            this.newStatusOptions.push(element)
          }
        })
      }
      this.status = forStatus
    },
    isVisible: function(newStatus, oldStatus) {
      newStatus === true ? this.showUpdateStudentModal() : this.hideUpdateStudentModal()
    }
  },
  methods: {
    toUserFriendly(status) {
      let statusFix = ''
      this.statusOptions.forEach(element => {
        if (element.name === status) {
          statusFix = element.display
        }
      })
      return statusFix
    },
    updateStudent() {
      this.updateData(this.listId, this.newStatus, this.paymentMethod, this.description)
    },
    showUpdateStudentModal() {
      this.$modal.show('update-student')
    },
    hideUpdateStudentModal() {
      this.$modal.hide('update-student')
    }
  }
}
