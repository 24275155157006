<template>
  <div class="flex items-center">
    <p>{{ text !== undefined && text.length > size ? text.substr(0, size) + '..' : text }}</p>
    <svg
      :class="`mx-1 ${disable ? 'cursor-not-allowed' : 'cursor-pointer hover:opacity-80'}`"
      @click="forAction()"
      :height="`${height}` || '20'"
      :width="`${width}` || '20'"
      :viewBox="`${viewBox}` || '0 0 20 20'"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        v-for="(item, index) in paths"
        :key="index"
        :d="item.d"
        :fill="`${disable ? '#58595B' : item.fill}` || '#000000'"
        :fill-rule="item.fillRule"
        :clip-rule="item.clipRule"
        :stroke="item.stroke"
        :stroke-width="item.strokeWidth"
        :stroke-miterlimit="item.strokeMiterlimit"
        :stroke-linecap="item.strokeLinecap"
        :stroke-linejoin="item.strokeLinejoin"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'text-to-action',
  props: {
    text: { type: String },
    paramsText: { type: String },
    size: { default: 15 },
    action: { type: Function, default: () => {} },
    height: { type: String, default: '20' },
    width: { type: String, default: '20' },
    viewBox: { type: String, default: '0 0 20 20' },
    paths: { type: Array }
  },
  data: () => ({
    disable: false
  }),
  methods: {
    async forAction() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      if (!this.disable) {
        let params = this.paramsText ? this.paramsText : this.text
        this.action(params)
        this.disable = true
        await delay(3500)
        this.disable = false
      }
    }
  }
}
</script>
