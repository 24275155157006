const validator = {
  email: text => {
    // let regex = /^[A-Za-z0-9+_.-]+@(.+)$/
    let regex = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/
    return regex.test(text) ? false : true
  },
  phone: number => {
    // let regex = /^(^\+62|62|^08)(\d{3,4}-?){2}\d{3,4}$/
    // let regex = /^[0-9]{10,13}$/
    let regex = /^(\+62|62|0)8[1-9][0-9]{6,11}$/
    let companyNum = /^\(\d{3}\)[0-9]+$/
    return regex.test(number) || companyNum.test(number) ? false : true
  }
}

export default validator
