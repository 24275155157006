/* eslint-disable no-unused-vars */
import { notification } from '@/util'
const CheckboxCrm = () => import(/* webpackChunkName: "keyword-search" */ '@/components/CheckboxCrm')
export default {
  name: 'import-file-modal',
  components: { CheckboxCrm },
  props: {
    isVisible: { type: Boolean, required: true },
    eventTypeOpt: { type: Array },
    stageOpt: { type: Array },
    closed: { type: Function, required: true }
  },
  data: () => ({
    disable: false,
    file: '',
    fileName: 'Choose file',
    eventType: '',
    stage: '',
    sendEmail: true
  }),
  watch: {
    fileName: function() {
      return this.fileName
    },
    isVisible: function(newStatus, oldStatus) {
      newStatus === true ? this.showUpload() : this.hideUpload()
    }
  },
  methods: {
    changeFile(event) {
      if (event !== undefined && event.target.files.length > 0) {
        this.fileName = event.target.files[0].name
        this.file = event.target.files[0]
      }
    },
    async uploadFile() {
      this.disable = true
      const delay = ms => new Promise(res => setTimeout(res, ms))
      if (this.$route.name === 'Event') {
        this.$emit('changeFile', this.file, this.sendEmail)
        await delay(3500)
        this.disable = false
      } else if (this.$route.name === 'AWS Clouducation Pre Event' || this.$route.name === 'AWS Clouducation Main Event') {
        if (this.eventType === '' || this.stage === '') {
          notification.errorMessage('Harap pilih event dan status!')
          this.disable = false
        } else {
          this.$emit('changeFile', this.file, this.eventType, this.stage)
          await delay(3500)
          this.disable = false
        }
      } else {
        this.$emit('changeFile', this.file)
        await delay(3500)
        this.disable = false
      }
    },
    showUpload() {
      this.$modal.show('upload-file')
    },
    hideUpload() {
      this.$modal.hide('upload-file')
    },
    changeCheck() {
      this.sendEmail = !this.sendEmail
    }
  }
}
