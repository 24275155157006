import { mapActions } from 'vuex'
import { converter } from '@/util'
const ImportFileModal = () => import(/* webpackChunkName: "import-file-modal" */ '@/components/ImportFileModal')
const ResultFileModal = () => import(/* webpackChunkName: "result-file-modal" */ '@/components/ResultFileModal')

export default {
  name: 'tanpa-kelas',
  components: { ImportFileModal, ResultFileModal },
  data: () => ({
    historyList: []
  }),
  mounted() {
    this.getHistory()
  },
  methods: {
    ...mapActions(['getPipelineHistory']),
    getHistory() {
      this.getPipelineHistory({ id: this.$route.params.id }).then(res => this.distributePayload(res))
    },
    distributePayload(res) {
      res.forEach(history => {
        this.historyList.push({
          id: history.id,
          projectValue: history.projectValue,
          progress: history.progress ? history.progress : '-',
          followUpStatus: history.followUpStatus ? history.followUpStatus : '-',
          followUpComment: history.followUpComment ? history.followUpComment : '-',
          companyName: history.companyName,
          updatedBy: history.updatedBy,
          updatedDate: converter.dateTimeView(history.updatedDate)
        })
      })
      this.historyList.reverse()
    }
  }
}
