import Vue from 'vue'
import Vuex from 'vuex'
import JadwalOrientasi from './modules/jadwal-orientasi'
import Siswa from './modules/siswa'
import Common from './modules/common'
import Email from './modules/email'
import Mitra from './modules/mitra'
import Prakerja from './modules/prakerja'
import Classes from './modules/classes'
import Spinner from 'vue-simple-spinner'

Vue.use(Vuex)
Vue.component('vue-simple-spinner', Spinner)

export default new Vuex.Store({
  modules: { JadwalOrientasi, Siswa, Common, Email, Mitra, Prakerja, Classes },
  components: { Spinner }
})
