export default {
  name: 'am-modal',
  props: {
    isVisible: { type: Boolean, required: true },
    closed: { type: Function, required: true },
    amName: { type: String, required: false },
    amMitraCount: { required: true },
    plByMitra: { type: Array, required: true }
  },
  watch: {
    isVisible: function(newStatus) {
      newStatus === true ? this.$modal.show('am-modal') : this.$modal.hide('am-modal')
    }
  },
  methods: {
    exit() {
      this.closed()
    }
  }
}
