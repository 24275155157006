import { mapActions } from 'vuex'

export default {
  name: 'flag-duplicate-modal',
  props: {
    isVisible: { type: Boolean, required: true },
    closed: { type: Function, required: true },
    selectedItem: { type: Object, required: true }
  },
  watch: {
    isVisible: function(newStatus) {
      newStatus === true ? this.$modal.show('flag-duplicate') : this.$modal.hide('flag-duplicate')
    }
  },
  methods: {
    ...mapActions(['updateDuplicate']),
    async simpan() {
      await this.updateDuplicate({
        id: this.selectedItem.id
      }).then(window.location.reload())
    }
  }
}
