import api from '@/api'

const state = {
  orientationScheduleList: {},
  orientationScheduleDetail: {}
}
const actions = {
  getEventList: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.event.getEventList(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getOrientationScheduleList: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.event.getOrientationScheduleList(params).then(
        response => {
          resolve(response.data.data)
          commit('setOrientationScheduleList', response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getOpenDayList: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.event.getOpenDayList(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getParticipantList: ({ commit }, { eventId, params }) => {
    return new Promise((resolve, reject) => {
      api.event.getParticipantList(eventId, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getOrientationScheduleDetail: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.event.getOrientationScheduleDetail(id).then(
        response => {
          resolve(response.data.data)
          commit('setOrientationScheduleDetail', response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAttendanceOfOrientation: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.event.getAttendanceOfOrientation(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  createOrientationSchedule: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.createOrientationSchedule(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  createOpenDay: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.createOpenDay(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateOpenDay: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.updateOpenDay(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getOpenDay: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.event.getOpenDay(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  createEvent: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.createEvent(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getEventById: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.event.getEventById(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  editEvent: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.editEvent(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  deleteEvent: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.event.deleteEvent(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getDetailPaymentReceiptEvent: ({ commit }, { id, params }) => {
    return new Promise((resolve, reject) => {
      api.event.getDetailPaymentReceiptEvent(id, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  uploadPaymentReceiptEvent: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.uploadPaymentReceiptEvent(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updatePaymentReceiptEvent: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.updatePaymentReceiptEvent(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  deletePaymentReceiptEvent: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.event.deletePaymentReceiptEvent(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateInterest: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.event.updateInterest(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  editOrientationSchedule: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.editOrientationSchedule(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  deleteOrientationSchedule: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.event.editOrientationSchedule(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  sendInvoice: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.sendInvoice(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  reschedule: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.reschedule(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  enrollEvent: ({ commit }, { eventId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.enrollEvent(eventId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateEventParticipantLog: ({ commit }, { eventParticipantId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.event.updateEventParticipantLog(eventParticipantId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getEventParticipantLog: ({ commit }, { eventParticipantId, params }) => {
    return new Promise((resolve, reject) => {
      api.event.getEventParticipantLog(eventParticipantId, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  }
}
const mutations = {
  setOrientationScheduleList(state, value) {
    state.orientationScheduleList = value
  },
  setOrientationScheduleDetail(state, value) {
    state.orientationScheduleDetail = value
  }
}
const getters = {
  orientationScheduleList: state => {
    return state.orientationScheduleList
  },
  orientationScheduleDetail: state => {
    return state.orientationScheduleDetail
  }
}

export default { state, getters, actions, mutations }
