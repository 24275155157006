import { mapActions } from 'vuex'

export default {
  name: 'add-agreement',
  data: () => ({
    userRole: '',
    userId: '',
    id: '',
    pipelineName: '',
    assignTo: '',
    docName: '',
    docType: '',
    docFile: '',
    docLink: '',
    companyName: '',
    picName: '',
    pipelineList: [],
    pipelineId: '',
    amList: [],
    amId: '',
    docTypeOptions: [
      { name: 'MoU', displayName: 'MoU' },
      { name: 'PKS', displayName: 'PKS' }
    ]
  }),
  computed: {
    filename: function() {
      return this.docFile.split('-')[this.docFile.split('-').length - 1]
    }
  },
  watch: {
    pipelineId: function(newValue) {
      let filtered = this.pipelineList.filter(pipeline => pipeline.id === newValue)[0]
      this.companyName = filtered.companyName
      this.picName = filtered.picName
      this.assignTo = filtered.assignedTo
    }
  },
  mounted() {
    this.userRole = localStorage.getItem('user_role')
    this.userId = localStorage.getItem('user_id')
    this.getPipeline()
  },
  methods: {
    ...mapActions(['getAllPipelines', 'getAllAM', 'uploadAgreementDocument', 'addAgreement', 'updateAgreement', 'getAllAgreementByAM']),
    getPipeline() {
      if (this.userRole === 'CRM_MANAGEMENT') {
        this.getAllPipelines({}).then(res => this.distributePipeline(res))
      } else {
        this.getAllAgreementByAM({ amId: this.userId }).then(res => this.distributePipelineAm(res))
      }
    },
    getDaftarAm() {
      this.getAllAM({}).then(res => this.distributeAm(res))
    },
    distributePipeline(res) {
      res.forEach(pipeline => {
        let data = {
          id: pipeline.id,
          companyName: pipeline.mitraResponse.companyName,
          picName: pipeline.mitraResponse.picName,
          picEmail: pipeline.mitraResponse.picEmail,
          pipelineName: pipeline.name,
          type: pipeline.type,
          progress: pipeline.progress,
          status: pipeline.followUpStatus,
          comment: pipeline.followUpComment,
          registrationDate: '-',
          assignedTo: pipeline.accountManagerResponse.name,
          projectValue: pipeline.projectValue
        }
        this.pipelineList.push(data)
      })
    },
    distributePipelineAm(res) {
      res.forEach(pipeline => {
        let dataAm = {
          id: pipeline.id,
          companyName: pipeline.companyName,
          picName: pipeline.picName,
          pipelineName: pipeline.pipelineName,
          assignedTo: pipeline.assignTo
        }
        this.pipelineList.push(dataAm)
      })
    },
    distributeAm(res) {
      res.forEach(am => {
        this.amList.push({
          id: am.id,
          name: am.name
        })
      })
      this.amId = this.amList.filter(am => am.name === this.assignTo)[0].id
    },
    changeFile(event) {
      this.isLoading = true
      let fileData = new FormData()
      fileData.append('file', event.target.files[0])
      fileData.append('name', event.target.files[0].name)
      this.uploadAgreementDocument({ data: fileData }).then(res => (this.docFile = res.url))
      this.isLoading = false
    },
    async simpan() {
      await this.addAgreement({
        requestBody: {
          docFile: this.docFile,
          docLink: this.docLink,
          docName: this.docName,
          docType: this.docType,
          pipelineId: this.pipelineId
        }
      }).then(window.location.reload())
    },
    filename(item) {
      return item.split('-')[item.split('-').length - 1]
    }
  }
}
