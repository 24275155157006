<template>
  <div class="flex flex-col">
    <label for="multiple-select-with-label" class="text-sm pl-2 font-bold text-custom-black-1" :class="[optionLabelFocus, optionLabelError, optionHide]">{{ labelText }}</label>
    <v-select
      name="multiple-select-with-label"
      class="text-sm mt-1 rounded-md"
      :class="[isFocus ? 'focus' : 'blur', optionFocus, optionError, optionDisabled, optionHide]"
      :errorValue="errorValue"
      :disabled="disabled"
      :readValue="readValue"
      :writeValue="writeValue"
      :placeholder="placeholder"
      :clearable="false"
      :searchable="false"
      deselectFromDropdown
      multiple
      pushTags
      @open="setFocusTrue"
      @close="setFocusFalse"
      label="name"
      :reduce="option => option.id"
      :options="options"
      v-model="modelValue"
    >
      <template #open-indicator="{ attributes }">
        <div v-if="modelValue.length < 1" :class="`${width === 3 ? 'w-24' : 'w-32'} -ml-5`">{{ placeholder ? placeholder : 'Select' }}</div>
        <div v-else-if="modelValue.length === options.length" :class="`${width === 3 ? 'w-24' : 'w-32'} -ml-5`">All</div>
        <div v-else :class="`${width === 3 ? 'w-24' : 'w-32'} -ml-5`">{{ width === 3 ? modelValue.length + ' items sel..' : modelValue.length + ' items selected' }}</div>
        <svg v-bind="attributes" width="20" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.25 8.62501L12 15.375L18.75 8.62501" fill="transparent" stroke="#333333" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </template>
      <template #option="{ id, name }">
        <p class="text-sm" :class="[checkSelected(id) ? 'text-custom-orange-1 font-semibold' : '']">
          {{ name }}
        </p>
      </template>
      <template #selected-option-container="{}">
        <div />
      </template>
    </v-select>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
  name: 'multiple-select-with-label',
  components: { 'v-select': vSelect },
  props: {
    labelText: { type: String, required: true },
    placeholder: { type: String },
    width: { required: false },
    options: { type: Array },
    errorValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    readValue: { type: Array, required: true },
    writeValue: { type: Function, required: true }
  },
  data: () => ({
    modelValue: [],
    isFocus: false
  }),
  computed: {
    optionLabelFocus() {
      return this.isFocus ? 'text-custom-orange-1' : ''
    },
    optionLabelError() {
      return this.errorValue ? 'text-custom-red' : ''
    },
    optionFocus() {
      return this.isFocus ? 'border-custom-orange-1' : ''
    },
    optionError() {
      return this.errorValue ? 'border-custom-red' : ''
    },
    optionDisabled() {
      return this.disabled ? 'bg-custom-gray-1 cursor-not-allowed' : ''
    },
    optionHide() {
      return this.hide ? 'hidden' : ''
    }
  },
  watch: {
    readValue() {
      this.modelValue = this.readValue
    },
    modelValue: function() {
      this.writeValue(this.modelValue)
    }
  },
  methods: {
    checkSelected(id) {
      return this.modelValue.includes(id)
    },
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    }
  }
}
</script>
<style lang="scss" scoped>
li {
  font-size: 10px;
}
.blur,
.focus {
  --vs-border-color: #adadad;
  --vs-border-radius: 6px;
  --vs-font-size: 14px;
  --vs-line-height: 2;
  --vs-dropdown-option--active-bg: transparent;
  --vs-dropdown-option--deselect-bg: transparent;
  --vs-dropdown-option--active-color: #f7931e;
  --vs-dropdown-max-height: 500%;
  --vs-dropdown-min-width: 100%;
  --vs-dropdown-option-padding: 0px 10px;
  --vs-disabled-bg: #e8e8e8;
  --vs-disabled-border-radius: 6px;
}
.focus {
  --vs-border-color: #f7931e;
}
</style>
