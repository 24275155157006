import api from '@/api'

const state = {
  emailList: {},
  emailDetail: {}
}

const actions = {
  getPreviewEmailByName: ({ commit }, { templateName }) => {
    //ga kepake
    return new Promise((resolve, reject) => {
      api.email.getPreviewEmailByName(templateName).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getPreviewEmailByEvent: ({ commit }, { templateName, eventId }) => {
    return new Promise((resolve, reject) => {
      api.email.getPreviewEmailByEvent(templateName, eventId).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAllEmailTemplate: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.email.getAllEmailTemplate(params).then(
        response => {
          resolve(response.data.data)
          commit('setEmailList', response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getEmailTemplateDetail: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.email.getEmailTemplateDetail(id).then(
        response => {
          resolve(response.data.data)
          commit('setEmailDetail', response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getEmailTemplatePreviewByName: ({ commit }, { templateName }) => {
    return new Promise((resolve, reject) => {
      api.email.getEmailTemplatePreviewByName(templateName).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  addEmailTemplate: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.email.addEmailTemplate(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  updateEmailTemplate: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.email.updateEmailTemplate(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  deleteEmailTemplate: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.email.deleteEmailTemplate(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  sendEmailManual: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.email.sendEmailManual(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  }
}

const mutations = {
  setEmailList(state, value) {
    state.emailList = value
  },
  setEmailDetail(state, value) {
    state.emailDetail = value
  }
}

const getters = {
  emailList: state => {
    return state.emailList
  },
  emailDetail: state => {
    return state.emailDetail
  }
}

export default { state, getters, actions, mutations }
