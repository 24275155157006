import { validator, notification } from '@/util'
import { mapGetters, mapActions } from 'vuex'
const InputWithLabel = () => import(/* webpackChunkName: "input-with-label" */ '@/molecules/forms/InputWithLabel')
const Button = () => import(/* webpackChunkName: "button" */ '@/atoms/tags/Button')
const Modal = () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
const TextField = () => import(/* webpackChunkName: "TextField" */ '@/components/TextField/TextField')
const Chevron = () => import(/* webpackChunkName: "icons" */ '@/components/Icons/Chevron')
const Dropdown = () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
export default {
  name: 'login',
  components: { InputWithLabel, Button, Modal, TextField, Chevron, Dropdown },
  data() {
    return {
      email: '',
      password: '',
      disabled: false,
      visibleMultiroles: false,
      roleSelected: '',
      roleOptions: [],
      userType: {CRM_ADMIN: 'CRM B2C', CMS_ADMIN: 'CRM B2B'}
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn', 'userData'])
  },
  methods: {
    ...mapActions(['login', 'getMenu', 'removeUserData', 'showLoading', 'hideLoading']),
    async doLogin() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.disabled = true
      if (this.email === '') {
        notification.errorMessage('Email is required!')
      } else if (this.password === '') {
        notification.errorMessage('Password is required!')
      } else if (validator.email(this.email)) {
        notification.errorMessage('Email format is invalid!')
      } else {
        const requestBody = {
          email: this.email,
          password: this.password
        }
        this.showLoading()
        this.login({ requestBody })
          .then(async res => {
            const adminB2C = 'CRM_ADMIN'
            const adminB2B = 'CMS_ADMIN'
            const management = 'CRM_MANAGEMENT'
            const accountManager = 'CRM_ACCOUNT_MANAGER'
            let customerRoles = null
            for (let i = 0; i < res.customerRoles.length; i++) {
              if (
                res.customerRoles[i].roles?.includes(adminB2C) ||
                res.customerRoles[i].roles?.includes(adminB2B)
                ) {
                customerRoles = res.customerRoles[i]
                break
              }
            }
            let id = ''
            await localStorage.setItem('user_id', res.id)
            await localStorage.setItem('access_token', res.token)
            await localStorage.setItem('user_name', res.name)
            await localStorage.setItem('user_email', res.email)
            await localStorage.setItem('client_id', customerRoles.customer.id)

            if (res.customerRoles.length > 0 && res.customerRoles[0].customer.id === 3) {
              await localStorage.setItem('user_role', 'CRM_AWS')
              notification.successMessage(`Welcome ${res.name}!`)
              this.$router.push('/events/aws-clouducation-pre-event')
            } else {
              if (customerRoles.roles.includes(adminB2C) && customerRoles.roles.includes(adminB2B)) {
                this.roleOptions = customerRoles.roles.filter(role => [adminB2C, adminB2B].includes(role))
                this.toogleMultiroles()
                this.hideLoading()
              } else if (customerRoles.roles.includes(adminB2C)) {
                await localStorage.setItem('user_role', adminB2C)
                await this.getMenu({ params: { page: 0, size: 100 } }).then(res => {
                  id = res.currentPage + 1
                })
                notification.successMessage(`Welcome ${res.name}!`)
                this.$router.push(`/user/all-users/${id}`)
              } else if (customerRoles.roles.includes(adminB2B)) {
                await localStorage.setItem('user_role', adminB2B)
                notification.successMessage(`Welcome ${res.name}!`)
                this.$router.push(`/user/accounts`)
              } else if (customerRoles.roles.includes(management)) {
                await localStorage.setItem('user_role', management)
                notification.successMessage(`Welcome ${res.name}!`)
                this.$router.push('/b2b/daftar-perusahaan')
              } else if (customerRoles.roles.includes(accountManager)) {
                await localStorage.setItem('user_role', accountManager)
                notification.successMessage(`Welcome ${res.name}!`)
                this.$router.push('/b2b/daftar-perusahaan')
              } else {
                this.removeUserData()
                notification.errorMessage('Forbidden!')
                await delay(3500)
                this.hideLoading()
              }
            }
          })
          .catch(async res => {
            if (res.code === 422) {
              notification.errorMessage('Email or password is incorrect!')
            } else {
              notification.errorMessage('There is an error, please try again later!')
            }
            await delay(3500)
            this.hideLoading()
          })
      }
      await delay(3500)
      this.disabled = false
    },
    toogleMultiroles() {
      this.visibleMultiroles = !this.visibleMultiroles
    },
    clickRoles(role) {
      this.showLoading()
      this.roleSelected = role
      switch (this.roleSelected) {
        case 'CRM_ADMIN':
          let id = ''
          localStorage.setItem('user_role', 'CRM_ADMIN')
          this.getMenu({ params: { page: 0, size: 100 } }).then(res => {
            id = res.currentPage + 1
          })
          setTimeout(() => {
            this.toogleMultiroles()
            this.hideLoading()
            setTimeout(() => {
              this.$router.push(`/user/all-users/${id}`)
            }, 300)
          }, 2000)
          break
        case 'CMS_ADMIN':
          localStorage.setItem('user_role', 'CMS_ADMIN')
          setTimeout(() => {
            this.toogleMultiroles()
            this.hideLoading()
            setTimeout(() => {
              this.$router.push('/user/accounts')
            }, 300)
          }, 2000)
          break
      }
    }
  }
}
