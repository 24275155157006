const nameToUrl = name => {
    return name.replace(/\s+/g, '-').toLowerCase()
}

const urlToName = link => {
    if (!link) return ''
    return link.replace(/-/g, ' ').replace(/(?:^|\s)\S/g, function (firstChar) {
        return firstChar.toUpperCase()
    })
}

export { nameToUrl, urlToName }