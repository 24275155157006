import { render, staticRenderFns } from "./DetailAccount.vue?vue&type=template&id=2ce6c139&scoped=true&"
import script from "./js/detail-account.js?vue&type=script&lang=js&"
export * from "./js/detail-account.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ce6c139",
  null
  
)

export default component.exports