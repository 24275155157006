import { mapActions } from 'vuex'
import { converter } from '@/util'
const Pagination = () => import(/* webpackChunkName: "import-file-modal" */ '@/molecules/others/Pagination')
const TooltipTopLeft = () => import(/* webpackChunkName: "tooltip-top-left" */ '@/atoms/others/TooltipTopLeft')

export default {
  name: 'detail-student-modal',
  components: { Pagination, TooltipTopLeft },
  props: {
    isVisible: { type: Boolean, required: true },
    studentId: { type: String, required: true },
    fullName: { type: String, required: true },
    phoneNumber: { type: String, required: true },
    email: { type: String, required: true },
    channelType: { type: String, required: true },
    closed: { type: Function, required: true }
  },
  data: () => ({
    counter: 0,
    totalPages: 1,
    totalItems: 0,
    currentPage: 0,
    size: 10,
    detailStudent: [],
    enrollmentHistory: []
  }),
  watch: {
    isVisible: function(newStatus) {
      newStatus === true ? this.$modal.show('detail-student-modal') : this.$modal.hide('detail-student-modal')
    },
    studentId: function() {
      this.getDetailStudent(this.currentPage)
      this.getStudentEnrollmentHistory(this.currentPage)
    }
  },
  methods: {
    ...mapActions(['getRegistrationDetails', 'getEnrollmentHistory']),
    getDetailStudent(currentPage) {
      this.getRegistrationDetails({
        studentId: this.studentId,
        params: {
          page: currentPage,
          size: this.size
        }
      }).then(res => {
        this.distributePayload(res)
      })
    },
    getStudentEnrollmentHistory(currentPage) {
      this.getEnrollmentHistory({
        studentId: this.studentId,
        params: {
          page: currentPage,
          size: this.size
        }
      }).then(res => {
        res.forEach(data => {
          this.enrollmentHistory.push({
            id: data.id,
            programName: data.program.name,
            className: data.nama_kelas,
            enrollDate: converter.dateTimeView(data.start_date)
          })
        })
      })
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.detailStudent = []
      res.data.forEach(student => {
        this.detailStudent.push({
          id: student.id,
          studentId: student.studentId,
          coreClassId: student.coreClassId,
          coreClassName: student.coreClassName,
          fullName: student.fullName,
          email: student.email,
          phoneNumber: student.phoneNumber,
          lastStatus: student.description,
          lastTimestamp: converter.dateTimeView(student.updatedDate)
        })
      })
    },
    changePage(currentPage) {
      this.currentPage = currentPage
    },
    toEditSiswa(id, studentLmsClassId) {
      this.$router.push({
        name: 'Edit Siswa',
        params: {
          classType: 'bootcamp',
          programId: '0',
          description: '0',
          id,
          studentLmsClassId,
          otherId: '0'
        }
      })
    }
  }
}
