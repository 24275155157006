<template>
  <div class="flex flex-col">
    <label for="input-with-label" class="text-sm pl-2 font-bold text-custom-black-1" :class="[inputLabelFocus, inputLabelError, inputHide]">{{ labelText }}</label>
    <textarea
      name="input-with-label"
      class="resize-none h-20 text-sm p-2 rounded-md placeholder-custom-gray-11 border border-custom-gray-11 focus:placeholder-custom-gray-11 focus:border-custom-orange-1 focus:outline-none"
      :class="[inputFocus, inputError, inputDisabled, inputHide, labelText ? 'mt-1' : '']"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :minlength="minlength"
      :autofocus="autofocus"
      :errorValue="errorValue"
      :disabled="disabled"
      :readValue="readValue"
      :writeValue="writeValue"
      @focus="setFocusTrue()"
      @blur="setFocusFalse()"
      @keypress.enter="forAction"
      v-model="modelValue"
    />
  </div>
</template>
<script>
export default {
  name: 'textarea-with-label',
  props: {
    labelText: { type: String },
    placeholder: { type: String },
    maxlength: { required: false },
    minlength: { required: false },
    autofocus: { type: Boolean, default: false },
    errorValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    forAction: { type: Function, default: () => {} },
    readValue: { type: String, required: true },
    writeValue: { type: Function, required: true }
  },
  data: () => ({
    modelValue: '',
    isFocus: false,
    inputFocusClass: 'placeholder-custom-gray-11 border-custom-orange-1'
  }),
  mounted() {
    this.modelValue = this.readValue
  },
  computed: {
    inputLabelFocus() {
      return this.isFocus ? 'text-custom-orange-1' : ''
    },
    inputLabelError() {
      return this.errorValue ? 'text-custom-red' : ''
    },
    inputFocus() {
      return this.isFocus ? this.inputFocusClass : ''
    },
    inputError() {
      return this.errorValue ? 'border-custom-red' : ''
    },
    inputDisabled() {
      return this.disabled ? 'bg-custom-gray-1 cursor-not-allowed' : ''
    },
    inputHide() {
      return this.hide ? 'hidden' : ''
    }
  },
  watch: {
    readValue() {
      this.modelValue = this.readValue
    },
    modelValue: function() {
      this.writeValue(this.modelValue)
    }
  },
  methods: {
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    }
  }
}
</script>
