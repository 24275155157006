<template>
  <div>
    <PageHeader
      v-show="visibility[0]"
      :generalTitle="generalTitle"
      :action="action"
      :pageName="pageName"
      :realRoute="realRoute"
      :routePath="routePath"
      :flexDirection="flexDirection"
      :justifyContent="justifyContent"
      :buttons="buttons"
    />
    <FormCard v-show="visibility[1]" :inputs="inputs" :flexDirection="secondFlexDirection" :justifyContent="secondJustifyContent" :buttons="secondButtons" />
    <div class="my-5">
      <HistoryCard
        v-show="visibility[2]"
        :arrayList="arrayList"
        :inputs="secondInputs"
        :flexDirection="flexDirectionHistoryCard"
        :justifyContent="justifyContentHistoryCard"
        :buttons="buttonsHistoryCard"
      />
    </div>
    <TableList
      v-show="visibility[3]"
      :tableHeader="tableHeader"
      :tableHeaderAlign="tableHeaderAlign"
      :checkbox="checkbox"
      :actionCheckbox="actionCheckbox"
      :textToAction="textToAction"
      :tooltip="tooltip"
      :secondTooltip="secondTooltip"
      :tooltipSize="tooltipSize"
      :list="list"
      :listAlign="listAlign"
      :actionButtons="actionButtons"
      :totalPages="totalPages"
      :totalItems="totalItems"
      :currentPage="currentPage"
      :size="size"
      :counter="counter"
      :maxVisiblePages="maxVisiblePages"
      :changePage="changePage"
      :changeSize="changeSize"
      :headerTextNotFound="headerTextNotFound"
      :descriptionTextNotFound="descriptionTextNotFound"
      :actionNotFound="actionNotFound"
    />
  </div>
</template>
<script>
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/organisms/static/PageHeader')
const FormCard = () => import(/* webpackChunkName: "form-card" */ '@/organisms/static/FormCard')
const HistoryCard = () => import(/* webpackChunkName: "history-card" */ '@/organisms/static/HistoryCard')
const TableList = () => import(/* webpackChunkName: "table-list" */ '@/organisms/static/TableList')
export default {
  name: 'second-template',
  components: { PageHeader, FormCard, HistoryCard, TableList },
  props: {
    visibility: { type: Array, required: true },
    generalTitle: { type: Boolean, default: true },
    action: { type: Function, default: () => {} },
    pageName: { type: String },
    realRoute: { type: String },
    routePath: { type: String },
    flexDirection: { type: String, default: 'row' },
    justifyContent: { type: String, default: 'start' },
    buttons: {
      type: Array,
      default: () => {
        return []
      }
    },
    inputs: {
      type: Array,
      default: () => {
        return []
      }
    },
    arrayList: {
      type: Array,
      default: () => {
        return []
      }
    },
    secondInputs: {
      type: Array,
      default: () => {
        return []
      }
    },
    flexDirectionHistoryCard: { type: String, default: 'row' },
    justifyContentHistoryCard: { type: String, default: 'start' },
    buttonsHistoryCard: {
      type: Array,
      default: () => {
        return []
      }
    },
    secondFlexDirection: { type: String, default: 'row' },
    secondJustifyContent: { type: String, default: 'start' },
    secondButtons: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableHeader: { type: Array },
    tableHeaderAlign: { type: Array },
    checkbox: { type: Boolean, default: false },
    actionCheckbox: { type: Function, default: () => {} },
    textToAction: { type: Array },
    tooltip: { type: Array },
    secondTooltip: { type: Array },
    tooltipSize: { required: false },
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    listAlign: { type: Array },
    actionButtons: { type: Array },
    totalPages: { required: false },
    totalItems: { default: 0 },
    currentPage: { default: 0 },
    size: { default: 10 },
    counter: { default: 0 },
    maxVisiblePages: { required: false, default: 5 },
    changePage: { type: Function, default: () => {} },
    changeSize: { type: Function, default: () => {} },
    headerTextNotFound: { type: String },
    descriptionTextNotFound: { type: String },
    actionNotFound: { type: Object }
  }
}
</script>
