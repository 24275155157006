const ImportFileModal = () => import(/* webpackChunkName: "import-file-modal" */ '@/components/ImportFileModal')
const ResultFileModal = () => import(/* webpackChunkName: "result-file-modal" */ '@/components/ResultFileModal')
const UpdateStudentModal = () => import(/* webpackChunkName: "update-student-modal" */ '@/components/UpdateStudentModal')
const InfoModal = () => import(/* webpackChunkName: "info-modal" */ '@/components/InfoModal')
import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
const FirstTemplate = () => import(/* webpackChunkName: "first-template" */ '@/templates/FirstTemplate')
export default {
  name: 'bootcamp',
  components: {
    ImportFileModal,
    ResultFileModal,
    UpdateStudentModal,
    InfoModal,
    FirstTemplate
  },
  data: () => ({
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    activeClassType: 'Bootcamp',
    startDate: '',
    endDate: '',
    forEndDate: '',
    minimalDate: '',
    maximalDate: '',
    keyword: '',
    programId: '',
    isImportModalVisible: false,
    isResultModalVisible: false,
    isUpdateStudentModalVisible: false,
    isInfoModalVisible: false,
    hide: true,
    enabled: true,
    disabled: false,
    isLoading: true,
    canUpdate: true,
    selectedData: [],
    studentList: [],
    filterSelect: [],
    importResult: { errorList: [], successList: [] },
    forOptions: [
      {
        label: 'Class Type',
        options: [
          { id: '', name: 'All' },
          { id: 'Data Science', name: 'Data Science' },
          { id: 'Data Engineering', name: 'Data Engineering' },
          { id: 'Web & Mobile Development', name: 'Web & Mobile Development' }
        ]
      },
      {
        label: 'UTM',
        options: [
          { id: '', name: 'All' },
          { id: 'Facebook Ads', name: 'Facebook Ads' },
          { id: 'LinkedIn Ads', name: 'LinkedIn Ads' },
          { id: 'Google Ads', name: 'Google Ads' },
          { id: 'Placement', name: 'Placement' },
          { id: 'EDM', name: 'EDM' },
          { id: 'Website', name: 'Website' },
          { id: 'blank', name: '-' }
        ]
      },
      {
        label: 'Status',
        options: [
          { id: '', name: 'All' },
          { id: 'BOOTCAMP', name: 'Register Bootcamp' },
          { id: 'ORIENTED', name: 'Orientation' },
          { id: 'WAIT_PAYMENT_LOOPS', name: 'Pending Loops Payment' },
          { id: 'CONFIRM_PAYMENT_LOOPS', name: 'Loops Payment Confirmed' },
          { id: 'WAIT_PAYMENT_PRA_BOOTCAMP', name: 'Pending Pramp Payment' },
          { id: 'CONFIRM_PAYMENT_PRA_BOOTCAMP', name: 'Pramp Payment Confirmed' },
          { id: 'WAIT_PAYMENT_BOOTCAMP', name: 'Pending Bootcamp Payment' },
          { id: 'CONFIRM_PAYMENT_BOOTCAMP', name: 'Bootcamp Payment Confirmed' },
          { id: 'NOT_PROCEED', name: 'Not Continue' }
        ]
      }
    ]
  }),
  computed: {
    forFilterSelect: function() {
      let arr = []
      for (let i = 0; i < this.forOptions.length; i++) {
        arr.push({
          name: this.forOptions[i].label,
          value: this.forOptions[i].options[0].id
        })
      }
      return arr
    },
    forFilter: function() {
      return [
        {
          label: this.forOptions[0].label,
          options: this.forOptions[0].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[0].value : this.forFilterSelect[0].value,
          writeValue: response => (this.forFilterSelect[0].value = response)
        },
        {
          label: this.forOptions[1].label,
          options: this.forOptions[1].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[1].value : this.forFilterSelect[1].value,
          writeValue: response => (this.forFilterSelect[1].value = response)
        },
        {
          label: this.forOptions[2].label,
          options: this.forOptions[2].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[2].value : this.forFilterSelect[2].value,
          writeValue: response => (this.forFilterSelect[2].value = response)
        }
      ]
    },
    forInput: function() {
      return [
        {
          label: 'Start Date',
          type: 'date',
          max: this.maximalDate,
          readValue: this.startDate,
          writeValue: value => (this.startDate = value)
        },
        {
          label: 'End Date',
          type: 'date',
          max: this.maximalDate,
          min: this.minimalDate,
          disabled: this.enabled,
          readValue: this.forEndDate,
          writeValue: value => (this.forEndDate = value)
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.toEditSiswa('add', 'tambah'),
          text: 'Add',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.showUpload(),
          text: 'Import',
          type: 'secondary',
          size: 'small'
        },
        {
          action: () => this.download(),
          text: this.disabled ? 'Downloading' : 'Download',
          type: 'primary',
          size: 'small',
          disabled: this.disabled
        }
      ]
    },
    firstButtons: function() {
      return [
        {
          action: () => this.filter(),
          text: 'Apply',
          type: 'primary',
          size: 'small'
        },
        {
          action: () => this.clearFilter(),
          text: 'Clear',
          type: 'tertiary',
          size: 'small',
          hide: this.hide
        }
      ]
    },
    secondButtons: function() {
      return [
        {
          action: () => this.searchStudent(this.keyword),
          text: 'Search',
          type: 'primary',
          size: 'small'
        }
      ]
    },
    thirdButtons: function() {
      return [
        {
          action: () => this.$router.push({ name: 'Jadwal Orientasi' }),
          text: 'Orientation',
          type: 'secondary',
          size: 'regular'
        },
        {
          action: () => this.$router.push({ name: 'Open Day' }),
          text: 'Open Day',
          type: 'secondary',
          size: 'regular',
          hide: true
        },
        {
          action: () => this.$router.push({ name: 'Diagnostic Assessment' }),
          text: 'Diagnostic Assessment',
          type: 'secondary',
          size: 'regular',
          hide: true
        },
        {
          action: () => this.$router.push({ name: 'Pra Bootcamp Payment' }),
          text: 'Payment Slip',
          type: 'secondary',
          size: 'regular'
        }
      ]
    },
    fourthButtons: function() {
      return [
        {
          action: () => this.updateStudent(this.canUpdate),
          text: 'Update Status',
          type: 'primary',
          size: 'regular',
          hide: true
        },
        {
          action: () => this.updateStudent(this.canUpdate),
          text: 'Update Status',
          type: 'primary',
          size: 'regular'
        }
      ]
    }
  },
  watch: {
    startDate: function() {
      if (this.startDate === '') {
        this.hide = true
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate === this.maximalDate) {
        this.hide = false
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate > this.maximalDate) {
        this.hide = false
        notification.errorMessage('Please enter the start date correctly!')
        this.minimalDate = ''
        this.forEndDate = ''
        this.enabled = true
      } else {
        this.hide = false
        this.enabled = false
        this.minimalDate = this.startDate
      }
    },
    forEndDate: function() {
      this.endDate = converter.datePlusDay(this.forEndDate, 1)
    },
    forFilterSelect: {
      handler: function(newValue) {
        this.filterSelect = newValue
      },
      immediate: true
    },
    filterSelect: {
      handler: function() {
        this.filterSelect.forEach(element => {
          this.forOptions.forEach(item => {
            if (element.value !== item.options[0].id) {
              this.hide = false
            }
          })
        })
      },
      deep: true
    }
  },
  mounted() {
    this.isLoading = true
    this.maximalDate = converter.date(new Date())
    this.getProgramId()
    this.getAllStudent()
  },
  methods: {
    ...mapActions(['getAllPrograms', 'getStudentList', 'downloadCsv', 'uploadCsv', 'updateBulkSiswa', 'showLoading', 'hideLoading']),
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getAllStudent()
    },
    async download() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.disabled = true
      await this.downloadCsv(
        this.startDate !== '' && this.endDate !== ''
          ? {
              requestBody: {
                query: this.keyword,
                queryClassType: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
                queryUtm: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
                startDate: this.startDate,
                endDate: this.endDate,
                status: this.filterSelect.length > 0 ? this.filterSelect[2].value : '',
                type: this.activeClassType
              }
            }
          : {
              requestBody: {
                query: this.keyword,
                queryClassType: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
                queryUtm: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
                status: this.filterSelect.length > 0 ? this.filterSelect[2].value : '',
                type: this.activeClassType
              }
            }
      )
        .then(res => this.createDownloadFile(res))
        .catch(() => notification.errorMessage('Download failed, please try again later!'))
      await delay(3000)
      this.disabled = false
    },
    createDownloadFile(res) {
      var fileURL = window.URL.createObjectURL(new Blob([res]))
      var fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `${this.$route.name} - ${converter.dateTimeView(new Date())}.csv`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    getAllStudent() {
      if (this.startDate !== '' && this.endDate === '') {
        notification.errorMessage('Please input the start date and end date!')
      } else if (this.startDate !== '' && this.endDate !== '') {
        this.showLoading()
        this.getStudentList({
          params: {
            page: this.currentPage,
            size: this.size,
            query: this.keyword,
            queryClassType: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
            queryUtm: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
            startDate: this.startDate,
            endDate: this.endDate,
            status: this.filterSelect.length > 0 ? this.filterSelect[2].value : '',
            type: this.activeClassType
          }
        }).then(res => this.distributePayload(res))
      } else {
        this.showLoading()
        this.getStudentList({
          params: {
            page: this.currentPage,
            size: this.size,
            query: this.keyword,
            queryClassType: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
            queryUtm: this.filterSelect.length > 0 ? this.filterSelect[1].value : '',
            status: this.filterSelect.length > 0 ? this.filterSelect[2].value : '',
            type: this.activeClassType
          }
        }).then(res => {
          this.distributePayload(res)
        })
      }
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getAllStudent()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.studentList = []
      res.data.forEach(student => {
        this.studentList.push({
          valueParams: { id: student.id, fullName: student.fullName, status: student.status },
          firstQuery: student.studentId,
          secondQuery: student.id,
          firstColumn: student.fullName,
          secondColumn: student.phoneNumber,
          thirdColumn: student.email,
          fourthColumn: student.coreClassName,
          fifthColumn: student.utm ? student.utm : '-',
          sixthColumn: this.toUserFriendly(student.status),
          seventhColumn: converter.dateTimeView(student.createdDate)
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    toUserFriendly(id) {
      let status = '-'
      this.forOptions[2].options.forEach(element => {
        if (element.id === id) {
          status = element.name
        }
      })
      return status
    },
    changeFile(file) {
      this.hideUpload()
      this.showResult()
      let fileData = new FormData()

      fileData.append('uploadfile', file)
      fileData.append('classType', 'BOOTCAMP')
      this.uploadCsv({ data: fileData }).then(res => this.distributeErrors(res))
    },
    distributeErrors(res) {
      this.importResult.errorList = []
      this.importResult.successList = []
      res.successEmail.length > 0 ? res.successEmail.forEach(email => this.importResult.successList.push(email.email)) : (this.importResult.successList = [])
      res.failedEmail.length > 0 ? res.failedEmail.forEach(email => this.importResult.errorList.push(email.email)) : (this.importResult.errorList = [])
    },
    actionCheckbox(val) {
      this.selectedData = val
      for (let i = 0; i < this.selectedData.length; i++) {
        if (this.selectedData[i].status !== this.selectedData[0].status) {
          this.canUpdate = false
          return
        } else {
          this.canUpdate = true
        }
      }
    },
    updateStudent(bool) {
      bool ? this.showUpdateStudentModal() : this.toggleInfoModal()
    },
    updateData(arrayId, status, paymentMethod, description) {
      this.showLoading()
      let keterangan = ''
      status !== 'WAIT_PAYMENT_BOOTCAMP' ? (keterangan = description) : (keterangan = `[ ${paymentMethod} ] ${description !== '' ? `- ${description}` : ''}`)
      this.updateBulkSiswa({
        requestBody: {
          studentLmsClassIds: arrayId,
          keterangan,
          status,
          category: status === 'WAIT_PAYMENT_BOOTCAMP' ? paymentMethod : '',
          type: 'BOOTCAMP'
        }
      })
        .then(() => {
          notification.successMessage('Data updated successfully!')
          this.hideLoading()
          this.hideUpdateStudentModal()
          this.getAllStudent()
        })
        .catch(() => {
          notification.errorMessage('Data failed to update, please try again later!')
          this.hideLoading()
        })
    },
    showUpdateStudentModal() {
      this.isUpdateStudentModalVisible = true
    },
    hideUpdateStudentModal() {
      this.isUpdateStudentModalVisible = false
    },
    toggleInfoModal() {
      this.isInfoModalVisible = !this.isInfoModalVisible
    },
    showUpload() {
      this.isImportModalVisible = true
    },
    hideUpload() {
      this.isImportModalVisible = false
    },
    showResult() {
      this.isResultModalVisible = true
    },
    hideResult() {
      this.isResultModalVisible = false
      this.$router.go()
    },
    searchStudent(keyword) {
      this.keyword = keyword
      this.currentPage = 0
      this.getAllStudent()
    },
    filter() {
      if (this.forEndDate > this.maximalDate) {
        notification.errorMessage('Please enter the end date correctly!')
      } else if (this.startDate > this.forEndDate) {
        notification.errorMessage('Please enter the start date or end date correctly!')
      } else {
        this.filterSelect = this.forFilterSelect
        this.currentPage = 0
        this.getAllStudent()
      }
    },
    clearFilter() {
      this.hide = true
      this.startDate = ''
      for (let i = 0; i < this.forFilterSelect.length; i++) {
        this.forFilterSelect[i].value = this.forOptions[i].options[0].id
      }
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getAllStudent()
    },
    clearSearch() {
      this.keyword = ''
      this.currentPage = 0
      this.getAllStudent()
    },
    getProgramId() {
      this.getAllPrograms({}).then(res => {
        res.forEach(item => {
          if (item.code === 'Bootcamp') this.programId = item.id
        })
      })
    },
    toEditSiswa(id, studentLmsClassId) {
      this.$router.push({
        name: 'Edit Siswa',
        params: {
          classType: 'bootcamp',
          programId: this.programId,
          description: '0',
          id,
          studentLmsClassId,
          otherId: '0'
        }
      })
    }
  }
}
