import { mapActions } from 'vuex'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
const TemplateEmailPreview = () => import(/* webpackChunkName: "keyword-search" */ '@/components/TemplateEmailPreview')
const YesNoModal = () => import(/* webpackChunkName: "keyword-search" */ '@/components/YesNoModal')

export default {
  name: 'temp-edit',
  components: { quillEditor, TemplateEmailPreview, YesNoModal },
  data: () => ({
    namaTemplate: '',
    judulEmail: '',
    isYesNoModalVisible: false,
    isiEmail: '',
    // poster: 'https://g2labcms.s3-ap-southeast-1.amazonaws.com/1627044449220-Placeholder_-_Instructor.png',
    poster: '',
    option: {
      modules: {
        toolbar: [['bold', 'italic', 'underline', 'strike'], [{ list: 'ordered' }, { list: 'bullet' }], [{ size: ['small', false, 'large', 'huge'] }], ['clean']]
      }
    },
    isLoading: true
  }),
  computed: {
    isEdit: function () {
      return this.$route.params.id !== 'add'
    }
  },
  mounted() {
    if (this.$route.params.id === 'add') {
      this.isLoading = false
    } else {
      this.isLoading = true
      this.getEmail()
    }
  },
  methods: {
    ...mapActions(['getEmailTemplateDetail', 'addEmailTemplate', 'updateEmailTemplate', 'deleteEmailTemplate', 'uploadImageAsset', 'showLoading', 'hideLoading']),
    getEmail() {
      this.getEmailTemplateDetail({
        id: this.$route.params.id
      }).then(res => this.distributePayload(res))
    },
    distributePayload(res) {
      this.namaTemplate = res.namaTemplate
      this.judulEmail = res.judulEmail
      this.linkZoom = res.linkZoom
      this.isiEmail = res.isiEmail
      this.poster = res.attachmentUrl
    },
    saveEmail() {
      let requestBody = {
        namaTemplate: this.namaTemplate,
        judulEmail: this.judulEmail,
        isiEmail: this.isiEmail,
        attachmentUrl: this.poster
      }
      if (this.$route.params.id === 'add') {
        this.addEmailTemplate({
          requestBody
        }).then(res => res)
      } else {
        this.updateEmailTemplate({
          id: this.$route.params.id,
          requestBody
        }).then(res => res)
      }
      this.$router.go(-1)
    },
    deleteEmail() {
      this.deleteEmailTemplate({
        id: this.$route.params.id
      }).then(res => res)
      this.$router.go(-1)
    },
    changeFile(event) {
      if (event.target.files.length > 0) {
        this.showLoading()
        this.isLoading = true
        let fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('name', event.target.files[0].name)
        this.uploadImageAsset({
          data: fileData,
          success: res => {
            this.poster = res.url
            this.isLoading = false
            this.hideLoading()
          }
        })
      } else {
        this.isLoading = false
        this.hideLoading()
      }
    },
    toggleYesNoModal() {
      this.isYesNoModalVisible = !this.isYesNoModalVisible
    }
  }
}
