import { mapActions } from 'vuex'

export default {
  name: 'set-priority-modal',
  props: {
    isVisible: { type: Boolean, required: true },
    closed: { type: Function, required: true },
    selectedItem: { type: Object, required: true }
  },
  data: () => ({
    priorityUpdate: ''
  }),
  watch: {
    isVisible: function(newStatus) {
      newStatus ? this.$modal.show('set-priority') : this.$modal.hide('set-priority')
    }
  },
  methods: {
    ...mapActions(['updatePriority']),
    async simpan() {
      await this.updatePriority({
        id: this.selectedItem.id,
        requestBody: { priority: this.priorityUpdate }
      }).then(window.location.reload())
    }
  }
}
