<template>
  <button class="font-bold rounded-lg border cursor-pointer focus:outline-none" @click="handleClick" :class="[buttonSize, buttonType, buttonDisabled, buttonHide]" :disabled="disabled">
    {{ text }}
  </button>
</template>
<script>
export default {
  name: 'custom-button',
  props: {
    disabled: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    text: { type: String, required: true },
    type: { type: String, default: 'primary' },
    size: { type: String, default: 'regular' },
    action: { type: Function }
  },
  data: () => ({
    buttonClass: {
      primary: 'text-white border-custom-orange-1 bg-custom-orange-1 hover:border-custom-orange-2 hover:bg-custom-orange-2',
      secondary: 'text-custom-orange-1 border-custom-orange-3 bg-custom-orange-3 hover:text-custom-orange-2',
      tertiary: 'text-custom-orange-1 border-custom-orange-1 bg-transparent hover:text-custom-orange-2 hover:border-custom-orange-2'
    },
    buttonSizeClass: {
      regular: 'text-sm py-2 px-6',
      big: 'text-base py-3 px-6',
      full: 'text-base py-2 px-6 w-full',
      small: 'text-xs py-2 px-3',
      extraSmall: 'text-xs py-1 px-3'
    },
    buttonDisabledClass: {
      primary: 'border-custom-gray-4 bg-custom-gray-4 cursor-not-allowed hover:border-custom-gray-4 hover:bg-custom-gray-4',
      secondary: 'text-custom-gray-4 border-custom-gray-1 bg-custom-gray-1 cursor-not-allowed hover:text-custom-gray-4',
      tertiary: 'text-custom-gray-4 border-custom-gray-4 cursor-not-allowed hover:text-custom-gray-4 hover:border-custom-gray-4'
    }
  }),
  computed: {
    buttonType() {
      return this.buttonClass[this.type]
    },
    buttonSize() {
      return this.buttonSizeClass[this.size]
    },
    buttonDisabled() {
      return this.disabled ? this.buttonDisabledClass[this.type] : ''
    },
    buttonHide() {
      return this.hide ? 'hidden' : ''
    }
  },
  methods: {
    handleClick() {
      this.action()
    }
  }
}
</script>
