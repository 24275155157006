<template>
  <div class="tooltip">
    {{ text !== undefined && text.length > size ? text.substr(0, size) + '..' : text }}
    <span class="tooltiptext">{{ tooltipText }}</span>
  </div>
</template>
<script>
export default {
  name: 'tooltip-top-right',
  props: {
    text: { type: String },
    tooltipText: { type: String },
    size: { required: true }
  }
}
</script>
<style lang="scss" scoped>
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #f7931e;
  opacity: 0.9;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  @media (max-width: 910px) {
    bottom: 0;
    left: 0;
    margin-left: auto;
    top: -5px;
    left: 110%;
    width: fit-content;
    height: fit-content;
  }
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #f7931e transparent transparent transparent;
  @media (max-width: 910px) {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    margin-left: auto;
    border-color: #f7931e #f7931e transparent transparent;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
