import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
const DateSearch = () => import(/* webpackChunkName: "date-search" */ '@/components/DateSearch')
const DetailStudentModal = () => import(/* webpackChunkName: "import-file-modal" */ '@/components/DetailStudentModal')
const SiswaStatus = () => import(/* webpackChunkName: "siswa-status" */ '@/components/SiswaStatus')
const TableList = () => import(/* webpackChunkName: "table-list" */ '@/components/TableList')

const SOURCE = {
  WEB: 'Web G2',
  SALES_PERSON: 'Sales Person',
  EMAIL: 'Email', 
  WEB_EDUVERSE: 'Eduverse'
}

export default {
  name: 'international',
  components: { DateSearch, DetailStudentModal, SiswaStatus, TableList },
  data: () => ({
    // General
    counter: 0,
    currentPage: 0,
    isLoading: true,
    keyword: '',
    size: 10,
    studentList: [],
    totalItems: 0,
    totalPages: 1,

    // All Student
    email: '',
    fullName: '',
    channelType: '',
    isDetailStudentModalVisible: false,
    phoneNumber: '',
    studentId: '',

    // International
    activeStatusName: '',
    endDate: '',
    event: '',
    openDayId: '',
    startDate: '',
    statuses: [],
    workshopId: '',
    sourceFilter: '',
    count: 0,
    
  }),
  computed: {
    program() {
      this.count++
      return this.$route.params.program
    },
    filter() {
      const options = [
        { id: '', name: 'All' }
      ]
      for(const key in SOURCE) {
        options.push({ id: key, name: SOURCE[key] })
      }
      const filter_opts = {
        length: 15,
        label: 'Source',
        options,
        readValue: '',
        writeValue: response => (this.sourceFilter = response)
      }
      return filter_opts
    }
  },
  watch: {
    // International
    activeStatusName: function () {
      if (this.$route.params.program === 'international') {
        if (this.activeStatusName === this.statuses[0].name || this.activeStatusName === this.statuses[1].name || this.activeStatusName === this.statuses[2].name) {
          this.event = 'open-day'
        } else {
          this.event = 'workshop'
        }
      }
    },
    count: function () {
      this.switchProgram()
    }
  },
  methods: {
    ...mapActions([
      // General
      'showLoading',
      'hideLoading',

      // All Student
      'getAllStudentList',

      // International
      'getAllParticipants',
      'getCountSubStage',
      'getProgramStages'
    ]),
    switchProgram() {
      this.currentPage = 0
      this.isLoading = true
      this.keyword = ''
      this.size = 10
      this.studentList = []
      this.totalItems = 0
      this.totalPages = 1

      // All Student
      this.email = ''
      this.fullName = ''
      this.isDetailStudentModalVisible = false
      this.phoneNumber = ''
      this.studentId = ''

      // International
      this.activeStatusName = ''
      this.endDate = ''
      this.event = ''
      this.openDayId = ''
      this.startDate = ''
      this.statuses = []
      this.workshopId = ''
      switch (this.program) {
        case 'all-users':
          this.isLoading = true
          this.changePage(this.currentPage)
          break
        case 'international':
          this.getFlow()
          break
        default:
          break
      }
    },
    // General
    changePage(page) {
      switch (this.program) {
        case 'all-users':
          this.keyword !== '' ? this.searchByKeyword(null, page) : this.getList(null, page)
          break
        case 'international':
          this.changeStatus(this.activeStatusName, page)
          break
        default:
          break
      }
    },
    getList(status, currentPage) {
      this.showLoading()
      let params = {}
      //   // General
      //   page: currentPage,
      //   size: this.size,

      //   // International
      //   programId: this.$route.params.id,
      //   programStageId: '',
      //   stageId: status
      // }
      switch (this.program) {
        case 'all-users':
          params = {
            page: currentPage,
            size: this.size,
            channel_type: this.sourceFilter
          }
          this.getAllStudentList({ params }).then(res => this.distributePayload(res))
          break
        case 'international':
          params = {
            page: currentPage,
            size: this.size,
            programId: this.$route.params.id,
            programStageId: '',
            stageId: status
          }
          this.getAllParticipants({ params })
            .then(res => this.distributePayload(res))
            .catch(() => console.log('gagal'))
          break
        default:
          break
      }
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.studentList = []
      res.data.forEach(student => {
        switch (this.program) {
          case 'all-users':
            this.studentList.push({
              firstQuery: student.studentId,
              secondQuery: student.fullName,
              thirdQuery: student.phoneNumber,
              fourthQuery: student.email,
              fifthQuery: SOURCE[student.channelType] || '-',
              firstColumn: student.fullName,
              secondColumn: student.phoneNumber,
              thirdColumn: student.email,
              fourthColumn: SOURCE[student.channelType] || '-',
              fifthColumn: converter.dateTimeView(student.createdDate),
              sixthColumn: converter.dateTimeView(student.lastActivity),
            })
            break
          case 'international':
            this.studentList.push({
              firstQuery: this.$route.params.id,
              secondQuery: student.studentId,
              thirdQuery: student.eventParticipantId,
              firstColumn: student.studentName,
              secondColumn: student.mainStageName,
              thirdColumn: student.eventName,
              fourthColumn: student.phoneNumber,
              fifthColumn: student.email,
              sixthColumn: student.description ? student.description : '-',
              seventhColumn: converter.dateTimeView(student.timestamp)
            })
            break
          default:
            break
        }
      })
      this.isLoading = false
      this.hideLoading()
    },
    searchStudent(keyword) {
      this.keyword = keyword
      switch (this.program) {
        case 'all-users':
          this.searchByKeyword(null, 0)
          break
        case 'international':
          this.searchByKeyword(this.activeStatusName, 0)
          break
        default:
          break
      }
    },
    searchByKeyword(status, currentPage) {
      this.showLoading()
      let params = {
        //   // General
        //   page: currentPage,
        //   size: this.size,
        //   query: this.keyword,
        //   // International
        //   programId: this.$route.params.id,
        //   programStageId: '',
        //   stageId: status
      }
      if (this.keyword !== '') {
        switch (this.program) {
          case 'all-users':
            params = {
              // General
              page: currentPage,
              size: this.size,
              query: this.keyword
            }
            this.getAllStudentList({ params }).then(res => this.distributePayload(res))
            break
          case 'international':
            params = {
              // General
              page: currentPage,
              size: this.size,
              query: this.keyword,

              // International
              programId: this.$route.params.id,
              programStageId: '',
              stageId: status
            }
            this.getAllParticipants({ params }).then(res => this.distributePayload(res))
            break
          default:
            break
        }
      } else {
        switch (this.program) {
          case 'all-users':
            this.changePage(0)
            break
          case 'international':
            this.changeStatus(this.activeStatusName, 0)
            break
          default:
            break
        }
      }
    },

    // All Student
    // getAllStudent(currentPage) {
    //   this.showLoading()
    //   this.getAllStudentList({
    //     params: {
    //       page: currentPage,
    //       size: this.size
    //     }
    //   }).then(res => {
    //     this.distributePayload(res)
    //   })
    // },
    // distributePayload(res) {
    //   this.currentPage = res.currentPage
    //   this.totalItems = res.totalRows
    //   this.totalPages = res.totalPages
    //   this.studentList = []
    //   res.data.forEach(student => {
    //     this.studentList.push({
    //       firstQuery: student.studentId,
    //       secondQuery: student.fullName,
    //       thirdQuery: student.phoneNumber,
    //       fourthQuery: student.email,
    //       firstColumn: student.fullName,
    //       secondColumn: student.phoneNumber,
    //       thirdColumn: student.email,
    //       fourthColumn: converter.dateTimeView(student.createdDate),
    //       fifthColumn: converter.dateTimeView(student.lastActivity)
    //     })
    //   })
    //   this.isLoading = false
    //   this.hideLoading()
    // },
    // searchStudent(keyword) {
    //   this.keyword = keyword
    //   this.searchByKeyword(0)
    // },
    // searchByKeyword(currentPage) {
    //   if (this.keyword !== '') {
    //     this.showLoading()
    //     this.getAllStudentList({
    //       params: {
    //         page: currentPage,
    //         size: this.size,
    //         query: this.keyword
    //       }
    //     }).then(res => {
    //       this.distributePayload(res)
    //     })
    //   } else {
    //     this.changePage(0)
    //   }
    // },
    showDetailStudentModal(studentId, fullName, phoneNumber, email, channelType) {
      this.studentId = studentId
      this.fullName = fullName
      this.phoneNumber = phoneNumber
      this.email = email
      this.channelType = channelType
      this.isDetailStudentModalVisible = true
    },
    hideDetailStudentModal() {
      this.isDetailStudentModalVisible = false
    },

    // International
    // getList(status, currentPage) {
    //   this.showLoading()
    //   this.getAllParticipants({
    //     params: {
    //       programId: this.$route.params.id,
    //       programStageId: '',
    //       stageId: status,
    //       page: currentPage,
    //       size: this.size
    //     }
    //   }).then(res => {
    //     this.distributePayload(res)
    //   })
    // },
    // distributePayload(res) {
    //   this.currentPage = res.currentPage
    //   this.totalItems = res.totalRows
    //   this.totalPages = res.totalPages
    //   this.studentList = []
    //   res.data.forEach(student => {
    //     this.studentList.push({
    //       firstQuery: this.$route.params.id,
    //       secondQuery: student.studentId,
    //       thirdQuery: student.eventParticipantId,
    //       firstColumn: student.studentName,
    //       secondColumn: student.mainStageName,
    //       thirdColumn: student.eventName,
    //       fourthColumn: student.phoneNumber,
    //       fifthColumn: student.email,
    //       sixthColumn: student.description ? student.description : '-',
    //       seventhColumn: converter.dateTimeView(student.timestamp)
    //     })
    //   })
    //   this.isLoading = false
    //   this.hideLoading()
    // },
    getFlow() {
      this.getProgramStages({
        programId: this.$route.params.id,
        params: {
          page: 0,
          size: 100
        }
      }).then(res => {
        this.openDayId = res.data[0].id
        this.workshopId = res.data[1].id
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < res.data[i].stageResponse.subStageList.length; j++) {
            this.activeStatusName = res.data[0].stageResponse.subStageList[0].id
            let list = res.data[i].stageResponse.subStageList[j]
            let subStage = {
              id: list.id,
              name: list.id,
              programStageId: res.data[i].id,
              displayName: list.name,
              siswaCount: 0,
              seqNo: list.seqNo
            }
            this.statuses.push(subStage)
          }
        }
        this.getCount()
      })
    },
    getCount() {
      this.statuses.forEach(element => {
        this.getCountSubStage({
          params: {
            programId: this.$route.params.id,
            programStageId: element.programStageId,
            stageId: element.id
          }
        }).then(res => {
          element.siswaCount = res
        })
      })
      this.changeStatus(this.activeStatusName, this.currentPage)
    },
    changeStatus(statusName, currentPage) {
      this.isLoading = true
      this.activeStatusName = statusName
      if (this.startDate !== '' && this.endDate !== '') {
        this.searchByDate(statusName, currentPage)
      } else if (this.keyword !== '') {
        this.searchByKeyword(statusName, currentPage)
      } else {
        this.getList(statusName, currentPage)
      }
      for (let i = 0; i < this.size; i++) {
        this.studentList.push({
          id: '',
          fullName: '',
          email: '',
          phoneNumber: '',
          description: '',
          updatedDate: ''
        })
      }
    },
    searchStudentByDate(startDate, endDate) {
      this.startDate = startDate
      this.endDate = endDate
      this.searchByDate(this.activeStatusName, 0)
    },
    searchByDate(statusName, currentPage) {
      if (this.startDate !== '' && this.endDate !== '') {
        this.getAllParticipants({
          params: {
            programId: this.$route.params.id,
            programStageId: '',
            stageId: statusName,
            page: currentPage,
            size: this.size,
            startDate: this.startDate,
            endDate: this.endDate
          }
        }).then(res => {
          this.distributePayload(res)
        })
      } else {
        notification.errorMessage('Please input the start date and end date!')
      }
    },
    // searchStudent(keyword) {
    //   this.keyword = keyword
    //   this.searchByKeyword(this.activeStatusName, 0)
    // },
    // searchByKeyword(statusName, currentPage) {
    //   if (this.keyword !== '') {
    //     this.getAllParticipants({
    //       params: {
    //         programId: this.$route.params.id,
    //         programStageId: '',
    //         stageId: statusName,
    //         page: currentPage,
    //         size: this.size,
    //         query: this.keyword
    //       }
    //     }).then(res => {
    //       this.distributePayload(res)
    //     })
    //   } else {
    //     this.changeStatus(this.activeStatusName, 0)
    //   }
    // },
    toEditSiswa(programId, id, studentLmsClassId) {
      this.$router.push(`/user/international/${programId}/edit/${this.event}/${id}/${studentLmsClassId}/0`)
    },
    toJadwalEvent(event, programStageId) {
      this.$router.push({
        name: 'Jadwal Event',
        params: {
          programId: this.$route.params.id,
          event,
          programStageId
        }
      })
    }
  }
}
