/* eslint-disable no-unused-vars */
import { mapActions } from 'vuex'
import { notification, validator } from '@/util'

export default {
  name: 'akun-modal',
  data: () => ({
    status: '',
    computedStatusOptions: [
      {
        name: 'INTERNAL',
        display: 'Internal'
      },
      {
        name: 'EKSTERNAL',
        display: 'Eksternal'
      }
    ]
  }),
  props: {
    isVisible: { type: Boolean, required: true },
    closed: { type: Function, required: true },
    payload: { type: Object, required: true }
  },
  watch: {
    isVisible: function(newStatus, oldStatus) {
      newStatus === true ? this.showModal() : this.hideModal()
    }
  },
  methods: {
    ...mapActions(['updateManagementAccount', 'updateAMAccount', 'createManagementAccount', 'createAMAccount']),
    saveAkun() {
      if (this.payload.isEdit) {
        if (this.payload.akunType === 'AM') {
          if (this.emailValidation() && this.phoneNumberValidation()) {
            this.updateAMAccount({
              id: this.payload.id,
              requestBody: {
                email: this.payload.email,
                name: this.payload.name,
                phoneNumber: this.payload.phoneNumber,
                status: this.payload.status
              }
            }).then(() => {
              this.hideModal()
              this.$router.push('/b2b/akun')
            })
          }
        } else {
          if (this.emailValidation() && this.phoneNumberValidation()) {
            this.updateManagementAccount({
              id: this.payload.id,
              requestBody: {
                email: this.payload.email,
                name: this.payload.name,
                phoneNumber: this.payload.phoneNumber
              }
            }).then(() => {
              this.hideModal()
              this.$router.push('/b2b/akun')
            })
          }
        }
      } else {
        if (this.payload.akunType === 'AM') {
          if (this.emailValidation() && this.phoneNumberValidation()) {
            this.createAMAccount({
              requestBody: {
                email: this.payload.email,
                name: this.payload.name,
                password: this.payload.password,
                phoneNumber: this.payload.phoneNumber,
                status: this.payload.status
              }
            }).then(() => {
              this.hideModal()
              this.$router.push('/b2b/akun')
            })
          }
        } else {
          if (this.emailValidation() && this.phoneNumberValidation()) {
            this.createManagementAccount({
              requestBody: {
                email: this.payload.email,
                name: this.payload.name,
                password: this.payload.password,
                phoneNumber: this.payload.phoneNumber
              }
            }).then(() => {
              this.hideModal()
              this.$router.push('/b2b/akun')
            })
          }
        }
      }
    },
    showModal() {
      this.$modal.show('upload-file')
    },
    hideModal() {
      this.$modal.hide('upload-file')
    },
    phoneNumberValidation() {
      if (this.payload.phoneNumber === '') {
        notification.errorMessage('Nomor telepon harus diisi!')
      } else if (validator.phone(this.payload.phoneNumber)) {
        notification.errorMessage('Format nomor telepon yang anda masukkan salah!')
      } else {
        return true
      }
    },
    emailValidation() {
      if (this.payload.email === '') {
        notification.errorMessage('Email harus diisi!')
      } else if (validator.email(this.payload.email)) {
        notification.errorMessage('Format email yang anda masukkan salah!')
      } else {
        return true
      }
    }
  }
}
