import { mapActions } from 'vuex'
import Pagination from '@/molecules/others/Pagination.vue'
const TemplateEmailPreview = () => import(/* webpackChunkName: "keyword-search" */ '@/components/TemplateEmailPreview')

export default {
  name: 'temp-email',
  components: { Pagination, TemplateEmailPreview },
  data: () => ({
    emailList: [],
    subject: '',
    html: '',
    poster: '',
    isPreviewModalVisible: false,
    isLoading: true
  }),
  mounted() {
    this.isLoading = true
    this.getAllEmail()
  },
  methods: {
    ...mapActions(['getAllEmailTemplate', 'getEmailTemplatePreviewByName', 'showLoading', 'hideLoading']),
    getAllEmail() {
      this.showLoading()
      this.getAllEmailTemplate({
        params: {
          page: 0,
          size: 500
        }
      }).then(res => this.distributePayload(res))
    },
    distributePayload(res) {
      res.forEach(email => {
        this.emailList.push({
          id: email.id,
          name: email.namaTemplate,
          body: email.isiEmail,
          subject: email.judulEmail
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    showPreviewModal(templateName, index) {
      this.showLoading()
      this.getEmailTemplatePreviewByName({
        templateName
      })
        .then(res => {
          this.subject = res.subject
          this.html = res.html
          this.poster = res.attachmentUrl
          this.isPreviewModalVisible = true
        })
        .catch(res => {
          if (res.slice(-3) === '500') {
            this.subject = this.emailList[index].subject
            this.html = this.emailList[index].body
            this.isPreviewModalVisible = true
          }
        })
      this.hideLoading()
    },
    hidePreviewModal() {
      this.isPreviewModalVisible = false
    }
  }
}
