<template>
  <div>
    <div class="px-2 py-5 border rounded-lg bg-white">
      <div class="flex flex-col px-3">
        <div v-if="arrayList && arrayList.length > 0" class="timeline">
          <div class="timestamp mb-4 h-1/4 flex m-2" v-for="(item, index) in arrayList" :key="'arrayList-' + index">
            <div style="width: 2%;">
              <div class="rounded-full w-4 h-4 my-1" v-bind:class="[index === 0 ? 'bg-custom-orange-1' : 'bg-gray-400']"></div>
            </div>
            <div style="width: 98%;">
              <div class="mx-6">
                <p v-bind:class="[index === 0 ? 'text-custom-orange-1 font-bold' : 'text-gray-500']">
                  {{ item.firstText }} -
                  {{ item.secondText }}
                </p>
                <p class="text-gray-500 my-2" v-bind:class="[index === 0 ? 'font-bold' : '']">
                  {{ item.thirdText }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="flex justify-center items-center text-custom-gray-2">- empty list -</div>
        <hr class="my-6" />
        <div class="flex" v-for="(input, index) in inputs" :key="'input-' + index">
          <div v-show="!input.mode" class="py-2 w-full">
            <InputWithLabel
              :labelText="input.label"
              :type="input.type"
              :placeholder="input.placeholder"
              :max="input.max"
              :min="input.min"
              :maxlength="input.maxlength"
              :minlength="input.minlength"
              :autofocus="input.autofocus"
              :errorValue="input.errorValue"
              :disabled="input.disabled"
              :value="input.value"
              :customValuePlus="input.customValuePlus"
              :customValueMinus="input.customValueMinus"
              :readValue="input.readValue"
              :writeValue="input.writeValue"
            />
            <p :class="input.description ? 'pl-2 pt-1 text-xs text-custom-black-1' : 'hide'">{{ input.description }}</p>
          </div>
          <div v-show="input.mode === 'select'" class="py-2 w-full">
            <SelectWithLabel
              :labelText="input.label"
              :textLength="input.textLength"
              :options="input.options"
              :errorValue="input.errorValue"
              :disabled="input.disabled"
              :readValue="input.readValue"
              :writeValue="input.writeValue"
            />
            <p :class="input.description ? 'pl-2 pt-1 text-xs text-custom-black-1' : 'hide'">{{ input.description }}</p>
          </div>
          <div v-show="input.mode === 'textarea'" class="py-2 w-full">
            <TextareaWithLabel
              :labelText="input.label"
              :placeholder="input.placeholder"
              :maxlength="input.maxlength"
              :minlength="input.minlength"
              :autofocus="input.autofocus"
              :errorValue="input.errorValue"
              :disabled="input.disabled"
              :readValue="input.readValue"
              :writeValue="input.writeValue"
            />
            <p :class="input.description ? 'pl-2 pt-1 text-xs text-custom-black-1' : 'hide'">{{ input.description }}</p>
          </div>
        </div>
        <div class="py-2 w-full">
          <ButtonsGroup :flexDirection="flexDirection" :justifyContent="justifyContent" :buttons="buttons" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const InputWithLabel = () => import(/* webpackChunkName: "input-with-label" */ '@/molecules/forms/InputWithLabel')
const SelectWithLabel = () => import(/* webpackChunkName: "select-with-label" */ '@/molecules/forms/SelectWithLabel')
const TextareaWithLabel = () => import(/* webpackChunkName: "textarea-with-label" */ '@/molecules/forms/TextareaWithLabel')
const ButtonsGroup = () => import(/* webpackChunkName: "buttons-group" */ '@/molecules/tags/ButtonsGroup')
export default {
  name: 'history-card',
  components: { InputWithLabel, SelectWithLabel, TextareaWithLabel, ButtonsGroup },
  props: {
    arrayList: {
      type: Array,
      default: () => {
        return []
      }
    },
    inputs: {
      type: Array,
      default: () => {
        return []
      }
    },
    flexDirection: { type: String, default: 'row' },
    justifyContent: { type: String, default: 'start' },
    buttons: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>
