const Search = () => import(/* webpackChunkName: "search" */ '@/atoms/icons/Search')
export default {
  name: 'keyword-search',
  components: { Search },
  data: () => ({
    keyword: '',
    isFocus: false,
    inputFocusClass: 'placeholder-custom-gray-11 border-custom-orange-1'
  }),
  props: {
    errorValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    searchingLabel: { type: String },
    inputPlaceholder: { type: String },
    searchThis: { type: Function }
  },
  computed: {
    inputLabelFocus() {
      return this.isFocus ? 'text-custom-orange-1' : ''
    },
    inputLabelError() {
      return this.errorValue ? 'text-custom-red' : ''
    },
    inputFocus() {
      return this.isFocus ? this.inputFocusClass : ''
    },
    inputError() {
      return this.errorValue ? 'border-custom-red' : ''
    },
    inputDisabled() {
      return this.disabled ? 'bg-custom-gray-4' : ''
    }
  },
  methods: {
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    },
    clickAction() {
      this.searchThis(this.keyword)
    }
  }
}
