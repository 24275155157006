/* eslint-disable no-unused-vars */
import { mapActions } from 'vuex'
import CurrencyInput from '@/components/CurrencyInput'

export default {
  name: 'edit-siswa',
  components: { CurrencyInput },
  data: () => ({
    namaPipeline: '',
    companyName: { id: '', name: '' },
    picName: '',
    progress: '',
    progressOptions: [
      { name: '0% - Created', display: '0% - Created' },
      { name: '10% - PNL Review', display: '10% - PNL Review' },
      {
        name: '25% - Proposal/Quotation Sent',
        display: '25% - Proposal/Quotation Sent'
      },
      { name: '50% - Follow Up', display: '50% - Follow Up' },
      { name: '75% - Negotiation', display: '75% - Negotiation' },
      { name: '90% - PKS Review', display: '90% - PKS Review' }
    ],
    followUpStatus: '',
    followUpComment: '',
    status: '',
    projectValue: '',
    keterangan: '',
    isLoading: false,
    pipelineTypeOptions: [
      {
        name: 'Talent Hiring',
        display: 'Talent Hiring'
      },
      {
        name: 'Technology Project',
        display: 'Technology Project'
      },
      {
        name: 'Corporate Training & Bootcamp',
        display: 'Corporate Training & Bootcamp'
      }
    ],
    pipelineType: '',
    amOptions: [],
    assignedTo: ''
  }),
  computed: {
    userRole: function() {
      return localStorage.getItem('user_role')
    },
    // get class type by route ex:"/user/edit/tanpa-kelas/add" will returns "TANPA_KELAS"
    classType() {
      return this.classTypes.filter(type => type.routeName === this.$route.params.classType)[0].type
    },
    computedStatusOptions() {
      return this.$route.params.classType !== 'tanpa-kelas' ? this.statusOptions : this.classTypeOptions
    }
  },
  created() {
    this.getPipeline()
  },
  methods: {
    ...mapActions(['getMitraById', 'getAllAM', 'getPipelineById', 'updatePipeline']),
    getPipeline() {
      this.getPipelineById({ id: this.$route.params.id }).then(res => {
        this.namaPipeline = res.name
        this.companyName.name = res.mitraResponse.companyName
        this.companyName.id = res.mitraResponse.id
        this.picName = res.mitraResponse.picName
        this.projectValue = res.projectValue
        this.assignedTo = res.accountManagerResponse.id
        this.followUpStatus = res.followUpStatus
        this.followUpComment = res.followUpComment
        this.progress = res.progress
      })
      this.getAMs()
    },
    getAMs() {
      this.isLoading = true
      this.getAllAM({}).then(res => {
        res.forEach(am => this.amOptions.push({ name: am.id, display: am.name }))
      })
    },
    editPipeline() {
      this.isLoading = true
      this.updatePipeline({
        id: this.$route.params.id,
        requestBody: {
          followUpComment: this.followUpComment,
          followUpStatus: this.followUpStatus,
          mitraId: this.companyName.id,
          name: this.namaPipeline,
          progress: this.progress,
          projectValue: this.projectValue,
          type: this.pipelineType,
          userID: localStorage.getItem('user_id'),
          userType: localStorage.getItem('user_role')
        }
      }).then(() => this.$router.push('/b2b/daftar-pipeline'))
    }
  }
}
