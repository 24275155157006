<template>
  <div class="flex flex-col">
    <label for="input-with-label" class="text-sm pl-2 font-bold text-custom-black-1" :class="[inputLabelFocus, inputLabelError, inputHide]">{{ labelText }}</label>
    <input
      name="input-with-label"
      class="h-10 text-sm rounded-md p-2 mt-1 placeholder-custom-gray-11 border border-custom-gray-11 focus:placeholder-custom-gray-11 focus:border-custom-orange-1 focus:outline-none"
      :class="[inputFocus, inputError, inputDisabled, inputHide]"
      :type="type"
      :placeholder="placeholder"
      :max="max"
      :min="min"
      :maxlength="maxlength"
      :minlength="minlength"
      :autofocus="autofocus"
      :errorValue="errorValue"
      :disabled="disabled"
      :value="value"
      :customValuePlus="customValuePlus"
      :customValueMinus="customValueMinus"
      :readValue="readValue"
      :writeValue="writeValue"
      @focus="setFocusTrue()"
      @blur="setFocusFalse()"
      @keypress.enter="forAction"
      v-model="modelValue"
    />
  </div>
</template>
<script>
import { converter } from '@/util'
export default {
  name: 'input-with-label',
  props: {
    labelText: { type: String, required: true },
    type: { type: String, default: 'text' },
    placeholder: { type: String },
    max: { required: false },
    min: { required: false },
    maxlength: { required: false },
    minlength: { required: false },
    autofocus: { type: Boolean, default: false },
    errorValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    value: { type: String },
    customValuePlus: { type: Number },
    customValueMinus: { type: Number },
    forAction: { type: Function, default: () => {} },
    readValue: { required: true },
    writeValue: { type: Function, required: true }
  },
  data: () => ({
    modelValue: '',
    isFocus: false,
    inputFocusClass: 'placeholder-custom-gray-11 border-custom-orange-1'
  }),
  mounted() {
    this.modelValue = this.readValue
  },
  computed: {
    inputLabelFocus() {
      return this.isFocus ? 'text-custom-orange-1' : ''
    },
    inputLabelError() {
      return this.errorValue ? 'text-custom-red' : ''
    },
    inputFocus() {
      return this.isFocus ? this.inputFocusClass : ''
    },
    inputError() {
      return this.errorValue ? 'border-custom-red' : ''
    },
    inputDisabled() {
      return this.disabled ? 'bg-custom-gray-1 cursor-not-allowed' : ''
    },
    inputHide() {
      return this.hide ? 'hidden' : ''
    }
  },
  watch: {
    readValue() {
      this.modelValue = this.readValue
    },
    modelValue: function() {
      if (this.customValuePlus) {
        this.writeValue(converter.datePlusDay(this.modelValue, this.customValuePlus))
      } else if (this.customValueMinus) {
        this.writeValue(converter.dateMinusDay(this.modelValue, this.customValueMinus))
      } else {
        this.writeValue(this.modelValue)
      }
    }
  },
  methods: {
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    }
  }
}
</script>
