export default {
  name: 'send-email-write',
  data: () => ({
    judul: '',
    isi: ''
  }),
  methods: {
    send() {
      sessionStorage.setItem('emailBody', JSON.stringify({ judul: this.judul, isi: this.isi }))
      this.$router.push({ name: 'Result Email' })
    }
  }
}
