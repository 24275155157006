import { mapActions } from 'vuex'
import { notification } from '@/util'
export default {
  name: 'detail-account',
  components: {
    InputWithLabel: () => import(/* webpackChunkName: "input-with-label" */ '@/components/Forms/InputWithLabel'),
    Checkbox: () => import('@/components/Forms/Checkbox'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    SelectWithLabel: () => import(/* webpackChunkName: "select-with-label" */ '@/components/Forms/SelectWithLabel'),
    TextareaWithLabel: () => import('@/components/Forms/TextareaWithLabel'),
  },
  props: {
    id: Number
  },
  data() {
    return {
      selectedTab: 'details',
      isLoading: false,
      coperationEducation: false,
      coperationManpower: false,
      coperationCuration: false,
      coperationPrakerja: false,
      coperationMarketplace: false,
      coperationProject: false,
      payload: {
        id: 0,
        accountName: "",
        accountType: {
        },
        phoneNumber: "",
        source: {
        },
        description: "",
        leadOwner: "",
        cooperationInterest: [],
        sales: {}
      },
      primaryId: '(None)',
      primaryContact: [],
      errors: []
    };
  },
  mounted() {
    this.fetchAccountData(this.id)
  },
  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'getAccountById', 'getLookup', 'updatePrimaryContact']),
    selectTab(tabName) {
      this.selectedTab = tabName;
    },
    editAccount() {
      this.$router.push({ name: 'Edit Account', params: {id: this.id}})
    },
    resetCooperation(){
      this.coperationEducation = false
      this.coperationManpower = false
      this.coperationCuration = false
      this.coperationPrakerja = false
      this.coperationMarketplace = false
      this.coperationProject = false
    },
    fetchAccountData(id) {
      this.getAccountById({ id: id }).then((res) => {
        this.payload = res
        this.primaryId = res.sales.primaryContact.id
        res.sales.contacts.forEach(item => {
          this.primaryContact.push({
            id: item.contactId,
            name: item.name
          })
        })
        this.resetCooperation()
        for (var i = 0; i < this.payload.cooperationInterest.length; i++) {
            if (this.payload.cooperationInterest[i].name === 'Education') this.coperationEducation = true;
            else if (this.payload.cooperationInterest[i].name === 'Manpower') this.coperationManpower = true;
            else if (this.payload.cooperationInterest[i].name === 'Project') this.coperationProject = true;
            else if (this.payload.cooperationInterest[i].name === 'Curation') this.coperationCuration = true;
            else if (this.payload.cooperationInterest[i].name === 'Marketplace(Eduverse)') this.coperationMarketplace = true;
            else if (this.payload.cooperationInterest[i].name === 'LMS & Prakerja') this.coperationPrakerja = true;
        }
      })
    },
    payloadDescription(){
      return this.payload.description;
    },
    handleChange(changeId) {
      this.updatePrimaryContact({
        accountId: this.id,
        contactId: changeId,
        requestBody: {}
      }).then(() => {
        notification.successMessage('Successfully set Primary Contact!')
      }).catch(() => {
        notification.errorMessage('Failed to set Primary Contact!')
      })
    },
    addContact() {
      this.$router.push({ name: 'Add Contact', params: {accId: this.payload.id, accName: this.payload.accountName}})
    }
  },
};