import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
const ImportFileModal = () => import(/* webpackChunkName: "import-file-modal" */ '@/components/ImportFileModal')
const ResultFileModal = () => import(/* webpackChunkName: "result-file-modal" */ '@/components/ResultFileModal')
const FirstTemplate = () => import(/* webpackChunkName: "first-template" */ '@/templates/FirstTemplate')
export default {
  name: 'eduverse',
  components: { ImportFileModal, ResultFileModal, FirstTemplate },
  data: () => ({
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    activeChannelType: 'WEB_EDUVERSE',
    startDate: '',
    endDate: '',
    forEndDate: '',
    minimalDate: '',
    maximalDate: '',
    keyword: '',
    hide: true,
    enabled: true,
    disabled: false,
    isLoading: true,
    studentList: [],
    filterSelect: [],
    forOptions: [
      {
        label: 'UTM',
        options: [
          { id: '', name: 'All' },
          { id: 'Facebook Ads', name: 'Facebook Ads' },
          { id: 'LinkedIn Ads', name: 'LinkedIn Ads' },
          { id: 'Google Ads', name: 'Google Ads' },
          { id: 'Placement', name: 'Placement' },
          { id: 'EDM', name: 'EDM' },
          { id: 'Website', name: 'Website' },
          { id: 'blank', name: '-' }
        ]
      }
    ]
  }),
  computed: {
    forFilterSelect: function() {
      let arr = []
      for (let i = 0; i < this.forOptions.length; i++) {
        arr.push({
          name: this.forOptions[i].label,
          value: this.forOptions[i].options[0].id
        })
      }
      return arr
    },
    forFilter: function() {
      return [
        {
          label: this.forOptions[0].label,
          options: this.forOptions[0].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[0].value : this.forFilterSelect[0].value,
          writeValue: response => (this.forFilterSelect[0].value = response),
          disabled: true
        }
      ]
    },
    forInput: function() {
      return [
        {
          label: 'Start Date',
          type: 'date',
          max: this.maximalDate,
          readValue: this.startDate,
          writeValue: value => (this.startDate = value),
          disabled: true
        },
        {
          label: 'End Date',
          type: 'date',
          max: this.maximalDate,
          min: this.minimalDate,
          disabled: this.enabled,
          readValue: this.forEndDate,
          writeValue: value => (this.forEndDate = value)
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.toEditSiswa('add', 'tambah'),
          text: 'Add',
          type: 'tertiary',
          size: 'small',
          hide: true
        },
        {
          action: () => this.showUpload(),
          text: 'Import',
          type: 'secondary',
          size: 'small',
          hide: true
        },
        {
          action: () => this.download(),
          text: this.disabled ? 'Downloading' : 'Download',
          type: 'primary',
          size: 'small',
          disabled: this.disabled
        }
      ]
    },
    firstButtons: function() {
      return [
        {
          action: () => this.filter(),
          text: 'Apply',
          type: 'primary',
          size: 'small',
          disabled: true
        },
        {
          action: () => this.clearFilter(),
          text: 'Clear',
          type: 'tertiary',
          size: 'small',
          hide: this.hide
        }
      ]
    },
    secondButtons: function() {
      return [
        {
          action: () => this.searchStudent(this.keyword),
          text: 'Search',
          type: 'primary',
          size: 'small'
        }
      ]
    }
  },
  watch: {
    startDate: function() {
      if (this.startDate === '') {
        this.hide = true
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate === this.maximalDate) {
        this.hide = false
        this.forEndDate = this.startDate
        this.minimalDate = ''
        this.enabled = true
      } else if (this.startDate > this.maximalDate) {
        this.hide = false
        notification.errorMessage('Please enter the start date correctly!')
        this.minimalDate = ''
        this.forEndDate = ''
        this.enabled = true
      } else {
        this.hide = false
        this.enabled = false
        this.minimalDate = this.startDate
      }
    },
    forEndDate: function() {
      this.endDate = converter.datePlusDay(this.forEndDate, 1)
    },
    forFilterSelect: {
      handler: function(newValue) {
        this.filterSelect = newValue
      },
      immediate: true
    },
    filterSelect: {
      handler: function() {
        this.filterSelect.forEach(element => {
          this.forOptions.forEach(item => {
            if (element.value !== item.options[0].id) {
              this.hide = false
            }
          })
        })
      },
      deep: true
    }
  },
  mounted() {
    this.isLoading = true
    this.maximalDate = converter.date(new Date())
    this.getAllStudent()
  },
  methods: {
    ...mapActions(['getContactList', 'downloadContact', 'showLoading', 'hideLoading']),
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getAllStudent()
    },
    async download() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.disabled = true
      await this.downloadContact(
        this.startDate !== '' && this.endDate !== ''
          ? {
              requestBody: {
                query: this.keyword,
                queryUtm: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
                startDate: this.startDate,
                endDate: this.endDate,
                channelType: this.activeChannelType
              }
            }
          : {
              requestBody: {
                query: this.keyword,
                queryUtm: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
                channelType: this.activeChannelType
              }
            }
      )
        .then(res => this.createDownloadFile(res))
        .catch(() => notification.errorMessage('Download failed, please try again later!'))
      await delay(3000)
      this.disabled = false
    },
    createDownloadFile(res) {
      var fileURL = window.URL.createObjectURL(new Blob([res]))
      var fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `${this.$route.name} - ${converter.dateTimeView(new Date())}.csv`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    getAllStudent() {
      if (this.startDate !== '' && this.endDate === '') {
        notification.errorMessage('Please input the start date and end date!')
      } else if (this.startDate !== '' && this.endDate !== '') {
        this.showLoading()
        this.getContactList({
          params: {
            page: this.currentPage,
            size: this.size,
            query: this.keyword,
            queryUtm: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
            startDate: this.startDate,
            endDate: this.endDate,
            channelType: this.activeChannelType
          }
        }).then(res => this.distributePayload(res))
      } else {
        this.showLoading()
        this.getContactList({
          params: {
            page: this.currentPage,
            size: this.size,
            query: this.keyword,
            queryUtm: this.filterSelect.length > 0 ? this.filterSelect[0].value : '',
            channelType: this.activeChannelType
          }
        }).then(res => {
          this.distributePayload(res)
        })
      }
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getAllStudent()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.studentList = []
      res.data.forEach(student => {
        this.studentList.push({
          firstQuery: student.id,
          secondQuery: student.id,
          firstColumn: student.fullName,
          firstColumnDesc: student.email,
          secondColumn: student.phoneNumber,
          thirdColumn: student.utm ? student.utm : '-',
          fourthColumn: student.createdDate ? this.toUserFriendlyTime('date', converter.dateTimeView(student.createdDate), undefined) : '-',
          fourthColumnDesc: student.createdDate ? this.toUserFriendlyTime('hour', converter.dateTimeView(student.createdDate), undefined) : undefined
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    toUserFriendlyTime(time, firstText, secondText) {
      switch (time) {
        case 'date':
          if (secondText) {
            return `${firstText.slice(0, -6)} - ${secondText.slice(0, -6)}`
          } else {
            return firstText.slice(0, -6)
          }
        case 'hour':
          if (secondText) {
            return `${firstText.slice(-6)} - ${secondText.slice(-6)} (GMT+7)`
          } else {
            return `${firstText.slice(-6)} (GMT+7)`
          }
      }
    },
    searchStudent(keyword) {
      this.keyword = keyword
      this.currentPage = 0
      this.getAllStudent()
    },
    filter() {
      if (this.forEndDate > this.maximalDate) {
        notification.errorMessage('Please enter the end date correctly!')
      } else if (this.startDate > this.forEndDate) {
        notification.errorMessage('Please enter the start date or end date correctly!')
      } else {
        this.filterSelect = this.forFilterSelect
        this.currentPage = 0
        this.getAllStudent()
      }
    },
    clearFilter() {
      this.hide = true
      this.startDate = ''
      for (let i = 0; i < this.forFilterSelect.length; i++) {
        this.forFilterSelect[i].value = this.forOptions[i].options[0].id
      }
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getAllStudent()
    },
    clearSearch() {
      this.keyword = ''
      this.currentPage = 0
      this.getAllStudent()
    },
    toEditSiswa(id, studentLmsClassId) {
      this.$router.push({
        name: 'Edit Siswa',
        params: {
          classType: 'eduverse',
          programId: '0',
          description: '0',
          id,
          studentLmsClassId,
          otherId: '0'
        }
      })
    }
  }
}
