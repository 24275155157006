import api from '@/api'

const actions = {
  getAllPartnersPage: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllPartnersPage(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllPartnersByAM: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllPartnersByAM(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getPipelineByMitra: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.partner.getPipelineByMitra(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllPipelines: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllPipelines().then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllPipelinesWithAllStatus: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllPipelinesWithAllStatus(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllPipelinesByAM: ({ commit }, { amId }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllPipelinesByAM(amId).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllAM: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllAM().then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getPipelineById: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.partner.getPipelineById(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getMitraById: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.partner.getMitraById(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllAgreement: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllAgreement().then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllAgreementByAM: ({ commit }, { amId }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllAgreementByAM(amId).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAgreementById: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAgreementById(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  uploadAgreementDocument: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      api.partner.uploadAgreementDocument(data).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  addAgreement: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.addAgreement(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateAgreement: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.updateAgreement(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  addPipeline: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.addPipeline(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  createAMAccount: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.createAMAccount(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  createManagementAccount: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.createManagementAccount(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updatePipeline: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.updatePipeline(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getPipelineHistory: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.partner.getPipelineHistory(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateAMAccount: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.updateAMAccount(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateManagementAccount: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.updateManagementAccount(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updatePriority: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.updatePriority(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateNote: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.updateNote(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateParkir: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.partner.updateParkir(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateDuplicate: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.partner.updateDuplicate(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateAM: ({ commit }, { id, params }) => {
    return new Promise((resolve, reject) => {
      api.partner.updateAM(id, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateUser: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.partner.updateUser(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllManagement: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllManagement().then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllPartners: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllPartners().then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllAccounts: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.b2b.getAllAccounts(params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAccountById: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.b2b.getAccountById(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  addAccount: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.b2b.addAccount(requestBody).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateAccount: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.b2b.updateAccount(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getLookup: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.b2b.getLookup(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllContacts: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.b2b.getAllContacts(params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getContactById: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.b2b.getContactById(id).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  addContact: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.b2b.addContact(requestBody).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  updateContact: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.b2b.updateContact(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  addAccountByContact: ({ commit }, { contactId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.b2b.addAccountByContact(contactId, requestBody).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  deleteAccountByContact: ({ commit }, { contactId, accountId }) => {
    return new Promise((resolve, reject) => {
      api.b2b.deleteAccountByContact(contactId, accountId).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  updatePrimaryAccount: ({ commit }, { contactId, accountId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.b2b.updatePrimaryAccount(contactId, accountId, requestBody).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  updatePrimaryContact: ({ commit }, { accountId, contactId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.b2b.updatePrimaryContact(accountId, contactId, requestBody).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  }
}

export default { actions }
