import Pagination from '@/molecules/others/Pagination'
const KeywordSearch = () => import(/* webpackChunkName: "keyword-search" */ '@/components/KeywordSearch')
// const Pagination = () => import(/* webpackChunkName: "pagination" */ '@/molecules/others/Pagination')
const TooltipTopLeft = () => import(/* webpackChunkName: "tooltip-top-left" */ '@/atoms/others/TooltipTopLeft')
const TooltipTopRight = () => import(/* webpackChunkName: "tooltip-top-right" */ '@/atoms/others/TooltipTopRight')
const SelectWithLabel = () => import(/* webpackChunkName: "selectt" */ '@//molecules/forms/SelectWithLabel')

export default {
  name: 'table-list',
  components: { 
    KeywordSearch, 
    Pagination, 
    TooltipTopLeft, 
    TooltipTopRight,
    SelectWithLabel
  },
  props: {
    title: { type: String },
    firstButton: { type: String },
    actionFirstButton: { type: Function },
    secondButton: { type: String },
    actionSecondButton: { type: Function },
    searchingLabel: { type: String },
    inputPlaceholder: { type: String },
    searchThis: { type: Function },
    tableHeader: { type: Array },
    tableHeaderAlign: { type: Array },
    tooltip: { type: Array },
    secondTooltip: { type: Array },
    tooltipSize: { required: false },
    list: { type: Array },
    listAlign: { type: Array },
    actionButtons: { type: Array },
    actualPage: { required: true },
    counter: { required: true },
    totalPages: { required: false },
    totalItems: { required: false },
    size: { required: false },
    filter: { type: Object },
    changePage: { type: Function }
  },
  methods: {
    actionForFirstButton() {
      if (this.firstButton) {
        this.actionFirstButton()
      }
    },
    actionForSecondButton() {
      if (this.secondButton) {
        this.actionSecondButton()
      }
    }
  }
}
