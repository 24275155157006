<template>
  <div class="flex flex-col">
    <label for="multiple-select-with-label" class="text-sm pl-2 text-custom-black-1" :class="[optionLabelFocus, optionLabelError]">{{ labelText }}</label>
    <v-select
      name="multiple-select-with-label"
      class="text-sm mt-1 rounded-md"
      :class="[isFocus ? 'focus' : 'blur', optionFocus, optionError, optionDisabled]"
      :errorValue="errorValue"
      :disabled="disabled"
      :readValue="readValue"
      :writeValue="writeValue"
      :placeholder="placeholder"
      :clearable="false"
      :searchable="false"
      deselectFromDropdown
      multiple
      pushTags
      @open="setFocusTrue"
      @close="setFocusFalse"
      label="name"
      :reduce="option => option.id"
      :options="options"
      v-model="modelValue"
    >
      <template #open-indicator="{ attributes }">
        <div v-if="modelValue.length < 1" class="w-32 -ml-5">{{ placeholder ? placeholder : 'All' }}</div>
        <div v-else-if="modelValue.length === options.length" class="w-32 -ml-5">All items selected</div>
        <div v-else class="w-32 -ml-5">{{ modelValue.length }} items selected</div>
        <svg v-bind="attributes" width="20" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.25 8.62501L12 15.375L18.75 8.62501" fill="transparent" stroke="#333333" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </template>
      <template #option="{ id, name }">
        <p class="text-sm" :class="[checkSelected(id) ? 'text-yellow font-semibold' : '']">
          {{ name.length > textLength ? name.substr(0, textLength - 2) + '..' : name }}
        </p>
      </template>
      <template #selected-option-container="{}">
        <div />
      </template>
    </v-select>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
  name: 'multiple-select-with-label',
  components: { 'v-select': vSelect },
  props: {
    labelText: { type: String, required: true },
    placeholder: { type: String },
    textLength: { default: 5 },
    options: { type: Array },
    errorValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    readValue: { type: Array, required: true },
    writeValue: { type: Function, required: true }
  },
  data: () => ({
    modelValue: [],
    isFocus: false
  }),
  computed: {
    optionLabelFocus() {
      return this.isFocus ? 'text-yellow' : ''
    },
    optionLabelError() {
      return this.errorValue ? 'text-red' : ''
    },
    optionFocus() {
      return this.isFocus ? 'border-yellow' : ''
    },
    optionError() {
      return this.errorValue ? 'border-red' : ''
    },
    optionDisabled() {
      return this.disabled ? 'bg-grey-e cursor-not-allowed' : ''
    }
  },
  watch: {
    readValue() {
      this.modelValue = this.readValue
    },
    modelValue: function() {
      this.writeValue(this.modelValue)
    }
  },
  methods: {
    checkSelected(id) {
      return this.modelValue.includes(id)
    },
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    }
  }
}
</script>
<style lang="scss" scoped>
li {
  font-size: 10px;
}
.blur,
.focus {
  --vs-border-color: #adadad;
  --vs-border-radius: 6px;
  --vs-font-size: 14px;
  --vs-line-height: 2;
  --vs-dropdown-option--active-bg: transparent;
  --vs-dropdown-option--deselect-bg: transparent;
  --vs-dropdown-option--active-color: #f7931e;
  --vs-dropdown-max-height: 500%;
  --vs-dropdown-min-width: 100%;
  --vs-dropdown-option-padding: 0px 10px;
  --vs-disabled-bg: #e8e8e8;
  --vs-disabled-border-radius: 6px;
}
.focus {
  --vs-border-color: #f7931e;
}
</style>
