const ButtonsGroup = () => import(/* webpackChunkName: "buttons-group" */ '@/molecules/tags/ButtonsGroup')
const Modal = () => import(/* webpackChunkName: "modal" */ '@/atoms/tags/Modal')
const SelectWithLabel = () => import(/* webpackChunkName: "select-with-label" */ '@/molecules/forms/SelectWithLabel')
const FirstTemplate = () => import(/* webpackChunkName: "first-template" */ '@/templates/FirstTemplate')
import { mapActions } from 'vuex'
import { notification } from '@/util'
export default {
  name: 'status',
  components: { ButtonsGroup, Modal, SelectWithLabel, FirstTemplate },
  data: () => ({
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    programId: '',
    programName: '',
    stageId: '',
    stageName: '',
    newStageId: '',
    isLoading: true,
    isModal: false,
    isModalChangeStatus: false,
    canNotUpdate: true,
    availableOptions: [],
    options: [],
    statusList: [],
    forOptions: [
      {
        label: 'Program',
        options: [{ id: '', name: 'All' }]
      }
    ]
  }),
  computed: {
    customerId: function() {
      return localStorage.getItem('client_id')
    },
    select: function() {
      return {
        label: 'New Status',
        textLength: 40,
        maxHeight: '80px',
        options: this.availableOptions,
        readValue: this.newStageId,
        writeValue: value => (this.newStageId = value)
      }
    },
    forFilterSelect: function() {
      let arr = []
      for (let i = 0; i < this.forOptions.length; i++) {
        arr.push({
          name: this.forOptions[i].label,
          value: i === 0 ? '' : this.forOptions[i].options[0].id
        })
      }
      return arr
    },
    forFilter: function() {
      return [
        {
          label: this.forOptions[0].label,
          options: this.forOptions[0].options,
          readValue: this.filterSelect.length > 0 ? this.filterSelect[0].value : this.forFilterSelect[0].value,
          writeValue: response => (this.forFilterSelect[0].value = response)
        }
      ]
    },
    buttonsModal: function() {
      return [
        {
          action: () => this.hideModal(),
          text: 'No',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.deleteStatus(),
          text: 'Yes',
          type: 'secondary',
          size: 'small'
        }
      ]
    },
    buttonsModalChangeStatus: function() {
      return [
        {
          action: () => this.hideModalChangeStatus(),
          text: 'Cancel',
          type: 'tertiary',
          size: 'small'
        },
        {
          action: () => this.updateStatus(),
          text: 'Update',
          type: 'secondary',
          size: 'small',
          disabled: this.canNotUpdate
        }
      ]
    },
    buttons: function() {
      return [
        {
          action: () => this.toUpdateSetting('add'),
          text: 'Add',
          size: 'small'
        },
        {
          action: () => this.showUpload(),
          text: 'Import',
          type: 'secondary',
          size: 'small',
          hide: true
        }
      ]
    },
    firstButtons: function() {
      return [
        {
          action: () => this.filter(),
          text: 'Apply',
          type: 'primary',
          size: 'small'
        },
        {
          action: () => {},
          text: 'Clear',
          type: 'tertiary',
          size: 'small',
          hide: true
        }
      ]
    }
  },
  watch: {
    programId: function() {
      this.getOptions()
    },
    newStageId: function() {
      this.newStageId === '' ? (this.canNotUpdate = true) : (this.canNotUpdate = false)
    },
    forFilterSelect: {
      handler: function(newValue) {
        this.filterSelect = newValue
      },
      immediate: true
    },
    filterSelect: {
      handler: function() {
        this.filterSelect[0].value !== '' ? (this.hide = false) : (this.hide = true)
      },
      deep: true
    }
  },
  mounted() {
    this.isLoading = true
    this.getProgramId()
  },
  methods: {
    ...mapActions(['getStatusList', 'getAllPrograms', 'deletePipelineStage', 'deleteUpdatePipelineStage', 'getPipelineStages', 'showLoading', 'hideLoading']),
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getAllStatus()
    },
    getProgramId() {
      this.forOptions[0].options = []
      this.getAllPrograms({}).then(async res => {
        await res.forEach(item => {
          if (item.code === 'Bootcamp') this.forFilterSelect[0].value = this.programId = item.id
          this.forOptions[0].options.push({ id: item.id, name: item.name })
        })
        this.getAllStatus()
      })
    },
    getOptions() {
      this.getPipelineStages({ params: { programId: this.programId, customerId: this.customerId } }).then(res => {
        this.options = []
        for (let j = 0; j < res.length; j++) {
          this.options.push({
            id: `${res[j].id}`,
            name: res[j].name
          })
        }
      })
    },
    reduceOptions(id) {
      let arrayOptions = []
      for (let i = 0; i < this.options.length; i++) {
        if (this.options[i].id !== `${id}`) arrayOptions.push({ id: `${this.options[i].id}`, name: this.options[i].name })
      }
      this.availableOptions = arrayOptions
    },
    getAllStatus() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.showLoading()
      this.getStatusList({
        customerId: this.customerId,
        params: {
          page: this.currentPage,
          size: this.size,
          sort_by: 'showSeqNo',
          programId: this.filterSelect.length > 0 ? `${this.filterSelect[0].value}` : ''
        }
      })
        .then(res => this.distributePayload(res))
        .catch(async error => {
          switch (error) {
            case '404':
              this.counter === 3 ? (this.counter = 0) : this.counter++
              this.currentPage = 0
              this.totalItems = 0
              this.totalPages = 1
              this.statusList = []
              break
            default:
              this.showLoading()
              notification.errorMessage('Failed to load data, please try again later!')
              await delay(3500)
              this.$router.go()
              break
          }
        })
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getAllStatus()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.programId = res.data.length > 0 ? `${res.data[0].program_id}` : '0'
      this.statusList = []
      if (res.data.length > 0) {
        res.data.forEach(status => {
          this.statusList.push({
            firstQuery: status.id,
            secondQuery: status.name,
            thirdQuery: status.program_name,
            firstColumn: status.name,
            secondColumn: status.program_name ? status.program_name : '-',
            thirdColumn: status.seq_no ? `${status.seq_no}` : '-'
          })
        })
      }
      this.isLoading = false
      this.hideLoading()
    },
    filter() {
      this.filterSelect = this.forFilterSelect
      this.currentPage = 0
      this.getAllStatus()
    },
    deleteStatus() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.showLoading()
      this.deletePipelineStage({ stageId: this.stageId, customerId: this.customerId })
        .then(async () => {
          notification.successMessage(`Successfully delete status!`)
          this.hideModal()
          await delay(3500)
          this.isLoading = true
          this.getAllStatus()
        })
        .catch(async error => {
          switch (error) {
            case '422':
              this.hideModal()
              this.showModalChangeStatus()
              await delay(3500)
              this.hideLoading()
              break
            default:
              notification.errorMessage('Failed to load data, please try again later!')
              await delay(3500)
              this.hideLoading()
              break
          }
        })
    },
    updateStatus() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.showLoading()
      this.deleteUpdatePipelineStage({
        stageId: this.stageId,
        customerId: this.customerId,
        requestBody: {
          new_stage_id: this.newStageId,
          program_id: this.programId
        }
      })
        .then(async () => {
          notification.successMessage(`Successfully delete and change status!`)
          this.hideModalChangeStatus()
          await delay(3500)
          this.isLoading = true
          this.getAllStatus()
        })
        .catch(async () => {
          notification.errorMessage('Failed to delete and change status, please try again later!')
          await delay(3500)
          this.hideLoading()
        })
    },
    showModal(stageId, stageName, programName) {
      this.reduceOptions(stageId)
      this.stageId = stageId
      this.stageName = stageName
      this.programName = programName
      this.isModal = true
    },
    hideModal() {
      this.isModal = false
    },
    showModalChangeStatus() {
      this.isModalChangeStatus = true
    },
    hideModalChangeStatus() {
      this.newStageId = ''
      this.isModalChangeStatus = false
    },
    toUpdateSetting(id) {
      this.$router.push({
        name: 'Create Update',
        params: {
          submenu: 'status',
          id
        }
      })
    }
  }
}
