import { render, staticRenderFns } from "./EditSiswa.vue?vue&type=template&id=0be9554f&scoped=true&"
import script from "./js/edit-siswa.js?vue&type=script&lang=js&"
export * from "./js/edit-siswa.js?vue&type=script&lang=js&"
import style0 from "./EditSiswa.vue?vue&type=style&index=0&id=0be9554f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0be9554f",
  null
  
)

export default component.exports