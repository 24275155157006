<template>
  <div>
    <div class="px-2 py-5 border rounded-lg bg-white">
      <div class="flex flex-col">
        <div class="flex" v-for="(input, index) in inputs" :key="'input-' + index">
          <div v-show="!input.mode" class="px-5 py-2 w-1/2 md:w-full">
            <InputWithLabel
              :labelText="input.label"
              :type="input.type"
              :placeholder="input.placeholder"
              :max="input.max"
              :min="input.min"
              :maxlength="input.maxlength"
              :minlength="input.minlength"
              :autofocus="input.autofocus"
              :errorValue="input.errorValue"
              :disabled="input.disabled"
              :hide="input.hide"
              :value="input.value"
              :customValuePlus="input.customValuePlus"
              :customValueMinus="input.customValueMinus"
              :readValue="input.readValue"
              :writeValue="input.writeValue"
            />
            <p :class="input.description ? 'pl-2 pt-1 text-xs text-custom-black-1' : 'hide'">{{ input.description }}</p>
          </div>
          <div v-show="input.mode === 'select'" class="px-5 py-2 w-1/2 md:w-full">
            <SelectWithLabel
              :labelText="input.label"
              :textLength="input.textLength"
              :options="input.options"
              :errorValue="input.errorValue"
              :disabled="input.disabled"
              :hide="input.hide"
              :readValue="input.readValue"
              :writeValue="input.writeValue"
            />
            <p :class="input.description ? 'pl-2 pt-1 text-xs text-custom-black-1' : 'hide'">{{ input.description }}</p>
          </div>
        </div>
        <div class="px-5 py-2 w-1/2 md:w-full">
          <ButtonsGroup :flexDirection="flexDirection" :justifyContent="justifyContent" :buttons="buttons" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const InputWithLabel = () => import(/* webpackChunkName: "input-with-label" */ '@/molecules/forms/InputWithLabel')
const SelectWithLabel = () => import(/* webpackChunkName: "select-with-label" */ '@/molecules/forms/SelectWithLabel')
const ButtonsGroup = () => import(/* webpackChunkName: "buttons-group" */ '@/molecules/tags/ButtonsGroup')
export default {
  name: 'form-card',
  components: { InputWithLabel, SelectWithLabel, ButtonsGroup },
  props: {
    inputs: { type: Array },
    flexDirection: { type: String, default: 'row' },
    justifyContent: { type: String, default: 'start' },
    buttons: { type: Array, required: true }
  }
}
</script>
