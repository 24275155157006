import axios from 'axios'
import { endpoint } from '@/constant'
import { endpointFunction } from '@/util'

const event = {
  createEvent: requestBody => {
    const url = `${endpoint.events}`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  createOrientationSchedule: requestBody => {
    const url = `${endpoint.jadwalOrientasi}`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  createOpenDay: requestBody => {
    const url = `${endpoint.openDays}`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  deleteEvent: id => {
    const url = `${endpoint.events}/${id}`
    return axios.delete(url, { headers: endpointFunction.getAuthHeader() })
  },
  deleteOrientationSchedule: id => {
    const url = `${endpoint.jadwalOrientasi}/${id}`
    return axios.delete(url, { headers: endpointFunction.getAuthHeader() })
  },
  deletePaymentReceiptEvent: id => {
    const url = `${endpoint.events}/participants/${id}/payment-receipts`
    return axios.delete(url, { headers: endpointFunction.getAuthHeader() })
  },
  editEvent: (id, requestBody) => {
    const url = `${endpoint.events}/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  editOrientationSchedule: (id, requestBody) => {
    const url = `${endpoint.jadwalOrientasi}/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  enrollEvent: (eventId, requestBody) => {
    const url = `${endpoint.events}/${eventId}/participants`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  getAttendanceOfOrientation: id => {
    const url = `${endpoint.jadwalOrientasi}/findAbsen/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getDetailPaymentReceiptEvent: (id, params) => {
    const url = `${endpoint.events}/participants/${id}/payment-receipts${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getEventById: id => {
    const url = `${endpoint.events}/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getEventList: params => {
    const url = `${endpoint.events}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getEventParticipantLog: (eventParticipantId, params) => {
    const url = `${endpoint.events}/participants/${eventParticipantId}/stages${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getOpenDay: id => {
    const url = `${endpoint.openDays}/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getOpenDayList: params => {
    const url = `${endpoint.openDays}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getOrientationScheduleDetail: id => {
    const url = `${endpoint.jadwalOrientasi}/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getOrientationScheduleList: params => {
    const url = `${endpoint.crmV2}/orientation-schedule-list${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getParticipantList: (eventId, params) => {
    const url = `${endpoint.openDays}/${eventId}/attendances${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  reschedule: requestBody => {
    const url = `${endpoint.jadwalOrientasi}/kirim-orientasi-ulang`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  sendInvoice: requestBody => {
    const url = `${endpoint.jadwalOrientasi}/kirim-prabootcamp`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateEventParticipantLog: (eventParticipantId, requestBody) => {
    const url = `${endpoint.events}/participants/${eventParticipantId}`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateInterest: id => {
    const url = `${endpoint.jadwalOrientasi}/update-interest/${id}`
    return axios.put(url, { headers: endpointFunction.getAuthHeader() })
  },
  updateOpenDay: (id, requestBody) => {
    const url = `${endpoint.openDays}/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updatePaymentReceiptEvent: (id, requestBody) => {
    const url = `${endpoint.events}/participants/${id}/payment-receipts`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  uploadPaymentReceiptEvent: (id, requestBody) => {
    const url = `${endpoint.events}/participants/${id}/payment-receipts`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  }
}

export default event
