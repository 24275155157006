import { mapActions } from 'vuex'
export default {
  name: 'server-down',
  mounted() {
    this.checking()
  },
  methods: {
    ...mapActions(['checkServer']),
    checking() {
      this.checkServer({})
        .then(() => this.$router.push('/login'))
        .catch(() => {})
    }
  }
}
