import { mapActions } from 'vuex'

export default {
  name: 'set-parkir-modal',
  props: {
    isVisible: { type: Boolean, required: true },
    closed: { type: Function, required: true },
    selectedItem: { type: Object, required: true }
  },
  watch: {
    isVisible: function(newStatus) {
      newStatus ? this.$modal.show('set-parkir') : this.$modal.hide('set-parkir')
    }
  },
  data() {
    return {
      statusOptions: [
        { name: 'ACTIVE', display: 'Aktif' },
        { name: 'INACTIVE', display: 'Tidak Aktif' }
      ]
    }
  },
  methods: {
    ...mapActions(['updateParkir']),
    async simpan() {
      await this.updateParkir({
        id: this.selectedItem.id,
        requestBody: {
          status: this.selectedItem.status
        }
      }).then(window.location.reload())
    }
  }
}
