<template>
  <div>
    <PageHeader
      v-show="visibility[0]"
      :generalTitle="generalTitle"
      :action="action"
      :pageName="pageName"
      :realRoute="realRoute"
      :routePath="routePath"
      :flexDirection="flexDirection"
      :justifyContent="justifyContent"
      :buttons="buttons"
    />
    <FindingData
      v-show="visibility[1]"
      :filters="filters"
      :inputs="inputs"
      :searchable="searchable"
      :inputIconSVGAdditionalIconStyle="inputIconSVGAdditionalIconStyle"
      :inputIconSVGIconAction="inputIconSVGIconAction"
      :inputIconSVGHeightIcon="inputIconSVGHeightIcon"
      :inputIconSVGWidthIcon="inputIconSVGWidthIcon"
      :inputIconSVGViewBox="inputIconSVGViewBox"
      :paths="paths"
      :inputIconSVGFillIconDefault="inputIconSVGFillIconDefault"
      :inputIconSVGFillIconFocus="inputIconSVGFillIconFocus"
      :inputIconSVGLabelText="inputIconSVGLabelText"
      :inputIconSVGType="inputIconSVGType"
      :inputIconSVGPlaceholder="inputIconSVGPlaceholder"
      :inputIconSVGMax="inputIconSVGMax"
      :inputIconSVGMin="inputIconSVGMin"
      :inputIconSVGMaxlength="inputIconSVGMaxlength"
      :inputIconSVGMinlength="inputIconSVGMinlength"
      :inputIconSVGAutofocus="inputIconSVGAutofocus"
      :inputIconSVGErrorValue="inputIconSVGErrorValue"
      :inputIconSVGDisabled="inputIconSVGDisabled"
      :inputIconSVGValue="inputIconSVGValue"
      :inputIconSVGCustomValuePlus="inputIconSVGCustomValuePlus"
      :inputIconSVGCustomValueMinus="inputIconSVGCustomValueMinus"
      :inputIconSVGReadValue="inputIconSVGReadValue"
      :inputIconSVGWriteValue="inputIconSVGWriteValue"
      :firstButtonFlexDirection="firstButtonFlexDirection"
      :firstButtonJustifyContent="firstButtonJustifyContent"
      :firstButtonButtons="firstButtonButtons"
      :secondButtonFlexDirection="secondButtonFlexDirection"
      :secondButtonJustifyContent="secondButtonJustifyContent"
      :secondButtonButtons="secondButtonButtons"
      :thirdButtonFlexDirection="thirdButtonFlexDirection"
      :thirdButtonJustifyContent="thirdButtonJustifyContent"
      :thirdButtonButtons="thirdButtonButtons"
      :selectedData="selectedData"
      :fourthButtonFlexDirection="fourthButtonFlexDirection"
      :fourthButtonJustifyContent="fourthButtonJustifyContent"
      :fourthButtonButtons="fourthButtonButtons"
    />
    <TableList
      v-show="visibility[2]"
      :tableHeader="tableHeader"
      :tableHeaderAlign="tableHeaderAlign"
      :checkbox="checkbox"
      :actionCheckbox="actionCheckbox"
      :textToAction="textToAction"
      :tooltip="tooltip"
      :secondTooltip="secondTooltip"
      :tooltipSize="tooltipSize"
      :list="list"
      :listAlign="listAlign"
      :actionButtons="actionButtons"
      :totalPages="totalPages"
      :totalItems="totalItems"
      :currentPage="currentPage"
      :size="size"
      :counter="counter"
      :maxVisiblePages="maxVisiblePages"
      :changePage="changePage"
      :changeSize="changeSize"
      :headerTextNotFound="headerTextNotFound"
      :descriptionTextNotFound="descriptionTextNotFound"
      :actionNotFound="actionNotFound"
    />
  </div>
</template>
<script>
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/organisms/static/PageHeader')
const FindingData = () => import(/* webpackChunkName: "finding-data" */ '@/organisms/dynamic/FindingData')
const TableList = () => import(/* webpackChunkName: "table-list" */ '@/organisms/static/TableList')
export default {
  name: 'first-template',
  components: { PageHeader, FindingData, TableList },
  props: {
    visibility: { type: Array, required: true },
    generalTitle: { type: Boolean, default: true },
    action: { type: Function, default: () => {} },
    pageName: { type: String },
    realRoute: { type: String },
    routePath: { type: String },
    flexDirection: { type: String, default: 'row' },
    justifyContent: { type: String, default: 'start' },
    buttons: {
      type: Array,
      default: () => {
        return []
      }
    },
    filters: {
      type: Array,
      default: () => {
        return []
      }
    },
    inputs: {
      type: Array,
      default: () => {
        return []
      }
    },
    searchable: { type: Boolean, default: true },
    inputIconSVGAdditionalIconStyle: { type: String, default: '' },
    inputIconSVGIconAction: { type: Function, default: () => {} },
    inputIconSVGHeightIcon: { type: String, default: '20' },
    inputIconSVGWidthIcon: { type: String, default: '20' },
    inputIconSVGViewBox: { type: String, default: '0 0 20 20' },
    paths: {
      type: Array,
      default: () => {
        return []
      }
    },
    inputIconSVGFillIconDefault: { type: String, default: '#000000' },
    inputIconSVGFillIconFocus: { type: String, default: '#000000' },
    inputIconSVGLabelText: { type: String, default: 'label' },
    inputIconSVGType: { type: String, default: 'text' },
    inputIconSVGPlaceholder: { type: String },
    inputIconSVGMax: { required: false },
    inputIconSVGMin: { required: false },
    inputIconSVGMaxlength: { required: false },
    inputIconSVGMinlength: { required: false },
    inputIconSVGAutofocus: { type: Boolean, default: false },
    inputIconSVGErrorValue: { type: Boolean, default: false },
    inputIconSVGDisabled: { type: Boolean, default: false },
    inputIconSVGValue: { type: String },
    inputIconSVGCustomValuePlus: { type: Number },
    inputIconSVGCustomValueMinus: { type: Number },
    inputIconSVGReadValue: { type: String, default: 'value' },
    inputIconSVGWriteValue: { type: Function, default: () => {} },
    firstButtonFlexDirection: { type: String, default: 'row' },
    firstButtonJustifyContent: { type: String, default: 'start' },
    firstButtonButtons: {
      type: Array,
      default: () => {
        return []
      }
    },
    secondButtonFlexDirection: { type: String, default: 'row' },
    secondButtonJustifyContent: { type: String, default: 'start' },
    secondButtonButtons: {
      type: Array,
      default: () => {
        return []
      }
    },
    thirdButtonFlexDirection: { type: String, default: 'row' },
    thirdButtonJustifyContent: { type: String, default: 'start' },
    thirdButtonButtons: {
      type: Array,
      default: () => {
        return []
      }
    },
    fourthButtonFlexDirection: { type: String, default: 'row' },
    fourthButtonJustifyContent: { type: String, default: 'end' },
    fourthButtonButtons: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectedData: {
      type: Array,
      default: () => {
        return []
      }
    },
    tableHeader: { type: Array },
    tableHeaderAlign: { type: Array },
    checkbox: { type: Boolean, default: false },
    actionCheckbox: { type: Function, default: () => {} },
    textToAction: { type: Array },
    tooltip: { type: Array },
    secondTooltip: { type: Array },
    tooltipSize: { required: false },
    list: { type: Array, required: true },
    listAlign: { type: Array },
    actionButtons: { type: Array },
    totalPages: { required: false },
    totalItems: { required: true },
    currentPage: { required: true },
    size: { required: true },
    counter: { required: true },
    maxVisiblePages: { required: false, default: 5 },
    changePage: { type: Function, required: true },
    changeSize: { type: Function, required: true },
    headerTextNotFound: { type: String },
    descriptionTextNotFound: { type: String },
    actionNotFound: { type: Object }
  }
}
</script>
