const SiswaStatus = () => import(/* webpackChunkName: "siswa-status" */ '@/components/SiswaStatus')
import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
import Pagination from '@/molecules/others/Pagination.vue'

export default {
  name: 'prakerja',
  components: { Pagination, SiswaStatus },
  data: () => ({
    counter: 0,
    activeClassType: 'PRAKERJA',
    classPrakerjaList: [],
    classPrakerjaIdSelect: '',
    statuses: [
      {
        name: 'PRAKERJA_WAITING_CLASS',
        displayName: 'Belum Memiliki Kelas',
        siswaCount: 0
      },
      {
        name: 'PRAKERJA_CLASS_ASSIGNED',
        displayName: 'Sudah Memiliki Kelas',
        siswaCount: 0
      }
    ],
    studentList: [],
    keyword: '',
    //
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    size: 10,
    activeStatusName: 'BOOTCAMP',
    startDate: '',
    endDate: '',
    isImportModalVisible: false,
    isResultModalVisible: false,
    importResult: { errorList: [], successList: [] },
    isLoading: true,
    siswaList: []
  }),
  mounted() {
    this.changeStatus('PRAKERJA_WAITING_CLASS', this.currentPage)
    this.getStudentPrakerjaStatus()
    this.getClassPrakerja()
  },
  methods: {
    ...mapActions(['getStudentCount', 'getAllSiswaPage', 'getSiswaDetail', 'getAllClassPrakerja']),
    getList(status, currentPage) {
      this.getStudentList({
        params: {
          page: currentPage,
          size: 10,
          status,
          type: this.activeClassType
        }
      }).then(res => {
        this.distributePayload(res)
      })
    },
    changeStatus(status, currentPage) {
      this.isLoading = true
      this.activeStatusName = status
      if (this.startDate !== '' && this.endDate !== '') {
        this.searchByDate(status, currentPage)
      } else if (this.keyword !== '') {
        this.searchByKeyword(status, currentPage)
      } else if (this.classPrakerjaIdSelect !== '') {
        this.getStudentByClass(status, currentPage)
      } else {
        this.getList(status, currentPage)
      }
      for (let i = 0; i < 10; i++) {
        this.studentList.push({
          id: '',
          fullName: '',
          phoneNumber: '',
          email: '',
          className: 'Kelas Dummy Dummy Dummy',
          lastStatus: 'Belum Memiliki Kelas',
          timeStamp: ''
        })
      }
    },
    getClassPrakerja() {
      this.getAllClassPrakerja({
        params: {
          page: 0,
          size: 100
        }
      }).then(res => {
        if (res.status === 200) {
          this.classPrakerjaList.push({
            id: '',
            kelas: 'Semua'
          })
          res.body.data.data.map(classPrakerja => {
            this.classPrakerjaList.push(classPrakerja)
          })
          this.classPrakerjaIdSelect = ''
          return
        }
        notification.errorMessage('Get Class Prakerja Gagal!')
      })
    },

    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalPages = res.totalPages
      this.studentList = []
      res.data.forEach(student => {
        this.studentList.push({
          id: student.studentId,
          fullName: student.fullName,
          phoneNumber: student.phoneNumber,
          email: student.email,
          className: 'Kelas Dummy Dummy Dummy',
          lastStatus: 'Belum Memiliki Kelas',
          timeStamp: converter.date(student.updatedDate)
        })
      })
      this.isLoading = false
    },
    searchStudentByDate(startDate, endDate) {
      this.startDate = startDate
      this.endDate = endDate
      this.searchByDate(this.activeStatusName, 0)
    },
    searchStudent(keyword) {
      this.keyword = keyword
      this.searchByKeyword(this.activeStatusName, 0)
    },
    searchByKeyword(statusName, currentPage) {
      if (this.keyword !== '') {
        this.getStudentList({
          params: {
            page: currentPage,
            size: 10,
            query: this.keyword,
            status: statusName,
            type: this.activeClassType
          }
        }).then(res => {
          this.distributePayload(res)
        })
      } else {
        this.changeStatus('PRAKERJA_WAITING_CLASS', 0)
      }
    },

    searchByClass() {
      this.getStudentByClass(this.activeStatusName, 0)
    },

    getStudentByClass(statusName, currentPage) {
      this.getStudentList({
        params: {
          status: statusName,
          page: currentPage,
          size: 10,
          prakerjaClassID: this.classPrakerjaIdSelect,
          type: this.activeClassType
        }
      }).then(res => {
        this.distributePayload(res)
      })
    },

    searchByDate(statusName, currentPage) {
      if (this.startDate !== '' && this.endDate !== '') {
        this.getStudentList({
          params: {
            page: currentPage,
            size: 10,
            startDate: this.startDate,
            endDate: this.endDate,
            status: statusName,
            type: this.activeClassType
          }
        }).then(res => {
          this.distributePayload(res)
        })
      } else {
        notification.errorMessage('Please input the start date and end date!')
      }
    },

    getStudentPrakerjaStatus() {
      this.getStudentCount({
        params: {
          type: this.activeClassType
        }
      }).then(res => {
        if (res.status === 200) {
          this.statuses[0].siswaCount = res.body.data.countWaitingClass
          this.statuses[1].siswaCount = res.body.data.countClassAssigned
          return
        }
        notification.errorMessage('Get Class Prakerja Gagal!')
      })
    },

    // ------------------------  //

    getSiswas(status, currentPage) {
      // fill list for skeleton loading animation while fetching
      this.getAllSiswaPage({
        params: {
          page: currentPage,
          size: this.size,
          status
        }
      }).then(res => this.distributePayload(res))
    },

    getLastStatus() {
      this.siswaList.forEach(siswa => {
        this.getSiswaDetail({ id: siswa.id }).then(res => {
          res.studentLogResponses.reverse()
          siswa.lastStatus = this.statuses.filter(status => status.name === res.studentLogResponses[0].statusTerbaru)[0].displayName
          siswa.lastTimestamp = converter.dateTimeView(res.studentLogResponses[0].dateAccessTime)
        })
      })
      this.isLoading = false
    },
    getSiswaCount() {
      this.statuses.forEach(status => {
        this.getAllSiswaPage({
          params: {
            page: 0,
            size: 1,
            status: status.name
          }
        }).then(res => (status.siswaCount = res.totalItems))
      })
    }
    // changeStatus(statusName, currentPage) {
    //   this.isLoading = true
    //   this.activeStatusName = statusName
    //   this.getSiswas(statusName, currentPage)
    //   for (let i = 0; i < this.size; i++) {
    //     this.siswaList.push({
    //       id: '',
    //       name: '',
    //       email: '',
    //       phoneNumber: '',
    //       lastStatus: '',
    //       lastTimestamp: ''
    //     })
    //   }
    // }
    // changePage(page) {
    //   this.changeStatus(this.activeStatusName, page)
    // }
    // changeStatus(statusName, currentPage) {
    //   this.isLoading = true
    //   this.activeStatusName = statusName
    //   this.getSiswas(statusName, currentPage)
    //   for (let i = 0; i < 10; i++) {
    //     this.siswaList.push({
    //       id: '',
    //       name: '',
    //       email: '',
    //       phoneNumber: '',
    //       lastStatus: '',
    //       lastTimestamp: ''
    //     })
    //   }
    // }
  }
}
