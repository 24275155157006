<template>
  <div class="flex flex-col">
    <label for="textarea-with-label" class="text-sm pl-2" :class="[inputLabelFocus, inputLabelError]">{{ labelText }}</label>
    <textarea
      name="textarea-with-label"
      class="h-20 text-sm rounded-md p-2 mt-1 placeholder-neutral-300 border border-neutral-300 focus:placeholder-neutral-300 focus:border-yellow focus:outline-none"
      :class="[resize, inputFocus, inputError, inputDisabled]"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :minlength="minlength"
      :autofocus="autofocus"
      :errorValue="errorValue"
      :disabled="disabled"
      :readValue="readValue"
      :writeValue="writeValue"
      @focus="setFocusTrue()"
      @blur="setFocusFalse()"
      @keypress.enter="forAction"
      v-model="modelValue"
    />
  </div>
</template>
<script>
export default {
  name: 'textarea-with-label',
  props: {
    labelText: { type: String, required: true },
    resize: { type: String, default: 'resize' },
    placeholder: { type: String },
    maxlength: { required: false },
    minlength: { required: false },
    autofocus: { type: Boolean, default: false },
    errorValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    forAction: { type: Function, default: () => {} },
    readValue: { type: String, required: true },
    writeValue: { type: Function, required: true }
  },
  data: () => ({
    modelValue: '',
    isFocus: false,
    inputFocusClass: 'placeholder-neutral-300 border-yellow'
  }),
  mounted() {
    this.modelValue = this.readValue
  },
  computed: {
    inputLabelFocus() {
      return this.isFocus ? 'text-yellow' : ''
    },
    inputLabelError() {
      return this.errorValue ? 'text-red' : ''
    },
    inputFocus() {
      return this.isFocus ? this.inputFocusClass : ''
    },
    inputError() {
      return this.errorValue ? 'border-red' : ''
    },
    inputDisabled() {
      return this.disabled ? 'bg-grey-e' : ''
    }
  },
  watch: {
    readValue() {
      this.modelValue = this.readValue
    },
    modelValue: function() {
      this.writeValue(this.modelValue)
    }
  },
  methods: {
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    }
  }
}
</script>
