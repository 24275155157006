<template>
  <div class="my-3">
    <div class="h-96 flex justify-center">
      <img class="-ml-16" src="@/assets/illustrations/search-animated.svg" />
    </div>
    <div class="text-center -mt-16">
      <h1 class="mb-2 text-lg font-semibold">{{ headerText }}</h1>
      <p class="my-1 text-sm">{{ descriptionText }}</p>
      <p class="my-1 text-sm text-custom-orange-1 cursor-pointer hover:opacity-80" @click="action.next()">{{ action.text }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'data-not-found',
  props: {
    headerText: { type: String },
    descriptionText: { type: String },
    action: {
      type: Object,
      default: () => {
        return { text: '', next: () => {} }
      }
    }
  }
}
</script>
