const SiswaStatus = () => import(/* webpackChunkName: "siswa-status" */ '@/components/SiswaStatus')
const ImportFileModal = () => import(/* webpackChunkName: "import-file-modal" */ '@/components/ImportFileModal')
const ResultFileModal = () => import(/* webpackChunkName: "result-file-modal" */ '@/components/ResultFileModal')
const KeywordSearch = () => import(/* webpackChunkName: "keyword-search" */ '@/components/KeywordSearch')
const DateSearch = () => import(/* webpackChunkName: "date-search" */ '@/components/DateSearch')
import { mapActions } from 'vuex'
import Pagination from '@/molecules/others/Pagination.vue'
import TooltipTopLeft from '@/atoms/others/TooltipTopLeft.vue'
import TooltipTopRight from '@/atoms/others/TooltipTopRight.vue'
import { converter, notification } from '@/util'

export default {
  name: 'aws-clouducation',
  components: { Pagination, TooltipTopLeft, TooltipTopRight, SiswaStatus, ImportFileModal, ResultFileModal, KeywordSearch, DateSearch },
  data: () => ({
    counter: 0,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    activeStatusName: '',
    size: 10,
    startDate: '',
    endDate: '',
    keyword: '',
    eventType: '',
    isImportModalVisible: false,
    isLoading: true,
    downloads: [],
    siswaList: [],
    statuses: [],
    importEventTypeOpt: [],
    importStageOpt: [],
    eventTypeOpt: []
  }),
  computed: {
    type: function() {
      if (this.$route.name === 'AWS Clouducation Main Event') {
        return 'Main Event'
      } else {
        return 'Pre Event'
      }
    },
    forRoute: function() {
      if (this.$route.name === 'AWS Clouducation Main Event') {
        return 'aws-clouducation-main-event'
      } else {
        return 'aws-clouducation-pre-event'
      }
    },
    forTypeAPI: function() {
      if (this.$route.name === 'AWS Clouducation Main Event') {
        return 'clouducation-main-event'
      } else {
        return 'clouducation-pre-event'
      }
    },
    forStatusAPI: function() {
      if (this.$route.name === 'AWS Clouducation Main Event') {
        this.statuses = [
          {
            name: '6',
            displayName: 'Menunggu Konfirmasi Bukti Pelajar',
            siswaCount: 0
          },
          {
            name: '7',
            displayName: 'Menunggu Pembayaran',
            siswaCount: 0
          },
          {
            name: '8',
            displayName: 'Pembayaran Terkonfirmasi',
            siswaCount: 0
          },
          {
            name: '15',
            displayName: 'Reminder Terkirim',
            siswaCount: 0
          },
          {
            name: '9',
            displayName: 'Mengikuti Main Event',
            siswaCount: 0
          },
          {
            name: '10',
            displayName: 'Sertifikat Terkirim',
            siswaCount: 0
          }
        ]
      } else {
        this.statuses = [
          {
            name: '11',
            displayName: 'Pending Reminder',
            siswaCount: 0
          },
          {
            name: '12',
            displayName: 'Reminder Terkirim',
            siswaCount: 0
          },
          {
            name: '13',
            displayName: 'Mengikuti Pre Event',
            siswaCount: 0
          },
          {
            name: '14',
            displayName: 'Sertifikat Terkirim',
            siswaCount: 0
          }
        ]
      }
      return this.statuses[0].name
    }
  },
  watch: {
    type: function() {
      this.switchEvent()
    },
    eventType: function() {
      this.downloads = []
      if (this.eventType === '') {
        this.importEventTypeOpt.forEach(element => {
          this.downloads.push(element.id)
        })
      } else {
        this.downloads.push(this.eventType)
      }
      this.searchByEventType(this.activeStatusName, this.currentPage)
    }
  },
  mounted() {
    this.changeStatus(this.forStatusAPI, this.currentPage)
    this.getSiswaCount()
    this.getFilterOption()
  },
  methods: {
    ...mapActions(['getStudentList', 'getStudentCount', 'getProgramOptions', 'getSiswaDetail', 'download', 'eventsUploadCsv', 'showLoading', 'hideLoading']),
    switchEvent() {
      this.currentPage = 0
      this.keyword = ''
      this.startDate = ''
      this.endDate = ''
      this.eventType = ''
      this.changeStatus(this.forStatusAPI, this.currentPage)
      this.getSiswaCount()
      this.getFilterOption()
    },
    getFilterOption() {
      this.getProgramOptions({
        params: {
          page: 0,
          size: 10,
          programCode: this.forTypeAPI
        }
      }).then(res => {
        this.eventTypeOpt = []
        this.importEventTypeOpt = []
        this.downloads = []
        this.eventTypeOpt.push({
          id: '',
          name: 'Semua'
        })
        res.data.forEach(element => {
          this.eventTypeOpt.push({
            id: element.id,
            name: element.name
          })
          this.importEventTypeOpt.push({
            id: element.id,
            name: element.name
          })
          this.downloads.push(element.id)
        })
      })
    },
    getSiswas(status, currentPage) {
      this.showLoading()
      this.getStudentList({
        params: {
          page: currentPage,
          size: this.size,
          status,
          type: this.forTypeAPI
        }
      }).then(res => this.distributePayload(res))
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.siswaList = []
      res.data.forEach(siswa => {
        this.siswaList.push({
          id: siswa.id,
          studentId: siswa.studentId,
          coreClassName: siswa.coreClassName,
          name: siswa.fullName,
          email: siswa.email,
          phoneNumber: siswa.phoneNumber,
          lastStatus: siswa.description,
          lastTimestamp: converter.dateTimeView(siswa.updatedDate)
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    getSiswaCount() {
      this.getStudentCount({
        params: {
          type: this.forTypeAPI
        }
      }).then(res => {
        for (let i = 0; i < this.statuses.length; i++) {
          this.statuses[i].displayName = res[i].pipelineStage.name
          this.statuses[i].siswaCount = res[i].count
        }
      })
      this.forImportStageOpt()
    },
    forImportStageOpt() {
      if (this.type === 'Main Event') {
        this.importStageOpt = [
          {
            id: this.statuses[4].name,
            name: this.statuses[4].displayName
          }
        ]
      } else {
        this.importStageOpt = [
          {
            id: this.statuses[2].name,
            name: this.statuses[2].displayName
          }
        ]
      }
    },
    changeStatus(statusName, currentPage) {
      this.isLoading = true
      this.activeStatusName = statusName
      if (this.startDate !== '' && this.endDate !== '') {
        this.searchByDate(statusName, currentPage)
      } else if (this.keyword !== '') {
        this.searchByKeyword(statusName, currentPage)
      } else if (this.eventType !== '') {
        this.searchByEventType(statusName, currentPage)
      } else {
        this.getSiswas(statusName, currentPage)
      }
      for (let i = 0; i < this.size; i++) {
        this.siswaList.push({
          id: '',
          name: '',
          email: '',
          phoneNumber: '',
          lastStatus: '',
          lastTimestamp: ''
        })
      }
    },
    async downloadFile() {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.showLoading()
      await this.download({
        requestBody: {
          coreClassIds: this.downloads,
          eventType: this.forTypeAPI
        }
      })
        .then(res => this.createDownloadFile(res))
        .catch(() => notification.errorMessage('Download file gagal, harap mencoba beberapa saat lagi!'))
      await delay(3000)
      this.hideLoading()
    },
    createDownloadFile(res) {
      var fileURL = window.URL.createObjectURL(new Blob([res]))
      var fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', `${this.$route.name} - ${converter.dateTimeView(new Date())}.csv`)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
    async changeFile(file, eventType, status) {
      const delay = ms => new Promise(res => setTimeout(res, ms))
      this.hideUpload()
      this.showLoading()
      let fileData = new FormData()
      fileData.append('file', file)
      await this.eventsUploadCsv({
        params: {
          coreClassId: eventType,
          status
        },
        file: fileData
      })
        .then(() => notification.successMessage('Import file berhasil!'))
        .catch(() => notification.errorMessage('Import file gagal, harap mencoba beberapa saat lagi!'))
      await delay(3000)
      this.hideLoading()
      this.$router.go()
    },
    showUpload() {
      this.isImportModalVisible = true
    },
    hideUpload() {
      this.isImportModalVisible = false
    },
    searchStudentByDate(startDate, endDate) {
      this.startDate = startDate
      this.endDate = endDate
      this.searchByDate(this.activeStatusName, 0)
    },
    searchByDate(statusName, currentPage) {
      if (this.startDate !== '' && this.endDate !== '') {
        this.showLoading()
        this.getStudentList({
          params: {
            page: currentPage,
            size: this.size,
            startDate: this.startDate,
            endDate: this.endDate,
            status: statusName,
            type: this.forTypeAPI
          }
        }).then(res => this.distributePayload(res))
      } else {
        notification.errorMessage('Please input the start date and end date!')
      }
    },
    searchStudent(keyword) {
      this.keyword = keyword
      this.searchByKeyword(this.activeStatusName, 0)
    },
    searchByKeyword(statusName, currentPage) {
      if (this.keyword !== '') {
        this.showLoading()
        this.getStudentList({
          params: {
            page: currentPage,
            size: this.size,
            query: this.keyword,
            status: statusName,
            type: this.forTypeAPI
          }
        }).then(res => this.distributePayload(res))
      } else {
        this.changeStatus(this.forStatusAPI, 0)
      }
    },
    searchByEventType(statusName, currentPage) {
      if (this.eventType !== '') {
        this.showLoading()
        this.getStudentList({
          params: {
            page: currentPage,
            size: this.size,
            eventType: this.eventType,
            status: statusName,
            type: this.forTypeAPI
          }
        }).then(res => this.distributePayload(res))
      } else {
        this.changeStatus(this.forStatusAPI, 0)
      }
    },
    toEditSiswa(id, studentLmsClassId, description) {
      this.$router.push({
        name: 'Visitor Detail',
        params: {
          classType: this.forRoute,
          programId: '0',
          description,
          id,
          studentLmsClassId,
          otherId: '0'
        }
      })
    },
    changePage(page) {
      this.changeStatus(this.activeStatusName, page)
    }
  }
}
