/* eslint-disable no-unused-vars */
const SiswaStatus = () => import(/* webpackChunkName: "siswa-status" */ '@/components/SiswaStatus')
const AkunModal = () => import(/* webpackChunkName: "akun-modal" */ '@/components/AkunModal')
import { mapActions } from 'vuex'
import TooltipTopLeft from '@/atoms/others/TooltipTopLeft.vue'

export default {
  name: 'bootcamp',
  components: { SiswaStatus, AkunModal, TooltipTopLeft },
  data: () => ({
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    activeStatusName: 'MITRA',
    isAkunModalVisible: false,
    isLoading: true,
    akunList: [],
    selectedRow: {},
    statuses: [
      {
        name: 'MITRA',
        displayName: 'Mitra',
        siswaCount: -1
      },
      {
        name: 'MANAGEMENT',
        displayName: 'Management',
        siswaCount: -1
      },
      {
        name: 'AM',
        displayName: 'Account Manager',
        siswaCount: -1
      }
    ]
  }),
  mounted() {
    this.getDaftarAkun('MITRA', this.currentPage)
    this.getAkunCount()
  },
  methods: {
    ...mapActions(['getAllPartners', 'getAllAM', 'getAllManagement', 'showLoading', 'hideLoading']),
    getDaftarAkun(status, currentPage) {
      this.showLoading()
      this.isLoading = true
      switch (status) {
        case 'MITRA':
          this.getAllPartners({}).then(res => this.distributePayload(res))
          break
        case 'AM':
          this.getAllAM({}).then(res => this.distributePayload(res))
          break
        case 'MANAGEMENT':
          this.getAllManagement({}).then(res => this.distributePayload(res))
          break
        default:
          this.getAllPartners({}).then(res => this.distributePayload(res))
          break
      }
    },
    distributePayload(res) {
      this.currentPage = res.currentPage - 1
      this.totalItems = res.totalItems
      this.totalPages = res.totalPages
      this.akunList = []
      res.forEach(akun => {
        this.akunList.push({
          id: akun.id,
          name: akun.name ? akun.name : akun.picName,
          email: akun.email ? akun.email : akun.picEmail,
          phoneNumber: akun.phoneNumber,
          status: akun.status ? akun.status : '',
          companyName: akun.companyName ? akun.companyName : ''
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    changeStatus(statusName, currentPage) {
      this.isLoading = true
      this.activeStatusName = statusName
      this.getDaftarAkun(statusName, currentPage)
      for (let i = 0; i < 10; i++) {
        this.akunList.push({
          id: '',
          name: '',
          email: '',
          phoneNumber: '',
          status: '',
          companyName: ''
        })
      }
    },
    getAkunCount() {
      this.statuses.forEach(status => {
        switch (status.name) {
          case 'MITRA':
            status.siswaCount = this.getAllPartners({}).then(res => (status.siswaCount = res.length))
            break
          case 'AM':
            status.siswaCount = this.getAllAM({}).then(res => (status.siswaCount = res.length))
            break
          case 'MANAGEMENT':
            status.siswaCount = this.getAllManagement({}).then(res => (status.siswaCount = res.length))
            break
          default:
            status.siswaCount = this.getAllPartners({}).then(res => (status.siswaCount = res.length))
            break
        }
      })
    },
    addAkun() {
      this.selectedRow = { akunType: this.activeStatusName }
      this.isAkunModalVisible = true
    },
    showModal(item) {
      item.akunType = this.activeStatusName
      item.isEdit = true
      this.selectedRow = item
      this.isAkunModalVisible = true
    },
    hideModal() {
      this.isAkunModalVisible = false
      this.getDaftarAkun('MITRA', this.currentPage)
    }
  }
}
