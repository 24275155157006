import { converter } from '@/util'

export default {
  name: 'currency-input',
  data: () => ({
    isInputActive: false
  }),
  props: ['value'],
  computed: {
    displayValue: {
      get: function() {
        if (this.isInputActive) {
          return this.value.toString()
        } else {
          return converter.rupiahCurrency(this.value)
        }
      },
      set: function(modifiedValue) {
        let newValue = parseFloat(modifiedValue.replace(/[^\d\\.]/g, ''))
        if (isNaN(newValue)) {
          newValue = 0
        }
        this.$emit('input', newValue)
      }
    }
  }
}
