<template>
  <div>
    <SidebarChat v-if="getIsChatCardVisible" />
    <div id="app">
      <router-view />
    </div>
    <div class="loading" v-if="loading">
      <FullScreenLoading />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Home',
  components: {
    SidebarChat: () => import(/* webpackChunkName: "sidebar-chat" */ '@/components/SidebarChat'),
    FullScreenLoading: () => import(/* webpackChunkName: "full-screen-loading" */ '@/atoms/illustrations/FullScreenLoading')
  },
  computed: {
    ...mapGetters(['loading', 'getIsChatCardVisible'])
  }
}
</script>

<style lang="scss">
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 250px;
  }
}
</style>
