<template>
  <div>
    <div v-if="generalTitle" class="flex flex-col">
      <Title :pageName="pageName" />
      <BreadCrumbs :realRoute="realRoute" :routePath="routePath" />
    </div>
    <div v-else class="flex justify-center items-center mt-5 mb-8">
      <GeneralIcon
        :action="action"
        class="cursor-pointer"
        height="32"
        width="32"
        viewBox="0 0 24 24"
        :paths="[{ d: 'M11.4375 18.75L4.6875 12L11.4375 5.25M5.625 12H19.3125', stroke: '#333333', strokeWidth: '2.25', strokeLinecap: 'round', strokeLinejoin: 'round' }]"
      />
      <h1 class="ml-3 text-3xl font-bold">{{ pageName ? pageName : $route.name }}</h1>
    </div>
  </div>
</template>
<script>
const BreadCrumbs = () => import(/* webpackChunkName: "bread-crumbs" */ '@/atoms/others/BreadCrumbs')
const Title = () => import(/* webpackChunkName: "title" */ '@/atoms/others/Title')
const GeneralIcon = () => import(/* webpackChunkName: "general-icon" */ '@/atoms/icons/GeneralIcon')
export default {
  name: 'page-title',
  components: { BreadCrumbs, Title, GeneralIcon },
  props: {
    generalTitle: { type: Boolean },
    action: { type: Function, default: () => {} },
    pageName: { type: String },
    realRoute: { type: String },
    routePath: { type: String }
  }
}
</script>
