import { mapActions } from 'vuex'

export default {
  name: 'manage-user-modal',
  props: {
    isVisible: { type: Boolean, required: true },
    closed: { type: Function, required: true },
    selectedItem: { type: Object, required: true }
  },
  data: () => ({
    companyName: '',
    result: [],
    perusahaanList: [],
    perusahaanId: ''
  }),
  watch: {
    isVisible: function(newStatus) {
      newStatus ? this.$modal.show('manage-user') : this.$modal.hide('manage-user')
    },
    companyName: function(oldv) {
      if (oldv !== '') {
        this.result = this.perusahaanList.filter(perusahaan => perusahaan.companyName.toLowerCase().search(oldv.toLowerCase()) !== -1)
      }
    }
  },
  mounted() {
    this.getPerusahaan()
  },
  methods: {
    ...mapActions(['updateUser', 'getAllPartnersPage']),
    getPerusahaan() {
      this.getAllPartnersPage({
        params: {
          page: 0,
          size: 1000,
          status: 'NEW'
        }
      }).then(res => this.distributePayload(res.mitraLists))
    },
    distributePayload(res) {
      this.perusahaanList = []
      res.forEach(perusahaan => {
        let data = {
          id: perusahaan.id,
          companyName: perusahaan.companyName,
          picName: perusahaan.picName
        }
        this.perusahaanList.push(data)
      })
    },
    async simpan() {
      await this.updateUser({
        params: {
          newMitraId: this.perusahaanId,
          oldMitraId: this.selectedItem.id
        }
      }).then(this.closed())
    },
    editCompanyName() {
      alert('edit prshn: ' + this.companyName)
    }
  }
}
