import { mapActions, mapGetters } from 'vuex'
import { nameToUrl } from '@/util'
export default {
    name: 'waiting-list',
    components: {
        Button: () => import(/* webpackChunkName: "button" */ '@/components/Button/Button'),
        Search: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Search'),
        TextField: () => import(/* webpackChunkName: "text-field" */ '@/components/TextField/TextField'),
        TableComponent: () => import(/* webpackChunkName: "simple-table" */ '@/components/Table/SimpleTable'),
        DataNotFound: () => import(/* webpackChunkName: "data-not-found" */ '@/molecules/others/DataNotFound'),
        Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
        Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
    },
    data() {
        return {
            query: '',
            currentPage: 1,
            currentSize: 10,
            currentSizeOptions: [5, 10],
            programSelected: {
                "code": "All",
                "name": "All",
                "id": ""
            },
            programOptions: [
                {
                    "code": "All",
                    "name": "All",
                    "id": ""
                },
                {
                    "code": "Bootcamp",
                    "name": "Bootcamp",
                    "id": 2
                },
                {
                    "code": "Short Course",
                    "name": "Short Course",
                    "id": 5
                }
            ]
        }
    },
    watch: {
        currentSize() {
            this.getClassList()
        }
    },
    created() {
        this.getClassList()
    },
    computed: {
        ...mapGetters(['waitingListData', 'waitingListTotal', 'waitingListPages', 'loading']),
        params() {
            return {
                query: this.query,
                page: this.currentPage - 1,
                size: this.currentSize,
                direction: "ASC",
                programId: this.programSelected.id
            }
        },
        columns() {
            return [
                { label: 'Class Name', field: 'coreClassName' },
                { label: 'Program Type', field: 'programType' },
                { label: 'Waiting List', field: 'totalLeads' },
                { label: 'Actions', field: 'coreClassId' }
            ]
        },
        intervalData() {
            return `${this.firstIntervalText} - ${this.secondIntervalText} out of ${this.waitingListTotal}`
        },
        firstIntervalText() {
            if (this.currentPage === 1) return `${this.currentPage}`
            return ((this.currentPage - 1) * this.currentSize) + 1
        },
        secondIntervalText() {
            if (this.waitingListData.length === this.currentSize) return ((this.currentPage - 1) * this.currentSize) + this.currentSize
            return this.waitingListTotal
        }
    },
    methods: {
        ...mapActions(['getClassListWithTotalLeads', 'showLoading', 'hideLoading']),
        onPageChange(page) {
            this.currentPage = page
            this.getClassList()
        },
        toDetails(item) {
            const programId = this.programOptions.filter((program) => program.code === item.programType)[0].id
            this.$router.push({ name: 'Waiting List Details', params: { coreClassId: item.coreClassId, programId }, query: { core_class_name: item.coreClassName } })
        },
        getClassList() {
            this.showLoading()
            this.getClassListWithTotalLeads({ params: this.params }).then(() => {
                this.hideLoading()
            })
        }
    },
}