import { mapActions } from 'vuex'
import { notification } from '@/util'
export default {
  name: 'detail-contact',
  components: {
    InputWithLabel: () => import(/* webpackChunkName: "input-with-label" */ '@/components/Forms/InputWithLabel'),
    Checkbox: () => import('@/components/Forms/Checkbox'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    SelectWithLabel: () => import(/* webpackChunkName: "select-with-label" */ '@/components/Forms/SelectWithLabel'),
    TextareaWithLabel: () => import('@/components/Forms/TextareaWithLabel'),
  },
  props: {
    id: Number
  },
  data() {
    return {
      selectedTab: 'details',
      isLoading: false,
      payload: {
        id: 0,
        name: "",
        email: "",
        phoneNumber: "",
        description: "",
        leadOwner: "",
        sales: {}
      },
      customerId: 0,
      title: '',
      primary: '',
      visibleAddAccount: false,
      visibleRemoveAcc: false,
      accountOptions: [],
      isAccountDataReady: true,
      accountSelected: null,
      idxRemoveAcc: '',
      nameRemoveAcc: '',
      primaryId: '',
      primaryAcc: [],
      primaryAccList: [],
      errors: []
    };
  },
  created() {
    this.customerId = localStorage.getItem('client_id')
  },
  mounted() {
    this.fetchContactData(this.id)
  },
  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'getContactById', 'getAllAccounts', 'addAccountByContact', 'deleteAccountByContact', 'updatePrimaryAccount', 'getLookup']),
    selectTab(tabName) {
      this.selectedTab = tabName;
    },
    editContact() {
      this.$router.push({ name: 'Edit Contact', params: {id: this.id}})
    },
    fetchContactData(id) {
      this.getContactById({ id: id }).then((res) => {
        this.payload = res
        this.primaryId = res.sales.primaryAccount.id
        res.sales.accounts.forEach(item => {
          this.primaryAcc.push({
            id: item.accountId,
            name: item.accountName
          })
          this.primaryAccList = res.sales.accounts
        })
      })
    },
    payloadDescription(){
      return this.payload.description;
    },
    toogleAddAccount() {
      this.visibleAddAccount = !this.visibleAddAccount
    },
    getAccountOptions(name) {
      this.isAccountDataReady = false
      const params = {
        limit: 10,
        page: 0,
        q: name,
        direction: 'DESC',
        sortBy: 'updatedDate',
        customerId: this.customerId,
      }
      this.getAllAccounts({
        params: params
      }).then((res) => {
        this.accountOptions = res.data
        this.isAccountDataReady = true
      })
    },
    addAccountContact() {
      if (this.accountSelected === null) {
        notification.errorMessage('Please select your account!')
        this.visibleAddAccount = false
      }
      const payloads = {
        customerId: this.customerId,
        accountId: this.accountSelected.id,
        title: this.title
      }
      this.addAccountByContact({
        contactId: this.id,
        requestBody: payloads
      }).then(() => {
        notification.successMessage('Account is successfully added by Contact!')
        this.accountSelected = null
        this.visibleAddAccount = false
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }).catch(() => {
        notification.errorMessage('Account ' +this.accountSelected.accountName+ ' is already exists by Contact!')
        this.accountSelected = null
        this.visibleAddAccount = false
      })
    },
    toogleRemoveAccount(idx) {
      this.idxRemoveAcc = idx
      this.nameRemoveAcc = this.payload.sales.accounts[idx].accountName
      this.visibleRemoveAcc = !this.visibleRemoveAcc
    },
    removeAccount() {
      this.deleteAccountByContact({
        contactId: this.id,
        accountId: this.payload.sales.accounts[this.idxRemoveAcc].accountId
      }).then(() => {
        notification.successMessage('Successfully delete Account by Contact!')
        this.visibleRemoveAcc = false
        setTimeout(() => {
          window.location.reload()
        }, 500)
      }).catch(() => {
        notification.errorMessage('Cannot delete the Primary Account! Please select another one.')
        this.visibleRemoveAcc = false
      })
    },
    handleChange(changeId) {
      this.updatePrimaryAccount({
        contactId: this.id,
        accountId: changeId,
        requestBody: {}
      }).then(() => {
        notification.successMessage('Successfully set Primary Account by Contact!')
      }).catch(() => {
        notification.errorMessage('Failed to set Primary Account by Contact!')
      })
    }
  },
};