import { mapActions } from 'vuex'
import CurrencyInput from '@/components/CurrencyInput'
import CurrencyInputDisabled from '@/components/CurrencyInputDisabled'
import { converter, notification } from '@/util'

export default {
  name: 'edit-payment-pra-bootcamp',
  components: { CurrencyInput, CurrencyInputDisabled },
  data: () => ({
    isEdit: false,
    name: '',
    phoneNumber: '',
    email: '',
    coreClassName: '',
    lmsClassName: '',
    receiptName: '',
    amount: '',
    buktiPembayaran: '',
    tanggalEmail: '',
    studentLmsClassPaymentReceiptId: '',
    isLoading: true,
    selectedItem: [],
    receipts: []
  }),
  mounted() {
    this.isLoading = true
    this.getDetail()
  },
  methods: {
    ...mapActions(['getDetailPayment', 'uploadBuktiPembayaran', 'uploadReceipt', 'updateReceipt', 'deleteReceipt', 'showLoading', 'hideLoading']),
    getDetail() {
      this.showLoading()
      this.getDetailPayment({ studentLmsClassId: this.$route.params.studentLmsClassId }).then(res => {
        this.name = res.fullName
        this.email = res.email
        this.phoneNumber = res.phoneNumber
        this.coreClassName = res.coreClassName
        this.lmsClassName = res.lmsClassName
        this.receipts = []
        res.studentClassPaymentReceipts.forEach(element => {
          this.receipts.push({
            createdDate: converter.dateTimeView(element.createdDate),
            emailSentDate: converter.dateTimeView(element.emailSentDate),
            paymentReceipt: element.paymentReceipt,
            studentClassPaymentReceiptId: element.studentClassPaymentReceiptId
          })
        })
        this.isLoading = false
        this.hideLoading()
      })
    },
    changeFile(event) {
      if (event.target.files.length > 0) {
        this.showLoading()
        this.isLoading = true
        let fileData = new FormData()
        fileData.append('file', event.target.files[0])
        fileData.append('name', event.target.files[0].name)
        this.uploadBuktiPembayaran({ data: fileData }).then(res => {
          this.buktiPembayaran = res.url
          this.isLoading = false
          this.hideLoading()
        })
      } else {
        this.isLoading = false
        this.hideLoading()
      }
    },
    updateStatus() {
      if (this.receipts.length > 0) {
        if (this.isEdit) {
          this.showLoading()
          this.updateReceipt({
            studentLmsClassId: this.$route.params.studentLmsClassId,
            requestBody: {
              studentLmsClassPaymentReceipts: [
                {
                  name: this.receiptName,
                  paymentAmount: this.amount,
                  paymentProofImageUrl: this.buktiPembayaran,
                  studentLmsClassPaymentReceiptId: this.studentLmsClassPaymentReceiptId
                }
              ]
            }
          })
            .then(() => {
              notification.successMessage('Data siswa berhasil diupdate!')
              this.isEdit = false
              this.getDetail()
              this.hideLoading()
            })
            .catch(() => {
              notification.errorMessage('Data siswa gagal diupdate, harap mencoba beberapa saat lagi!')
              this.hideLoading()
            })
        } else {
          this.getDetail()
        }
      } else {
        if (this.receiptName === '' || this.amount === '' || this.buktiPembayaran === '') {
          notification.errorMessage('Data tidak boleh kosong!')
        } else {
          this.showLoading()
          this.uploadReceipt({
            studentLmsClassId: this.$route.params.studentLmsClassId,
            requestBody: {
              paymentReceipts: [
                {
                  name: this.receiptName,
                  paymentAmount: this.amount,
                  paymentProofImageUrl: this.buktiPembayaran
                }
              ]
            }
          })
            .then(() => {
              notification.successMessage('Data siswa berhasil diupdate!')
              this.getDetail()
              this.hideLoading()
            })
            .catch(() => {
              notification.errorMessage('Data siswa gagal diupdate, harap mencoba beberapa saat lagi!')
              this.hideLoading()
            })
        }
      }
    },
    deletePayments() {
      let studentLmsClassPaymentReceipts = []
      for (let i = 0; i < this.selectedItem.length; i++) {
        let obj = {
          studentLmsClassPaymentReceiptId: this.selectedItem[i].studentLmsClassPaymentReceiptId
        }
        studentLmsClassPaymentReceipts.push(obj)
      }
      this.showLoading()
      this.deleteReceipt({
        studentLmsClassId: this.$route.params.studentLmsClassId,
        requestBody: { studentLmsClassPaymentReceipts }
      })
        .then(() => {
          notification.successMessage('Data siswa berhasil diupdate!')
          this.receiptName = ''
          this.amount = ''
          this.buktiPembayaran = ''
          this.selectedItem = []
          this.getDetail()
          this.hideLoading()
        })
        .catch(() => {
          notification.errorMessage('Data siswa gagal diupdate, harap mencoba beberapa saat lagi!')
          this.hideLoading()
        })
    },
    toEdit(studentLmsClassPaymentReceiptId, receiptName, amount, buktiPembayaran) {
      this.studentLmsClassPaymentReceiptId = studentLmsClassPaymentReceiptId
      this.receiptName = receiptName
      this.amount = amount
      this.buktiPembayaran = buktiPembayaran
      this.selectedItem = []
      this.isEdit = true
    },
    closeEdit() {
      this.isEdit = false
    }
  }
}
