<template>
  <modal :name="name" :height="height ? height : 600" :width="width ? width : 450" @closed="closed">
    <div :style="`max-height: ${height ? height : 600}px; max-width: ${width ? width : 450}px; overflow: auto;`">
      <slot name="modal-content" />
    </div>
  </modal>
</template>
<script>
export default {
  name: 'custom-modal',
  props: {
    name: { type: String, required: true },
    height: { required: false },
    width: { required: false },
    visibility: { type: Boolean, required: true },
    closed: { type: Function, required: true }
  },
  watch: {
    visibility: function(status) {
      status ? this.showModal() : this.hideModal()
    }
  },
  methods: {
    showModal() {
      this.$modal.show(this.name)
    },
    hideModal() {
      this.$modal.hide(this.name)
    }
  }
}
</script>
