import { mapActions } from 'vuex'
import { converter } from '@/util'
import Pagination from '@/molecules/others/Pagination.vue'

export default {
  name: 'orientasi',
  components: { Pagination },
  data: () => ({
    counter: 0,
    totalPages: 0,
    currentPage: 0,
    countData: 0,
    size: 10,
    classType: 'BOOTCAMP',
    jadwalOrientasiList: [],
    isLoading: true
  }),
  mounted() {
    this.isLoading = true
    this.changePage(this.currentPage)
  },
  methods: {
    ...mapActions(['getOrientationScheduleList', 'showLoading', 'hideLoading']),
    changePage(currentPage) {
      this.getJadwalOrientasies(currentPage)
      for (let i = 0; i < this.size; i++) {
        this.jadwalOrientasiList.push({
          id: '',
          title: '',
          date: '',
          time: '',
          onlineMeetingLink: '',
          updatedBy: '',
          studentCount: '',
          updateDate: ''
        })
      }
    },
    getJadwalOrientasies(currentPage) {
      this.showLoading()
      this.getOrientationScheduleList({
        params: {
          page: currentPage,
          size: this.size,
          type: this.classType
        }
      }).then(res => {
        this.distributePayload(res)
      })
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.countData = res.totalRows
      this.totalPages = res.totalPages
      this.jadwalOrientasiList = []
      res.data.forEach(orientation => {
        this.jadwalOrientasiList.push({
          id: orientation.id,
          title: orientation.title,
          dateTime: converter.dateTimeView(`${orientation.date}T${orientation.time}.000+0000`),
          onlineMeetingLink: orientation.onlineMeetingLink,
          updatedBy: orientation.updatedBy,
          studentCount: orientation.studentCount,
          updateDate: converter.dateTimeView(orientation.updateDate)
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    toAbsensi(orientasiId) {
      this.$router.push({
        name: 'Absensi Orientasi',
        params: {
          classType: this.classType.toLowerCase(),
          orientasiId
        }
      })
    },
    toEdit(id) {
      this.$router.push({
        name: 'Edit Jadwal',
        params: {
          id
        }
      })
    }
  }
}
