<template>
  <div class="flex flex-col">
    <label for="select-with-label" class="text-sm pl-2 font-bold text-custom-black-1" :class="[optionLabelFocus, optionLabelError, optionHide]">{{ labelText }}</label>
    <!-- <select
      name="select-with-label"
      class="h-10 text-sm rounded-md p-2 mt-1 border border-custom-gray-11 focus:border-custom-orange-1 focus:outline-none"
      :class="[optionFocus, optionError, optionDisabled]"
      :errorValue="errorValue"
      :disabled="disabled"
      :readValue="readValue"
      :writeValue="writeValue"
      @focus="setFocusTrue()"
      @blur="setFocusFalse()"
      v-model="modelValue"
    >
      <option v-for="(item, index) in options" :key="index + Math.random()" v-bind:value="item.id">{{ item.name }}</option>
    </select> -->
    <v-select
      name="select-with-label"
      :style="`--vs-dropdown-max-height: ${maxHeight ? maxHeight : '500%'};`"
      class="text-sm mt-1 rounded-md"
      :class="[isFocus ? 'focus' : 'blur', optionFocus, optionError, optionDisabled, optionHide]"
      :errorValue="errorValue"
      :disabled="disabled"
      :readValue="readValue"
      :writeValue="writeValue"
      :placeholder="placeholder"
      :clearable="false"
      @open="setFocusTrue"
      @close="setFocusFalse"
      label="name"
      :reduce="option => option.id"
      :options="options"
      v-model="modelValue"
    >
      <template #open-indicator="{ attributes }">
        <svg v-bind="attributes" width="20" height="12" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.25 8.62501L12 15.375L18.75 8.62501" fill="transparent" stroke="#333333" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </template>
      <template #selected-option="{ name }">
        <p>{{ name.length > textLength ? name.substr(0, textLength - 2) + '..' : name }}</p>
      </template>
    </v-select>
  </div>
</template>
<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
  name: 'select-with-label',
  components: { 'v-select': vSelect },
  props: {
    labelText: { type: String, required: true },
    placeholder: { type: String },
    textLength: { default: 5 },
    maxHeight: { type: String },
    options: { type: Array },
    errorValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hide: { type: Boolean, default: false },
    readValue: { required: true },
    writeValue: { type: Function, required: true }
  },
  data: () => ({
    modelValue: '',
    isFocus: false
  }),
  mounted() {
    this.modelValue = this.readValue
  },
  computed: {
    optionLabelFocus() {
      return this.isFocus ? 'text-custom-orange-1' : ''
    },
    optionLabelError() {
      return this.errorValue ? 'text-custom-red' : ''
    },
    optionFocus() {
      return this.isFocus ? 'border-custom-orange-1' : ''
    },
    optionError() {
      return this.errorValue ? 'border-custom-red' : ''
    },
    optionDisabled() {
      return this.disabled ? 'bg-custom-gray-1 cursor-not-allowed' : ''
    },
    optionHide() {
      return this.hide ? 'hidden' : ''
    }
  },
  watch: {
    readValue() {
      this.modelValue = this.readValue
    },
    modelValue: function() {
      this.writeValue(this.modelValue)
    }
  },
  methods: {
    setFocusTrue() {
      this.isFocus = true
    },
    setFocusFalse() {
      this.isFocus = false
    }
  }
}
</script>
<style lang="scss" scoped>
.blur,
.focus {
  --vs-border-color: #adadad;
  --vs-border-radius: 6px;
  --vs-font-size: 14px;
  --vs-line-height: 2;
  --vs-dropdown-option--active-bg: transparent;
  --vs-dropdown-option--active-color: #f7931e;
  --vs-dropdown-min-width: 100%;
  --vs-dropdown-option-padding: 0px 10px;
  --vs-disabled-bg: #e8e8e8;
  --vs-disabled-border-radius: 6px;
}
.focus {
  --vs-border-color: #f7931e;
}
</style>
