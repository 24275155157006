import { mapActions } from 'vuex'
import { isNotEmptyError } from '@/util/form-validator'
import { notification, validator } from '@/util'
const CONFIG = {
  name: 'Full Name'
}
export default {
  components: {
    InputWithLabel: () => import(/* webpackChunkName: "input-with-label" */ '@/components/Forms/InputWithLabel'),
    SelectWithLabel: () => import(/* webpackChunkName: "select-with-label" */ '@/components/Forms/SelectWithLabel'),
    TextareaWithLabel: () => import(/* webpackChunkName: "textarea-with-label" */ '@/components/Forms/TextareaWithLabel'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
  },
  props: {
    id: Number,
    accId: Number,
    accName: String
  },
  data() {
    return {
      lookupSource: [],
      lookupSourceOriginal: [],
      lookupType: [],
      lookupTypeOriginal: [],
      sourceB2B: '23',
      typeB2B: '16',
      customerId: 0,
      name: '',
      payload: {
        name: ''
      },
      title: '',
      email: '',
      phoneNumber: '',
      description: '',
      selectedCooperationType: [],
      cooperation: [],
      errors: [],
      phoneInputType: 'number',
      modeForm: null,
      selectedType: {},
      selectedSource: {},
      accountOptions: [],
      accountSelected: null,
      isAccountDataReady: true
    }
  },
  created() {
    this.modeForm = this.$route.meta.mode
    this.customerId = localStorage.getItem('client_id')
  },
  mounted() {
    if (this.modeForm != 'edit') {
      this.name = localStorage.getItem('user_name')
      if (this.accId !== undefined) {
        this.accountSelected = {
          id: this.accId,
          accountName: this.accName
        }
      }
    } else {
      this.fetchContactData(this.id)
    }
  },
  methods: {
    ...mapActions(['getAllAccounts', 'addContact', 'updateContact', 'getContactById', 'showLoading', 'hideLoading']),
    fetchContactData(id) {
      this.getContactById({ id: id }).then((res) => {
        this.payload.name = res.name
        this.email = res.email
        this.phoneNumber = res.phoneNumber
        this.description = res.description
        this.name = res.leadOwner
      })
    },
    getAccountOptions(name) {
      this.isAccountDataReady = false
      const params = {
        limit: 10,
        page: 0,
        q: name,
        direction: 'DESC',
        sortBy: 'updatedDate',
        customerId: this.customerId,
      }
      this.getAllAccounts({
        params: params
      }).then((res) => {
        this.accountOptions = res.data
        this.isAccountDataReady = true
      })
    },
    save() {
      if (this.validForm()) {
        if (this.modeForm != 'edit') {
          const payloads = {
            customerId: this.customerId,
            name: this.payload.name,
            accountId: this.accountSelected?.id,
            title: this.title,
            email: this.email,
            phoneNumber: this.phoneNumber,
            description: this.description,
          }
          if (payloads.accountId === undefined) {
            notification.errorMessage('Please select your account!')
            return
          } else if (payloads.email !== '' && validator.email(payloads.email)) {
            notification.errorMessage('Incorrect email format!')
            return
          } else if (payloads.phoneNumber !== '' && validator.phone(payloads.phoneNumber)) {
            notification.errorMessage('Phone number is invalid!')
            return
          }
          this.showLoading()
          this.addContact({
            requestBody: payloads
          }).then(() => {
            notification.successMessage('Contact is successfully added!')
            this.hideLoading()
            this.$router.push('/user/contacts')
          }).catch((err) => {
            if (err === 'Request failed with status code 500') {
              notification.errorMessage('This email address already belongs to a contact in your database.')
            } else {
              notification.errorMessage('Failed to add contact! Please try again.')
            }
            this.hideLoading()
          })
        } else {
          const payloads = {
            name: this.payload.name,
            title: this.title,
            email: this.email,
            phoneNumber: this.phoneNumber,
            description: this.description,
          }
          if (payloads.email !== '' && validator.email(payloads.email)) {
            notification.errorMessage('Incorrect email format!')
            return
          } else if (payloads.phoneNumber !== '' && validator.phone(payloads.phoneNumber)) {
            notification.errorMessage('Phone number is invalid!')
            return
          }
          this.showLoading()
          this.updateContact({
            id: this.id,
            requestBody: payloads
          }).then(() => {
            notification.successMessage('Contact is successfully updated!')
            this.hideLoading()
            this.$router.push('/user/contacts')
          }).catch(() => {
            notification.errorMessage('Failed to update contact! Please try again.')
            this.hideLoading()
          })
        }
      } else {
        notification.errorMessage('Failed to add new contact, please make sure all required information is provided!')
      }
    },
    validForm() {
      const error = isNotEmptyError(this.payload)
      this.errors = error.reduce(function(map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    }
  }
}