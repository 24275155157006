import { mapActions, mapGetters } from 'vuex'
import { urlToName, utcToLocal, notification } from '@/util'
export default {
    name: 'waiting-list-details',
    components: {
        Chevron: () => import(/* webpackChunkName: "chevron" */ '@/components/Icons/Chevron'),
        Button: () => import(/* webpackChunkName: "button" */ '@/components/Button/Button'),
        Search: () => import(/* webpackChunkName: "icon" */ '@/components/Icons/Search'),
        TextField: () => import(/* webpackChunkName: "text-field" */ '@/components/TextField/TextField'),
        TableComponent: () => import(/* webpackChunkName: "simple-table" */ '@/components/Table/SimpleTable'),
        DataNotFound: () => import(/* webpackChunkName: "data-not-found" */ '@/molecules/others/DataNotFound'),
        Pagination: () => import(/* webpackChunkName: "Pagination" */ '@/components/Pagination/Pagination'),
        Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal')
    },
    data() {
        return {
            query: '',
            currentPage: 1,
            currentSize: 10,
            totalData: 0,
            totalPages: 0,
            classType: 'SHORTCOURSE',
            prospectList: [],
            currentSizeOptions: [5, 10],
            prospectChecked: [],
            sendEmailFailedReport: [],
            visibleSendEmailConfirmation: false,
            isBroadcastEmail: false,
            selectedStudent: null
        }
    },
    created() {
        this.getData()
    },
    computed: {
        ...mapGetters(['loading']),
        coreClassName() {
            return this.$route.query.core_class_name
        },
        coreClassid() {
            return this.$route.params.coreClassId
        },
        programId() {
            return this.$route.params.programId
        },
        globalEventNameWL() {
            return this.programId === 5 ? 'WAITING_LIST_SHORT_COURSE' : 'WAITING_LIST_BOOTCAMP'
        },
        globalEventNamePending() {
            return this.programId === 5 ? 'PENDING_PAYMENT_SHORTCOURSE' : 'PENDING_PAYMENT_BOOTCAMP'
        },
        customerId() {
            return localStorage.getItem('client_id')
        },
        columns() {
            return [
                { label: 'Student Name', field: 'fullName' },
                { label: 'Email', field: 'email' },
                { label: 'Timestamp', field: 'updatedDate' },
                { label: 'Actions', field: 'btn' }
            ]
        },
        intervalData() {
            return `${this.firstIntervalText} - ${this.secondIntervalText} out of ${this.totalData}`
        },
        firstIntervalText() {
            if (this.currentPage === 1) return `${this.currentPage}`
            return ((this.currentPage - 1) * this.currentSize) + 1
        },
        secondIntervalText() {
            if (this.prospectList.length === this.currentSize) return ((this.currentPage - 1) * this.currentSize) + this.currentSize
            return this.totalData
        }
    },
    watch: {
        currentSize() {
            this.getClassList()
        }
    },
    methods: {
        ...mapActions(['getStudentList', 'getProspectList', 'getPipelineStages', 'updateProspect', 'showLoading', 'hideLoading']),
        toggleModal() {
            this.visibleSendEmailConfirmation = !this.visibleSendEmailConfirmation
        },
        showEmailConfirmation(isBroadcastEmail, selectedStudent) {
            this.isBroadcastEmail = isBroadcastEmail
            this.selectedStudent = selectedStudent
            this.toggleModal()
        },
        sendEmail() {
            this.isBroadcastEmail ? this.sendEmailToCheckedProspect() : this.sendEmailandUpdateStatus()
        },
        async sendEmailToCheckedProspect() {
            const waitingPaymentStageId = await this.getPipelineStageId(this.globalEventNamePending)
            this.toggleModal()
            this.showLoading()
            this.prospectChecked.map(async (prospect, prospectIdx) => {
                const isSuccess = await this.broadcastEmail(prospect, waitingPaymentStageId)
                if (!isSuccess) this.sendEmailFailedReport.push(prospect)
                if (prospectIdx === this.prospectChecked.length - 1) {
                    this.getData()
                    this.prospectChecked = []
                    notification.successMessage(`Kirim invoice berhasil!`)
                }
            })
        },
        getCheckedData(value) {
            this.sendEmailFailedReport = []
            this.prospectChecked = value
        },
        convertUtcToLocal(date) {
            return utcToLocal(date, "DD MMMM YYYY HH:mm")
        },
        onPageChange(page) {
            this.currentPage = page
            this.getClassList()
        },
        async sendEmailandUpdateStatus() {
            this.toggleModal()
            this.showLoading()
            const waitingPaymentStageId = await this.getPipelineStageId(this.globalEventNamePending)
            const studentId = this.selectedStudent.studentId
            const requestBody = {
                keterangan: "Midtrans - Update Manual by Admin",
                status: waitingPaymentStageId,
                type: this.classType,
                coreClassId: this.selectedStudent.coreClassId
            }
            this.updateProspect({
                studentId,
                requestBody
            }).then(() => {
                notification.successMessage(`Kirim invoice berhasil!`)
                this.getData()
            }).catch(() => {
                this.hideLoading()
                notification.errorMessage('Schedule tidak tersedia!')
            })
        },

        broadcastEmail(prospect, waitingPaymentStageId) {
            return new Promise((resolve, reject) => {
                const studentId = prospect.studentId
                const requestBody = {
                    keterangan: "Update Manual by Admin",
                    status: waitingPaymentStageId,
                    type: this.classType,
                    coreClassId: prospect.coreClassId
                }
                this.updateProspect({
                    studentId,
                    requestBody
                }).then(() => {
                    resolve(true)
                }).catch(() => {
                    reject(false)
                })
            })
        },
        getPipelineStageId(globalEventName) {
            return new Promise((resolve, reject) => {
                this.getPipelineStages({ params: { programId: this.programId, customerId: this.customerId } }).then(res => {
                    const filterData = res.filter((item) => item?.global_event_name === globalEventName)[0]
                    resolve(filterData.id)
                })
            })
        },
        async getData() {
            this.showLoading()
            const waitingListStageId = await this.getPipelineStageId(this.globalEventNameWL)
            this.getProspectList({
                params: {
                    page: this.currentPage - 1,
                    size: this.currentSize,
                    query: this.query,
                    queryClassType: this.coreClassName,
                    status: waitingListStageId,
                    customerId: this.customerId,
                    programId: this.programId
                }
            }).then(res => {
                if (res) {
                    this.totalData = res.totalRows
                    this.prospectList = res.data
                    this.totalPages = res.totalPages
                }
                this.hideLoading()
            })
        }
    },
}