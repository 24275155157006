import { mapActions } from 'vuex'
export default {
  name: 'side-navigation',
  components: {
    Chevron: () => import(/* webpackChunkName: "chevron" */ '@/components/Icons/Chevron')
  },
  data: () => ({
    routes: [
      {
        id: '',
        path: '/user',
        name: 'User',
        icon: 'assets/icons/person.png',
        roles: ['CRM_ADMIN'],
        child: [
          {
            id: '0',
            path: 'all-users',
            name: 'All Users',
            icon: 'assets/icons/book.png',
            roles: ['CRM_ADMIN']
          },
          {
            id: '',
            path: 'tanpa-kelas',
            name: 'Tanpa Kelas',
            icon: 'assets/icons/network.png',
            roles: ['CRM_ADMIN']
          },
          {
            id: '',
            path: 'waiting-list',
            name: 'Waiting List',
            icon: 'assets/icons/people.png',
            roles: ['CRM_ADMIN']
          },
          {
            id: '',
            path: 'bootcampv3',
            name: 'New Bootcamp',
            icon: 'assets/icons/people.png',
            roles: ['CRM_ADMIN']
          },
          {
            id: '',
            path: 'bootcampv2',
            name: 'Old Bootcamp',
            icon: 'assets/icons/people.png',
            roles: ['CRM_ADMIN']
          },
          {
            id: '',
            path: 'event',
            name: 'Event',
            icon: 'assets/icons/people.png',
            roles: ['CRM_ADMIN']
          },
          // {
          //   id: '',
          //   path: 'eduverse',
          //   name: 'Eduverse',
          //   icon: 'assets/icons/network.png',
          //   roles: ['CRM_ADMIN']
          // },
          {
            id: '',
            path: 'short-course',
            name: 'Short Course',
            icon: 'assets/icons/computer.png',
            roles: ['CRM_ADMIN']
          },
          // {
          //   id: '',
          //   path: '',
          //   name: '',
          //   icon: 'assets/icons/international.png',
          //   roles: ['CRM_ADMIN']
          // },
          // {
          //   id: '',
          //   path: '',
          //   name: '',
          //   icon: 'assets/icons/prakerja.png',
          //   roles: ['CRM_ADMIN']
          // }
        ]
      },
      {
        id: '',
        path: '/payment',
        name: 'Payment',
        icon: 'assets/icons/cash.png',
        roles: ['CRM_ADMIN'],
        child: [
          {
            id: '',
            path: 'g2-scholarship',
            name: 'G2 Scholarship',
            icon: 'assets/icons/beasiswag2.png',
            roles: ['CRM_ADMIN']
          },
          {
            id: '',
            path: 'alliance-scholarship',
            name: 'Alliance Scholarship',
            icon: 'assets/icons/beasiswamitra.png',
            roles: ['CRM_ADMIN']
          }
        ]
      },
      {
        id: '',
        path: '/b2b',
        name: 'B2B',
        icon: 'assets/icons/business.png',
        roles: ['CRM_ADMIN', 'CRM_MANAGEMENT', 'CRM_ACCOUNT_MANAGER'],
        child: [
          {
            id: '',
            path: 'daftar-perusahaan',
            name: 'Daftar Perusahaan',
            icon: 'assets/icons/daftar-perusahaan.png',
            roles: ['CRM_MANAGEMENT', 'CRM_ACCOUNT_MANAGER']
          },
          {
            id: '',
            path: 'pipeline',
            name: 'Daftar Pipeline',
            icon: 'assets/icons/new-pipeline.png',
            roles: ['CRM_MANAGEMENT', 'CRM_ACCOUNT_MANAGER']
          },
          {
            id: '',
            path: 'agreement',
            name: 'Daftar Agreement',
            icon: 'assets/icons/book.png',
            roles: ['CRM_MANAGEMENT', 'CRM_ACCOUNT_MANAGER']
          },
          {
            id: '',
            path: 'akun',
            name: 'Akun',
            icon: 'assets/icons/network.png',
            roles: ['CRM_ADMIN']
          }
        ]
      },
      {
        id: '',
        path: '/events',
        name: 'Events',
        icon: 'assets/icons/doc.png',
        roles: ['CRM_AWS'],
        child: [
          {
            id: '',
            path: 'aws-clouducation-pre-event',
            name: 'AWS Clouducation Pre Event',
            icon: 'assets/icons/book.png',
            roles: ['CRM_AWS']
          },
          {
            id: '',
            path: 'aws-clouducation-main-event',
            name: 'AWS Clouducation Main Event',
            icon: 'assets/icons/book.png',
            roles: ['CRM_AWS']
          }
        ]
      },
      {
        id: '',
        path: '/setting',
        name: 'Setting',
        icon: 'assets/icons/settings.png',
        roles: ['CRM_ADMIN'],
        child: [
          {
            id: '',
            path: 'status',
            name: 'Status',
            icon: 'assets/icons/settings.png',
            roles: ['CRM_ADMIN']
          }
        ]
      }
    ],
    emailChildRoutes: [
      {
        id: '',
        path: 'email-templates',
        name: 'Email Templates',
        icon: 'assets/icons/doc.png',
        roles: ['CRM_ADMIN']
      },
      // {
      //   id: '',
      //   path: 'send-email',
      //   name: 'Send Email',
      //   icon: 'assets/icons/mail.png',
      //   roles: ['CRM_ADMIN']
      // }
    ],
    navItems: [
      {
        name: 'Leads',
        // icon: () => import('@/components/icons/Business'),
        active: false,
        id: 'leads',
        roles: ['CMS_ADMIN'],
        children: [
          {
            name: 'Active',
            url: '/user/active-leads'
            // icon: () => import('@/components/icons/Active')
          },
          {
            name: 'Archived',
            url: '/user/archived-leads'
            // icon: () => import('@/components/icons/Archive')
          }
        ]
      },
      {
        name: 'Accounts',
        // icon: () => import('@/components/icons/Home'),
        active: false,
        roles: ['CMS_ADMIN'],
        url: '/user/accounts'
      },
      {
        name: 'Contacts',
        // icon: () => import('@/components/icons/Home'),
        active: false,
        roles: ['CMS_ADMIN'],
        url: '/user/contacts'
      },
      {
        name: 'Deals',
        // icon: () => import('@/components/icons/Home'),
        active: false,
        roles: ['CMS_ADMIN'],
        url: '/user/deals'
      }
    ]
  }),
  created() {
    let self = this
    this.navItems.forEach(item => {
      if (item.children) {
        for (let i = 0; i < item.children.length; i++) {
          if (item.children[i].url === self.$route.path || self.$route.path.includes(item.children[i].url)) {
            item.active = true
          }
        }
      }
    })
  },
  computed: {
    childRoutes: function() {
      return this.routes.filter(nav => nav.name === this.$route.matched[0].name)[0].child
    },
    forPath: function() {
      return this.routes.filter(nav => nav.name === this.$route.matched[0].name)[0].path
    },
    forName: function() {
      return this.routes.filter(nav => nav.name === this.$route.matched[0].name)[0].name
    },
    user_role: function() {
      return localStorage.getItem('user_role')
    }
  },
  mounted() {
    if (localStorage.getItem('user_role') === 'CRM_ADMIN') {
      this.getRoutes()
    }
  },
  methods: {
    ...mapActions(['getMenu']),
    isActive(path) {
      return this.$route.path.includes(path) ? true : false
    },
    getRoutes() {
      this.getMenu({
        params: {
          page: 0,
          size: 100
        }
      }).then(res => {
        // this.routes[0].child[2].id = res.data[0].id
        // this.routes[0].child[2].path = res.data[0].name.toLowerCase()
        // this.routes[0].child[2].name = res.data[0].name
        // this.routes[0].child[8].id = res.data[1].id
        // this.routes[0].child[8].path = res.data[1].name.toLowerCase()
        // this.routes[0].child[8].name = res.data[1].name
        // this.routes[0].child[7].id = res.data[2].id
        // this.routes[0].child[9].path = res.data[2].name.toLowerCase()
        // this.routes[0].child[9].name = res.data[2].name
      })
    }
  }
}
