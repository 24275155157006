import { mapActions } from 'vuex'

const SiswaStatus = () => import(/* webpackChunkName: "siswa-status" */ '@/components/SiswaStatus')
const AbsensiModal = () => import(/* webpackChunkName: "absensi-modal" */ '@/components/AbsensiModal')

export default {
  name: 'absensi',
  components: { SiswaStatus, AbsensiModal },
  data: () => ({
    dataAbsensiModal: {},
    siswaList: [],
    selectedItem: [],
    activeStatusName: 'hadir',
    isLoading: false,
    isAbsensiModalVisible: false,
    statuses: [
      {
        name: 'tidakHadir',
        displayName: 'Tidak Hadir',
        siswaCount: 0
      },
      {
        name: 'hadir',
        displayName: 'Hadir',
        siswaCount: 0
      }
    ]
  }),
  computed: {
    absensiList: function() {
      return this.siswaList.filter(siswa => siswa.attend === this.statuses.filter(status => status.name === this.activeStatusName)[0].displayName)
    }
  },
  mounted() {
    this.getListAbsensi()
  },
  methods: {
    ...mapActions(['getAttendanceOfOrientation', 'sendInvoice', 'reschedule', 'updateInterest']),
    getListAbsensi() {
      this.getAttendanceOfOrientation({
        id: this.$route.params.orientasiId
      }).then(res => this.distributePayload(res))
    },
    distributePayload(res) {
      this.siswaList = []
      res.studentOrientationResponses.forEach(student =>
        this.siswaList.push({
          id: student.id,
          name: student.name,
          email: student.email,
          phoneNumber: student.phoneNumber,
          attend: student.attend === 'NOT YET' ? 'Tidak Hadir' : 'Hadir',
          orientationCode: student.orientationCode,
          invitedCount: student.invitedCount,
          isTertarik: student.proceed === 'YES' ? true : false
        })
      )
      this.statuses.forEach(status => {
        status.siswaCount = this.siswaList.filter(siswa => siswa.attend === status.displayName).length
      })
    },
    changeStatus(statusName) {
      this.activeStatusName = statusName
      this.selectedItem = []
    },
    sendEmail() {
      this.sendInvoice({
        requestBody: {
          studentOrientationKirimRequestList: this.selectedItem
        }
      }).then(res => res)
      this.$router.go()
    },
    rescheduleStudent() {
      this.reschedule({
        requestBody: {
          studentOrientationKirimRequestList: this.selectedItem
        }
      }).then(res => res)
      this.$router.go()
    },
    updateTertarik(item) {
      this.updateInterest({ id: item.id }).then(res => res)
      item.isTertarik = !item.isTertarik
    },
    showAbsensiModal(item) {
      this.dataAbsensiModal = item
      this.closeAbsensiModal()
    },
    closeAbsensiModal() {
      this.isAbsensiModalVisible = !this.isAbsensiModalVisible
    },
    hideAndRefresh() {
      this.isAbsensiModalVisible = !this.isAbsensiModalVisible
      this.getListAbsensi()
    }
  }
}
