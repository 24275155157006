import { mapGetters, mapMutations, mapActions } from 'vuex'
import { converter, notification } from '@/util'
export default {
  components: {
    GeneralIcon: () => import(/* webpackChunkName: "general-icon" */ '@/atoms/icons/GeneralIcon'),
    TextareaWithLabel: () => import(/* webpackChunkName: "textarea-with-label" */ '@/molecules/forms/TextareaWithLabel'),
    Button: () => import(/* webpackChunkName: "button" */ '@/atoms/tags/Button')
  },
  data() {
    return {
      currentPage: 0,
      size: 10,
      resetSize: 1,
      checkPage: 0,
      totalPages: 0,
      totalRows: 0,
      isMustSendBroadcast: false,
      isFit: false,
      isShow: false,
      templateChatUuid: '',
      templateChatName: '',
      message: '',
      messageType: 'text',
      detailChat: []
    }
  },
  watch: {
    isShow: function() {
      if (this.isShow) {
        this.size = this.resetSize
        this.getMessage()
      }
    },
    size: function() {
      if (!this.isFit && this.isShow && this.chatsScroll.scrollTop === 0) {
        setTimeout(() => {
          this.size = this.size + this.size
          this.getMessage()
        }, 200)
      } else {
        this.isFit = true
      }
    }
  },
  computed: {
    ...mapGetters(['getUserContact']),
    userPhoneNumber() {
      return `62${this.getUserContact.phoneNumber.substr(this.getUserContact.phoneNumber.indexOf('8'))}`
    },
    chatsScroll() {
      return document.getElementById('chatsDivId')
    }
  },
  mounted() {
    setTimeout(() => (this.isShow = true), 100)
    if (this.getUserContact.admin === 'QISQUS') this.checkChats()
    this.actionIfOnTop()
  },
  methods: {
    ...mapMutations(['setIsChatCardVisible']),
    ...mapActions(['getTemplateChat', 'getChatStatus', 'getAllChat', 'getAllChatSelenium', 'sendChat', 'sendChatSelenium', 'sendBroadcast', 'showLoading', 'hideLoading']),
    forInitial(name) {
      const arr = name.split(' ')
      let newArr = []
      newArr.push(arr[0].slice(0, 1).toUpperCase())
      if (arr.length > 1) newArr.push(arr[1].slice(0, 1).toUpperCase())
      return newArr.join('')
    },
    openFile(url) {
      window.open(url)
    },
    actionIfOnTop() {
      this.chatsScroll.onscroll = () => {
        if (this.chatsScroll.scrollTop === 0 && this.totalPages > 1) {
          this.size = this.size + this.size
          this.getMessage()
        }
      }
    },
    async toggle() {
      if (this.isShow) {
        this.isShow = false
        setTimeout(() => this.setIsChatCardVisible(), 550)
      }
    },
    checkChats() {
      this.getChatStatus({
        number: this.userPhoneNumber
      })
        .then(() => (this.isMustSendBroadcast = false))
        .catch(res => {
          if (`${res.substr(-3)}` === '400') {
            this.getTemplate()
            this.isMustSendBroadcast = true
          } else {
            this.showLoading()
            notification.errorMessage(res)
            setTimeout(() => this.hideLoading(), 3500)
            this.toggle()
          }
        })
    },
    getMessage() {
      let getChats = () => {}
      switch (this.getUserContact.admin) {
        case 'QISQUS':
          getChats = this.getAllChat
          break
        case 'CINDY':
          getChats = this.getAllChatSelenium
          break
      }
      getChats({
        number: this.userPhoneNumber,
        params: {
          page: this.currentPage,
          size: this.size
        }
      })
        .then(res => {
          this.currentPage = res.data.currentPage
          this.size = res.data.size
          this.totalPages = res.data.totalPages
          this.totalRows = res.data.totalRows
          if (this.currentPage === 0) this.detailChat = []
          const dataChats = res.data.data.reverse()
          if (dataChats.length > 0) {
            let parentChat = []
            for (let i = 0; i < dataChats.length; i++) {
              let dataPush = {
                id: this.currentPage * this.size + (i + 1),
                checkDate: this.forCheckDate(dataChats[i].timestamp),
                date: this.friendlyDate(dataChats[i].timestamp)
              }
              if (i === 0) {
                if (this.getUserContact.admin === 'QISQUS') {
                  dataPush['chats'] = [
                    {
                      chatId: dataChats[i].id,
                      name: dataChats[i].username,
                      chat: dataChats[i].message,
                      type: dataChats[i].type,
                      url: dataChats[i].payload && dataChats[i].payload.url ? dataChats[i].payload.url : undefined,
                      time: this.friendlyTime(dataChats[i].timestamp),
                      from: `${dataChats[i].email}` === this.userPhoneNumber ? 'them' : 'me',
                      read: dataChats[i].status === 'read' ? true : false
                    }
                  ]
                } else {
                  dataPush['chats'] = [
                    {
                      chatId: dataChats[i].uuid,
                      name: dataChats[i].user_phone,
                      chat: dataChats[i].comment,
                      time: this.friendlyTime(dataChats[i].timestamp),
                      from: `${dataChats[i].user_phone}` === this.userPhoneNumber ? 'them' : 'me'
                    }
                  ]
                }
                parentChat.push(dataPush)
              } else if (this.forCheckDate(dataChats[i].timestamp) !== this.forCheckDate(dataChats[i - 1].timestamp)) {
                if (this.getUserContact.admin === 'QISQUS') {
                  dataPush['chats'] = [
                    {
                      chatId: dataChats[i].id,
                      name: dataChats[i].username,
                      chat: dataChats[i].message,
                      type: dataChats[i].type,
                      url: dataChats[i].payload && dataChats[i].payload.url ? dataChats[i].payload.url : undefined,
                      time: this.friendlyTime(dataChats[i].timestamp),
                      from: `${dataChats[i].email}` === this.userPhoneNumber ? 'them' : 'me',
                      read: dataChats[i].status === 'read' ? true : false
                    }
                  ]
                } else {
                  dataPush['chats'] = [
                    {
                      chatId: dataChats[i].uuid,
                      name: dataChats[i].user_phone,
                      chat: dataChats[i].comment,
                      time: this.friendlyTime(dataChats[i].timestamp),
                      from: `${dataChats[i].user_phone}` === this.userPhoneNumber ? 'them' : 'me'
                    }
                  ]
                }
                parentChat.push(dataPush)
              } else {
                const forIndex = parentChat.map(e => e.checkDate).indexOf(this.forCheckDate(dataChats[i].timestamp))
                if (this.getUserContact.admin === 'QISQUS') {
                  parentChat[forIndex].chats.push({
                    chatId: dataChats[i].id,
                    name: dataChats[i].username,
                    chat: dataChats[i].message,
                    type: dataChats[i].type,
                    url: dataChats[i].payload && dataChats[i].payload.url ? dataChats[i].payload.url : undefined,
                    time: this.friendlyTime(dataChats[i].timestamp),
                    from: `${dataChats[i].email}` === this.userPhoneNumber ? 'them' : 'me',
                    read: dataChats[i].status === 'read' ? true : false
                  })
                } else {
                  parentChat[forIndex].chats.push({
                    chatId: dataChats[i].uuid,
                    name: dataChats[i].user_phone,
                    chat: dataChats[i].comment,
                    time: this.friendlyTime(dataChats[i].timestamp),
                    from: `${dataChats[i].user_phone}` === this.userPhoneNumber ? 'them' : 'me'
                  })
                }
              }
            }
            if (this.currentPage > 0) parentChat.reverse()
            for (let j = 0; j < parentChat.length; j++) {
              this.currentPage > 0 ? this.detailChat.unshift(parentChat[j]) : this.detailChat.push(parentChat[j])
            }
            this.chatsScroll.scrollTop = this.chatsScroll.scrollHeight
          }
          if (this.chatsScroll.scrollTop === 0 && this.totalPages < 2) this.isFit = true
        })
        .catch(res => {
          if (this.getUserContact.admin === 'QISQUS') {
            this.showLoading()
            notification.errorMessage(res)
            setTimeout(() => this.hideLoading(), 3500)
            this.toggle()
          } else {
            this.isFit = true
          }
        })
    },
    friendlyDate(when) {
      const today = converter.dateTimeFriendly(new Date())
      const date = converter.dateTimeFriendly(when)
      return date.substr(date.length - 4) === today.substr(today.length - 4) ? date.substr(0, date.length - 4) : date
    },
    forCheckDate(when) {
      return converter.date(when)
    },
    friendlyTime(when) {
      return converter.time(when)
    },
    sendByEnter(event) {
      if (event && !event.shiftKey && this.message) this.sendMessage()
    },
    sendMessage() {
      let send = () => {}
      let payload = {}
      switch (this.getUserContact.admin) {
        case 'QISQUS':
          send = this.sendChat
          payload['comment'] = this.message
          payload['type'] = this.messageType
          payload['extras'] = {}
          break
        case 'CINDY':
          send = this.sendChatSelenium
          payload['receiver'] = this.userPhoneNumber
          payload['message'] = this.message
          payload['attachment'] = ''
          break
      }
      send({
        number: this.userPhoneNumber,
        payload
      })
        .then(() => {
          this.message = ''
          this.reloadChat()
        })
        .catch(res => {
          this.showLoading()
          notification.errorMessage(res)
          setTimeout(() => this.hideLoading(), 3500)
        })
    },
    getTemplate() {
      this.getTemplateChat({}).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].name === 'template_general') {
            this.templateChatUuid = res.data[i].uuid
            this.templateChatName = res.data[i].name
          }
        }
      })
    },
    startMessage() {
      this.showLoading()
      this.sendBroadcast({
        payload: {
          name: this.templateChatName,
          template_uuid: this.templateChatUuid,
          phone_number: this.userPhoneNumber,
          variables: []
        }
      })
        .then(() => {
          this.checkChats()
          setTimeout(() => this.hideLoading(), 3500)
        })
        .catch(() => notification.errorMessage('Failed to load data, please try again later!'))
    },
    async reloadChat() {
      this.showLoading()
      if (this.getUserContact.admin === 'QISQUS') this.checkChats()
      this.isFit = await false
      this.size = this.resetSize
      this.chatsScroll.scrollTop = 0
      setTimeout(() => this.getMessage(), 1000)
      setTimeout(() => this.hideLoading(), 3500)
    }
  }
}
