import { mapActions } from 'vuex'
import TooltipTopLeft from '@/atoms/others/TooltipTopLeft.vue'
import TooltipTopRight from '@/atoms/others/TooltipTopRight.vue'

export default {
  name: 'daftar-agreement',
  components: { TooltipTopLeft, TooltipTopRight },
  data: () => ({
    isLoading: true,
    agreementList: []
  }),
  mounted() {
    this.isLoading = true
    this.getAgreement()
  },
  methods: {
    ...mapActions(['getAllAgreement', 'getAllAgreementByAM', 'showLoading', 'hideLoading']),
    getAgreement() {
      this.showLoading()
      if (localStorage.getItem('user_role') === 'CRM_MANAGEMENT') {
        this.getAllAgreement({}).then(res => this.distributePayload(res))
      } else {
        this.getAllAgreementByAM({ amId: localStorage.getItem('user_id') }).then(res => this.distributePayload(res))
      }
    },
    distributePayload(res) {
      this.agreementList = res
      this.hideLoading()
    },
    filename(item) {
      return item.split('-')[item.split('-').length - 1]
    }
  }
}
