/* eslint-disable no-unused-vars */
export default {
  name: 'result-file-modal',
  props: {
    isVisible: { type: Boolean, required: true },
    result: { type: Object, required: true },
    closed: { type: Function, required: true }
  },
  watch: {
    isVisible: function(newStatus, oldStatus) {
      newStatus === true ? this.showResult() : this.hideResult()
    }
  },
  methods: {
    showResult() {
      this.$modal.show('result-file')
    },
    hideResult() {
      this.$modal.hide('result-file')
    }
  }
}
