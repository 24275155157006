import api from '@/api'

const state = {
  classPrakerjaList: {}
}
const actions = {
  getAllClassPrakerja: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.prakerja.getAllClassPrakerja(params).then(
        response => {
          resolve(response.data.data)
          commit('setClassPrakerjaList', response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  }
}
const mutations = {
  setClassPrakerjaList(state, value) {
    state.classPrakerjaList = value
  }
}
const getters = {
  getClassPrakerja: state => {
    return state.classPrakerjaList
  }
}

export default { state, getters, actions, mutations }
