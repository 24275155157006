import { render, staticRenderFns } from "./EventInternational.vue?vue&type=template&id=7cd82f78&"
import script from "./js/event-international.js?vue&type=script&lang=js&"
export * from "./js/event-international.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports