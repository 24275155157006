var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"m-5 pt-2 border rounded-lg bg-white"},[_c('div',{staticClass:"flex h-14 w-full py-3 px-4"},[_vm._m(0),_c('div',{staticClass:"w-1/2 flex justify-end"},[_c('router-link',{staticClass:"mx-1 text-white text-xs font-bold rounded-lg px-2 border border-custom-orange-1 bg-custom-orange-1 hover:bg-transparent hover:text-custom-orange-1 focus:outline-none",attrs:{"tag":"button","to":{
          name: 'Add Agreement',
          params: { id: 'add' }
        },"exact":""}},[_vm._v(" +ADD AGREEMENT ")])],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.agreementList.length <= 0),expression:"agreementList.length <= 0"}],staticClass:"min-h-screen flex items-center justify-center flex-col"},[_c('img',{staticClass:"h-36",attrs:{"src":require("@/assets/images/empty-list.png")}}),_c('p',{staticClass:"my-8 text-2xl font-bold",staticStyle:{"font-family":"'Montserrat', sans-serif","color":"#5a5a5a"}},[_vm._v(" There is no agreement yet ")]),_c('router-link',{staticClass:"mx-1 text-white text-xs font-bold rounded-lg p-3 border border-custom-orange-1 bg-custom-orange-1 hover:bg-transparent hover:text-custom-orange-1 focus:outline-none",attrs:{"tag":"button","to":{
        name: 'Add Agreement',
        params: { id: 'add' }
      },"exact":""}},[_vm._v(" ADD AGREEMENT ")])],1),_c('div',{staticClass:"h-14 h-full"},[_c('table',{directives:[{name:"show",rawName:"v-show",value:(_vm.agreementList.length > 0),expression:"agreementList.length > 0"}],staticClass:"table-auto w-full"},[_vm._m(1),_c('tbody',[_vm._l((_vm.agreementList),function(index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],key:index + Math.random(),staticClass:"border-b h-14 hover:bg-gray-100 loading-row animate-pulse overflow-hidden"},[_vm._m(2,true),_vm._m(3,true),_vm._m(4,true),_vm._m(5,true),_vm._m(6,true),_vm._m(7,true)])}),_vm._l((_vm.agreementList),function(item){return _c('tr',{key:item.id + Math.random(),staticClass:"border-b h-14 hover:bg-gray-100"},[_c('td',{staticClass:"pl-5"},[_c('TooltipTopRight',{attrs:{"text":item.pipelineName,"size":10}})],1),_c('td',[_vm._v(_vm._s(_vm.filename(item.assignTo)))]),_c('td',[_vm._v(_vm._s(item.docFile))]),_c('td',[_vm._v(_vm._s(item.docType))]),_c('td',[_vm._v(_vm._s(item.docLink))]),_c('td',{staticClass:"flex justify-center py-3"},[_c('router-link',{staticClass:"bg-custom-orange-1 text-white text-xs font-bold rounded-md py-1 px-3 border border-custom-orange-1 hover:bg-white hover:text-custom-orange-1 focus:outline-none",attrs:{"tag":"button","to":{
                name: 'Edit Agreement',
                params: { id: item.id }
              },"exact":""}},[_vm._v(" EDIT ")])],1)])})],2)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-1/2 my-1"},[_c('h2',{staticClass:"font-bold"},[_vm._v("Agreement")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"border-b h-14"},[_c('th',{staticClass:"w-1/6 text-gray-400 text-left pl-5"},[_vm._v("Pipeline Name")]),_c('th',{staticClass:"w-1/6 text-gray-400 text-left"},[_vm._v("Assigned to")]),_c('th',{staticClass:"w-1/6 text-gray-400 text-left"},[_vm._v("Document")]),_c('th',{staticClass:"w-1/5 text-gray-400 text-left"},[_vm._v("Document Type")]),_c('th',{staticClass:"w-1/6 text-gray-400 text-left"},[_vm._v("Docusign Link")]),_c('th',{staticClass:"w-1/6 text-gray-400 text-left"})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"pl-5"},[_c('div',{staticClass:"h-4 bg-gray-200 rounded w-3/4"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',{staticClass:"h-4 bg-gray-200 rounded w-3/4"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',{staticClass:"h-4 bg-gray-200 rounded w-3/4"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',{staticClass:"h-4 bg-gray-200 rounded w-3/4"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('div',{staticClass:"h-4 bg-gray-200 rounded w-3/4"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{staticClass:"flex justify-center py-3"},[_c('div',{staticClass:"h-4 bg-gray-200 rounded w-1/3"})])}]

export { render, staticRenderFns }