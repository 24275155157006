import { mapActions } from 'vuex'
import { converter } from '@/util'
const DetailStudentModal = () => import(/* webpackChunkName: "import-file-modal" */ '@/components/DetailStudentModal')
const TableList = () => import(/* webpackChunkName: "table-list" */ '@/components/TableList')

export default {
  name: 'all-users',
  components: {
    DetailStudentModal,
    TableList
  },
  data: () => ({
    counter: 0,
    size: 10,
    keyword: '',
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    studentId: '',
    fullName: '',
    phoneNumber: '',
    email: '',
    isDetailStudentModalVisible: false,
    isImportModalVisible: false,
    isResultModalVisible: false,
    importResult: { errorList: [], successList: [] },
    isLoading: true,
    studentList: []
  }),
  mounted() {
    this.isLoading = true
    this.changePage(this.currentPage)
  },
  methods: {
    ...mapActions(['getAllStudentList', 'showLoading', 'hideLoading']),
    changePage(currentPage) {
      if (this.keyword !== '') {
        this.searchByKeyword(currentPage)
      } else {
        this.getAllStudent(currentPage)
      }
      for (let i = 0; i < this.size; i++) {
        this.studentList.push({
          id: '',
          name: '',
          email: '',
          phoneNumber: '',
          lastStatus: '',
          lastTimestamp: ''
        })
      }
    },
    getAllStudent(currentPage) {
      this.showLoading()
      this.getAllStudentList({
        params: {
          page: currentPage,
          size: this.size
        }
      }).then(res => {
        this.distributePayload(res)
      })
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.studentList = []
      res.data.forEach(student => {
        this.studentList.push({
          firstQuery: student.studentId,
          secondQuery: student.fullName,
          thirdQuery: student.phoneNumber,
          fourthQuery: student.email,
          firstColumn: student.fullName,
          secondColumn: student.phoneNumber,
          thirdColumn: student.email,
          fourthColumn: converter.dateTimeView(student.createdDate),
          fifthColumn: converter.dateTimeView(student.lastActivity)
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    searchStudent(keyword) {
      this.keyword = keyword
      this.searchByKeyword(0)
    },
    searchByKeyword(currentPage) {
      if (this.keyword !== '') {
        this.showLoading()
        this.getAllStudentList({
          params: {
            page: currentPage,
            size: this.size,
            query: this.keyword
          }
        }).then(res => {
          this.distributePayload(res)
        })
      } else {
        this.changePage(0)
      }
    },
    showDetailStudentModal(studentId, fullName, phoneNumber, email) {
      this.studentId = studentId
      this.fullName = fullName
      this.phoneNumber = phoneNumber
      this.email = email
      this.isDetailStudentModalVisible = true
    },
    hideDetailStudentModal() {
      this.isDetailStudentModalVisible = false
    }
  }
}
