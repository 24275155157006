import { mapActions } from 'vuex'

export default {
  name: 'send-email-choose',
  data: () => ({
    allSiswaList: [],
    displayedSiswaList: [],
    kelas: 'bootcamp',
    status: 'NEW',
    filterOptions: [
      {
        name: 'tanpa-kelas',
        display: 'Tanpa Kelas',
        statuses: [{ name: 'NEW', display: 'Baru Mendaftar' }]
      },
      {
        name: 'bootcamp',
        display: 'Bootcamp',
        statuses: [
          { name: 'NEW', display: 'Baru Mendaftar Bootcamp' },
          { name: 'ORIENTED', display: 'Orientasi' },
          {
            name: 'CONFIRM_PAYMENT_BOOTCAMP',
            display: 'Pembayaran Bootcamp Terkonfirmasi'
          }
        ]
      }
    ],
    search: '',
    selectedSiswa: [],
    isLoading: true
  }),
  computed: {
    statusOptions: function() {
      return this.filterOptions.filter(filter => filter.name === this.kelas)[0].statuses
    }
  },
  mounted() {
    this.getSiswa(this.status)
  },
  watch: {
    search: function(value) {
      this.displayedSiswaList = this.allSiswaList.filter(siswa => !siswa.name.toLowerCase().search(value.toLowerCase()))
    },
    status: function(value) {
      this.getSiswa(value)
    }
  },
  methods: {
    ...mapActions(['getAllSiswa']),
    getSiswa(status) {
      this.isLoading = true
      this.getAllSiswa({
        params: { page: 0, size: 10000, status }
      }).then(res => this.distributePayload(res))
    },
    distributePayload(res) {
      this.allSiswaList = []
      res.forEach(siswa =>
        this.allSiswaList.push({
          id: siswa.id,
          name: siswa.name,
          email: siswa.email,
          phoneNumber: siswa.phoneNumber,
          status: this.statusOptions.filter(status => status.name === this.status)[0].display,
          kelas: this.kelas
        })
      )
      this.allSiswaList.reverse()
      this.displayedSiswaList = this.allSiswaList
      this.isLoading = false
    },
    send() {
      sessionStorage.setItem('siswaForEmail', JSON.stringify(this.selectedSiswa))
      this.$router.push({ name: 'Write Email' })
    }
  }
}
