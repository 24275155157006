<template>
  <h1 class="mt-5 mb-3.5 text-3xl font-bold">{{ name }}</h1>
</template>
<script>
export default {
  props: {
    pageName: { type: String }
  },
  computed: {
    name: function() {
      return this.pageName ? this.pageName : this.$route.name
    }
  }
}
</script>
