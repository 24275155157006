import { mapActions } from 'vuex'

export default {
  name: 'kir-email3',
  components: {},
  data() {
    return {
      loadDetail: {},
      isiEmail: '',
      judulEmail: '',
      receivers: '',
      listPenerima: [],
      jumlahPenerima: '',
      loadListId: []
    }
  },
  created() {
    this.listPenerima = JSON.parse(this.$route.params.ids)
    this.loadDetail = JSON.parse(this.$route.params.payloads)
    this.isiEmail = this.loadDetail.isiEmail
    this.judulEmail = this.loadDetail.judulEmail
    this.receivers = this.loadDetail.listPenerima
    this.hitungPenerima()
  },
  methods: {
    ...mapActions(['sendEmailManual']),
    sendAct() {
      let requestBody = {
        isiEmail: this.isiEmail,
        judulEmail: this.judulEmail,
        listPenerima: this.receivers
        // listPenerima: 'rifqi@g2academy.co, rifqifakhirin@gmail.com'
        //harusnya this.receivers
      }
      this.sendEmailManual({
        requestBody
      }).then(res => res.alert('berhasil'))
      this.$router.push({ path: '/user/send-email' })
    },
    backAct() {
      this.$router.push({ path: '/user/tulis/' + this.$route.params.ids })
    },
    hitungPenerima() {
      this.jumlahPenerima = this.listPenerima.length
    }
  }
}
