const Button = () => import(/* webpackChunkName: "button" */ '@/atoms/tags/Button')
export default {
  name: 'info-modal',
  components: { Button },
  props: {
    height: { type: Number },
    width: { type: Number },
    title: { type: String },
    text: { type: String },
    buttonText: { type: String },
    isVisible: { type: Boolean },
    closed: { type: Function }
  },
  watch: {
    isVisible: function(newStatus) {
      newStatus === true ? this.$modal.show('info-modal') : this.$modal.hide('info-modal')
    }
  }
}
