<template>
  <div class="table-component" :class="{ compact: compact }">
    <div class="table-wrapper border border-custom-gray-4 rounded-lg">
      <table class="w-full border-collapse rounded-lg overflow-hidden">
        <thead class="">
          <tr>
            <td v-if="checkbox && list.length > 1" class="pl-5 w-1">
              <div class="flex justify-center items-center -mt-3">
                <label v-if="isHalfData" class="ctnr">
                  <input type="checkbox" v-model="isHalfData" @click="resetAllData" />
                  <span class="strip" />
                </label>
                <label v-else class="ctnr">
                  <input type="checkbox" v-model="isAllData" @click="onClickAllData" />
                  <span class="checkmark" />
                </label>
              </div>
            </td>
            <td v-for="(column, index) in columns" :key="'columns-' + index" class="whitespace-nowrap font-bold" :class="[column.position ? `text-${column.position}` : 'text-left']">
              {{ column.label }}
            </td>
          </tr>
        </thead>
        <tbody>
          <template v-if="listTemporary.length > 0">
            <tr v-for="(entry, idx) in listTemporary" :key="'entry-' + idx">
              <td v-if="checkbox && list.length > 1" class="pl-5 w-1">
                <div class="flex justify-center items-center -mt-3">
                  <label class="ctnr">
                    <input type="checkbox" :value="entry.isChecked" v-model="entry.isChecked" @click="onChecked(idx, entry.isChecked)" />
                    <span class="checkmark" />
                  </label>
                </div>
              </td>
              <td v-for="(column, index) in columns" :key="'columns-' + index" class="align-middle leading-5">
                <slot name="table-row" :entry="entry" :column="column" :index="idx" />
              </td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td :colspan="columns.length" class="text-center empty">
                <div class="flex justify-center items-center w-full flex-col gap-2">
                  <Empty width="48" height="48" />
                  <p class="">{{ emptyMessage }}</p>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Empty: () => import('@/components/Icons/Empty')
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
      require: true
    },
    list: {
      type: Array,
      default: () => [],
      require: true
    },
    compact: Boolean,
    emptyMessage: {
      type: String,
      default: 'Data is unavailable.'
    },
    checkbox: { type: Boolean, default: false }
  },
  data: () => ({
    isHalfData: false,
    isAllData: false,
    listTemporary: []
  }),
  computed: {
    lookingForListChecked() {
      return this.listTemporary.filter((item) => item.isChecked === true)
    },
    checkListIsAllChecked() {
      if (this.lookingForListChecked.length === this.listTemporary.length) return true
      return false
    }
  },
  created() {
    this.generateData()
  },
  watch: {
    isAllData() {
      this.sendData()
    }
  },
  methods: {
    sendData() {
      this.$emit('providingCheckedData', this.lookingForListChecked)
    },
    generateData() {
      if (this.checkbox) {
        this.listTemporary = this.list.map((item) => {
          return {
            ...item,
            isChecked: false
          }
        })
        return
      }
      this.listTemporary = this.list
    },
    onClickAllData() {
      for (let i = 0; i < this.list.length; i++) {
        this.listTemporary[i].isChecked = !this.isAllData
      }
    },
    resetAllData() {
      for (let i = 0; i < this.list.length; i++) {
        this.listTemporary[i].isChecked = false
      }
      this.isAllData = false
      this.isHalfData = false
    },
    onChecked(idx, checked) {
      this.listTemporary[idx].isChecked = !checked
      if (!this.checkListIsAllChecked) {
        this.isHalfData = true
      }
      if (this.lookingForListChecked.length === 0) {
        this.isHalfData = false
      }
      this.sendData()
    }
  }
}
</script>
<style lang="scss" scoped>
.table-component {
  // min-height: 480px;
  thead {
    tr {
      td {
        background-color: #f7f7f7;
        padding: 16px 16px;
        box-shadow: inset 0px -1px 0px rgba(206, 213, 222, 0.5);
      }
    }
  }
  tbody {
    tr {
      td {
        background-color: #fff;
        padding: 12px 16px;
        font-weight: 500;
        font-size: 14px;
        box-shadow: inset 0px -1px 0px rgba(206, 213, 222, 0.5);
      }
      &:last-child td {
        box-shadow: none;
      }
    }
  }
}
.row {
  &-item {
    &:last-child {
      .shadow-border-b {
        // box-shadow: none;
      }
    }
  }
}
.compact {
  min-height: 0 !important;
}
.empty {
  height: 400px;
}

/* The container */
.ctnr {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.ctnr input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark,
.strip {
  position: absolute;
  margin: 2px 0;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1px solid #888888;
  border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.ctnr:hover input ~ .checkmark,
.ctnr:hover input ~ .strip {
  background-color: rgb(243, 244, 246);
}

/* When the checkbox is checked, add a blue background */
.ctnr input:checked ~ .checkmark,
.ctnr input:checked ~ .strip {
  background-color: #f7931e;
  border: 1px solid #f7931e;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after,
.strip:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.ctnr input:checked ~ .checkmark:after,
.ctnr input:checked ~ .strip:after {
  display: block;
}

/* Style the checkmark/indicator */
.ctnr .checkmark:after {
  left: 5px;
  top: 1px;
  width: 8px;
  height: 12px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.ctnr .strip:after {
  left: 3px;
  top: 7px;
  width: 70%;
  border: solid white;
  border-width: 0 3px 3px 0;
}
</style>
