const endpointFunction = {
  getAuthHeaderOnlyBearer() {
    return {
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    }
  },
  getAuthHeader() {
    return {
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      'Access-Control-Allow-Origin': '*'
    }
  },
  getAuthHeaderJwt() {
    return {
      Authorization: 'jwt ' + localStorage.getItem('access_token'),
      'Access-Control-Allow-Origin': '*'
    }
  },
  getOriginHeader() {
    return {
      'Access-Control-Allow-Origin': '*'
    }
  },
  getAuthAndMultipartHeader() {
    return {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      'Access-Control-Allow-Origin': '*'
    }
  },
  serializeQueryParams(paramObj) {
    if (paramObj) {
      return (
        '?' +
        Object.keys(paramObj)
          .map(k => {
            if (typeof paramObj[k] === 'object') {
              return paramObj[k].map(v => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`).join('&')
            } else {
              return `${encodeURIComponent(k)}=${encodeURIComponent(paramObj[k])}`
            }
          })
          .join('&')
      )
    }
    return ''
  }
}

export default endpointFunction
