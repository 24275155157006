export default {
  name: 'edit-status',
  data: () => ({
    sortValue: '',
    sortOptions: [
      { text: 'Pembayaran Terkonfirmasi', value: 'confirmed' },
      { text: 'Menunggu Pembayaran', value: 'pending' }
    ]
  })
}
