import { mapActions } from 'vuex'
import { converter } from '@/util'
const TableList = () => import(/* webpackChunkName: "table-list" */ '@/components/TableList')

export default {
  name: 'event-schedule',
  components: { TableList },
  data: () => ({
    counter: 0,
    totalPages: 0,
    currentPage: 0,
    countData: 0,
    event: '',
    size: 10,
    keyword: '',
    classType: 'INTERNATIONAL',
    eventList: [],
    isLoading: true
  }),
  mounted() {
    this.$route.params.event === 'open-day' ? (this.event = 'Open Day') : (this.event = 'Workshop')
    this.isLoading = true
    this.changePage(this.currentPage)
  },
  methods: {
    ...mapActions(['getEventList', 'showLoading', 'hideLoading']),
    changePage(currentPage) {
      if (this.keyword !== '') {
        this.searchByKeyword(currentPage)
      } else {
        this.getScheduleList(currentPage)
      }
    },
    getScheduleList(currentPage) {
      this.showLoading()
      this.getEventList({
        params: {
          programStageId: this.$route.params.programStageId,
          page: currentPage,
          size: this.size
        }
      }).then(res => {
        this.distributePayload(res)
      })
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.countData = res.totalRows
      this.totalPages = res.totalPages
      this.eventList = []
      res.data.forEach(element => {
        this.eventList.push({
          firstQuery: this.$route.params.event,
          secondQuery: element.id,
          firstColumn: element.name,
          secondColumn: converter.dateTimeView(element.startDateTime),
          thirdColumn: this.event === 'Workshop' ? converter.dateTimeView(element.endDateTime) : `${element.registerCount}`,
          fourthColumn: this.event === 'Workshop' ? `${element.registerCount}` : `${element.attendCount}`,
          fifthColumn: this.event === 'Workshop' ? `${element.attendCount}` : element.currentStatus,
          sixthColumn: this.event === 'Workshop' ? element.currentStatus : converter.dateTimeView(element.updatedDate),
          seventhColumn: this.event === 'Workshop' ? converter.dateTimeView(element.updatedDate) : undefined,
          createdDate: converter.dateTimeView(element.createdDate),
          programStage: element.programStage
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    searchSchedule(keyword) {
      this.keyword = keyword
      this.searchByKeyword(0)
    },
    searchByKeyword(currentPage) {
      if (this.keyword !== '') {
        this.showLoading()
        this.getEventList({
          params: {
            programStageId: this.$route.params.programStageId,
            page: currentPage,
            size: this.size,
            query: this.keyword
          }
        }).then(res => {
          this.distributePayload(res)
        })
      } else {
        this.changePage(0)
      }
    },
    action() {
      this.toEvent(this.$route.params.event, 'add')
    },
    toEvent(event, id) {
      this.$router.push({
        name: 'Event International',
        params: {
          programId: this.$route.params.programId,
          programStageId: this.$route.params.programStageId,
          event,
          id
        }
      })
    }
  }
}
