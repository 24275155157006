import api from '@/api'

const state = {
  siswaList: {},
  siswaDetail: {}
}
const actions = {
  getAllStudentList: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getAllStudentList(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllParticipants: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getAllParticipants(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAlliances: ({ commit }, {}) => {
    return new Promise((resolve, reject) => {
      api.student.getAlliances().then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getTempContents: ({ commit }, {}) => {
    return new Promise((resolve, reject) => {
      api.student.getTempContents().then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getTempNotes: ({ commit }, {}) => {
    return new Promise((resolve, reject) => {
      api.student.getTempNotes().then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getClasses: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getClasses(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllPrograms: ({ commit }, {}) => {
    return new Promise((resolve, reject) => {
      api.student.getAllPrograms().then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  createPipelineStage: ({ commit }, { customerId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.createPipelineStage(customerId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updatePipelineStage: ({ commit }, { stageId, customerId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updatePipelineStage(stageId, customerId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  deleteUpdatePipelineStage: ({ commit }, { stageId, customerId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.deleteUpdatePipelineStage(stageId, customerId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  deletePipelineStage: ({ commit }, { stageId, customerId }) => {
    return new Promise((resolve, reject) => {
      api.student.deletePipelineStage(stageId, customerId).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.slice(32, 35))
        }
      )
    })
  },
  getPipelineStage: ({ commit }, { stageId, customerId }) => {
    return new Promise((resolve, reject) => {
      api.student.getPipelineStage(stageId, customerId).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getPipelineStages: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getPipelineStages(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getContactList: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getContactList(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getStudentList: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getStudentList(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getStudentCount: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getStudentCount(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getProfileDetailScholarship: ({ commit }, { scholarshipId }) => {
    return new Promise((resolve, reject) => {
      api.student.getProfileDetailScholarship(scholarshipId).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getStatusScholarshipList: ({ commit }, { scholarshipId }) => {
    return new Promise((resolve, reject) => {
      api.student.getStatusScholarship(scholarshipId).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getWorkflowOptionScholarship: ({ commit }, params) => {
    return new Promise((resolve, reject) => {
      api.student.getWorkflowOptionScholarship(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getStatusScholarship: ({ commit }, query) => {
    return new Promise((resolve, reject) => {
      api.student.getStatusesScholarship(query).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  addNewStatus: ({ commit }, { scholarshipId, body }) => {
    return new Promise((resolve, reject) => {
      api.student.addNewStatus(scholarshipId, body).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getStudentByEmail: ({ commit }, { email }) => {
    return new Promise((resolve, reject) => {
      api.student.getStudentByEmail(email).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getReceiptsList: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getReceiptsList(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getDetailPayment: ({ commit }, { studentLmsClassId }) => {
    return new Promise((resolve, reject) => {
      api.student.getDetailPayment(studentLmsClassId).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getRegistrationDetails: ({ commit }, { studentId, params }) => {
    return new Promise((resolve, reject) => {
      api.student.getRegistrationDetails(studentId, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getEnrollmentHistory: ({ commit }, { studentId, params }) => {
    return new Promise((resolve, reject) => {
      api.student.getEnrollmentHistory(studentId, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  uploadReceipt: ({ commit }, { studentLmsClassId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.uploadReceipt(studentLmsClassId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateReceipt: ({ commit }, { studentLmsClassId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updateReceipt(studentLmsClassId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  deleteReceipt: ({ commit }, { studentLmsClassId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.deleteReceipt(studentLmsClassId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllSiswa: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getAllSiswa(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAllSiswaPage: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getAllSiswaPage(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getSiswaDetail: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.student.getSiswaDetail(id).then(
        response => {
          resolve(response.data.data)
          commit('setSiswaDetail', response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getStudentLog: ({ commit }, { studentId, params }) => {
    return new Promise((resolve, reject) => {
      api.student.getStudentLog(studentId, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getStages: ({ commit }, { programName }) => {
    return new Promise((resolve, reject) => {
      api.student.getStages(programName).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getActivityDetail: ({ commit }, { activityId }) => {
    return new Promise((resolve, reject) => {
      api.student.getActivityDetail(activityId).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAwardeeList: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getAwardeeList(params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  downloadScholarship: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.downloadScholarship(params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getProspectDetail: ({ commit }, { studentId }) => {
    return new Promise((resolve, reject) => {
      api.student.getProspectDetail(studentId).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getProspectList: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getProspectList(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getStatusList: ({ commit }, { customerId, params }) => {
    return new Promise((resolve, reject) => {
      api.student.getStatusList(customerId, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.slice(32, 35))
        }
      )
    })
  },
  getProspectLog: ({ commit }, { studentId, params }) => {
    return new Promise((resolve, reject) => {
      api.student.getProspectLog(studentId, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getActivityLog: ({ commit }, { id, params }) => {
    return new Promise((resolve, reject) => {
      api.student.getActivityLog(id, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  addActivity: ({ commit }, { id, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.addActivity(id, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  addNote: ({ commit }, { activityId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.addNote(activityId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  addActivityBulk: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.addActivityBulk(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  getAvailableStages: ({ commit }, { studentId, params }) => {
    return new Promise((resolve, reject) => {
      api.student.getAvailableStages(studentId, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateProspect: ({ commit }, { studentId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updateProspect(studentId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  updateActivityOutcome: ({ commit }, { activityId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updateActivityOutcome(activityId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateProspectCoreClass: ({ commit }, { studentId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updateProspectCoreClass(studentId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateProspectPut: ({ commit }, { studentId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updateProspectPut(studentId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  createSiswa: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.createSiswa(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.slice(32, 35))
        }
      )
    })
  },
  getProgram: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.getProgram(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  uploadCsv: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      api.student.uploadCsv(data).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  eventUploadCsv: ({ commit }, { eventID, params, data }) => {
    return new Promise((resolve, reject) => {
      api.student.eventUploadCsv(eventID, params, data).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  download: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.download(requestBody).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  downloadContact: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.downloadContact(requestBody).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  downloadCsv: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.downloadCsv(requestBody).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  downloadProspect: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.student.downloadProspect(params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  eventsUploadCsv: ({ commit }, { params, file }) => {
    return new Promise((resolve, reject) => {
      api.student.eventsUploadCsv(params, file).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  importUser: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.importUser(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateSiswa: ({ commit }, { studentLmsClassId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updateSiswa(studentLmsClassId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateBulkProspect: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updateBulkProspect(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateBulkSiswa: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updateBulkSiswa(requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  updateKelas: ({ commit }, { studentId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updateKelas(studentId, requestBody).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  uploadBuktiPembayaran: ({ commit }, { data }) => {
    return new Promise((resolve, reject) => {
      api.student.uploadBuktiPembayaran(data).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error.data)
        }
      )
    })
  },
  importSchorashipDataViaCsv: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.importSchorashipDataViaCsv(requestBody).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  updatePaymentMethod: ({ commit }, { prospectId, requestBody }) => {
    return new Promise((resolve, reject) => {
      api.student.updatePaymentMethod(prospectId, requestBody).then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {
  setSiswaList(state, value) {
    state.siswaList = value
  },
  setSiswaDetail(state, value) {
    state.siswaDetail = value
  }
}
const getters = {
  siswaList: state => {
    return state.siswaList
  },
  siswaDetail: state => {
    return state.siswaDetail
  }
}

export default { state, getters, actions, mutations }
