<template>
  <div class="relative">
    <div :class="`px-2 py-5 border rounded-lg bg-white overflow-x-auto ${thirdButtonButtons.length > 0 ? 'my-3' : 'mt-3'}`">
      <div :class="`flex md:flex-col ${filters.length > 0 || inputs.length > 0 || firstButtonButtons.length > 0 ? '' : 'hidden'}`">
        <div class="h-16 md:h-full" v-for="(filter, index) in filters" :key="'filter-' + index">
          <div v-if="filter.mode === 'v-select'" :style="widthVueSelect" class="flex mr-9 md:h-20">
            <div class="absolute px-3 w-48" :class="[forWidth]">
              <VSelectWithLabel
                :labelText="filter.label"
                :textLength="filters.length === 3 ? 12 : 15"
                :options="filter.options"
                :errorValue="filter.errorValue"
                :disabled="filter.disabled"
                :readValue="filter.readValue"
                :writeValue="filter.writeValue"
              />
            </div>
          </div>
          <div v-else-if="filter.mode === 'multiple-select'" :style="widthVueSelect" class="flex mr-9 md:h-20">
            <div class="absolute px-3 w-48" :class="[forWidth]">
              <MultipleSelectWithLabel
                :labelText="filter.label"
                :textLength="filters.length === 3 ? 12 : 15"
                :options="filter.options"
                :errorValue="filter.errorValue"
                :disabled="filter.disabled"
                :readValue="filter.readValue"
                :writeValue="filter.writeValue"
              />
            </div>
          </div>
          <div v-else class="flex md:h-20">
            <div class="px-3 w-48" :class="[forWidth]">
              <SelectWithLabel
                :labelText="filter.label"
                :options="filter.options"
                :errorValue="filter.errorValue"
                :disabled="filter.disabled"
                :readValue="filter.readValue"
                :writeValue="filter.writeValue"
              />
            </div>
          </div>
        </div>
        <div class="flex" v-for="(input, index) in inputs" :key="index + Math.random()">
          <div class="px-3 w-48" :class="[forWidth]">
            <InputWithLabel
              :labelText="input.label"
              :type="input.type"
              :placeholder="input.placeholder"
              :max="input.max"
              :min="input.min"
              :maxlength="input.maxlength"
              :minlength="input.minlength"
              :autofocus="input.autofocus"
              :errorValue="input.errorValue"
              :disabled="input.disabled"
              :value="input.value"
              :customValuePlus="input.customValuePlus"
              :customValueMinus="input.customValueMinus"
              :readValue="input.readValue"
              :writeValue="input.writeValue"
            />
          </div>
        </div>
        <div class="px-3 py-0.5 flex items-end">
          <ButtonsGroup :flexDirection="firstButtonFlexDirection" :justifyContent="firstButtonJustifyContent" :buttons="firstButtonButtons" />
        </div>
      </div>
      <div :class="`flex ${filters.length > 0 || inputs.length > 0 || firstButtonButtons.length > 0 ? 'pt-5' : ''}`" v-if="!disableSearch">
        <div class="px-3 w-80">
          <InputIconSVGWithLabel
            :additionalIconStyle="inputIconSVGAdditionalIconStyle"
            :iconAction="inputIconSVGIconAction"
            :heightIcon="inputIconSVGHeightIcon"
            :widthIcon="inputIconSVGWidthIcon"
            :viewBox="inputIconSVGViewBox"
            :paths="paths"
            :fillIconDefault="inputIconSVGFillIconDefault"
            :fillIconFocus="inputIconSVGFillIconFocus"
            :labelText="inputIconSVGLabelText"
            :type="inputIconSVGType"
            :placeholder="inputIconSVGPlaceholder"
            :max="inputIconSVGMax"
            :min="inputIconSVGMin"
            :maxlength="inputIconSVGMaxlength"
            :minlength="inputIconSVGMinlength"
            :autofocus="inputIconSVGAutofocus"
            :errorValue="inputIconSVGErrorValue"
            :disabled="inputIconSVGDisabled"
            :value="inputIconSVGValue"
            :customValuePlus="inputIconSVGCustomValuePlus"
            :customValueMinus="inputIconSVGCustomValueMinus"
            :readValue="inputIconSVGReadValue"
            :writeValue="inputIconSVGWriteValue"
          />
        </div>
        <div class="px-3 py-1.5 flex items-end">
          <ButtonsGroup :flexDirection="secondButtonFlexDirection" :justifyContent="secondButtonJustifyContent" :buttons="secondButtonButtons" />
        </div>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div class="flex items-center">
        <ButtonsGroup :flexDirection="thirdButtonFlexDirection" :justifyContent="thirdButtonJustifyContent" :buttons="thirdButtonButtons" />
      </div>
      <div v-if="selectedData && selectedData.length > 0" class="flex items-center">
        <p class="mr-2">{{ selectedData.length }} Selected</p>
        <ButtonsGroup :flexDirection="fourthButtonFlexDirection" :justifyContent="fourthButtonJustifyContent" :buttons="fourthButtonButtons" />
      </div>
    </div>
  </div>
</template>
<script>
const VSelectWithLabel = () => import(/* webpackChunkName: "v-select-with-label" */ '@/components/Forms/VSelectWithLabel')
const MultipleSelectWithLabel = () => import(/* webpackChunkName: "multiple-select-with-label" */ '@/components/Forms/MultipleSelectWithLabel')
const SelectWithLabel = () => import(/* webpackChunkName: "select-with-label" */ '@/components/Forms/SelectWithLabel')
const InputWithLabel = () => import(/* webpackChunkName: "input-with-label" */ '@/components/Forms/InputWithLabel')
const InputIconSVGWithLabel = () => import(/* webpackChunkName: "input-icon-svg-with-label" */ '@/components/Forms/InputIconSVGWithLabel')
const ButtonsGroup = () => import(/* webpackChunkName: "buttons-group" */ '@/components/Button/ButtonsGroup')
export default {
  name: 'finding-data',
  components: { VSelectWithLabel, MultipleSelectWithLabel, SelectWithLabel, InputWithLabel, InputIconSVGWithLabel, ButtonsGroup },
  props: {
    filters: {
      type: Array,
      default: () => {
        return []
      }
    },
    inputs: {
      type: Array,
      default: () => {
        return []
      }
    },
    disableSearch: {
      type: Boolean,
      default: false
    },
    inputIconSVGAdditionalIconStyle: { type: String, default: '' },
    inputIconSVGIconAction: { type: Function, default: () => {} },
    inputIconSVGHeightIcon: { type: String, default: '20' },
    inputIconSVGWidthIcon: { type: String, default: '20' },
    inputIconSVGViewBox: { type: String, default: '0 0 20 20' },
    paths: { type: Array, required: true },
    inputIconSVGFillIconDefault: { type: String, default: '#000000' },
    inputIconSVGFillIconFocus: { type: String, default: '#000000' },
    inputIconSVGLabelText: { type: String, required: true },
    inputIconSVGType: { type: String, default: 'text' },
    inputIconSVGPlaceholder: { type: String },
    inputIconSVGMax: { required: false },
    inputIconSVGMin: { required: false },
    inputIconSVGMaxlength: { required: false },
    inputIconSVGMinlength: { required: false },
    inputIconSVGAutofocus: { type: Boolean, default: false },
    inputIconSVGErrorValue: { type: Boolean, default: false },
    inputIconSVGDisabled: { type: Boolean, default: false },
    inputIconSVGValue: { type: String },
    inputIconSVGCustomValuePlus: { type: Number },
    inputIconSVGCustomValueMinus: { type: Number },
    inputIconSVGReadValue: { type: String, required: true },
    inputIconSVGWriteValue: { type: Function, required: true },
    firstButtonFlexDirection: { type: String, default: 'row' },
    firstButtonJustifyContent: { type: String, default: 'start' },
    firstButtonButtons: {
      type: Array,
      default: () => {
        return []
      }
    },
    secondButtonFlexDirection: { type: String, default: 'row' },
    secondButtonJustifyContent: { type: String, default: 'start' },
    secondButtonButtons: {
      type: Array,
      default: () => {
        return []
      }
    },
    thirdButtonFlexDirection: { type: String, default: 'row' },
    thirdButtonJustifyContent: { type: String, default: 'start' },
    thirdButtonButtons: {
      type: Array,
      default: () => {
        return []
      }
    },
    selectedData: { type: Array },
    fourthButtonFlexDirection: { type: String, default: 'row' },
    fourthButtonJustifyContent: { type: String, default: 'end' },
    fourthButtonButtons: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    widthVueSelect: function() {
      return this.filters.length === 3 ? 'width: 155.5px;' : 'width: 160px;'
    },
    forWidth: function() {
      return this.filters.length === 3 ? 'w-38' : 'w-48'
    }
  }
}
</script>
