import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
const FirstTemplate = () => import(/* webpackChunkName: "first-template" */ '@/templates/FirstTemplate')
export default {
  name: 'open-day',
  components: { FirstTemplate },
  data: () => ({
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    isLoading: true,
    openDayList: []
  }),
  computed: {
    buttons: function() {
      return [
        // {
        //   action: () => this.toEditSiswa('add', 'tambah'),
        //   text: 'Tambah',
        //   type: 'tertiary',
        //   size: 'small'
        // },
        {
          action: () => this.showUpload(),
          text: 'Import',
          type: 'secondary',
          size: 'small',
          hide: true
        },
        {
          action: () => this.$router.push({ name: 'Update Event', params: { program: 'new-btc', event: 'open-day', id: 'add' } }),
          text: 'Add Schedule',
          type: 'primary',
          size: 'small'
        }
      ]
    }
  },
  mounted() {
    this.isLoading = true
    this.getAllOpenDay()
  },
  methods: {
    ...mapActions(['getOpenDayList', 'showLoading', 'hideLoading']),
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getAllOpenDay()
    },
    getAllOpenDay() {
      this.showLoading()
      this.getOpenDayList({
        params: {
          page: this.currentPage,
          size: this.size,
          customerId: 1
        }
      }).then(res => {
        this.distributePayload(res)
      })
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getAllOpenDay()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.openDayList = []
      res.data.forEach(element => {
        this.openDayList.push({
          firstQuery: element.id,
          firstColumn: element.name,
          secondColumn: this.toUserFriendly('date', converter.dateTimeView(element.startDateTime), undefined),
          secondColumnDesc: element.startDateTime ? this.toUserFriendly('hour', converter.dateTimeView(element.startDateTime), converter.dateTimeView(element.endDateTime)) : undefined,
          thirdColumn: element.zoomLink,
          fourthColumn: `${element.numberOfParticipant}`,
          fifthColumn: element.lastReminder ? this.toUserFriendly('date', converter.dateTimeView(element.lastReminder), undefined) : '-',
          fifthColumnDesc: element.lastReminder ? this.toUserFriendly('date', converter.dateTimeView(element.lastReminder), undefined) : undefined
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    toUserFriendly(time, firstText, secondText) {
      switch (time) {
        case 'date':
          if (secondText) {
            return `${firstText.slice(0, -6)} - ${secondText.slice(0, -6)}`
          } else {
            return firstText.slice(0, -6)
          }
        case 'hour':
          if (secondText) {
            return `${firstText.slice(-6)} - ${secondText.slice(-6)} (GMT+7)`
          } else {
            return `${firstText.slice(-6)} (GMT+7)`
          }
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    copyText(params) {
      const textArea = document.createElement('textarea')
      textArea.value = params
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        document.execCommand('copy')
        notification.successMessage('Copied!')
      } catch (err) {
        notification.errorMessage(err)
      }

      document.body.removeChild(textArea)
    },
    toEditOpenDay(id) {
      this.$router.push({
        name: 'Update Event',
        params: { program: 'new-btc', event: 'open-day', id }
      })
    },
    toAttendance(id) {
      this.$router.push({
        name: 'Attendance',
        params: { program: 'new-btc', event: 'open-day', id }
      })
    }
  }
}
