var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container mx-auto px-5"},[_c('FirstTemplate',{attrs:{"visibility":[true, true, true],"justifyContent":'end',"buttons":_vm.buttons,"inputIconSVGAdditionalIconStyle":'cursor-pointer',"inputIconSVGIconAction":function () { return _vm.searchStudent(_vm.keyword); },"inputIconSVGHeightIcon":'24',"inputIconSVGWidthIcon":'24',"inputIconSVGViewBox":'0 0 24 24',"paths":[
      {
        d:
          'M21.4073 19.7527L16.9969 15.3422C18.0587 13.9286 18.6319 12.208 18.63 10.44C18.63 5.92406 14.9559 2.25 10.44 2.25C5.92406 2.25 2.25 5.92406 2.25 10.44C2.25 14.9559 5.92406 18.63 10.44 18.63C12.208 18.6319 13.9286 18.0587 15.3422 16.9969L19.7527 21.4073C19.9759 21.6069 20.2671 21.7135 20.5664 21.7051C20.8658 21.6967 21.1506 21.574 21.3623 21.3623C21.574 21.1506 21.6967 20.8658 21.7051 20.5664C21.7135 20.2671 21.6069 19.9759 21.4073 19.7527ZM4.59 10.44C4.59 9.28298 4.9331 8.15194 5.5759 7.18991C6.21871 6.22789 7.13235 5.47808 8.2013 5.03531C9.27025 4.59253 10.4465 4.47668 11.5813 4.70241C12.7161 4.92813 13.7584 5.48529 14.5766 6.30343C15.3947 7.12156 15.9519 8.16393 16.1776 9.29872C16.4033 10.4335 16.2875 11.6098 15.8447 12.6787C15.4019 13.7476 14.6521 14.6613 13.6901 15.3041C12.7281 15.9469 11.597 16.29 10.44 16.29C8.88906 16.2881 7.40217 15.6712 6.30548 14.5745C5.2088 13.4778 4.59186 11.9909 4.59 10.44Z'
      }
    ],"inputIconSVGFillIconDefault":'#858585',"inputIconSVGFillIconFocus":'#F7931E',"inputIconSVGLabelText":'Search User',"inputIconSVGType":'text',"inputIconSVGPlaceholder":'Type here...',"inputIconSVGReadValue":_vm.keyword,"inputIconSVGWriteValue":function (value) { return (_vm.keyword = value); },"secondButtonButtons":_vm.secondButtons,"tableHeader":['Name', 'Phone Number', 'UTM', 'Timestamp'],"tableHeaderAlign":['left', 'left', 'left', 'left'],"list":_vm.studentList,"listAlign":['left', 'left', 'left', 'left'],"actionButtons":[
      {
        additionalStyle: 'p-1.5',
        height: '18',
        width: '18',
        viewBox: '0 0 18 18',
        paths: [
          {
            d:
              'M13.6103 0.753154C14.6145 -0.251051 16.2426 -0.251051 17.2468 0.753154C18.2511 1.75736 18.2511 3.3855 17.2468 4.3897L16.7577 4.87881C16.4648 5.1717 15.99 5.1717 15.6971 4.87881L13.1212 2.30292C12.8283 2.01003 12.8283 1.53515 13.1212 1.24226L13.6103 0.753154Z',
            fill: '#D6D6D6'
          },
          {
            d:
              'M11.6732 3.75093C11.3803 3.45803 10.9054 3.45803 10.6125 3.75093L0.21967 14.1438C0.0790179 14.2844 0 14.4752 0 14.6741V17.25C0 17.6642 0.335786 18 0.75 18H3.32589C3.5248 18 3.71556 17.921 3.85622 17.7803L14.2491 7.38747C14.542 7.09458 14.542 6.61971 14.2491 6.32681L11.6732 3.75093Z',
            fill: '#D6D6D6'
          }
        ],
        next: _vm.toEditSiswa,
        disabled: true
      }
    ],"tooltip":[false, false, true, false],"tooltipSize":15,"totalPages":_vm.totalPages,"totalItems":_vm.totalItems,"size":_vm.size,"currentPage":_vm.currentPage,"counter":_vm.counter,"changePage":_vm.changePage,"changeSize":_vm.changeSize,"headerTextNotFound":_vm.isLoading ? 'Searching data...' : 'Not found',"descriptionTextNotFound":_vm.isLoading ? '' : 'The result that you are looking for is not found',"actionNotFound":{ text: _vm.isLoading ? '' : _vm.keyword !== '' ? 'Clear search' : 'Clear filter', next: _vm.keyword !== '' ? _vm.clearSearch : _vm.clearFilter }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }