import { mapActions } from 'vuex'
import { isNotEmptyError } from '@/util/form-validator'
import { notification, validator } from '@/util'
const CONFIG = {
  companyName: 'Account Name'
}
export default {
  components: {
    InputWithLabel: () => import(/* webpackChunkName: "input-with-label" */ '@/components/Forms/InputWithLabel'),
    SelectWithLabel: () => import(/* webpackChunkName: "select-with-label" */ '@/components/Forms/SelectWithLabel'),
    TextareaWithLabel: () => import(/* webpackChunkName: "textarea-with-label" */ '@/components/Forms/TextareaWithLabel'),
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button')
  },
  props: {
    id: Number
  },
  data() {
    return {
      lookupSource: [],
      lookupSourceOriginal: [],
      lookupType: [],
      lookupTypeOriginal: [],
      sourceB2B: '23',
      typeB2B: '16',
      customerId: 1,
      name: '',
      payload: {
        companyName: ''
      },
      phoneNumber: '',
      description: '',
      selectedCooperationType: [],
      cooperation: [],
      errors: [],
      phoneInputType: 'number',
      modeForm: null,
      selectedType: {},
      selectedSource: {}
    }
  },
  created() {
    this.getLookupSource()
    this.getLookupType()
    this.getLookupCooperation()
    this.modeForm = this.$route.meta.mode
  },
  mounted() {
    if (this.modeForm != 'edit') {
      this.name = localStorage.getItem('user_name')
    } else {
      this.fetchAccountData(this.id)
    }
  },
  methods: {
    ...mapActions(['getLookup', 'addAccount', 'updateAccount', 'getAccountById', 'showLoading', 'hideLoading']),
    fetchAccountData(id) {
      this.getAccountById({ id: id }).then((res) => {
        this.name = res.leadOwner
        this.payload.companyName = res.accountName
        this.typeB2B = res.accountType.id
        this.phoneNumber = res.phoneNumber
        this.sourceB2B = res.source.id
        this.description = res.description
        const cooperation = res.cooperationInterest.map(item => item.id)
        this.selectedCooperationType = [].concat(...cooperation);
      })
    },
    getLookupSource() {
      this.getLookup({
        params: {
          lookupGroup: 'source'
        }
      }).then((res) => {
        res.forEach(item => {
          this.lookupSource.push({
            id: item.id,
            name: item.description
          })
        })
        this.lookupSourceOriginal = res
      })
    },
    getLookupType() {
      this.getLookup({
        params: {
          lookupGroup: 'account_type'
        }
      }).then((res) => {
        res.forEach(item => {
          this.lookupType.push({
            id: item.id,
            name: item.description
          })
        })
        this.lookupTypeOriginal = res
      })
    },
    getLookupCooperation() {
      this.getLookup({
        params: {
          lookupGroup: 'cooperation_interest'
        }
      }).then((res) => {
        res.forEach(item => {
          this.cooperation.push({
            id: item.id,
            label: item.description
          })
        })
      })
    },
    save() {
      if (this.validForm()) {
        if (this.modeForm != 'edit') {
          const payloads = {
            customerId: this.customerId,
            companyName: this.payload.companyName,
            accountType: this.lookupTypeOriginal.filter(item => item.id === this.typeB2B)[0]?.id || this.typeB2B,
            phoneNumber: this.phoneNumber,
            sourceId: this.lookupSourceOriginal.filter(item => item.id === this.sourceB2B)[0]?.id || this.sourceB2B,
            description: this.description,
            cooperationInterest: this.selectedCooperationType.map(item => {
              return item
            })
          }
          if (payloads.phoneNumber !== '' && validator.phone(payloads.phoneNumber)) {
            notification.errorMessage('Phone number is invalid!')
            return
          }
          this.showLoading()
          this.addAccount({
            requestBody: payloads
          }).then(() => {
            notification.successMessage('Account is successfully added!')
            this.hideLoading()
            this.$router.push('/user/accounts')
          }).catch(() => {
            notification.errorMessage('Account is failed to be added! Please try again.')
            this.hideLoading()
          })
        } else {
          const payloads = {
            customerId: this.customerId,
            companyName: this.payload.companyName,
            accountType: this.lookupTypeOriginal.filter(item => item.id === this.typeB2B)[0]?.id || '',
            phoneNumber: this.phoneNumber,
            sourceId: this.lookupSourceOriginal.filter(item => item.id === this.sourceB2B)[0]?.id || '',
            description: this.description,
            cooperationInterest: this.selectedCooperationType.map(item => {
              return item
            })
          }
          if (payloads.phoneNumber !== '' && validator.phone(payloads.phoneNumber)) {
            notification.errorMessage('Phone number is invalid!')
            return
          }
          this.showLoading()
          this.updateAccount({
            id: this.id,
            requestBody: payloads
          }).then(() => {
            notification.successMessage('Account is successfully updated!')
            this.hideLoading()
            this.$router.push('/user/accounts')
          }).catch(() => {
            notification.errorMessage('Account is failed to be update! Please try again.')
            this.hideLoading()
          })
        }
      } else {
        notification.errorMessage('Failed to add new account, please make sure all required information is provided!')
      }
    },
    validForm() {
      const error = isNotEmptyError(this.payload)
      this.errors = error.reduce(function(map, obj) {
        map[obj.field] = CONFIG[obj.field] + ' ' + obj.error
        return map
      }, {})
      return Object.keys(this.errors).length === 0
    }
  }
}