import { mapActions } from 'vuex'
import { converter } from '@/util'
import Pagination from '@/molecules/others/Pagination.vue'
const KeywordSearch = () => import(/* webpackChunkName: "keyword-search" */ '@/components/KeywordSearch')

export default {
  name: 'payment-pra-bootcamp',
  components: { Pagination, KeywordSearch },
  data: () => ({
    counter: 0,
    activeClassType: 'PRABOOTCAMP',
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    size: 10,
    keyword: '',
    siswaList: [],
    isLoading: true
  }),
  mounted() {
    this.changeStatus(this.currentPage)
  },
  methods: {
    ...mapActions(['getReceiptsList', 'showLoading', 'hideLoading']),
    getSiswas(currentPage) {
      this.showLoading()
      this.getReceiptsList({
        params: {
          page: currentPage,
          size: this.size,
          type: this.activeClassType
        }
      }).then(res => {
        this.distributePayload(res)
      })
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.siswaList = []
      res.data.forEach(siswa => {
        this.siswaList.push({
          id: siswa.studentId,
          studentLmsClassId: siswa.id,
          coreClassName: siswa.coreClassName,
          name: siswa.fullName,
          email: siswa.email,
          phoneNumber: siswa.phoneNumber,
          lastStatus: siswa.description,
          lastTimestamp: converter.dateTimeView(siswa.updatedDate),
          uploaded: siswa.uploaded
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    changeStatus(currentPage) {
      this.isLoading = true
      if (this.keyword !== '') {
        this.searchByKeyword(currentPage)
      } else {
        this.getSiswas(currentPage)
      }
      for (let i = 0; i < this.size; i++) {
        this.siswaList.push({
          id: '',
          name: '',
          email: '',
          phoneNumber: '',
          lastStatus: '',
          lastTimestamp: ''
        })
      }
    },
    changePage(page) {
      this.changeStatus(page)
    },
    searchStudent(keyword) {
      this.keyword = keyword
      this.searchByKeyword(0)
    },
    searchByKeyword(currentPage) {
      if (this.keyword !== '') {
        this.showLoading()
        this.getReceiptsList({
          params: {
            page: currentPage,
            size: this.size,
            query: this.keyword,
            type: this.activeClassType
          }
        }).then(res => {
          this.distributePayload(res)
        })
      } else {
        this.changeStatus(0)
      }
    },
    toEditPayment(studentLmsClassId) {
      this.$router.push({
        name: 'Edit Pra Bootcamp Payment',
        params: { studentLmsClassId }
      })
    }
  }
}
