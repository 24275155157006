<template>
  <div>
    <label v-if="label" class="text-xs pl-3 text-left font-medium text-gray-500 block mb-1">{{ label }}</label>
    <input
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
      :class="[withIcon ? 'pl-11' : 'pl-3', smallSize ? 'h-11' : 'py-3', { 'is-invalid': hasErrors, 'bg-grey cursor-not-allowed': disabled, 'border-grey-field border': borderEnabled }]"
      class="pr-3  focus:outline-none  w-full rounded-lg"
      :disabled="disabled || disabledWhite"
      v-on:keyup.enter="enterKeyAction()"
      v-on:keypress="keyPressAction()"
      @focus="onFocus"
      @blur="onBlur"
    />
    <span class="text-xs text-gray-500 leading-tight inline-block mt-1" v-if="notes">{{ notes }}</span>
    <transition name="fade" mode="out-in">
      <div class="text-red-500 text-left mt-1 text-xs" v-if="error">{{ error }}</div>
    </transition>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  props: {
    title: String,
    notes: String,
    disabled: Boolean,
    disabledWhite: {
      type: Boolean,
      default: false
    },
    borderEnabled: {
      type: Boolean,
      default: false
    },
    withIcon: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    maximumLength: {
      type: Number
    },
    smallSize: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasErrors() {
      return !!this.error.length
    }
  },
  methods: {
    enterKeyAction() {
      this.$emit('enter')
    },
    keyPressAction() {
      this.$emit('keypress')
    },
    onFocus() {
      this.$emit('onFocus')
    },
    onBlur() {
      this.$emit('onBlur')
    }
  }
}
</script>
<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
