import axios from 'axios'
import { endpoint } from '@/constant'
import { endpointFunction } from '@/util'

const b2b = {
  addAccount: requestBody => {
    const url = `${endpoint.newCrmV1}/account`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  updateAccount: (id, requestBody) => {
    const url = `${endpoint.newCrmV1}/account/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  getAllAccounts: params => {
    const url = `${endpoint.newCrmV1}/account${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  getAccountById: id => {
    const url = `${endpoint.newCrmV1}/account/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  getLookup: params => {
    const url = `${endpoint.newCrmV1}/lookup${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  getAllContacts: params => {
    const url = `${endpoint.newCrmV1}/contact${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  getContactById: id => {
    const url = `${endpoint.newCrmV1}/contact/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  addContact: requestBody => {
    const url = `${endpoint.newCrmV1}/contact`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  updateContact: (id, requestBody) => {
    const url = `${endpoint.newCrmV1}/contact/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  addAccountByContact: (contactId, requestBody) => {
    const url = `${endpoint.newCrmV1}/contact/${contactId}/account`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  deleteAccountByContact: (contactId, accountId) => {
    const url = `${endpoint.newCrmV1}/contact/${contactId}/account/${accountId}`
    const headers = {
      ...endpointFunction.getAuthHeaderOnlyBearer(),
      'Content-Type': 'application/json'
    }
    return axios.delete(url, { headers, data: {} })
  },
  updatePrimaryAccount: (contactId, accountId, requestBody) => {
    const url = `${endpoint.newCrmV1}/contact/${contactId}/account/${accountId}`
    const headers = {
      ...endpointFunction.getAuthHeaderOnlyBearer(),
      'Content-Type': 'application/json'
    }
    return axios.put(url, requestBody, { headers })
  },
  updatePrimaryContact: (accountId, contactId, requestBody) => {
    const url = `${endpoint.newCrmV1}/account/${accountId}/contact/${contactId}`
    const headers = {
      ...endpointFunction.getAuthHeaderOnlyBearer(),
      'Content-Type': 'application/json'
    }
    return axios.put(url, requestBody, { headers })
  }
}

export default b2b