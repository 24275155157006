<template>
  <div :class="`flex flex-${flexDirection} justify-${justifyContent}`">
    <div class="flex" v-for="(button, index) in buttons" :key="index + Math.random()">
      <div :class="button.hide ? '' : index === 0 ? 'mr-1' : index === buttons.length - 1 ? 'ml-1' : 'mx-1'">
        <ButtonNew :action="button.action" :text="button.text" :type="button.type" :size="button.size" :hide="button.hide" :disabled="button.disabled" />
      </div>
    </div>
  </div>
</template>
<script>
const ButtonNew = () => import(/* webpackChunkName: "button-new" */ '@/components/Button/ButtonNew')
export default {
  name: 'buttons-group',
  components: { ButtonNew },
  props: {
    flexDirection: { type: String, default: 'row' },
    justifyContent: { type: String, default: 'start' },
    buttons: { type: Array, required: true }
  }
}
</script>
