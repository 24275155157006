export default {
  name: 'siswa-status',
  props: {
    statuses: {
      type: Array,
      required: true
    },
    activeStatusName: {
      type: String,
      required: true
    },
    changeActiveStatus: {
      type: Function,
      required: true
    }
  }
}
