<template>
  <div class="flex flex-col">
    <h1 class="mb-3.5 text-3xl font-bold">{{ name }}</h1>
    <p class="font-semibold text-sm">
      <span
        class="mr-1"
        :class="[index === routeNameArr.length - 1 ? 'text-yellow' : item === '/' ? 'cursor-text' : 'cursor-pointer']"
        v-for="(item, index) in routeNameArr"
        :key="index + Math.random()"
        @click="check(item, index)"
      >
        {{ item }}
      </span>
    </p>
  </div>
</template>
<script>
export default {
  name: 'page-title',
  props: {
    pageName: { type: String },
    action: { type: Function },
    routePath: { type: String }
  },
  computed: {
    name: function() {
      return this.pageName ? this.pageName : this.$route.name
    },
    routeNameArr: function() {
      const routeArr = this.routePath ? this.routePath.split('/') : this.$route.path.split('/')
      let routeNameArr = []
      for (let i = 1; i < routeArr.length; i++) {
        let subRouteArr = routeArr[i].split('-')
        if (subRouteArr.length > 1) {
          let subRouteNameArr = []
          let subRouteNameFix = ''
          for (let j = 0; j < subRouteArr.length; j++) {
            const subLowerCase = subRouteArr[j]
            const subCamelCase = subLowerCase.charAt(0).toUpperCase() + subLowerCase.slice(1)
            subRouteNameArr.push(subCamelCase)
          }
          for (let k = 0; k < subRouteNameArr.length; k++) {
            k === subRouteNameArr.length - 1 ? (subRouteNameFix += `${subRouteNameArr[k]}`) : (subRouteNameFix += `${subRouteNameArr[k]} `)
          }
          routeNameArr.push(subRouteNameFix)
        } else {
          const lowerCase = routeArr[i]
          const camelCase = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
          routeNameArr.push(camelCase)
        }
        if (i !== routeArr.length - 1) routeNameArr.push('/')
      }
      return routeNameArr
    }
  },
  methods: {
    check(item, index) {
      if (index !== this.routeNameArr.length - 1 && item !== '/') {
        this.action(item, index)
      }
    }
  }
}
</script>
