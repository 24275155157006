import axios from 'axios'
import { endpoint } from '@/constant'
import { endpointFunction } from '@/util'

const student = {
  updatePaymentMethod: (prospectId, requestBody) => {
    const url = `${endpoint.prospectsV2}/${prospectId}/payment-method`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  addActivity: (id, requestBody) => {
    const url = `${endpoint.prospectsV2}/${id}/interaction-activities`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  addNewStatus: (scholarshipId, requestBody) => {
    const url = `${endpoint.newCrm}/crm/scholarships/${scholarshipId}/transitions`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  addNote: (id, requestBody) => {
    const url = `${endpoint.generalV2}/interaction-activities/${id}/notes`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  addActivityBulk: requestBody => {
    const url = `${endpoint.generalV2}/interaction-activities`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  createPipelineStage: (customerId, requestBody) => {
    const url = `${endpoint.customersV1}/${customerId}/pipeline-stages`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  createSiswa: requestBody => {
    const url = `${endpoint.student}/register`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  deletePipelineStage: (stageId, customerId) => {
    const url = `${endpoint.customersV1}/${customerId}/pipeline-stages/${stageId}`
    return axios.delete(url, { headers: endpointFunction.getAuthHeader() })
  },
  deleteReceipt: (studentLmsClassId, requestBody) => {
    const url = `${endpoint.paymentReceipt}/student-class/${studentLmsClassId}/delete-list`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  deleteUpdatePipelineStage: (stageId, customerId, requestBody) => {
    const url = `${endpoint.customersV1}/${customerId}/pipeline-stages/${stageId}/new-stage-prospect`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  download: requestBody => {
    const url = `${endpoint.prospect}/download`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  downloadContact: requestBody => {
    const url = `${endpoint.contact}/download`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  downloadCsv: requestBody => {
    const url = `${endpoint.student}/download`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  downloadProspect: params => {
    const url = `${endpoint.prospectsV2}/download${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  downloadScholarship: params => {
    // const url = `${endpoint.baseAssetsV4}/new/crm/crm/scholarships/download${endpointFunction.serializeQueryParams(params)}`
    const url = `${endpoint.newCrm}/crm/scholarships/download${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  eventUploadCsv: (eventID, params, data) => {
    const url = `${endpoint.events}/${eventID}/participants/bulk-enroll${endpointFunction.serializeQueryParams(params)}`
    return axios.post(url, data, { headers: endpointFunction.getAuthAndMultipartHeader() })
  },
  eventsUploadCsv: (params, file) => {
    const url = `${endpoint.prospect}/import${endpointFunction.serializeQueryParams(params)}`
    return axios.post(url, file, { headers: endpointFunction.getAuthAndMultipartHeader() })
  },
  getActivityDetail: id => {
    const url = `${endpoint.generalV2}/interaction-activities/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getActivityLog: (id, params) => {
    const url = `${endpoint.prospectsV2}/${id}/interaction-activities${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAlliances: () => {
    const url = `${endpoint.customers}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getTempContents: () => {
    const url = `${endpoint.interaction}/template-activity`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getTempNotes: () => {
    const url = `${endpoint.interaction}/template-notes`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllParticipants: params => {
    const url = `${endpoint.events}/participants${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllPrograms: () => {
    const url = `${endpoint.programs}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllSiswa: params => {
    const url = `${endpoint.siswa}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllSiswaPage: params => {
    const url = `${endpoint.siswa}/get-all${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllStudentList: params => {
    const url = `${endpoint.student}/list/all${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAvailableStages: (studentId, params) => {
    const url = `${endpoint.prospects}/${studentId}/available-stages${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAwardeeList: params => {
    // const url = `${endpoint.baseAssetsV4}/new/crm/crm/scholarships${endpointFunction.serializeQueryParams(params)}`
    const url = `${endpoint.newCrm}/crm/scholarships${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  getClasses: params => {
    const url = `${endpoint.baseClass}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getContactList: params => {
    const url = `${endpoint.contact}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getDetailPayment: studentLmsClassId => {
    const url = `${endpoint.paymentReceipt}/student-class/${studentLmsClassId}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getPipelineStage: (stageId, customerId) => {
    const url = `${endpoint.customersV1}/${customerId}/pipeline-stages/${stageId}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getPipelineStages: params => {
    const url = `${endpoint.crm}/pipeline-stages${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getProfileDetailScholarship: scholarshipId => {
    const url = `${endpoint.newCrm}/crm/scholarships/${scholarshipId}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  getProgram: params => {
    const url = `${endpoint.class}/program${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getProspectDetail: studentId => {
    const url = `${endpoint.prospects}/${studentId}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getProspectList: params => {
    const url = `${endpoint.prospectsV2}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getProspectLog: (studentId, params) => {
    const url = `${endpoint.prospects}/${studentId}/histories${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getReceiptsList: params => {
    const url = `${endpoint.student}/payment-receipts-list${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getRegistrationDetails: (studentId, params) => {
    const url = `${endpoint.student}/${studentId}/registration-details${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getEnrollmentHistory: (studentId, params) => {
    const url = `${endpoint.newCrmV1}/student/${studentId}/enrollment-history`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getSiswaDetail: id => {
    const url = `${endpoint.siswa}/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getStages: programName => {
    const url = `${endpoint.prospects}/${programName}/stages`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getStatusesScholarship: query => {
    const url = `${endpoint.newCrm}/crm/workflows/scholarship/statuses` + endpointFunction.serializeQueryParams(query)
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  getStatusList: (customerId, params) => {
    const url = `${endpoint.customersV1}/${customerId}/pipeline-stages${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getStatusScholarship: scholarshipId => {
    const url = `${endpoint.newCrm}/crm/scholarships/${scholarshipId}/transitions`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  getStudentByEmail: email => {
    const url = `${endpoint.student}/email/${email}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getStudentCount: params => {
    const url = `${endpoint.student}/count${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getStudentList: params => {
    const url = `${endpoint.student}/list${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getStudentLog: (studentId, params) => {
    const url = `${endpoint.student}/log/${studentId}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getWorkflowOptionScholarship: params => {
    const url = `${endpoint.newCrm}/crm/workflows/scholarship/transitions` + endpointFunction.serializeQueryParams(params)
    return axios.get(url, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  },
  importUser: requestBody => {
    const url = `${endpoint.prospectsV2}/import`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateActivityOutcome: (id, requestBody) => {
    const url = `${endpoint.generalV2}/interaction-activities/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateKelas: (studentId, requestBody) => {
    const url = `${endpoint.student}/enroll/${studentId}`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updatePipelineStage: (stageId, customerId, requestBody) => {
    const url = `${endpoint.customersV1}/${customerId}/pipeline-stages/${stageId}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateProspect: (studentId, requestBody) => {
    const url = `${endpoint.prospects}/${studentId}/histories`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateProspectCoreClass: (studentId, requestBody) => {
    const url = `${endpoint.prospectsV2}/${studentId}/core-class`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateProspectPut: (studentId, requestBody) => {
    const url = `${endpoint.prospects}/${studentId}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateReceipt: (studentLmsClassId, requestBody) => {
    const url = `${endpoint.paymentReceipt}/student-class/${studentLmsClassId}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateSiswa: (studentLmsClassId, requestBody) => {
    const url = `${endpoint.student}/status/${studentLmsClassId}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateBulkProspect: requestBody => {
    const url = `${endpoint.prospectsV2}/selected/status/bulk-update`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateBulkSiswa: requestBody => {
    const url = `${endpoint.student}/status/bulk-update`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  uploadBuktiPembayaran: data => {
    const url = `${endpoint.image}`
    return axios.post(url, data, { headers: endpointFunction.getAuthAndMultipartHeader() })
  },
  uploadCsv: data => {
    const url = `${endpoint.siswaNew}/create-by-upload`
    return axios.post(url, data, { headers: endpointFunction.getAuthAndMultipartHeader() })
  },
  uploadReceipt: (studentLmsClassId, requestBody) => {
    const url = `${endpoint.paymentReceipt}/student-class/${studentLmsClassId}`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  importSchorashipDataViaCsv: requestBody => {
    const url = `${endpoint.newCrm}/crm/scholarships/import`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeaderOnlyBearer() })
  }
}

export default student
