import axios from 'axios'
import { endpoint } from '@/constant'
import { endpointFunction } from '@/util'

const common = {
  changePassword: requestBody => {
    const url = `${endpoint.user.changePassword}`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  checkServer: () => {
    const url = `${endpoint.checkServer}`
    return axios.get(url)
  },
  getAllChat: (number, params) => {
    const url = `${endpoint.whatsapp}/api/admin/comment/user_id/${number}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderJwt() })
  },
  getAllChatSelenium: (number, params) => {
    const url = `${endpoint.whatsapp}/api/admin/comment_selenium/user_phone/${number}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderJwt() })
  },
  getChatStatus: number => {
    const url = `${endpoint.whatsapp}/api/admin/user_id/${number}/status`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderJwt() })
  },
  getCountSubStage: params => {
    const url = `${endpoint.programsV2}/count${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getMenu: params => {
    const url = `${endpoint.programsV2}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getNotifications: params => {
    const url = `${endpoint.whatsapp}/api/admin/room_selenium${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderJwt() })
  },
  getProgramOptions: params => {
    const url = `${endpoint.programs}/core-class${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getProgramStages: (programId, params) => {
    const url = `${endpoint.programsV2}/${programId}/stages${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getTemplateChat: () => {
    const url = `${endpoint.whatsapp}/api/admin/template`
    return axios.get(url, { headers: endpointFunction.getAuthHeaderJwt() })
  },
  getUploadUrl: params => {
    const url = `${endpoint.getUploadUrl}${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  login: requestBody => {
    const url = `${endpoint.user.login}`
    return axios.post(url, requestBody)
  },
  sendBroadcast: payload => {
    const url = `${endpoint.whatsapp}/api/admin/broadcast`
    return axios.post(url, payload, { headers: endpointFunction.getAuthHeaderJwt() })
  },
  sendBroadcastMultiple: payload => {
    const url = `${endpoint.whatsapp}/api/admin/broadcast/multi`
    return axios.post(url, payload, { headers: endpointFunction.getAuthHeaderJwt() })
  },
  sendChat: (number, payload) => {
    const url = `${endpoint.whatsapp}/api/admin/comment/user_id/${number}`
    return axios.post(url, payload, { headers: endpointFunction.getAuthHeaderJwt() })
  },
  sendChatSelenium: (number, payload) => {
    const url = `${endpoint.whatsapp}/api/admin/message_selenium`
    return axios.post(url, payload, { headers: endpointFunction.getAuthHeaderJwt() })
  },
  sendMessageMultiple: payload => {
    const url = `${endpoint.whatsapp}/api/admin/message_selenium/multi`
    return axios.post(url, payload, { headers: endpointFunction.getAuthHeaderJwt() })
  },
  updateUploadFile: (url, data, contentType) => {
    return axios.put(url, data, { headers: { 'Content-Type': `${contentType}` } })
  },
  uploadImageAsset: file => {
    const url = `${endpoint.image}`
    return axios.post(url, file, { headers: endpointFunction.getAuthAndMultipartHeader() })
  }
}

export default common
