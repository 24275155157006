const BASE_PATH = process.env.VUE_APP_API_URL
const BASE_ASSETS_V4 = process.env.VUE_APP_API_URL_ASSETS_V4
const BASE_PATH_WHATSAPP = process.env.VUE_APP_API_URL_WHATSAPP
const BASE_CRM = process.env.VUE_APP_API_URL_CRM
const endpoint = {
  basePath: BASE_PATH,
  baseAssetsV4: BASE_ASSETS_V4,
  checkServer: `${BASE_PATH}/`,
  getUploadUrl: `${BASE_ASSETS_V4}/uploads`,
  whatsapp: `${BASE_PATH_WHATSAPP}`,
  crm: `${BASE_PATH}/api/crm`,
  // newCrm: `${BASE_ASSETS_V4}/new/crm`,
  // newCrmV1: `${BASE_ASSETS_V4}/new/crm/v1/api`,
  newCrm: BASE_CRM,
  newCrmV1: `${BASE_CRM}/v1/api`,
  crmControllerV2: `${BASE_PATH}/api/v2/crm`,
  crmControllerV2Int: `${BASE_PATH}/api/v2/crm/international`,
  jadwalOrientasi: `${BASE_PATH}/api/jadwal-orientasi`,
  openDays: `${BASE_PATH}/api/open-days`,
  siswa: `${BASE_PATH}/api/students-internal`,
  siswaNew: `${BASE_PATH}/api/students-crm`,
  kirimEmail: `${BASE_PATH}/api/kirim-email`,
  emailTemplate: `${BASE_PATH}/api/template-email`,
  user: {
    login: `${BASE_PATH}/api/auth/login`,
    changePassword: `${BASE_PATH}/api/user/change-password`
  },
  image: `${BASE_PATH}/api/image`,
  mitra: `${BASE_PATH}/api/mitra`,
  pipeline: `${BASE_PATH}/api/pipeline`,
  accountManager: `${BASE_PATH}/api/account-manager`,
  management: `${BASE_PATH}/api/management`,
  agreement: `${BASE_PATH}/api/agreement`,
  files: `${BASE_PATH}/api/files`,
  prakerja: `${BASE_PATH}/api/kelas-prakerja`,
  customersV1: `${BASE_PATH}/v1/api/crm/customers`,
  customers: `${BASE_PATH}/api/customers`,
  prospect: `${BASE_PATH}/api/prospect`,
  prospects: `${BASE_PATH}/api/prospects`,
  baseClass: `${BASE_PATH}/api/class`,
  // new version API v2
  generalV2: `${BASE_PATH}/v2/api`,
  crmV2: `${BASE_PATH}/api/v2/crm`,
  class: `${BASE_PATH}/v2/api/class`,
  events: `${BASE_PATH}/v2/api/events`,
  programsV2: `${BASE_PATH}/v2/api/programs`,
  programs: `${BASE_PATH}/api/programs`,
  student: `${BASE_PATH}/v2/api/student`,
  contact: `${BASE_PATH}/v2/api/crm/contact`,
  prospectsV2: `${BASE_PATH}/v2/api/prospects`,
  interaction: `${BASE_PATH}/v2/api/interaction-activities`,
  paymentReceipt: `${BASE_PATH}/v2/api/payment-receipt`
}

export default endpoint
