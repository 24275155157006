<template>
  <svg @click="action()" :height="`${height}` || '20'" :width="`${width}` || '20'" :viewBox="`${viewBox}` || '0 0 20 20'" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      v-for="(item, index) in paths"
      :key="index"
      :d="item.d"
      :fill="item.fill"
      :fill-rule="item.fillRule"
      :clip-rule="item.clipRule"
      :stroke="item.stroke"
      :stroke-width="item.strokeWidth"
      :stroke-linecap="item.strokeLinecap"
      :stroke-linejoin="item.strokeLinejoin"
    />
  </svg>
</template>
<script>
export default {
  name: 'general-icon',
  props: {
    action: { type: Function, default: () => {} },
    height: { type: String, default: '20' },
    width: { type: String, default: '20' },
    viewBox: { type: String, default: '0 0 20 20' },
    paths: { type: Array }
  }
}
</script>
