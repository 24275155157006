import { mapActions } from 'vuex'
import { converter, notification } from '@/util'

export default {
  name: 'EditG2Scholarship',
  data: () => ({
    biodata: [
      {
        name: 'Full Name',
        value: ''
      },
      {
        name: 'Email',
        value: ''
      },
      {
        name: 'Phone Number:',
        value: ''
      }
    ],
    formNewStatus: {
      transitionId: '',
      notes: ''
    },
    newStatusOptions: [],
    statusList: [],
    classOption: []
  }),
  computed: {
    scholarship() {
      return this.$route.params.id
    }
  },
  mounted() {
    this.fetchProfileDetail()
    this.fetchStatusList()
  },
  methods: {
    ...mapActions(['getProfileDetailScholarship', 'getStatusScholarshipList', 'getWorkflowOptionScholarship', 'addNewStatus', 'showLoading', 'hideLoading']),
    async fetchProfileDetail() {
      this.showLoading()
      try {
        const response = await this.getProfileDetailScholarship({
          scholarshipId: this.scholarship
        })
        this.biodata[0].value = response.name
        this.biodata[1].value = response.email
        this.biodata[2].value = response.phoneNumber
        this.classOption = response.classType.split(' ')
      } catch {
        notification.errorMessage('Something went wrong!')
      } finally {
        this.hideLoading()
      }
    },
    async fetchStatusList() {
      this.showLoading()
      try {
        const response = await this.getStatusScholarshipList({
          scholarshipId: this.scholarship
        })
        this.statusList = response

        this.fetchWorkflowOption(
          response[0]
            ? response[0]
            : {
                toStatus: null
              }
        )
      } catch {
        notification.errorMessage('Something went wrong!')
      } finally {
        this.hideLoading()
      }
    },
    async fetchWorkflowOption(data) {
      this.showLoading()
      try {
        const response = await this.getWorkflowOptionScholarship({
          customerId: 1,
          currentWorkflowStatusId: 7
        })
        this.newStatusOptions = response.filter(item => item.statusName !== data.toStatus)
      } catch (e) {
        notification.errorMessage('Something went wrong!')
      } finally {
        this.hideLoading()
      }
    },
    async onSaveNewStatus() {
      this.showLoading()
      try {
        await this.addNewStatus({
          scholarshipId: this.scholarship,
          body: this.formNewStatus
        })
        notification.successMessage('Status successfully updated!')
        this.fetchStatusList()
        this.formNewStatus = {
          transitionId: '',
          notes: ''
        }
      } catch (error) {
        notification.errorMessage('Invalid transition id')
      } finally {
        this.hideLoading()
      }
    },
    dateConverter(date) {
      return converter.dateTimeRequest(date)
    }
  }
}
