import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '@/views/Home'
// const AllUsers = () => import(/* webpackChunkName: "all-users" */ '@/views/AllUsers')
const AWSClouducation = () => import(/* webpackChunkName: "aws-clouducation" */ '@/views/AWSClouducation')
const Orientasi = () => import(/* webpackChunkName: "orientasi" */ '@/views/Orientasi')
const Absensi = () => import(/* webpackChunkName: "absensi" */ '@/views/Absensi')
const EditSiswa = () => import(/* webpackChunkName: "edit-siswa" */ '@/views/EditSiswa')
const EditJadwal = () => import(/* webpackChunkName: "edit-jadwal" */ '@/views/EditJadwal')
const EditStatus = () => import(/* webpackChunkName: "edit-status" */ '@/views/EditStatus')
const TempEmail = () => import(/* webpackChunkName: "temp-email" */ '@/views/TempEmail.vue')
const TempEdit = () => import(/* webpackChunkName: "temp-edit" */ '@/views/TempEdit.vue')
const SendEmailChoose = () => import(/* webpackChunkName: "kir-email" */ '@/views/SendEmailChoose.vue')
const SendEmailWrite = () => import(/* webpackChunkName: "kir-email2" */ '@/views/SendEmailWrite.vue')
const SendEmailResult = () => import(/* webpackChunkName: "kir-email3" */ '@/views/SendEmailResult.vue')
const PaymentPraBootcamp = () => import(/* webpackChunkName: "edit-jadwal" */ '@/views/PaymentPraBootcamp')
const EditPaymentPraBootcamp = () => import(/* webpackChunkName: "edit-status" */ '@/views/EditPaymentPraBootcamp')
const NotFound = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/NotFound.vue')
const Login = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/Login.vue')
const DaftarPerusahaan = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/DaftarPerusahaan.vue')
const DaftarPipeline = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/DaftarPipeline.vue')
const EditPipeline = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/EditPipeline.vue')
const HistoryPipeline = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/HistoryPipeline.vue')
const AddPipeline = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/AddPipeline.vue')
const DaftarAkun = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/DaftarAkun.vue')
const DaftarAgreement = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/DaftarAgreement.vue')
const EditAgreement = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/EditAgreement.vue')
const AddAgreement = () => import(/* webpackChunkName: "payment-g2-edit" */ '@/views/AddAgreement.vue')
// const Prakerja = () => import(/* webpackChunkName: "prakerja" */ '@/views/Prakerja')
const International = () => import(/* webpackChunkName: "international" */ '@/views/International.vue')
const EventInternational = () => import(/* webpackChunkName: "computer-science-day" */ '@/views/EventInternational.vue')
const EventSchedule = () => import(/* webpackChunkName: "add-jadwal-cs-day" */ '@/views/EventSchedule.vue')
const Leads = () => import(/* webpackChunkName: "leads" */ '@/views/Leads.vue')
const AddLead = () => import(/* webpackChunkName: "add-leads" */ '@/views/AddLead.vue')
const ArchivedLeads = () => import(/* webpackChunkName: "archived-leads" */ '@/views/ArchivedLeads.vue')
const DetailLead = () => import(/* webpackChunkName: "detail-lead" */ '@/views/DetailLead.vue')
const Accounts = () => import(/* webpackChunkName: "accounts" */ '@/views/Accounts.vue')
const AddAccount = () => import(/* webpackChunkName: "add-account" */ '@/views/AddAccount.vue')
const DetailAccount = () => import(/* webpackChunkName: "detail-account" */ '@/views/DetailAccount.vue')
const Contacts = () => import(/* webpackChunkName: "contacts" */ '@/views/Contacts.vue')
const AddContact = () => import(/* webpackChunkName: "add-contact" */ '@/views/AddContact.vue')
const DetailContact = () => import(/* webpackChunkName: "detail-contact" */ '@/views/DetailContact.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/user'
  },
  { path: '/login', name: 'Login', component: Login },
  { path: '/server-down', name: 'ServerDown', component: () => import(/* webpackChunkName: "server-down" */ '@/views/ServerDown') },
  {
    path: '/user',
    // redirect: '/user/all-users',
    component: Home,
    name: 'User',
    children: [
      {
        path: 'all-users',
        name: 'All Users',
        // component: AllStudent
        component: NotFound
      },
      {
        path: 'tanpa-kelas',
        name: 'Tanpa Kelas',
        component: () => import(/* webpackChunkName: "tanpa-kelas" */ '@/views/student/TanpaKelas')
      },
      {
        path: 'bootcampv3',
        name: 'Bootcamp V3',
        component: () => import(/* webpackChunkName: "bootcamp" */ '@/views/student/BootcampV3')
      },
      {
        path: 'bootcampv2',
        name: 'Bootcamp V2',
        component: () => import(/* webpackChunkName: "new-bootcamp" */ '@/views/student/NewBootcamp')
      },
      {
        path: 'event',
        name: 'Event',
        component: () => import(/* webpackChunkName: "event" */ '@/views/student/Event')
      },
      {
        path: 'short-course',
        name: 'Short Course',
        component: () => import(/* webpackChunkName: "short-course" */ '@/views/student/ShortCourse')
      },
      {
        path: 'bootcamp/jadwal-orientasi',
        name: 'Jadwal Orientasi',
        component: Orientasi
      },
      {
        path: 'new-btc/open-day',
        name: 'Open Day',
        component: () => import(/* webpackChunkName: "open-day" */ '@/views/event/OpenDay')
      },
      {
        path: 'waiting-list',
        name: 'Waiting List',
        component: () => import(/* webpackChunkName: "waiting-list" */ '@/views/student/WaitingList')
      },
      {
        path: 'waiting-list/:programId/:coreClassId',
        name: 'Waiting List Details',
        component: () => import(/* webpackChunkName: "waiting-list-details" */ '@/views/student/WaitingListDetails')
      },
      {
        path: 'bootcamp/diagnostic-assessment',
        name: 'Diagnostic Assessment',
        component: () => import(/* webpackChunkName: "open-day" */ '@/views/event/OpenDay')
      },
      {
        path: 'bootcamp/pra-bootcamp-payment',
        name: 'Pra Bootcamp Payment',
        component: PaymentPraBootcamp
      },
      {
        path: 'bootcamp/pra-bootcamp-payment/:studentLmsClassId',
        name: 'Edit Pra Bootcamp Payment',
        component: EditPaymentPraBootcamp
      },
      {
        path: ':classType/absensi/:orientasiId',
        name: 'Absensi Orientasi',
        component: Absensi
      },
      {
        path: ':classType/:programId/edit/:description/:id/:studentLmsClassId/:otherId',
        name: 'Edit Siswa',
        component: EditSiswa
      },
      {
        path: ':classType/:programId/edit/:description/:id/:studentLmsClassId/:otherId/activities/:activityId',
        name: 'Update Activity',
        component: () => import(/* webpackChunkName: "update-activity" */ '@/views/student/UpdateActivity')
      },
      {
        path: 'bootcamp/jadwal-orientasi/:id',
        name: 'Edit Jadwal',
        component: EditJadwal
      },
      {
        path: ':program/:event/:id',
        name: 'Update Event',
        component: () => import(/* webpackChunkName: "update-event" */ '@/views/event/UpdateEvent')
      },
      {
        path: ':program/:event/attendance/:id',
        name: 'Attendance',
        component: () => import(/* webpackChunkName: "attendance" */ '@/views/event/Attendance')
      },
      {
        path: 'status/edit/:id',
        name: 'Edit Status',
        component: EditStatus
      },
      {
        path: 'email-templates',
        name: 'Email Templates',
        component: TempEmail
      },
      {
        path: 'email-templates/:id',
        name: 'Edit Template',
        component: TempEdit
      },
      {
        path: 'send-email',
        name: 'Send Email',
        // component: SendEmailChoose
        component: NotFound
      },
      {
        path: 'write-email',
        name: 'Write Email',
        component: SendEmailWrite
      },
      {
        path: 'result-email',
        name: 'Result Email',
        component: SendEmailResult
      },
      {
        path: ':program/:id',
        name: 'Program',
        component: International
      },
      {
        path: 'international/:programId/jadwal-event/:event/:programStageId',
        name: 'Jadwal Event',
        component: EventSchedule
      },
      {
        path: 'international/:programId/jadwal-event/:event/:programStageId/:id',
        name: 'Event International',
        component: EventInternational
      },
      {
        path: 'eduverse',
        name: 'Eduverse',
        component: () => import(/* webpackChunkName: "update-event" */ '@/views/student/Eduverse')
      },
      {
        path: 'prakerja',
        name: 'Prakerja',
        // component: Prakerja
        component: NotFound
      },
      {
        path: 'active-leads',
        name: 'Active Leads',
        component: Leads
      },
      {
        path: 'active-leads/add',
        name: 'Add New Lead',
        meta: {
          mode: 'add'
        },
        component: AddLead
      },
      {
        path: 'archived-leads',
        name: 'Archived Leads',
        component: ArchivedLeads
      },
      {
        path: ':category/detail-lead/:id',
        name: 'Detail Lead',
        component: DetailLead
      },
      {
        path: 'accounts',
        name: 'Accounts',
        component: Accounts
      },
      {
        path: 'accounts/add',
        name: 'Add Account',
        meta: {
          mode: 'add'
        },
        component: AddAccount
      },
      {
        path: 'accounts/detail-account/:id',
        name: 'Detail Account',
        component: DetailAccount,
        props: true
      },
      {
        path: 'accounts/edit/:id',
        name: 'Edit Account',
        meta: {
          mode: 'edit'
        },
        component: AddAccount,
        props: true
      },
      {
        path: 'contacts',
        name: 'Contacts',
        component: Contacts
      },
      {
        path: 'contacts/add',
        name: 'Add Contact',
        meta: {
          mode: 'add'
        },
        component: AddContact,
        props: true
      },
      {
        path: 'contacts/detail-contact/:id',
        name: 'Detail Contact',
        component: DetailContact,
        props: true
      },
      {
        path: 'contacts/edit/:id',
        name: 'Edit Contact',
        meta: {
          mode: 'edit'
        },
        component: AddContact,
        props: true
      }
    ]
  },
  {
    path: '/payment',
    name: 'Payment',
    redirect: '/payment/g2-scholarship',
    component: Home,
    children: [
      {
        path: 'g2-scholarship',
        name: 'G2 Scholarship',
        component: () => import(/* webpackChunkName: "update-event" */ '@/views/payment/G2Scholarship')
      },
      {
        path: 'g2-scholarship/edit/:id',
        name: 'G2 Scholarship / Edit',
        component: () => import(/* webpackChunkName: "update-event" */ '@/views/payment/EditG2Scholarship')
      },
      {
        path: 'alliance-scholarship',
        name: 'Alliance Scholarship',
        component: NotFound
      },
      {
        path: 'alliance-scholarship/edit/:id',
        name: 'Alliance Scholarship / Edit',
        component: NotFound
      }
    ]
  },
  {
    path: '/b2b',
    redirect: '/b2b/daftar-perusahaan',
    component: Home,
    name: 'B2B',
    children: [
      {
        path: 'daftar-perusahaan',
        name: 'Daftar Perusahaan',
        component: DaftarPerusahaan
      },
      {
        path: 'pipeline',
        name: 'Daftar Pipeline',
        component: DaftarPipeline
      },
      {
        path: 'pipeline/edit/:id',
        name: 'Edit Pipeline',
        component: EditPipeline
      },
      {
        path: 'pipeline/add/:id',
        name: 'Add Pipeline',
        component: AddPipeline
      },
      {
        path: 'pipeline/history/:id',
        name: 'History Pipeline',
        component: HistoryPipeline
      },
      {
        path: 'akun',
        name: 'Akun',
        component: DaftarAkun
      },
      {
        path: 'agreement',
        name: 'Daftar Agreement',
        component: DaftarAgreement
      },
      {
        path: 'agreement/edit/:id',
        name: 'Edit Agreement',
        component: EditAgreement
      },
      {
        path: 'agreement/add',
        name: 'Add Agreement',
        component: AddAgreement
      }
    ]
  },
  {
    path: '/events',
    redirect: '/events/aws-clouducation-pre-event',
    component: Home,
    name: 'Events',
    children: [
      {
        path: 'aws-clouducation-pre-event',
        name: 'AWS Clouducation Pre Event',
        component: AWSClouducation
      },
      {
        path: 'aws-clouducation-main-event',
        name: 'AWS Clouducation Main Event',
        component: AWSClouducation
      },
      {
        path: ':classType/:programId/edit/:description/:id/:studentLmsClassId/:otherId',
        name: 'Visitor Detail',
        component: EditSiswa
      }
    ]
  },
  {
    path: '/setting',
    redirect: '/setting/status',
    component: Home,
    name: 'Setting',
    children: [
      {
        path: 'status',
        name: 'Setting Status',
        component: () => import(/* webpackChunkName: "setting-status" */ '@/views/setting/Status')
      },
      {
        path: ':submenu/:id',
        name: 'Create Update',
        component: () => import(/* webpackChunkName: "create-update" */ '@/views/setting/CreateUpdate')
      }
    ]
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const parseJwt = token => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
  return JSON.parse(jsonPayload)
}

router.beforeEach((to, from, next) => {
  store.dispatch('getCurrentUser')
  if (store.getters['userData'].token) {
    const tokenExpiredAt = parseJwt(store.getters['userData'].token).exp
    if (new Date().getTime() > new Date(tokenExpiredAt * 1000).getTime()) {
      store.dispatch('removeUserData')
      next({
        name: 'Login'
      })
      return
    }
  }
  if (store.getters.isLoggedIn && to.name === 'Login') {
    next({
      name: 'Home'
    })
    return
  }
  if (to.name === 'Login') store.dispatch('checkServer')
  if (!store.getters.isLoggedIn && to.name !== 'Login' && to.name !== 'ServerDown') {
    store.dispatch('checkServer')
    next({
      name: 'Login'
    })
  }
  if (localStorage.getItem('user_role') === 'CMS_ADMIN' && to.name === 'Program' && to.params.program === 'contacts') {
    next({
      name: 'Add Contact'
    })
    return
  }
  if (localStorage.getItem('user_role') === 'CMS_ADMIN' && to.name === 'Program') {
    next({
      name: 'Add Account'
    })
    return
  }
  if (localStorage.getItem('user_role') === 'CMS_ADMIN' && to.name === 'Update Event' && to.params.program === 'contacts' && to.params.event === 'edit') {
    next({
      name: 'Edit Contact',
      params: { id: to.params.id }
    })
    return
  }
  if (localStorage.getItem('user_role') === 'CMS_ADMIN' && to.name === 'Update Event' && to.params.program === 'contacts') {
    next({
      name: 'Detail Contact',
      params: { id: to.params.id }
    })
    return
  }
  if (localStorage.getItem('user_role') === 'CMS_ADMIN' && to.name === 'Update Event') {
    next({
      name: 'Detail Account',
      params: { id: to.params.id }
    })
  }
  if (localStorage.getItem('user_role') === 'CMS_ADMIN' && to.name === 'Update Event' && to.params.event === 'edit') {
    next({
      name: 'Edit Account',
      params: { id: to.params.id }
    })
  }
  if (localStorage.getItem('user_role') === 'CRM_ADMIN' && to.name === 'Daftar Perusahaan') {
    next({
      name: 'Akun'
    })
  }
  if (localStorage.getItem('user_role') === 'CRM_ADMIN' && to.name === 'User') {
    next({
      path: `/user/all-users/${store.getters.ourMenu.currentPage + 2}`
    })
  } else if (localStorage.getItem('user_role') === 'CMS_ADMIN' && to.name === 'User') {
    next({
      path: '/user/accounts'
    })
  }
  if (store.getters.isLoggedIn) {
    store.dispatch('getNotifications', {
      params: {
        page: 0,
        size: 100
      }
    })
  }
  next()
})

export default router
