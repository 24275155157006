import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
const FirstTemplate = () => import(/* webpackChunkName: "first-template" */ '@/templates/FirstTemplate')
export default {
  name: 'attendance',
  components: { FirstTemplate },
  data: () => ({
    counter: 0,
    size: 10,
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    isLoading: true,
    selectedData: [],
    participantList: []
  }),
  computed: {
    fourthButtons: function() {
      return [
        {
          action: () => this.updateStudent(this.canUpdate),
          text: 'Pindah Status',
          type: 'primary',
          size: 'regular',
          hide: true
        },
        {
          action: () => this.updateStudent(this.canUpdate),
          text: 'Pindah Status',
          type: 'primary',
          size: 'regular'
        }
      ]
    }
  },
  mounted() {
    this.isLoading = true
    this.getAllParticipant()
  },
  methods: {
    ...mapActions(['getParticipantList', 'showLoading', 'hideLoading']),
    actionCheckbox(val) {
      this.selectedData = val
      for (let i = 0; i < this.selectedData.length; i++) {
        this.selectedData[i].status !== this.selectedData[0].status ? (this.canUpdate = false) : (this.canUpdate = true)
      }
    },
    changeSize(size) {
      this.currentPage = 0
      this.size = size
      this.getAllParticipant()
    },
    getAllParticipant() {
      this.showLoading()
      this.getParticipantList({
        eventId: this.$route.params.id,
        params: {
          page: this.currentPage,
          size: this.size
        }
      }).then(res => {
        this.distributePayload(res)
      })
    },
    changePage(currentPage) {
      this.currentPage = currentPage
      this.getAllParticipant()
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.participantList = []
      res.data.forEach(element => {
        this.participantList.push({
          firstQuery: element.id,
          secondQuery: element.id,
          firstColumn: element.name,
          firstColumnDesc: element.email,
          secondColumn: element.phoneNumber,
          thirdColumn: element.isPresent === null ? '-' : element.isPresent === true ? 'Present' : 'Absent',
          //   fourthColumn: `${element.numberOfInvitation}`
          fourthColumn: `0`
        })
      })
      this.isLoading = false
      this.hideLoading()
    },
    toUserFriendly(time, firstText, secondText) {
      switch (time) {
        case 'date':
          if (secondText) {
            return `${firstText.slice(0, -6)} - ${secondText.slice(0, -6)}`
          } else {
            return firstText.slice(0, -6)
          }
        case 'hour':
          if (secondText) {
            return `${firstText.slice(-6)} - ${secondText.slice(-6)} (GMT+7)`
          } else {
            return `${firstText.slice(-6)} (GMT+7)`
          }
      }
    },
    goBack() {
      this.$router.go(-1)
    },
    async copyText(params) {
      navigator.clipboard.writeText(params)
      notification.successMessage('Copied!')
    },
    toEditSiswa(id, studentLmsClassId) {
      this.$router.push({
        name: 'Edit Siswa',
        params: {
          classType: 'bootcamp',
          programId: '0',
          description: '0',
          id,
          studentLmsClassId,
          otherId: '0'
        }
      })
    }
  }
}
