import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const formatFullDate = (value) => {
    if (value) {
        return dayjs(String(value)).format('DD MMMM YYYY')
    }
}

const formatFullDateMinusDay = (value, days) => {
    if (value) {
        return dayjs(String(value)).subtract(days, 'days').format('DD MMMM YYYY')
    }
}

const utcToLocal = (day, format = '') => {
    const localTime = dayjs.utc(day).local().format(format)
    return localTime
}

export { formatFullDate, formatFullDateMinusDay, utcToLocal }
