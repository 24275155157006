import axios from 'axios'
import { endpoint } from '@/constant'
import { endpointFunction } from '@/util'

const classes = {
    getClassListWithTotalLeads: (params) => {
        const url = `${endpoint.prospectsV2}/waiting-list${endpointFunction.serializeQueryParams(params)}`
        return axios.get(url, { headers: endpointFunction.getAuthHeader() })
    }
}

export default classes