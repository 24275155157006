import api from '@/api'

const state = {
  userData: { token: null },
  menu: [],
  notifications: [],
  userContact: null,
  isChatCardVisible: false,
  disable: false,
  loading: false,
  templateCsvScholarship: 'https://g2labcms.s3.ap-southeast-1.amazonaws.com/template_g2scholarship.csv'
}

const actions = {
  checkServer: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api.common.checkServer().then(
        response => resolve(response.data.data),
        error => reject(error)
      )
    })
  },
  getMenu: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.common.getMenu(params).then(
        response => {
          resolve(response.data.data)
          commit('setMenu', response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getProgramOptions: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.common.getProgramOptions(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getProgramStages: ({ commit }, { programId, params }) => {
    return new Promise((resolve, reject) => {
      api.common.getProgramStages(programId, params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getCountSubStage: ({ commit }, { params }) => {
    return new Promise((resolve, reject) => {
      api.common.getCountSubStage(params).then(
        response => {
          resolve(response.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getCurrentUser: ({ commit }) => {
    const data = {
      token: localStorage.getItem('access_token'),
      name: localStorage.getItem('user_name'),
      role: localStorage.getItem('user_role'),
      team: localStorage.getItem('user_team')
    }
    commit('setUserData', data)
  },
  login: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.common.login(requestBody).then(
        response => {
          if (response.data.code === 200) {
            resolve(response.data.data)
            commit('setUserData', response.data.data)
          } else {
            reject(response.data)
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  changePassword: ({ commit }, { requestBody }) => {
    return new Promise((resolve, reject) => {
      api.common.changePassword(requestBody).then(
        response => {
          if (response.data.code === 200) {
            resolve(response.data.data)
          } else {
            reject(response.data)
          }
        },
        error => {
          reject(error)
        }
      )
    })
  },
  uploadImageAsset: ({ commit }, { file }) => {
    return new Promise((resolve, reject) => {
      api.common.uploadImageAsset(file).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getUploadUrl({ _ }, { params }) {
    return new Promise((resolve, reject) => {
      api.common.getUploadUrl(params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  updateUploadFile({ _ }, { url, data, contentType }) {
    return new Promise((resolve, reject) => {
      api.common.updateUploadFile(url, data, contentType).then(
        response => {
          resolve(response.body)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getChatStatus({ _ }, { number }) {
    return new Promise((resolve, reject) => {
      api.common.getChatStatus(number).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getTemplateChat({ _ }) {
    return new Promise((resolve, reject) => {
      api.common.getTemplateChat().then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getNotifications({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      api.common.getNotifications(params).then(
        response => {
          resolve(response.data)
          commit('setNotifications', response.data.data.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAllChat({ _ }, { number, params }) {
    return new Promise((resolve, reject) => {
      api.common.getAllChat(number, params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  getAllChatSelenium({ _ }, { number, params }) {
    return new Promise((resolve, reject) => {
      api.common.getAllChatSelenium(number, params).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  sendBroadcast({ _ }, { payload }) {
    return new Promise((resolve, reject) => {
      api.common.sendBroadcast(payload).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  sendBroadcastMultiple({ _ }, { payload }) {
    return new Promise((resolve, reject) => {
      api.common.sendBroadcastMultiple(payload).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  sendChat({ _ }, { number, payload }) {
    return new Promise((resolve, reject) => {
      api.common.sendChat(number, payload).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  sendChatSelenium({ _ }, { number, payload }) {
    return new Promise((resolve, reject) => {
      api.common.sendChatSelenium(number, payload).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  sendMessageMultiple({ _ }, { payload }) {
    return new Promise((resolve, reject) => {
      api.common.sendMessageMultiple(payload).then(
        response => {
          resolve(response.data)
        },
        error => {
          reject(error)
        }
      )
    })
  },
  showLoading: ({ commit }) => {
    commit('setLoadingStatus', true)
  },
  hideLoading: ({ commit }) => {
    commit('setLoadingStatus', false)
  },
  removeUserData: ({ commit }) => {
    localStorage.removeItem('user_id')
    localStorage.removeItem('client_id')
    localStorage.removeItem('access_token')
    localStorage.removeItem('user_name')
    localStorage.removeItem('user_email')
    localStorage.removeItem('user_role')
    localStorage.removeItem('user_team')
  }
}

const mutations = {
  setNotifications(state, value) {
    state.notifications = value
  },
  setIsChatCardVisible(state) {
    state.isChatCardVisible = !state.isChatCardVisible
  },
  setUserContact(state, value) {
    state.userContact = value
  },
  setLoadingStatus(state, value) {
    state.loading = value
  },
  setMenu(state, value) {
    state.menu = value
  },
  setDisableStatus(state, value) {
    state.disable = value
  },
  setUserData(state, value) {
    state.userData = value
  }
}

const getters = {
  notifications: state => {
    return state.notifications
  },
  getIsChatCardVisible: state => {
    return state.isChatCardVisible
  },
  getUserContact: state => {
    return state.userContact
  },
  loading: state => {
    return state.loading
  },
  ourMenu: state => {
    return state.menu
  },
  getDisable: state => {
    return state.disable
  },
  userData: state => {
    return state.userData
  },
  isLoggedIn: state => {
    return state && state.userData ? state.userData.token !== null : false
  },
  templateCsvScholarship: state => {
    return state.templateCsvScholarship
  },
}

export default { state, getters, actions, mutations }
