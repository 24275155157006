<template>
  <div class="my-3">
    <p class="text-custom-gray-2 font-semibold text-sm">{{ `Showing ${startData} - ${endData} of ${totalDatas}` }}</p>
  </div>
</template>
<script>
export default {
  name: 'showing-of-data',
  props: {
    currentPage: { required: true },
    size: { required: true },
    totalItemsCurrentPage: { required: true },
    totalDatas: { required: true }
  },
  computed: {
    startData: function() {
      let number = 0
      if (this.totalItemsCurrentPage !== 0) {
        number = this.currentPage * this.size
        number += 1
      }
      return number
    },
    endData: function() {
      let number = 0
      if (this.totalItemsCurrentPage !== 0) {
        number = this.currentPage * this.size
        number += this.totalItemsCurrentPage
      }
      return number
    }
  }
}
</script>
