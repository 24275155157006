import moment from 'moment'
import { notification } from '@/util'
import { mapActions } from 'vuex'
export default {
  name: 'Contacts',
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Forms/TextField'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown')
  },
  mounted: function () {
    this.initData()
  },
  data: function () {
    return {
      options: {
        limit: 10,
        page: 0,
        q: '',
        direction: 'ASC',
        sortBy: 'name'
      },
      customerId: 1,
      isFetching: false,
      isSearching: false,
      contactList: [],
      showProgress: true,
      counterProgress: 0,
      modeForm: '',
      locked: false,
      unlockPassword: '',
      stillLoading: true,
      testingName: '',
      existingEmployee: '',
      modalContact: false,
      fileList: [],
      fileName: '',
      typeFileOrImage: '',
      response: { name: '' },
      tmp: '',
      isReset: false,
      filterSelected: {
        accountType: null
      },
      totalRecords: 0,
      dropdownItems: {
        accountType: []
      }
    }
  },
  methods: {
    ...mapActions(['showLoading', 'hideLoading', 'getAllContacts', 'getContactById', 'getLookup']),
    formatDate(date) {
      if (date) {
        return {
          date: moment(date).local().format('DD MMM YYYY'),
          time: moment(date).local().format('HH:mm:ss')
        }
      } else {
        return '-'
      }
    },
    importContacts() {
      this.modalContact = true
    },
    createFile(file) {
      this.fileName = file.name
      this.typeFileOrImage = file.type
      let reader = new FileReader()
      reader.onload = (e) => {
        this.response.name = e.target.result
      }
      reader.readAsDataURL(file)
    },
    dragover(event) {
      event.preventDefault()
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100')
        event.currentTarget.classList.add('bg-yellow-200')
      }
    },
    onChange() {
      this.fileList = [...this.$refs.file.files]
      this.createFile(this.$refs.file.files[0])
    },
    remove(i) {
      this.fileList.splice(i)
    },
    dragFile(e) {
      e.preventDefault()
      let droppedFiles = e.dataTransfer.files
      this.fileList.push(droppedFiles[0])
      this.createFile(this.fileList[0])
    },
    cancelChangeFile() {
      this.modalContact = false
      this.fileList = []
    },
    async saveChangeFile() {
      if (!this.fileList.length) {
        alert('File harus di isi')
        return
      }
      this.showLoading()
      if (this.typeFileOrImage === 'text/csv') {
        await this.upladImageControl(this.response.name)
        this.modalContact = false
        this.fileList = []
        return
      }
      setTimeout(() => {
        this.hideLoading()
        this.fileList = []
        notification.errorMessage('Document must be in csv format')
      }, 3000)
    },
    initData() {
      this.showLoading()
      this.modeForm = this.$route.meta.mode
      this.counterProgress = 0
      this.options.customerId = this.customerId
      this.getAllContacts({
              params: this.options
            }).then((res) => {
               this.contactList = res.data
               this.totalRecords = res.pagination.total
               this.hideLoading()
            })
    },
    onRowClick(params) {
      this.$router.push({ name: 'Detail Contact', params: {id: params.row.id} })
    },
    onPageChange(params) {
      if (params.currentPerPage !== this.options.limit) {
        this.options.page = 0
      } else {
        this.options.page = params.currentPage - 1
      }
      this.options.limit = params.currentPerPage
      this.initData()
    },
    onSortChange(params) {
      this.options.sortBy = params[0].field
      if (params[0].field === 'account'){
        this.options.sortBy = 'accountName'
      }
      if (params[0].type == 'none') {
        this.options.direction = 'ASC'
        this.options.sortBy = 'name'
      } else if (params[0].type == 'desc') {
        this.options.direction = 'DESC'
      } else {
        this.options.direction = 'ASC'
      }
      this.initData()
    },
    onSearch() {
      this.options.page = 0
      this.initData()
    },
    addData() {
      this.$router.push({ name: 'Add Contact'})
    },
    rowStyleClassFn(row) {
      let result = 'default'

      return result
    },
    async clearFilter() {
      this.showLoading()
      this.isReset = !this.isReset
      this.filterSelected = {
        accountType: null
      }
      this.currentPage = 0
      this.options = {
        limit: 10,
        page: 0,
        q: '',
        direction: 'DESC',
        sortBy: 'updatedDate',
        customerId: this.customerId,
        accountTypeId: null
      }
      this.initData()
      this.hideLoading()
    },
    getLookupType() {
      this.getLookup({
        params: {
          lookupGroup: 'account_type'
        }
      }).then((res) => {
        this.dropdownItems.accountType = res
      })
    }
  },
  computed: {
    columns: function () {
      return [
        { field: 'name', label: 'Name' },
        { field: 'account', label: 'Primary Account' },
        { field: 'phoneNumber', label: 'Phone Number' },
        { field: 'email', label: 'Email' },
        { field: 'title', label: 'Title' },
        { field: 'owner', label: 'Owner' },
        { field: 'createdDate', label: 'Created Date', tdClass: 'text-center', thClass: 'text-center' },
        { field: 'updatedDate', label: 'Last Update', tdClass: 'text-center', thClass: 'text-center' },
      ]
    },
    filters: function () {
      return [{ field: 'q', label: 'Search by Contact or Account' }]
    },
    isClearBtnActive() {
      return this.filterSelected.accountType || this.filterSelected.department  ? true : false
    },
    contactIds() {
      return this.contactList.map(contact => contact.id);
    }
  },
  created: function () {},
  watch: {
    $route(to, from) {
      this.showProgress = true
      this.initData()
    }
  }
}