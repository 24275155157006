<template>
  <div :class="`mt-8 h-20 flex justify-between ${generalTitle ? 'items-end mb-5' : 'items-center'}`">
    <PageTitle :generalTitle="generalTitle" :action="action" :pageName="pageName" :realRoute="realRoute" :routePath="routePath" />
    <div :class="generalTitle ? '-mb-2' : '-mt-2'">
      <ButtonsGroup :flexDirection="flexDirection" :justifyContent="justifyContent" :buttons="buttons" />
    </div>
  </div>
</template>
<script>
const PageTitle = () => import(/* webpackChunkName: "page-title" */ '@/molecules/others/PageTitle')
const ButtonsGroup = () => import(/* webpackChunkName: "buttons-group" */ '@/molecules/tags/ButtonsGroup')
export default {
  name: 'page-header',
  components: { PageTitle, ButtonsGroup },
  props: {
    generalTitle: { type: Boolean, default: true },
    action: { type: Function, default: () => {} },
    pageName: { type: String },
    realRoute: { type: String },
    routePath: { type: String },
    flexDirection: { type: String, default: 'row' },
    justifyContent: { type: String, default: 'start' },
    buttons: { type: Array, required: true }
  }
}
</script>
