import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/styles/index.css'
import VuePageTransition from 'vue-page-transition'
import VModal from 'vue-js-modal'
import moment from 'moment'
import axios from 'axios'
window.queryString = require('query-string')
import VueGoodTablePlugin from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'

Vue.config.productionTip = false
Vue.use(VuePageTransition)
Vue.use(VModal)
Vue.use(VueGoodTablePlugin)
Vue.prototype.moment = moment
axios.defaults.headers.common['X-G2-TimeZone-Offset'] = new Date().getTimezoneOffset()
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 403 || error.response.status === 502) {
      store.dispatch('removeUserData')
      store.dispatch('hideLoading')
      router.push({ name: error.response.status === 403 ? 'Login' : 'ServerDown' })
    }
    return Promise.reject(error.message)
  }
)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
