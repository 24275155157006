export default {
  name: 'yes-no-modal',
  props: {
    height: { type: Number },
    width: { type: Number },
    text: { type: String },
    isVisible: { type: Boolean },
    closed: { type: Function },
    execute: { type: Function }
  },
  watch: {
    isVisible: function(newStatus) {
      newStatus === true ? this.$modal.show('yes-no-modal') : this.$modal.hide('yes-no-modal')
    }
  },
  methods: {
    exit() {
      this.closed()
    }
  }
}
