import { mapActions } from 'vuex'

export default {
  name: 'kir-email2',
  components: {},
  data() {
    return {
      judulEmail: '',
      isiEmail: '',
      loadListId: [],
      payload: {},
      listPenerima: [],
      receivers: {}
    }
  },
  created() {
    this.loadListId = JSON.parse(this.$route.params.ids)
    this.getEmails()
  },
  methods: {
    ...mapActions(['getSiswaDetail']),
    getEmails() {
      for (let i = 0; i < this.loadListId.length; i++) {
        this.getSiswaDetail({ id: this.loadListId[i] }).then(res => this.listPenerima.push(res.email))
      }
    },
    updateCheck() {
      this.receivers = ''
      this.receivers = this.listPenerima.toString()
    },
    sendAct() {
      this.payload = {
        listPenerima: this.receivers,
        judulEmail: this.judulEmail,
        isiEmail: this.isiEmail
      }
      this.$router.push({
        path: '/user/' + JSON.stringify(this.listPenerima) + '/preview/' + JSON.stringify(this.payload)
      })
    },
    backAct() {
      this.$router.push({ path: '/user/send-email' })
    }
  }
}
