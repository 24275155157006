<template>
  <p class="font-semibold text-sm">
    <router-link
      class="mr-1"
      :class="[index === routeNameArr.length - 1 ? 'text-custom-orange-1 cursor-text' : item === '/' ? 'cursor-text' : 'hover:opacity-80']"
      v-for="(item, index) in routeNameArr"
      :key="index + Math.random()"
      :to="index === forRoute.length - 1 || forRoute[index] === '/' ? '' : { name: forRoute[index] }"
      >{{ item }}</router-link
    >
  </p>
</template>
<script>
export default {
  props: {
    realRoute: { type: String },
    routePath: { type: String }
  },
  computed: {
    forRoute: function() {
      const routeArr = this.realRoute ? this.realRoute.split('/') : this.routePath ? this.routePath.split('/') : this.$route.path.split('/')
      let forRoute = []
      for (let i = 1; i < routeArr.length; i++) {
        let subRouteArr = routeArr[i].split('-')
        if (subRouteArr.length > 1) {
          let subRouteNameArr = []
          let subRouteNameFix = ''
          for (let j = 0; j < subRouteArr.length; j++) {
            const subLowerCase = subRouteArr[j]
            const subCamelCase = subLowerCase.charAt(0).toUpperCase() + subLowerCase.slice(1)
            subRouteNameArr.push(subCamelCase)
          }
          for (let k = 0; k < subRouteNameArr.length; k++) {
            k === subRouteNameArr.length - 1 ? (subRouteNameFix += `${subRouteNameArr[k]}`) : (subRouteNameFix += `${subRouteNameArr[k]} `)
          }
          forRoute.push(subRouteNameFix)
        } else {
          const lowerCase = routeArr[i]
          const camelCase = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
          forRoute.push(camelCase)
        }
        i === routeArr.length - 1 ? () => {} : forRoute.push('/')
      }
      return forRoute
    },
    routeNameArr: function() {
      const routeArr = this.routePath ? this.routePath.split('/') : this.$route.path.split('/')
      let routeNameArr = []
      for (let i = 1; i < routeArr.length; i++) {
        let subRouteArr = routeArr[i].split('-')
        if (subRouteArr.length > 1) {
          let subRouteNameArr = []
          let subRouteNameFix = ''
          for (let j = 0; j < subRouteArr.length; j++) {
            const subLowerCase = subRouteArr[j]
            const subCamelCase = subLowerCase.charAt(0).toUpperCase() + subLowerCase.slice(1)
            subRouteNameArr.push(subCamelCase)
          }
          for (let k = 0; k < subRouteNameArr.length; k++) {
            k === subRouteNameArr.length - 1 ? (subRouteNameFix += `${subRouteNameArr[k]}`) : (subRouteNameFix += `${subRouteNameArr[k]} `)
          }
          routeNameArr.push(subRouteNameFix)
        } else {
          const lowerCase = routeArr[i]
          const camelCase = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
          routeNameArr.push(camelCase)
        }
        i === routeArr.length - 1 ? () => {} : routeNameArr.push('/')
      }
      return routeNameArr
    }
  }
}
</script>
