/* eslint-disable no-unused-vars */
export default {
  name: 'import-file-modal',
  props: {
    isVisible: { type: Boolean, required: true },
    closed: { type: Function, required: true }
  },
  watch: {
    isVisible: function (newStatus, oldStatus) {
      newStatus === true ? this.showUpload() : this.hideUpload()
    }
  },
  methods: {
    changeFile(event) {
      this.hideUpload()
      this.$emit('changeFile', event.target.files[0])
    },
    openFile() {
      this.$refs['fileCsv'].click()
    },
    showUpload() {
      this.$modal.show('upload-file')
    },
    hideUpload() {
      this.$modal.hide('upload-file')
    }
  }
}
