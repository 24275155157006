/* eslint-disable no-unused-vars */
const SiswaStatus = () => import(/* webpackChunkName: "siswa-status" */ '@/components/SiswaStatus')
const PipelineModal = () => import(/* webpackChunkName: "pipeline-modal" */ '@/components/PipelineModal')
import { mapActions } from 'vuex'
import TooltipTopLeft from '@/atoms/others/TooltipTopLeft.vue'
import TooltipTopRight from '@/atoms/others/TooltipTopRight.vue'
import { converter } from '@/util'

export default {
  name: 'daftar-pipeline',
  components: { SiswaStatus, PipelineModal, TooltipTopLeft, TooltipTopRight },
  data: () => ({
    userRole: '',
    userId: '',
    searchPerusahaan: '',
    sortBy: '',
    sortByOptions: [],
    sortFn: '',
    sortFnOpt: [
      {
        name: 'companyName',
        display: 'Company Name'
      },
      {
        name: 'picName',
        display: 'PIC'
      },
      {
        name: 'picEmail',
        display: 'Email'
      },
      {
        name: 'pipelineName',
        display: 'Pipeline Name'
      },
      {
        name: 'type',
        display: 'Type'
      },
      {
        name: 'progress',
        display: 'Progress'
      },
      {
        name: 'status',
        display: 'FU Status'
      },
      {
        name: 'comment',
        display: 'FU Comment'
      },
      {
        name: 'registrationDate',
        display: 'Registration Date'
      },
      {
        name: 'assignedTo',
        display: 'Assigned to'
      },
      {
        name: 'projectValue',
        display: 'Project Value'
      }
    ],
    filterByAm: '',
    filterByAmOpt: [],
    currentPage: 0,
    totalItems: 0,
    totalPages: 1,
    activeStatusName: 'IN_PROGRESS',
    startDate: '',
    endDate: '',
    isImportModalVisible: false,
    isResultModalVisible: false,
    importResult: { errorList: [], successList: [] },
    isLoading: true,
    pipelineList: [],
    pipelineListSearch: [],
    statuses: [
      {
        name: 'IN_PROGRESS',
        displayName: 'In Progress',
        siswaCount: 0
      },
      {
        name: 'NEW',
        displayName: 'New',
        siswaCount: 0
      }
    ]
  }),
  watch: {
    searchPerusahaan: function(oldv) {
      this.pipelineListSearch = this.pipelineList.filter(pipeline => pipeline.companyName.toLowerCase().search(oldv.toLowerCase()) !== -1)
    },
    sortBy: function(oldv) {
      this.pipelineListSearch = this.pipelineList.filter(pipeline => pipeline.assignedTo === oldv)
    },
    filterByAm: function() {
      this.pipelineListSearch = this.pipelineList.filter(pipeline => pipeline.assignedTo.toLowerCase().search(this.filterByAm.toLowerCase()) !== -1)
    },
    sortFn: function() {
      switch (this.sortFn) {
        case 'companyName':
          this.pipelineListSearch = this.pipelineList.sort((a, b) =>
            a.companyName.toLowerCase() > b.companyName.toLowerCase() ? 1 : b.companyName.toLowerCase() > a.companyName.toLowerCase() ? -1 : 0
          )
          break
        case 'picName':
          this.pipelineListSearch = this.pipelineList.sort((a, b) => (a.picName.toLowerCase() > b.picName.toLowerCase() ? 1 : b.picName.toLowerCase() > a.picName.toLowerCase() ? -1 : 0))
          break
        case 'picEmail':
          this.pipelineListSearch = this.pipelineList.sort((a, b) => (a.picEmail.toLowerCase() > b.picEmail.toLowerCase() ? 1 : b.picEmail.toLowerCase() > a.picEmail.toLowerCase() ? -1 : 0))
          break
        case 'pipelineName':
          this.pipelineListSearch = this.pipelineList.sort((a, b) =>
            a.pipelineName.toLowerCase() > b.pipelineName.toLowerCase() ? 1 : b.pipelineName.toLowerCase() > a.pipelineName.toLowerCase() ? -1 : 0
          )
          break
        case 'type':
          this.pipelineListSearch = this.pipelineList.sort((a, b) => (a.type.toLowerCase() > b.type.toLowerCase() ? 1 : b.type.toLowerCase() > a.type.toLowerCase() ? -1 : 0))
          break
        case 'progress':
          this.pipelineListSearch = this.pipelineList.sort((a, b) => (a.progress.toLowerCase() > b.progress.toLowerCase() ? 1 : b.progress.toLowerCase() > a.progress.toLowerCase() ? -1 : 0))
          break
        case 'status':
          this.pipelineListSearch = this.pipelineList.sort((a, b) => (a.status.toLowerCase() > b.status.toLowerCase() ? 1 : b.status.toLowerCase() > a.status.toLowerCase() ? -1 : 0))
          break
        case 'comment':
          this.pipelineListSearch = this.pipelineList.sort((a, b) => (a.comment.toLowerCase() > b.comment.toLowerCase() ? 1 : b.comment.toLowerCase() > a.comment.toLowerCase() ? -1 : 0))
          break
        case 'registrationDate':
          this.pipelineListSearch = this.pipelineList.sort((a, b) => (a.registrationDate < b.registrationDate ? 1 : b.registrationDate < a.registrationDate ? -1 : 0))
          break
        case 'assignedTo':
          this.pipelineListSearch = this.pipelineList.sort((a, b) => (a.assignedTo.toLowerCase() > b.assignedTo.toLowerCase() ? 1 : b.assignedTo.toLowerCase() > a.assignedTo.toLowerCase() ? -1 : 0))
          break
        case 'projectValue':
          this.pipelineListSearch = this.pipelineList.sort((a, b) => (a.projectValue < b.projectValue ? 1 : b.projectValue < a.projectValue ? -1 : 0))
          break
        default:
          break
      }
    }
  },
  mounted() {
    ;(this.userRole = localStorage.getItem('user_role')), (this.userId = localStorage.getItem('user_id')), this.changeStatus('IN_PROGRESS', this.currentPage), this.renderListAm()
  },
  methods: {
    ...mapActions(['getAllPipelinesWithAllStatus', 'getPipelineByMitra', 'getAllPipelinesByAM', 'getAllAM', 'showLoading', 'hideLoading']),
    renderListAm() {
      this.getAllAM({}).then(res => (this.filterByAmOpt = res))
    },
    getSiswas(status, currentPage) {
      this.showLoading()
      if (this.userRole === 'CRM_MANAGEMENT') {
        this.getAllPipelinesWithAllStatus({
          params: {
            page: currentPage,
            size: 100
          }
        }).then(res => this.distributePayload(res.pipelineLists))
      } else {
        this.getAllPipelinesByAM({ amId: this.userId }).then(res => this.distributePayload(res))
      }
    },
    distributePayload(res) {
      res.forEach(pipeline => {
        this.statuses[1].siswaCount++
        let data = {
          id: pipeline.id,
          companyName: pipeline.mitraResponse.companyName,
          picName: pipeline.mitraResponse.picName,
          picEmail: pipeline.mitraResponse.picEmail,
          pipelineName: pipeline.name,
          type: pipeline.type,
          progress: pipeline.progress,
          status: pipeline.followUpStatus,
          comment: pipeline.followUpComment,
          registrationDate: '-',
          assignedTo: pipeline.accountManagerResponse.name,
          projectValue: converter.rupiahCurrency(pipeline.projectValue)
        }
        this.sortByOptions.filter(option => option.name === pipeline.accountManagerResponse.name).length > 0
          ? ''
          : this.sortByOptions.push({
              name: pipeline.accountManagerResponse.name,
              display: pipeline.accountManagerResponse.name
            })
        this.pipelineList.push(data)
      })
      this.pipelineList = this.pipelineList.filter(pipeline => pipeline.companyName !== '')
      this.pipelineListSearch = this.pipelineList
      this.isLoading = false
      this.hideLoading()
    },
    getPipeline() {
      this.pipelineList.forEach(pipeline => {
        this.getPipelineByMitra({ id: pipeline.id }).then(res => {
          res.forEach(pipeline => {
            pipeline.pipeline.push(pipeline.name)
          })
        })
      })
      this.pipelineList = this.pipelineList.filter(pipeline => pipeline.companyName !== '')
      this.isLoading = false
    },
    changeStatus(statusName, currentPage) {
      this.isLoading = true
      this.activeStatusName = statusName
      this.getSiswas(statusName, currentPage)
      for (let i = 0; i < 10; i++) {
        this.pipelineList.push({
          id: '',
          companyName: '',
          picName: '',
          picEmail: '',
          registrationDate: '',
          tujuan: '',
          accountManager: '',
          priority: '',
          notes: '',
          pipeline: [],
          isPipelineExpanded: false,
          isActionExpanded: false
        })
      }
    },
    toggleAction(item) {
      item.isActionExpanded = !item.isActionExpanded
    },
    togglePipeline(item) {
      item.isPipelineExpanded = !item.isPipelineExpanded
    },
    showUpload() {
      this.isImportModalVisible = true
    },
    hideUpload() {
      this.isImportModalVisible = false
    },
    showResult() {
      this.isResultModalVisible = true
    },
    hideResult() {
      this.isResultModalVisible = false
      this.$router.go()
    }
  }
}
