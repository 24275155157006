/* eslint-disable */
import { mapActions } from 'vuex'
import { converter, notification } from '@/util'
const ImportFileModal = () => import(/* webpackChunkName: "import-file-modal" */ '@/components/ImportFileModal')
const ResultFileModal = () => import(/* webpackChunkName: "result-file-modal" */ '@/components/ResultFileModal')
const YesNoModal = () => import(/* webpackChunkName: "yes-no-modal" */ '@/components/YesNoModal')
const SiswaStatus = () => import(/* webpackChunkName: "siswa-status" */ '@/components/SiswaStatus')
const TableList = () => import(/* webpackChunkName: "table-list" */ '@/components/TableList')
const TemplateEmailPreview = () => import(/* webpackChunkName: "template-email-preview" */ '@/components/TemplateEmailPreview')

export default {
  name: 'event-international',
  components: {
    ImportFileModal,
    ResultFileModal,
    YesNoModal,
    SiswaStatus,
    TableList,
    TemplateEmailPreview
  },
  data: () => ({
    counter: 0,
    dataEvent: {},
    emailInvitationTemplateId: '',
    emailConfirmPaymentTemplateId: '',
    emailPostEventTemplateId: '',
    emailReminderTemplateId: '',
    emailInvitationTemplateName: '',
    emailConfirmPaymentTemplateName: '',
    emailPostEventTemplateName: '',
    emailReminderTemplateName: '',
    name: '',
    onlineMeetingLink: '',
    event: '',
    minimalDate: '',
    date: '',
    time: '',
    subEvents: [
      {
        name: '',
        date: '',
        time: '',
        startDateTime: '',
        endDateTime: '',
        eventEmails: [
          {
            emailTemplateId: '',
            name: '',
            rule: 'SYSTEM_START',
            seqNo: 1,
            value: -24
          }
        ]
      }
    ],
    templateOptions: [],
    statuses: [],
    activeStatusName: '',
    isImportModalVisible: false,
    isResultModalVisible: false,
    isYesNoModalVisible: false,
    isPreviewModalVisible: false,
    subject: '',
    html: '',
    poster: '',
    importResult: { errorList: [], successList: [] },
    keyword: '',
    currentPage: 0,
    totalItems: 0,
    size: 10,
    totalPages: 1,
    studentList: []
  }),
  mounted() {
    this.minimalDate = converter.date(new Date())
    this.$route.params.event === 'open-day' ? (this.event = 'Open Day') : (this.event = 'Workshop')
    this.getEmailTemplate()
    if (this.$route.params.id !== 'add') {
      this.getDetailEvent()
    }
    this.getFlow()
  },
  watch: {
    emailInvitationTemplateId: function() {
      this.templateOptions.forEach(element => {
        element.id === this.emailInvitationTemplateId ? (this.emailInvitationTemplateName = element.namaTemplate) : null
      })
    },
    emailPostEventTemplateId: function() {
      this.templateOptions.forEach(element => {
        element.id === this.emailPostEventTemplateId ? (this.emailPostEventTemplateName = element.namaTemplate) : null
      })
    },
    emailReminderTemplateId: function() {
      this.templateOptions.forEach(element => {
        element.id === this.emailReminderTemplateId ? (this.emailReminderTemplateName = element.namaTemplate) : null
      })
    },
    emailConfirmPaymentTemplateId: function() {
      this.templateOptions.forEach(element => {
        element.id === this.emailConfirmPaymentTemplateId ? (this.emailConfirmPaymentTemplateName = element.namaTemplate) : null
      })
    }
  },
  methods: {
    ...mapActions([
      'getEventById',
      'createEvent',
      'editEvent',
      'deleteEvent',
      'getAllEmailTemplate',
      'getPreviewEmailByEvent',
      'getAllParticipants',
      'getProgramStages',
      'getCountSubStage',
      'eventUploadCsv',
      'showLoading',
      'hideLoading'
    ]),
    getDetailEvent() {
      this.showLoading()
      this.getEventById({ id: this.$route.params.id })
        .then(res => {
          this.dataEvent = res
          this.name = res.name
          this.onlineMeetingLink = res.onlineMeetingLink
          this.emailInvitationTemplateId = res.eventEmails[0].emailTemplateResponse.id
          this.emailInvitationTemplateName = res.eventEmails[0].emailTemplateResponse.namaTemplate
          if (this.event === 'Open Day') {
            this.date = converter.date(res.startDateTime)
            this.time = converter.time(res.startDateTime)
            this.emailReminderTemplateId = res.eventEmails[1].emailTemplateResponse.id
            this.emailPostEventTemplateId = res.eventEmails[2].emailTemplateResponse.id
            this.emailReminderTemplateName = res.eventEmails[1].emailTemplateResponse.namaTemplate
            this.emailPostEventTemplateName = res.eventEmails[2].emailTemplateResponse.namaTemplate
          } else {
            this.subEvents = []
            for (let i = 0; i < res.subEvents.length; i++) {
              for (let j = 0; j < res.subEvents[i].eventEmails.length; j++) {
                let subEvent = {
                  id: res.subEvents[i].id,
                  name: res.subEvents[i].name,
                  date: converter.date(res.subEvents[i].startDateTime),
                  time: converter.time(res.subEvents[i].startDateTime),
                  startDateTime: '',
                  endDateTime: '',
                  eventEmails: [
                    {
                      id: res.subEvents[i].eventEmails[j].id,
                      emailTemplateId: res.subEvents[i].eventEmails[j].emailTemplateResponse.id,
                      name: res.subEvents[i].eventEmails[j].name,
                      rule: res.subEvents[i].eventEmails[j].rule,
                      seqNo: res.subEvents[i].eventEmails[j].seqNo,
                      value: res.subEvents[i].eventEmails[j].value
                    }
                  ]
                }
                this.subEvents.push(subEvent)
              }
            }
            this.emailConfirmPaymentTemplateId = res.eventEmails[1].emailTemplateResponse.id
            this.emailReminderTemplateId = res.eventEmails[2].emailTemplateResponse.id
            this.emailPostEventTemplateId = res.eventEmails[3].emailTemplateResponse.id
            this.emailConfirmPaymentTemplateName = res.eventEmails[1].emailTemplateResponse.namaTemplate
            this.emailReminderTemplateName = res.eventEmails[2].emailTemplateResponse.namaTemplate
            this.emailPostEventTemplateName = res.eventEmails[3].emailTemplateResponse.namaTemplate
          }
          this.hideLoading()
        })
        .catch(() => {
          notification.errorMessage('Gagal mengambil data!')
          this.hideLoading()
        })
    },
    getEmailTemplate() {
      this.showLoading()
      this.getAllEmailTemplate({
        params: {
          page: 0,
          size: 100
        }
      }).then(res => {
        this.templateOptions = res
        this.hideLoading()
      })
    },
    getEmailPreview(templateName) {
      this.showLoading()
      this.getPreviewEmailByEvent({
        templateName,
        eventId: this.$route.params.id
      }).then(res => {
        this.subject = res.subject
        this.html = res.html
        this.poster = res.attachmentUrl
        this.isPreviewModalVisible = true
        this.hideLoading()
      })
    },
    updateEvent() {
      if (this.name === '') {
        notification.errorMessage('Nama acara harus diisi!')
      } else if (this.event === 'Open Day' && this.date === '') {
        notification.errorMessage('Tanggal acara harus diisi!')
      } else if (this.event === 'Open Day' && this.date < converter.date(new Date())) {
        notification.errorMessage('Harap pilih tanggal acara dengan benar!')
      } else if (this.event === 'Open Day' && this.time === '') {
        notification.errorMessage('Waktu acara harus diisi!')
      } else if (this.event === 'Workshop' && this.subEvents.length < 1) {
        notification.errorMessage('Tanggal dan waktu acara harus diisi dengan klik Tambah Hari!')
      } else if (
        this.event === 'Workshop' &&
        this.subEvents.length > 0 &&
        ((this.subEvents[0] !== undefined && (this.subEvents[0].date === '' || this.subEvents[0].time === '')) ||
          (this.subEvents[1] !== undefined && (this.subEvents[1].date === '' || this.subEvents[1].time === '')) ||
          (this.subEvents[2] !== undefined && (this.subEvents[2].date === '' || this.subEvents[2].time === '')) ||
          (this.subEvents[3] !== undefined && (this.subEvents[3].date === '' || this.subEvents[3].time === '')) ||
          (this.subEvents[4] !== undefined && (this.subEvents[4].date === '' || this.subEvents[4].time === '')))
      ) {
        notification.errorMessage(`Tanggal atau waktu acara harus diisi!`)
      } else if (
        this.event === 'Workshop' &&
        this.subEvents.length > 0 &&
        ((this.subEvents[0] !== undefined && this.subEvents[0].date < converter.date(new Date())) ||
          (this.subEvents[1] !== undefined && this.subEvents[1].date < converter.date(this.subEvents[0].date)) ||
          (this.subEvents[2] !== undefined && this.subEvents[2].date < converter.date(this.subEvents[1].date)) ||
          (this.subEvents[3] !== undefined && this.subEvents[3].date < converter.date(this.subEvents[2].date)) ||
          (this.subEvents[4] !== undefined && this.subEvents[4].date < converter.date(this.subEvents[3].date)))
      ) {
        notification.errorMessage('Harap pilih tanggal acara dengan benar!')
      } else if (this.onlineMeetingLink === '') {
        notification.errorMessage('Link zoom acara harus diisi!')
      } else if (this.emailInvitationTemplateId === '') {
        notification.errorMessage('Template email undangan acara harus diisi!')
      } else if (this.event === 'Workshop' && this.emailConfirmPaymentTemplateId === '') {
        notification.errorMessage('Template email pembayaran terkonfirmasi harus diisi!')
      } else if (this.emailReminderTemplateId === '') {
        notification.errorMessage('Template email reminder acara harus diisi!')
      } else if (this.emailPostEventTemplateId === '') {
        notification.errorMessage('Template email setelah acara harus diisi!')
      } else {
        this.showLoading()
        let subEvents = []
        if (this.event === 'Workshop') {
          for (let i = 0; i < this.subEvents.length; i++) {
            for (let j = 0; j < this.subEvents[i].eventEmails.length; j++) {
              let listEvent = {
                id: this.$route.params.id !== 'add' && this.subEvents[i] !== undefined ? this.subEvents[i].id : undefined,
                name: `Day ${i + 1}`,
                startDateTime: converter.dateTimeRequest(this.subEvents[i].date + ' ' + this.subEvents[i].time),
                endDateTime: converter.dateTimeRequest(this.subEvents[i].date + ' ' + this.subEvents[i].time),
                eventEmails: [
                  {
                    id: this.$route.params.id !== 'add' && this.subEvents[i] !== undefined ? this.subEvents[i].eventEmails[j].id : undefined,
                    emailTemplateId: this.emailReminderTemplateId,
                    name: `Reminder Day ${i + 1}`,
                    rule: this.subEvents[i].eventEmails[j].rule,
                    seqNo: this.subEvents[i].eventEmails[j].seqNo,
                    value: this.subEvents[i].eventEmails[j].value
                  }
                ]
              }
              subEvents.push(listEvent)
            }
          }
        }
        let requestBodyOD = {
          endDateTime: converter.dateTimeRequest(this.date + ' ' + this.time),
          eventEmails: [
            {
              emailTemplateId: this.emailInvitationTemplateId,
              id: this.$route.params.id !== 'add' && this.dataEvent.eventEmails[0] ? this.dataEvent.eventEmails[0].id : undefined,
              name: 'Email Undangan - STAGE',
              rule: 'STAGE',
              seqNo: 1,
              stageId: this.statuses[0].id
            },
            {
              emailTemplateId: this.emailReminderTemplateId,
              id: this.$route.params.id !== 'add' && this.dataEvent.eventEmails[1] ? this.dataEvent.eventEmails[1].id : undefined,
              name: 'Email Reminder',
              rule: 'SYSTEM_START',
              seqNo: 2,
              value: -72
            },
            {
              emailTemplateId: this.emailPostEventTemplateId,
              id: this.$route.params.id !== 'add' && this.dataEvent.eventEmails[2] ? this.dataEvent.eventEmails[2].id : undefined,
              name: 'Email Setelah Acara',
              rule: 'SYSTEM_END',
              seqNo: 3,
              value: 24
            },
            {
              emailTemplateId: this.emailInvitationTemplateId,
              id: this.$route.params.id !== 'add' && this.dataEvent.eventEmails[3] ? this.dataEvent.eventEmails[3].id : undefined,
              name: 'Email Undangan - SYSTEM_START',
              rule: 'SYSTEM_START',
              seqNo: 4,
              value: -72
            }
          ],
          id: this.$route.params.id !== 'add' && this.dataEvent ? this.dataEvent.id : undefined,
          name: this.name,
          onlineMeetingLink: this.onlineMeetingLink,
          programStageId: this.$route.params.programStageId,
          startDateTime: converter.dateTimeRequest(this.date + ' ' + this.time)
        }
        let requestBodyWS = {
          eventEmails: [
            {
              emailTemplateId: this.emailInvitationTemplateId,
              id: this.$route.params.id !== 'add' && this.dataEvent.eventEmails[0] ? this.dataEvent.eventEmails[0].id : undefined,
              name: 'Email Undangan - STAGE',
              rule: 'STAGE',
              seqNo: 1,
              stageId: this.statuses[0].id
            },
            {
              emailTemplateId: this.emailConfirmPaymentTemplateId,
              id: this.$route.params.id !== 'add' && this.dataEvent.eventEmails[1] ? this.dataEvent.eventEmails[1].id : undefined,
              name: 'Email Pembayaran Terkonfirmasi',
              rule: 'STAGE',
              seqNo: 2,
              stageId: this.statuses[1].id
            },
            {
              emailTemplateId: this.emailReminderTemplateId,
              id: this.$route.params.id !== 'add' && this.dataEvent.eventEmails[2] ? this.dataEvent.eventEmails[2].id : undefined,
              name: 'Email Reminder',
              rule: 'SYSTEM_START',
              seqNo: 3,
              value: -72
            },
            {
              emailTemplateId: this.emailPostEventTemplateId,
              id: this.$route.params.id !== 'add' && this.dataEvent.eventEmails[3] ? this.dataEvent.eventEmails[3].id : undefined,
              name: 'Email Setelah Acara',
              rule: 'SYSTEM_END',
              seqNo: 4,
              value: 24
            },
            {
              emailTemplateId: this.emailInvitationTemplateId,
              id: this.$route.params.id !== 'add' && this.dataEvent.eventEmails[4] ? this.dataEvent.eventEmails[4].id : undefined,
              name: 'Email Undangan - SYSTEM_START',
              rule: 'SYSTEM_START',
              seqNo: 5,
              value: -72
            }
          ],
          id: this.$route.params.id !== 'add' && this.dataEvent ? this.dataEvent.id : undefined,
          name: this.name,
          onlineMeetingLink: this.onlineMeetingLink,
          programStageId: this.$route.params.programStageId,
          subEvents
        }
        if (this.$route.params.id === 'add') {
          this.createEvent({
            requestBody: this.$route.params.event === 'open-day' ? requestBodyOD : requestBodyWS
          })
            .then(() => {
              notification.successMessage('Event berhasil dibuat!')
              this.hideLoading()
              this.$router.go(-1)
            })
            .catch(() => {
              notification.errorMessage('Event gagal dibuat, harap mencoba beberapa saat lagi!')
              this.hideLoading()
            })
        } else {
          this.editEvent({
            id: this.$route.params.id,
            requestBody: this.$route.params.event === 'open-day' ? requestBodyOD : requestBodyWS
          })
            .then(() => {
              notification.successMessage('Event berhasil diupdate!')
              this.hideLoading()
              this.$router.go(-1)
            })
            .catch(() => {
              notification.errorMessage('Event gagal diupdate!')
              this.hideLoading()
            })
        }
      }
    },
    deleteSchedule() {
      this.showLoading()
      this.deleteEvent({ id: this.$route.params.id })
        .then(() => {
          notification.successMessage('Event berhasil dihapus!')
          this.hideLoading()
          this.$router.go(-1)
        })
        .catch(() => {
          notification.errorMessage('Event gagal dihapus, harap mencoba beberapa saat lagi!')
          this.hideLoading()
        })
    },
    addSubEvents() {
      let subEvent = {
        name: '',
        date: '',
        time: '',
        startDateTime: '',
        endDateTime: '',
        eventEmails: [
          {
            emailTemplateId: '',
            name: '',
            rule: 'SYSTEM_START',
            seqNo: 1,
            value: -24
          }
        ]
      }
      this.subEvents.push(subEvent)
    },
    deleteSubEvent(index) {
      this.subEvents.splice(index, 1)
    },
    changeFile(file, isSendEmail) {
      let fileData = new FormData()
      fileData.append('studentsCsv', file)
      this.eventUploadCsv({
        eventID: this.$route.params.id,
        params: { isSendEmail },
        data: fileData
      })
        .then(res => {
          this.hideUpload()
          this.showResult()
          this.distributeErrors(res)
        })
        .catch(async res => {
          if (res.code === 422) {
            notification.errorMessage('Format file harus CSV!')
          } else {
            notification.errorMessage('Gagal upload file, harap mencoba beberapa saat kembali!')
          }
        })
    },
    distributeErrors(res) {
      this.importResult.errorList = []
      this.importResult.successList = []
      res.Success.length > 0 ? res.Success.forEach(email => this.importResult.successList.push(email.email)) : (this.importResult.successList = [])
      res.Error.length > 0 ? res.Error.forEach(email => this.importResult.errorList.push(email.email)) : (this.importResult.errorList = [])
    },
    showUpload() {
      this.isImportModalVisible = true
    },
    hideUpload() {
      this.isImportModalVisible = false
    },
    showResult() {
      this.isResultModalVisible = true
    },
    hideResult() {
      this.isResultModalVisible = false
      this.$router.go()
    },
    toggleYesNoModal() {
      this.isYesNoModalVisible = !this.isYesNoModalVisible
    },
    toEditTemplate(forWhat) {
      this.$router.push(`/user/international/${forWhat}/${this.$route.params.id}`)
    },
    showPreviewModal(email) {
      switch (email) {
        case 'invitation':
          this.getEmailPreview(this.emailInvitationTemplateName)
          break
        case 'reminder':
          this.getEmailPreview(this.emailReminderTemplateName)
          break
        case 'postEvent':
          this.getEmailPreview(this.emailPostEventTemplateName)
          break
        case 'confirmPayment':
          this.getEmailPreview(this.emailConfirmPaymentTemplateName)
          break
        default:
          break
      }
    },
    hidePreviewModal() {
      this.isPreviewModalVisible = false
    },
    getList(status, currentPage) {
      this.showLoading()
      this.getAllParticipants({
        params: {
          programId: this.$route.params.programId,
          programStageId: this.$route.params.programStageId,
          stageId: status,
          eventId: this.$route.params.id,
          page: currentPage,
          size: this.size
        }
      }).then(res => {
        this.distributePayload(res)
      })
    },
    distributePayload(res) {
      this.counter === 3 ? (this.counter = 0) : this.counter++
      this.currentPage = res.currentPage
      this.totalItems = res.totalRows
      this.totalPages = res.totalPages
      this.studentList = []
      res.data.forEach(student => {
        this.studentList.push({
          firstQuery: student.studentId,
          secondQuery: student.eventParticipantId,
          firstColumn: student.studentName,
          secondColumn: student.phoneNumber,
          thirdColumn: student.email,
          fourthColumn: student.description ? student.description : '-',
          fifthColumn: student.hasBeenInvited ? 'Ya' : 'Tidak',
          sixthColumn: converter.dateTimeView(student.timestamp)
        })
      })
      this.hideLoading()
    },
    getFlow() {
      this.showLoading()
      this.getProgramStages({
        programId: this.$route.params.programId,
        params: {
          page: 0,
          size: 100
        }
      }).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          for (let j = 0; j < res.data[i].stageResponse.subStageList.length; j++) {
            let list = res.data[i].stageResponse.subStageList[j]
            let subStage = {
              id: list.id,
              name: list.id,
              programStageId: res.data[i].id,
              displayName: list.name,
              siswaCount: 0,
              seqNo: list.seqNo
            }
            this.statuses.push(subStage)
          }
        }
        this.statuses = this.statuses.filter(element => element.programStageId === this.$route.params.programStageId)
        this.activeStatusName = this.statuses[0].id
        this.getCount()
        this.hideLoading()
      })
    },
    getCount() {
      this.statuses.forEach(element => {
        this.getCountSubStage({
          params: {
            programId: this.$route.params.programId,
            programStageId: element.programStageId,
            stageId: element.id,
            eventId: this.$route.params.id
          }
        }).then(res => {
          element.siswaCount = res
        })
      })
      this.changeStatus(this.activeStatusName, this.currentPage)
    },
    changeStatus(statusName, currentPage) {
      this.showLoading()
      this.activeStatusName = statusName
      if (this.keyword !== '') {
        this.searchByKeyword(statusName, currentPage)
      } else {
        this.getList(statusName, currentPage)
      }
    },
    searchStudent(keyword) {
      this.keyword = keyword
      this.searchByKeyword(this.activeStatusName, 0)
    },
    searchByKeyword(statusName, currentPage) {
      if (this.keyword !== '') {
        this.getAllParticipants({
          params: {
            programId: this.$route.params.programId,
            programStageId: this.$route.params.programStageId,
            stageId: statusName,
            eventId: this.$route.params.id,
            page: currentPage,
            size: this.size,
            query: this.keyword
          }
        }).then(res => {
          this.distributePayload(res)
        })
      } else {
        this.changeStatus(this.activeStatusName, 0)
      }
    },
    action() {
      this.toEditSiswa('add', 'tambah')
    },
    toEditSiswa(id, studentLmsClassId) {
      this.$router.push(`/user/international/${this.$route.params.programId}/edit/${this.$route.params.event}/${id}/${studentLmsClassId}/${this.$route.params.id}`)
    },
    changePage(page) {
      this.changeStatus(this.activeStatusName, page)
    }
  }
}
