import axios from 'axios'
import { endpoint } from '@/constant'
import { endpointFunction } from '@/util'

const partner = {
  addAgreement: requestBody => {
    const url = `${endpoint.agreement}/create`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  addPipeline: requestBody => {
    const url = `${endpoint.pipeline}/create`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  createAMAccount: requestBody => {
    const url = `${endpoint.accountManager}/register`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  createManagementAccount: requestBody => {
    const url = `${endpoint.management}/register`
    return axios.post(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  getAgreementById: id => {
    const url = `${endpoint.agreement}/get/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllAgreement: () => {
    const url = `${endpoint.agreement}/get-all`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllAgreementByAM: amId => {
    const url = `${endpoint.agreement}/get-byAM/${amId}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllAM: () => {
    const url = `${endpoint.accountManager}/get-all`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllManagement: () => {
    const url = `${endpoint.management}/get-all`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllPartners: () => {
    const url = `${endpoint.mitra}/get-all`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllPartnersPage: params => {
    const url = `${endpoint.mitra}/get-all-page${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllPartnersByAM: params => {
    const url = `${endpoint.mitra}/get-all-byAM${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllPipelines: () => {
    const url = `${endpoint.pipeline}/get-all`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllPipelinesByAM: amId => {
    const url = `${endpoint.pipeline}/get-byAM/${amId}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getAllPipelinesWithAllStatus: params => {
    const url = `${endpoint.pipeline}/get-all-status${endpointFunction.serializeQueryParams(params)}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getMitraById: id => {
    const url = `${endpoint.mitra}/get/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getPipelineById: id => {
    const url = `${endpoint.pipeline}/get/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getPipelineByMitra: id => {
    const url = `${endpoint.pipeline}/get-byMitra/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  getPipelineHistory: id => {
    const url = `${endpoint.pipeline}/pipelineLog/${id}`
    return axios.get(url, { headers: endpointFunction.getAuthHeader() })
  },
  updateAgreement: (id, requestBody) => {
    const url = `${endpoint.agreement}/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateAM: (id, params) => {
    const url = `${endpoint.mitra}/setAM/${id}${endpointFunction.serializeQueryParams(params)}`
    return axios.put(url, { headers: endpointFunction.getAuthHeader() })
  },
  updateAMAccount: (id, requestBody) => {
    const url = `${endpoint.accountManager}/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateDuplicate: id => {
    const url = `${endpoint.mitra}/setDuplicate/${id}`
    return axios.put(url, { headers: endpointFunction.getAuthHeader() })
  },
  updateManagementAccount: (id, requestBody) => {
    const url = `${endpoint.management}/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateNote: (id, requestBody) => {
    const url = `${endpoint.mitra}/setNotes/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateParkir: (id, requestBody) => {
    const url = `${endpoint.mitra}/setParkir/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updatePipeline: (id, requestBody) => {
    const url = `${endpoint.pipeline}/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updatePriority: (id, requestBody) => {
    const url = `${endpoint.mitra}/setPriority/${id}`
    return axios.put(url, requestBody, { headers: endpointFunction.getAuthHeader() })
  },
  updateUser: params => {
    const url = `${endpoint.mitra}/manageUsers${endpointFunction.serializeQueryParams(params)}`
    return axios.put(url, { headers: endpointFunction.getAuthHeader() })
  },
  uploadAgreementDocument: data => {
    const url = `${endpoint.files}`
    return axios.post(url, data, { headers: endpointFunction.getAuthAndMultipartHeader() })
  }
}

export default partner
